import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface DayMonthRadioGroupProps {
  selectedValue: string;
  onValueChange: (value: "daily" | "monthly") => void;
  sx?: FormControlProps["sx"];
}

const DayMonthRadioGroup: React.FC<DayMonthRadioGroupProps> = ({
  selectedValue,
  onValueChange,
  sx,
}) => {
  const { t } = useTranslation();

  const radioChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(event.target.value as "daily" | "monthly");
  };

  return (
    <FormControl sx={sx}>
      <FormLabel>{t("summarizeData")}</FormLabel>
      <RadioGroup
        row
        name="row-radio-buttons-group"
        value={selectedValue}
        onChange={radioChangeHandler}
      >
        <FormControlLabel
          value="daily"
          control={<Radio />}
          label={t("daily")}
        />
        <FormControlLabel
          value="monthly"
          control={<Radio />}
          label={t("monthly")}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default DayMonthRadioGroup;
