import React from "react";
import { Toolbar, Typography } from "@mui/material";
//import { IconButton } from "@mui/material";
//import NotificationsIcon from "@mui/icons-material/Notifications";
import { useAuth0 } from "@auth0/auth0-react";
import ProfileButton from "components/UI/ProfileButton";
//import NotificationsBadge from "components/UI/NotificationsBadge";
import { appBarTitle } from "utils/constants/Layout";
import LanguageSelector from "components/UI/LanguageSelector";
import StyledDesktopAppbar from "./StyledDesktopAppbar";

const DesktopAppBar: React.FC = (props) => {
  const { isAuthenticated, user } = useAuth0();

  return (
    <StyledDesktopAppbar>
      <Toolbar>
        <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
          {appBarTitle}
        </Typography>
        <LanguageSelector variant="standard" />
        {isAuthenticated && (
          <>
            {/* <IconButton
              color="inherit"
              aria-label="notifications"
              sx={{ mx: 1 }}
            >
              <NotificationsBadge>
                <NotificationsIcon />
              </NotificationsBadge>
            </IconButton> */}
            <ProfileButton name={user?.name} picture={user?.picture} />
          </>
        )}
      </Toolbar>
    </StyledDesktopAppbar>
  );
};

export default DesktopAppBar;
