import React from "react";
import { Toolbar, AppBar } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsBadge from "components/UI/NotificationsBadge";
import { useDrawer } from "hooks/use-drawer";
import { appBarTitle } from "utils/constants/Layout";
import LanguageSelector from "components/UI/LanguageSelector";

const MobileAppBar: React.FC = (props) => {
  const { toggleDrawer } = useDrawer();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={(theme) => ({
        background: theme.palette.frame,
        color: theme.palette.text.primary,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open or close side drawer"
          edge="start"
          onClick={toggleDrawer}
          sx={{ mr: 2 }}
        >
          <NotificationsBadge>
            <MenuIcon />
          </NotificationsBadge>
        </IconButton>

        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {appBarTitle}
        </Typography>
        <LanguageSelector variant="short" />
      </Toolbar>
    </AppBar>
  );
};

export default MobileAppBar;
