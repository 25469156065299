import React, { useContext } from "react";

import MultipleSelectChip from "components/UI/MultipleSelectChip";
import LocationFilterContext from "context/LocationFilterContext";
import { Location } from "types/Devices";
import { useTranslation } from "react-i18next";

interface LocationsFilterSelectProps {
  availableLocations: Location[];
}

const LocationsFilterSelect: React.FC<LocationsFilterSelectProps> = (props) => {
  const { setSelectedLocations } = useContext(LocationFilterContext);
  const { t } = useTranslation();
  const { availableLocations } = props;

  return (
    <MultipleSelectChip
      data={availableLocations}
      display={(location) => location.name}
      label={t("locations")}
      defaultLabel={t("allLocations")!}
      onSelectedItemsChange={(items) => setSelectedLocations(items)}
    />
  );
};

export default LocationsFilterSelect;
