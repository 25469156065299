import React from "react";
import { useNavigationItems } from "hooks/use-navigation-items";
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useMobileBreakpoint } from "hooks/use-breakpoint";
import { useDrawer } from "hooks/use-drawer";
import { usePathname } from "hooks/use-pathname";
import { drawerListItemHeight } from "utils/constants/Layout";

const ExpandedNavBar = () => {
  const navBarItemsGroups = useNavigationItems();
  const isMobile = useMobileBreakpoint();
  const { toggleDrawer } = useDrawer();
  const pathname = usePathname();

  const clickHandler = (action: () => void) => {
    action();

    if (isMobile) {
      toggleDrawer();
    }
  };

  return (
    <>
      {navBarItemsGroups.map((navBarItemsGroup) => {
        return (
          <React.Fragment key={navBarItemsGroup.id}>
            <List
              // disablePadding
              component="nav"
              sx={{
                "&& .Mui-selected, && .Mui-selected:hover": {
                  borderRight: 1,
                  borderColor: (theme) => theme.palette.primary.light,
                },
              }}
            >
              {navBarItemsGroup.header && (
                <ListSubheader
                  color="primary"
                  sx={{ pl: (theme) => theme.spacing(2.5) }}
                >
                  {navBarItemsGroup.header}
                </ListSubheader>
              )}
              {navBarItemsGroup.items.map((navBarItem) => {
                return (
                  <ListItemButton
                    // disableGutters
                    sx={{
                      height: (theme) => theme.spacing(drawerListItemHeight),
                    }}
                    key={navBarItem.id}
                    selected={
                      !isMobile && pathname === navBarItem.pageInfo?.path
                    }
                    onClick={() => clickHandler(navBarItem.clickAction)}
                  >
                    <ListItemIcon>{navBarItem.icon}</ListItemIcon>
                    <ListItemText primary={navBarItem.title} />
                  </ListItemButton>
                );
              })}
            </List>
            <Divider />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ExpandedNavBar;
