import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from "components/UI/LoadingSpinner";

interface RequireAuthProps {
  children: React.ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  loginWithRedirect({
    appState: { returnTo: window.location.pathname },
  });
  return <LoadingSpinner />;
};

export default RequireAuth;
