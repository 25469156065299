import React from "react";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";

type ChipData = {
  label: string;
  color: "error" | "warning" | "default";
  variant: "outlined" | "filled";
};

interface AgeChipProps {
  dataAge: number;
}

const AgeChip: React.FC<AgeChipProps> = ({ dataAge }) => {
  const { t } = useTranslation();

  const chipData = (): ChipData => {
    const errorAge = 10;
    const warningAge = 5;
    const defaultAge = 0;

    if (dataAge > errorAge) {
      return { label: "> 10 min", color: "error", variant: "filled" };
    }
    if (dataAge > warningAge) {
      return { label: "> 5 min", color: "warning", variant: "outlined" };
    }
    if (dataAge > defaultAge) {
      return { label: "> 2 min", color: "default", variant: "filled" };
    }

    return { label: t("upToDate"), color: "default", variant: "filled" };
  };

  const chipDataValue = chipData();

  return (
    <Chip
      label={chipDataValue.label}
      color={chipDataValue.color}
      size="small"
      variant={chipDataValue.variant}
      sx={{ px: 0.5 }}
    />
  );
};

export default AgeChip;
