import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme, Typography, Box, LinearProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DataTableSkeleton from "components/UI/DataTableSkeleton";
import { CustomFooter } from "./CustomFooter";
import { useAlarmsApiClient } from "hooks/use-api-client";
import NoAlarmsOverlay from "components/UI/NoAlarmsOverlay";
import AlarmStatusIcon from "features/dashboard/ActiveAlarmsCard/AlarmsDataTable/AlarmStatusIcon";
import { LogLevel } from "types/Alarms";
import { DataGridPro, GridCellParams, GridColDef } from "@mui/x-data-grid-pro";

const AlarmsDataTable = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const sizeOfFourTableRows = 317;
  const pageSize = 4;
  const [currentPage, setCurrentPage] = useState(0);
  const { getAlarms } = useAlarmsApiClient();
  const { data, isLoading, isFetching } = useQuery(
    ["AlarmsData", `Page: ${currentPage}`],
    () =>
      getAlarms({
        page: currentPage + 1,
        pageSize: pageSize,
        onlyOpen: true,
        logLevels: ["Error", "Critical"],
      }),
    { keepPreviousData: true, refetchInterval: 30000 }
  );

  const columns: GridColDef[] = [
    {
      field: "device",
      headerName: t("device")!,
      renderCell: (params: GridCellParams) => (
        <Typography noWrap variant="body2">
          {params.value.name}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: "createdOn",
      headerName: t("date")!,
      renderCell: (params: GridCellParams) => (
        <Typography fontSize={14}>
          {new Date(params.value).toLocaleString()}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: "errorCategory",
      headerName: t("message")!,
      renderCell: (params: GridCellParams) => {
        const logLevel = params.row.logLevel as LogLevel;
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <AlarmStatusIcon code={params.value} level={logLevel} />
          </Box>
        );
      },
      flex: 0.5,
    },
  ];

  return isLoading ? (
    <DataTableSkeleton columns={3} rows={4} />
  ) : (
    <DataGridPro
      localeText={theme.dataGridTranslations}
      components={{
        Footer: CustomFooter,
        NoRowsOverlay: NoAlarmsOverlay,
        LoadingOverlay: () => (
          <LinearProgress color="inherit" variant="indeterminate" />
        ),
      }}
      loading={isFetching}
      rows={data?.results || []}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={[pageSize]}
      pagination
      paginationMode="server"
      rowCount={data?.rowCount}
      page={currentPage}
      onPageChange={(newPage) => setCurrentPage(newPage)}
      disableSelectionOnClick
      sx={{
        height: sizeOfFourTableRows,
      }}
    />
  );
};

export default AlarmsDataTable;
