import { Card, CardContent, CardHeader } from "@mui/material";
import ErrorBoundary from "components/UI/ErrorBoundary";
import { useTranslation } from "react-i18next";
import EnergyFlowIllustration from "./EnergyFlowIllustration";

const EnergyFlowIllustrationCard = () => {
  const { t } = useTranslation();
  return (
    <Card variant="outlined">
      <CardHeader
        title={t("energyFlow")}
        titleTypographyProps={{ fontSize: 20, fontWeight: 500 }}
      />
      <CardContent>
        <ErrorBoundary>
          <EnergyFlowIllustration />
        </ErrorBoundary>
      </CardContent>
    </Card>
  );
};

export default EnergyFlowIllustrationCard;
