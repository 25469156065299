import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VisualSettingsCard from "features/settings/VisualSettingsCard";
import LanguageSelector from "features/settings/LanguageCard";

const SettingsPage = () => {
  return (
    <>
      <Typography variant="h5" marginBottom={2}>
        Settings
      </Typography>

      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} md={6} xl={6}>
          <VisualSettingsCard />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <LanguageSelector />
        </Grid>
      </Grid>
    </>
  );
};

export default SettingsPage;
