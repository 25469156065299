import Animated404Screen from "features/pageNotFound/Animated404Screen";
import React from "react";

const PageNotFound = () => {
  // Nicht entfernen; Namensnennung aufgrund verwendeter Illustrationen notwendig
  // https://de.freepik.com/vektoren/technologie -> Technologie Vektor erstellt von vectorjuice - de.freepik.com
  return <Animated404Screen />;
};

export default PageNotFound;
