import { Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldOutput from "../TextFieldOutput";

interface ProfileBlockTextItemProps {
  label: string;
  value: string;
  loading?: boolean;
  error?: Error | null;
}

const ProfileBlockTextItem: React.FC<ProfileBlockTextItemProps> = ({
  error,
  loading,
  value,
  label,
}) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (error !== undefined && error !== null) {
      setHasError(true);
    }
  }, [error]);

  return (
    <Grid item xs={12} my={2} mx={"2%"}>
      {loading ? (
        <Skeleton variant="text" />
      ) : (
        <TextFieldOutput
          error={error ? hasError : false}
          label={label}
          value={hasError ? error?.toString() : value}
        ></TextFieldOutput>
      )}
    </Grid>
  );
};

export default ProfileBlockTextItem;
