import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Skeleton,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { TimeseriesDatabase } from "types/Measurements";
import InfluxDataDisplay from "./InfluxDataDisplay";
import CreateInfluxBucketForm from "./CreateInfluxBucketForm";
import { useAuth0 } from "@auth0/auth0-react";
import { useMeasurementsApiClient } from "hooks/use-api-client";

const InfluxBucketCard = () => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const { getTimeseriesDatabases } = useMeasurementsApiClient();

  const { isLoading, data } = useQuery(["bucketData"], getTimeseriesDatabases);
  const bucketData: TimeseriesDatabase | undefined = data?.results[0];

  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        title={<Typography variant="h6">{t("influxData")}</Typography>}
      />
      <CardContent>
        {isLoading ? (
          <Skeleton variant="text" width={"100%"} height={"15rem"} />
        ) : bucketData ? (
          <InfluxDataDisplay bucketData={bucketData} />
        ) : (
          <>
            <Alert severity="warning" variant="outlined" sx={{ mb: 2 }}>
              {t("noAvailableBucket")}
            </Alert>
            <CreateInfluxBucketForm orgId={user?.org_id} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default InfluxBucketCard;
