import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from "react-error-boundary";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useLocation } from "react-router-dom";

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Alert
        severity="error"
        action={
          <Button endIcon={<RefreshIcon />} onClick={resetErrorBoundary}>
            {t("retryLoading")}
          </Button>
        }
      >
        <AlertTitle>{t("errorBoundaryFallbackText")}</AlertTitle>
        <Typography>{error.message}</Typography>
      </Alert>
    </Box>
  );
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const location = useLocation();

  return (
    <ReactErrorBoundary
      key={location.pathname}
      FallbackComponent={ErrorFallback}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
