import {
  GridEventListener,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { UseMutateFunction } from "@tanstack/react-query";

export const handleRowEditStart = (
  params: GridRowParams,
  event: MuiEvent<React.SyntheticEvent>
) => {
  event.defaultMuiPrevented = true;
};

export const handleRowEditStop: GridEventListener<"rowEditStop"> = (
  params,
  event
) => {
  event.defaultMuiPrevented = true;
};

export const handleSaveClick =
  (
    id: GridRowId,
    setRowModesModel: (row: GridRowModesModel) => void,
    rowModesModel: GridRowModesModel
  ) =>
  () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

export const handleDeleteClick =
  (
    id: GridRowId,
    mutateDelete: UseMutateFunction<unknown, unknown, string, unknown>
  ) =>
  () => {
    mutateDelete(id as string);
  };

export const handleCancelClick =
  (
    id: GridRowId,
    setRowModesModel: (row: GridRowModesModel) => void,
    rowModesModel: GridRowModesModel,
    rows: readonly GridValidRowModel[],
    setNewRow: (newRow: GridRowsProp | undefined) => void,
    setPageSize: React.Dispatch<React.SetStateAction<number>>,
    setNewRowTouched: React.Dispatch<React.SetStateAction<boolean>>,
    pageIsFull: boolean
  ) =>
  () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      pageIsFull && setPageSize((oldSize) => oldSize - 1);
      setNewRow(undefined);
      setNewRowTouched(false);
    }
  };

export const handleEditClick =
  (
    id: GridRowId,
    setRowModesModel: (row: GridRowModesModel) => void,
    rowModesModel: GridRowModesModel
  ) =>
  () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
