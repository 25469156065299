import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

interface ProfileBlockProps {
  heading?: string;
  children?: React.ReactNode;
}

const ProfileBlock: React.FC<ProfileBlockProps> = (props) => {
  return (
    <>
      <Grid item xs={12} sm={6} marginTop={6} marginBottom={4}>
        {props.heading && (
          <Typography
            sx={{
              textAlign: {
                xs: "center",
                sm: "left",
              },
              fontSize: {
                xs: "1.5rem",
                sm: "1.5rem",
              },
              marginLeft: {
                sm: "2%",
              },
            }}
          >
            {props.heading}
          </Typography>
        )}
        <Divider />
        {props.children}
      </Grid>
    </>
  );
};

export default ProfileBlock;
