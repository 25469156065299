import React from "react";
import { format, fromUnixTime } from "date-fns";
import { toUnixTimestamp } from "services/date-helpers";
import { useTheme, Stack, Typography } from "@mui/material";
import DataArrayIcon from "@mui/icons-material/DataArray";
import {
  BarChart,
  Bar,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import {
  calculateUnitScale,
  getMinMaxStats,
  calculateYTicksAndDomain,
} from "services/chart-helpers";
import { AxisDomain } from "recharts/types/util/types";
import { ChartDateType } from "types/Common";
import { useTranslation } from "react-i18next";
import { DataPoint, MeasurementUnit } from "types/Measurements";
import { createMeasurementUnitHandler } from "services/measurement-unit-handlers";

// Todo: add tooltips

const convertDataPoints = (
  dataPoints: DataPoint[],
  conversionFunc: (v: number) => number
) => {
  return dataPoints.map((dp) => {
    return {
      value: conversionFunc(dp.value),
      timestamp: dp.timestamp,
    };
  });
};

const dateFormatter = (timestamp: number, dateFormat: string) => {
  return format(fromUnixTime(timestamp), dateFormat);
};

export type ChartVariant = "bar" | "area" | "line";

export interface TimeAxisChartProps {
  dataPoints: DataPoint[];
  dateFormat: ChartDateType;
  variant: ChartVariant;
  measurementUnit: MeasurementUnit;
}

const TimeAxisChart: React.FC<TimeAxisChartProps> = (props) => {
  const unitHandler = createMeasurementUnitHandler(props.measurementUnit);

  const theme = useTheme();
  const { t } = useTranslation();

  const transformData = (dataPoints: DataPoint[], divideBy: number) => {
    return dataPoints.map((dataPoint) => {
      return {
        x: toUnixTimestamp(new Date(dataPoint.timestamp)),
        value: dataPoint.value / divideBy,
      };
    });
  };

  const { dataPoints, dateFormat, variant } = props;

  if (!dataPoints || dataPoints.length === 0) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
        spacing={2}
      >
        <DataArrayIcon color="disabled" sx={{ fontSize: 48 }} />
        <Typography color="InactiveCaptionText" sx={{ fontSize: 24 }}>
          {t("noDataForRequestedDateRange")}
        </Typography>
      </Stack>
    );
  }

  const convertedDataPoints = convertDataPoints(
    dataPoints,
    unitHandler.convertFromBaseValue
  );

  const minMaxStats = getMinMaxStats(convertedDataPoints);

  const unitScale = calculateUnitScale(minMaxStats.absMax, unitHandler.symbol);

  const { yTicks, yDomain } = calculateYTicksAndDomain(
    minMaxStats,
    unitScale.divideBy
  );

  const series = transformData(convertedDataPoints, unitScale.divideBy);

  const cartesianGrid = <CartesianGrid strokeDasharray="3 3" />;

  let domain: AxisDomain | undefined;
  let type: "number" | "category";

  switch (variant) {
    case "bar":
      type = "category";
      domain = undefined;
      break;

    case "area":
    case "line":
    default:
      type = "number";
      const start = dataPoints[0].timestamp;
      const end = series[series.length - 1].x;
      domain = [start, end];
  }

  const xAxis = (
    <XAxis
      dataKey="x"
      tickFormatter={(value) => dateFormatter(value, dateFormat)}
      domain={domain}
      fontSize={14}
      type={type}
      tick={{ fill: theme.palette.text.primary }}
    />
  );
  const yAxis = (
    <YAxis
      fontSize={14}
      interval="preserveEnd"
      ticks={yTicks}
      domain={yDomain}
      unit={unitScale?.unit}
      tick={{ fill: theme.palette.text.primary }}
    />
  );

  let chart: JSX.Element;
  const margin = { left: 10, right: 10 };

  switch (variant) {
    case "bar":
      chart = (
        <BarChart data={series} margin={margin}>
          {cartesianGrid}
          {xAxis}
          {yAxis}
          {/* <Tooltip /> */}
          <Bar
            dataKey="value"
            type="monotone"
            strokeOpacity={0}
            fill={theme.palette.chartPrimary.main}
            opacity={0.6}
          />
        </BarChart>
      );
      break;

    case "area":
      chart = (
        <AreaChart data={series} margin={margin}>
          {cartesianGrid}
          {xAxis}
          {yAxis}
          {/* <Tooltip /> */}
          <Area
            type="monotone"
            dataKey="value"
            strokeOpacity={0}
            fill={theme.palette.chartPrimary.main}
          />
        </AreaChart>
      );
      break;

    case "line":
    default:
      chart = (
        <LineChart data={series} margin={margin}>
          {cartesianGrid}
          {xAxis}
          {yAxis}
          {/* <Tooltip /> */}
          <Line
            type="monotone"
            dataKey="value"
            dot={false}
            stroke={theme.palette.chartPrimary.main}
            strokeWidth={2}
          />
        </LineChart>
      );
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      {chart}
    </ResponsiveContainer>
  );
};

export default TimeAxisChart;
