import React from "react";
import { CssBaseline, Box, Grid, useTheme } from "@mui/material";
import ResponsiveDrawer from "./ResponsiveDrawer";
import ResponsiveAppBar from "./ResponsiveAppBar";
import Main from "./Main";
import { DrawerContextProvider } from "context/DrawerContext";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import ErrorBoundary from "components/UI/ErrorBoundary";

const Layout: React.FC = (props) => {
  const theme = useTheme();
  const footerHeight = theme.layoutContainerHeight.footer;
  return (
    <CssBaseline enableColorScheme>
      <DrawerContextProvider>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                minHeight: `calc(100vh - ${footerHeight.height}px - ${footerHeight.spacing}px)`,
              }}
            >
              <ResponsiveAppBar />
              <ResponsiveDrawer />
              <Main>
                <ErrorBoundary>
                  <Outlet />
                </ErrorBoundary>
              </Main>
            </Grid>
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </Box>
      </DrawerContextProvider>
    </CssBaseline>
  );
};

export default Layout;
