export const hexToDec = (hex: string) => parseInt(hex, 16);

export const rgbaToRgb = (
  r: number,
  g: number,
  b: number,
  a: number,
  r2: number,
  g2: number,
  b2: number
) => {
  const r3 = Math.round((1 - a) * r2 + a * r);
  const g3 = Math.round((1 - a) * g2 + a * g);
  const b3 = Math.round((1 - a) * b2 + a * b);
  return "rgb(" + r3 + "," + g3 + "," + b3 + ")";
};

export const addOpacity = (color: string, opacity: number) => {
  if (opacity > 1 || opacity < 0) {
    throw new Error("Opacity must be between 0 and 1");
  }
  const roundedOpacity = Math.round(opacity * 255);
  return color + roundedOpacity.toString(16).toUpperCase();
};
