import { SvgIcon, useTheme } from "@mui/material";
import React from "react";

const AnimatableLogoutIcon = () => {
  const theme = useTheme();
  const color = theme.palette.text.primary;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ width: "5rem", position: "absolute", left: -5 }}
    >
      <path
        fill={color}
        className="logoutArrowAnimation"
        d="m17 8-1.41 1.41L17.17 11H9v2h8.17l-1.58 1.58L17 16l4-4-4-4z"
      ></path>
      <path
        fill={color}
        d="M5 5h7V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7v-2H5V5z"
      ></path>
    </SvgIcon>
  );
};

export default AnimatableLogoutIcon;
