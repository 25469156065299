import React, { useState } from "react";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import StateIndicator from "../StateIndicator";
import AgeIndicator from "../AgeIndicator";
import { useTheme, Tooltip } from "@mui/material";
import DataTableSkeleton from "components/UI/DataTableSkeleton";
import { useApiClient } from "hooks/use-api-client";
import WarningIcon from "@mui/icons-material/Warning";
import { WattConverter } from "utils/helpers/unit-helpers";

const EnergyFlowDataTable: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getEnergyFlowData } = useApiClient();
  const [hasMissingValues, setHasMissingValues] = useState(false);
  const wattConverter = new WattConverter();

  const { data, isLoading } = useQuery(["EnergyFlowData"], getEnergyFlowData, {
    refetchInterval: 30000,
  });

  const rows = data?.map((device) => {
    let hasMissingValue = false;
    let energyFlowMeasurementSum = 0;
    let oldestTimestamp: number | undefined;

    for (let i = 0; i < device.measurements.length; i++) {
      const currentMeasurement = device.measurements[i];

      if (currentMeasurement === null) {
        hasMissingValue = true;
        if (!hasMissingValues) {
          setHasMissingValues(true);
        }
      } else {
        const currentTimestamp = new Date(
          currentMeasurement.timestamp
        ).getTime();
        energyFlowMeasurementSum += currentMeasurement.value;

        oldestTimestamp = oldestTimestamp
          ? currentTimestamp < oldestTimestamp
            ? currentTimestamp
            : oldestTimestamp
          : currentTimestamp;
      }
    }

    return {
      id: device.device.id,
      displayName: device.device.name,
      state: {
        deviceType: device.device.kind,
        energyFlowRole: device.energyFlowRole,
        value: energyFlowMeasurementSum,
      },
      age: oldestTimestamp,
      power: energyFlowMeasurementSum,
      hasMissingValue: hasMissingValue,
    };
  });

  const columns: GridColDef[] = [
    {
      field: "displayName",
      headerName: t("device")!,
      flex: 1.3,
      // renderCell: (params: GridCellParams) => (
      //   <Link color="inherit" onClick={() => console.log("hello from device")}>
      //     {params.value}
      //   </Link>
      // ),
    },
    {
      field: "state",
      headerName: t("state")!,
      renderCell: (params: GridCellParams) =>
        params.value && <StateIndicator state={params.value} />,
      flex: 0.7,
    },
    {
      field: "age",
      headerName: t("age")!,
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <AgeIndicator age={new Date(params.value).getTime()} />
        ) : (
          t("noData")
        ),
      flex: 1,
    },
    {
      field: "power",
      headerName: t("power")!,
      flex: 0.7,
      renderCell: (params: GridCellParams) => {
        const value = wattConverter.convertFromBaseUnit(params.value, "W");
        return `${value.value} ${value.unit}`;
      },
    },
  ];

  if (hasMissingValues) {
    columns.push({
      field: "hasMissingValue",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridCellParams) =>
        params.value && (
          <Tooltip title={t("hasMissingValue")}>
            <WarningIcon color="warning" />
          </Tooltip>
        ),
      flex: 0.2,
    });
  }

  return isLoading ? (
    <DataTableSkeleton columns={3} rows={7} />
  ) : (
    <DataGrid
      localeText={theme.dataGridTranslations}
      rows={rows || []}
      columns={columns}
      pageSize={7}
      rowsPerPageOptions={[7]}
      autoHeight
      disableSelectionOnClick
      onRowClick={(params) => {}}
    />
  );
};

export default EnergyFlowDataTable;
