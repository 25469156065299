import {
  Card,
  CardHeader,
  Box,
  Typography,
  Tooltip,
  Icon,
  CardActions,
  Button,
  Chip,
  CardContent,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import AnimatedSettingsButton from "components/AnimatedIcons/AnimatedSettingsButton";
import { Location } from "types/Devices";
import MockChargerCarSvg from "./MockChargerCarSvg";
import Paper from "@mui/material/Paper";
import { addOpacity } from "utils/helpers/converters";
import Divider from "@mui/material/Divider";
import { useEffect, useMemo, useState } from "react";
import { roundDecimalPlaces } from "utils/helpers/math-helpers";
import { useQuery } from "@tanstack/react-query";

const getInitialMockData = () => {
  const isCarCharging = Math.random() > 0.5;
  const batteryLevel = Math.floor(Math.random() * (20 - 10 + 1) + 10);
  const licensePlate = `VEC-ST ${Math.floor(
    Math.random() * (999 - 100 + 1) + 100
  )}`;
  const chargingFinishedTimestamp = new Date().getTime();

  return {
    isCarCharging,
    batteryLevel,
    licensePlate,
    chargingFinishedTimestamp,
  };
};

const simulateData = (
  isCarCharging: boolean,
  batteryLevel: number,
  chargingFinishedTimestamp: number
) => {
  const currentTimestamp = new Date().getTime();
  const power = roundDecimalPlaces(Math.random() * (155 - 145 + 1) + 145, 1);

  if (isCarCharging) {
    if (Math.random() <= 0.6) {
      batteryLevel += 1;
    }

    if (batteryLevel > 100) {
      batteryLevel = 100;
    }
  }

  if (isCarCharging && batteryLevel === 100) {
    isCarCharging = false;
    chargingFinishedTimestamp = currentTimestamp;
    batteryLevel = Math.floor(Math.random() * (20 - 10 + 1) + 10);
  }

  if (!isCarCharging && currentTimestamp - chargingFinishedTimestamp > 120000) {
    isCarCharging = true;
  }

  return {
    isCarCharging,
    power,
    batteryLevel,
    chargingFinishedTimestamp,
  };
};

interface MockChargerProps {
  name: string;
  description?: string;
}

const MockCharger: React.FC<MockChargerProps> = ({ name, description }) => {
  const { t } = useTranslation();
  const location: Location = {
    id: "12345",
    name: "Visbek",
    latitude: 52.8348155,
    longitude: 8.3117064,
    organizationId: "org_1QZvsjXyTIUwiMfD",
  };
  const initialData = useMemo(getInitialMockData, []);
  const [batteryLevel, setBatteryLevel] = useState(initialData.batteryLevel);
  const [chargingFinishedTimestamp, setChargingFinishedTimestamp] = useState(
    initialData.chargingFinishedTimestamp
  );
  const [isCarCharging, setIsCarCharging] = useState(initialData.isCarCharging);

  const { data } = useQuery(
    ["mockCharger", name],
    () => simulateData(isCarCharging, batteryLevel, chargingFinishedTimestamp),
    {
      refetchInterval: 4000,
    }
  );

  useEffect(() => {
    if (data) {
      setBatteryLevel(data.batteryLevel);
      setChargingFinishedTimestamp(data.chargingFinishedTimestamp);
      setIsCarCharging(data.isCarCharging);
    }
  }, [data]);

  const content = isCarCharging ? (
    <>
      {data && (
        <>
          <Paper
            sx={{
              padding: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
              background: (theme) =>
                addOpacity(theme.palette.background.paper, 0.8),
            }}
            variant="outlined"
          >
            <Typography variant={"h6"} color="text.secondary">
              {t("power")}
            </Typography>
            <Divider flexItem />
            <Typography variant={"body1"} sx={{ mt: 1 }}>
              {`${data.power} kW`}
            </Typography>
          </Paper>
          <Paper
            sx={{
              padding: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
              background: (theme) =>
                addOpacity(theme.palette.background.paper, 0.8),
            }}
            variant="outlined"
          >
            <Typography variant={"h6"} color="text.secondary">
              {t("stateofcharge")}
            </Typography>
            <Divider flexItem />
            <Typography variant={"body1"} sx={{ mt: 1 }}>
              {`${data.batteryLevel} %`}
            </Typography>
          </Paper>
        </>
      )}
    </>
  ) : (
    <Paper
      sx={{
        padding: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        background: (theme) => addOpacity(theme.palette.background.paper, 0.8),
      }}
      variant="outlined"
    >
      <Typography variant={"h6"} color="text.secondary">
        {t("watingForCar")}
      </Typography>
    </Paper>
  );

  return (
    // <Grid item xs={12} lg={4}>
    <Card variant="outlined">
      <CardHeader
        title={
          <Box sx={{ display: "flex", alignItems: "center", maxWidth: "80%" }}>
            <Typography variant="h5">{name}</Typography>
            {description && (
              <Tooltip
                title={<Typography variant="body2">{description}</Typography>}
              >
                <Icon color="disabled" sx={{ ml: 1 }}>
                  <InfoOutlinedIcon />
                </Icon>
              </Tooltip>
            )}
          </Box>
        }
      />

      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              flex: 1,
              height: 83,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {content}
          </Box>
          <Box
            sx={{
              height: 83,
              flex: 0.6,
            }}
          >
            <MockChargerCarSvg
              isCarCharging={isCarCharging}
              batteryLevel={batteryLevel}
              numberPlate={initialData.licensePlate}
            />
          </Box>
        </Box>
      </CardContent>

      <CardActions>
        <Button
          size="small"
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {t("details")}
        </Button>
        <AnimatedSettingsButton />
        {location && (
          <Chip
            label={location.name}
            variant="outlined"
            size="small"
            sx={{ ml: 1 }}
          />
        )}
      </CardActions>
    </Card>
    // </Grid>
  );
};

export default MockCharger;
