import React, { useState } from "react";
import { Grid } from "@mui/material";
import DeviceDetailsBreadcrumbs from "features/devices/DeviceDetailsBreadcrumbs";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  useDevicesApiClient,
  useMeasurementsApiClient,
} from "hooks/use-api-client";
import DeviceDetailsFilterCard from "features/devices/DeviceDetailsFilterCard";
import { getDateRangeUntilNow } from "services/date-helpers";
import ChartSkeleton from "./ChartSkeleton";
import NoTimeseriesData from "features/devices/NoTimeseriesData";
import TimeAxisChartCard from "components/UI/TimeAxisChartCard";
import { useTranslation } from "react-i18next";
import { Device } from "types/Devices";
import { TimeseriesData } from "types/Measurements";
import { distinctBy, contains, createRange } from "utils/helpers/array-helpers";
import { DateRange } from "@mui/x-date-pickers-pro";

const getDeviceOptions = (timeseriesData?: TimeseriesData[]): Device[] => {
  const deviceOptions =
    timeseriesData
      ?.filter((d) => d.metadata.device !== undefined)
      .map((d) => d.metadata.device!) ?? [];

  return distinctBy(deviceOptions, (d) => d.id);
};

interface RenderIfProps {
  condition: boolean;
  children: React.ReactNode;
}

const RenderIf: React.FC<RenderIfProps> = ({ condition, children }) => {
  if (!condition) {
    return null;
  }

  return <>{children}</>;
};

const DeviceDetailsPage = () => {
  const params = useParams<"deviceId">();
  const deviceId = params.deviceId!;

  const { t } = useTranslation();

  const toTitle = (metadata: { field: string; customFieldName?: string }) => {
    if (metadata.customFieldName) {
      return metadata.customFieldName;
    }

    return t(metadata.field);
  };

  const initialRange = getDateRangeUntilNow(7);

  const [range, setRange] = useState<DateRange<Date>>(initialRange);

  const [selectedDeviceOptions, setSelectedDeviceOptions] = useState<
    Device[] | null
  >(null);

  const { getDeviceById } = useDevicesApiClient();
  const deviceQuery = useQuery(["getDeviceById", deviceId], () =>
    getDeviceById(deviceId)
  );

  const { getTimeseriesDataInfoByDevice, getTimeseriesDataByDevice } =
    useMeasurementsApiClient();

  const { isLoading: isLoadingInfos, data: infos } = useQuery(
    ["getTimeseriesDataInfoByDevice", deviceId],
    () => getTimeseriesDataInfoByDevice(deviceId)
  );

  const from = range[0]!;
  const to = range[1]!;

  const { isLoading: isLoadingData, data } = useQuery({
    queryKey: ["getTimeseriesDataByDevice", deviceId, from, to],
    queryFn: () => getTimeseriesDataByDevice(deviceId, from, to),
    enabled: !isLoadingInfos && !!infos,
  });

  let minDate: Date | undefined;
  const maxDate = new Date();

  if (!isLoadingInfos && infos) {
    minDate = infos.earliestTimestamp
      ? new Date(infos.earliestTimestamp)
      : undefined;
  }

  const chartSkeletonIndexes = createRange(6);

  const noTimeseriesData = !!infos && infos.nonEmptyTimeseriesCount === 0;

  let content: JSX.Element;

  if (noTimeseriesData) {
    content = (
      <Grid xs={12}>
        <NoTimeseriesData />
      </Grid>
    );
  } else {
    const deviceOptions = getDeviceOptions(data);

    content = (
      <>
        <Grid item xs={12}>
          <DeviceDetailsFilterCard
            loading={isLoadingInfos}
            minDate={minDate}
            maxDate={maxDate}
            onFilterChanged={(state) => {
              if (state.rangeFilter) {
                setRange(state.rangeFilter.range);
              }
              if (state.devicesFilter) {
                setSelectedDeviceOptions(
                  state.devicesFilter.selectedDeviceOptions
                );
              }
            }}
            range={range}
            deviceOptions={deviceOptions}
            selectedDeviceOptions={selectedDeviceOptions}
          />
        </Grid>
        <RenderIf condition={isLoadingInfos || isLoadingData}>
          {chartSkeletonIndexes.map((i) => {
            return (
              <Grid key={i} item xs={12} sm={6} lg={4}>
                <ChartSkeleton />
              </Grid>
            );
          })}
        </RenderIf>

        <RenderIf condition={!isLoadingData && !!data}>
          {data
            ?.filter(
              (x) =>
                selectedDeviceOptions === null ||
                contains(
                  selectedDeviceOptions,
                  (d) => d.id === x.metadata?.device?.id
                )
            )
            .map((x) => {
              return (
                <Grid key={x.metadata.deviceId} item xs={12} md={6} lg={4}>
                  <TimeAxisChartCard
                    dataPoints={x.dataPoints}
                    title={toTitle(x.metadata)}
                    measurementUnit={x.metadata.unit}
                    dateFormat="dd/MMM HH:mm"
                    variant="line"
                    deviceName={x.metadata.device?.name}
                  />
                </Grid>
              );
            })}
        </RenderIf>
      </>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DeviceDetailsBreadcrumbs
          deviceId={deviceId!}
          loading={deviceQuery.isLoading}
          content={deviceQuery.data?.name}
          variant="details"
        />
      </Grid>
      {content}
    </Grid>
  );
};

export default DeviceDetailsPage;
