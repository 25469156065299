import React, { useContext } from "react";
import { Box } from "@mui/material";
import logoColorSvg from "assets/logo.svg";
import logoWhiteSvg from "assets/logoWhite.svg";
import logoBlackSvg from "assets/logoBlack.svg";
import ThemeContext from "context/ThemeContext";

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const getImage = (variant: string, mode: string) => {
  const logoColored = { logo: logoColorSvg, alt: "colored" };
  const logoBlack = { logo: logoBlackSvg, alt: "black" };
  const logoWhite = { logo: logoWhiteSvg, alt: "white" };

  const image =
    variant === "colored"
      ? mode === "light"
        ? logoColored
        : logoWhite
      : mode === "light"
      ? logoBlack
      : logoWhite;

  return {
    logo: image.logo,
    alt: `${image.alt} Schulz Systemtechnik GmbH logo`,
  };
};

interface SchulzLogoProps {
  variant: "monotone" | "colored";
}

const SchulzLogo: React.FC<SchulzLogoProps> = (props) => {
  const themeContext = useContext(ThemeContext);
  const mode = themeContext.currentTheme;

  const image = getImage(props.variant, mode);

  return (
    <Box
      sx={{ width: "100%", height: "100%" }}
      component="img"
      onClick={() => openInNewTab("https://schulz.st")}
      src={image.logo}
      alt={image.alt}
    />
  );
};

export default SchulzLogo;
