import React from "react";
import { Card, CardHeader, CardContent, useTheme } from "@mui/material";
import ErrorBoundary from "components/UI/ErrorBoundary";
import { useTranslation } from "react-i18next";
import AlarmsTable from "features/alarms/AlarmsTable";

const Alarms = () => {
  const { t } = useTranslation();
  const { layoutContainerHeight } = useTheme();
  const mainContainerHeight = `calc(100vh - ${layoutContainerHeight.header.height}px - ${layoutContainerHeight.header.spacing}px - ${layoutContainerHeight.footer.height}px - ${layoutContainerHeight.footer.spacing}px)`;

  return (
    <Card variant="outlined" sx={{ height: mainContainerHeight }}>
      <CardHeader
        title={t("allAlarms")}
        titleTypographyProps={{ fontSize: 20, fontWeight: 500 }}
      />
      <CardContent sx={{ height: "100%" }}>
        <ErrorBoundary>
          <AlarmsTable />
        </ErrorBoundary>
      </CardContent>
    </Card>
  );
};

export default Alarms;
