import React, { useEffect, useRef, useState } from "react";
import { Device, LiveSummaryMeasurement } from "types/Devices";
import { Box, CardContent } from "@mui/material";
import { useDevicesApiClient } from "hooks/use-api-client";
import { useQuery } from "@tanstack/react-query";
import PhotovoltaicSvg from "./svg/PhotovoltaicSvg";
import GridConnectionSvg from "./svg/GridConnectionSvg";
import ChargePointSvg from "./svg/ChargePointSvg";
import BessContainerSvg from "./svg/BessContainerSvg";
import InverterSvg from "./svg/InverterSvg";
import ElectricMeterSvg from "./svg/ElectricMeterSvg";
import ConsumerSvg from "./svg/ConsumerSvg";
import AnimatedBatteryLarge from "components/AnimatedIcons/AnimatedBatteryLarge";
import { DeviceType } from "types/Common";
import DeviceCardContentSkeleton from "./DeviceCardContentSkeleton";
import MeasurementDisplay from "./MeasurementDisplay";
import { useTranslation } from "react-i18next";
import { checkAndSetErrors } from "./checkAndSetErrors";

interface DeviceCardContentProps {
  device: Device;
  onDataError: (errorMessage: string | null) => void;
}

const DeviceCardContent: React.FC<DeviceCardContentProps> = ({
  device,
  onDataError,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>();
  const [heightOfContent, setHeightOfContent] = useState<number | undefined>(0);
  const { getDeviceLiveSummary } = useDevicesApiClient();
  const { data, isLoading } = useQuery(
    [`getDeviceLiveSummary-${device.id}`],
    () => getDeviceLiveSummary(device.id),
    { refetchInterval: 30000, staleTime: 30000 }
  );

  useEffect(() => {
    if (!isLoading) {
      checkAndSetErrors(data as LiveSummaryMeasurement[], onDataError, t);
      setHeightOfContent(ref?.current?.clientHeight);
    }
  }, [ref?.current?.clientHeight, isLoading, data, onDataError, t]);

  const getIllustrationByKindAndData = (
    kind: DeviceType,
    data?: LiveSummaryMeasurement[]
  ) => {
    switch (kind) {
      case "Photovoltaic":
        return PhotovoltaicSvg;
      case "Battery":
        return () => {
          const soc = data?.find((device) => device.key === "stateofcharge")
            ?.measurement?.value;
          const power = data?.find((device) => device.key === "power")
            ?.measurement?.value;
          const charging = power ? power > 50 : undefined;
          return soc && power ? (
            <AnimatedBatteryLarge
              stateOfCharge={soc * 100}
              charging={charging}
            />
          ) : null;
        };
      case "GridConnection":
        return GridConnectionSvg;
      case "ChargePoint":
        return ChargePointSvg;
      case "BessContainer":
        return BessContainerSvg;
      case "Inverter":
        return InverterSvg;
      case "ElectricMeter":
        return ElectricMeterSvg;
      case "PgsController":
        return InverterSvg;
      case "Consumer":
        return ConsumerSvg;
      default:
        return null;
    }
  };

  const Illustration = getIllustrationByKindAndData(device.kind, data);

  const showMeasurements = !isLoading && data;

  const content = showMeasurements ? (
    data.map((data) => {
      // the combination of device ID and field (key) is guaranteed
      // to be unique accross all device cards and <MeasurementDisplay />s
      const uniqueKey = `${device.id}-${data.key}`;

      return <MeasurementDisplay data={data} key={uniqueKey} />;
    })
  ) : (
    <DeviceCardContentSkeleton />
  );

  return (
    <CardContent>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
            justifyContent: "space-evenly",
            alignContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {content}
        </Box>
        {Illustration && (
          <Box
            sx={{
              height: heightOfContent,
              flex: 0.3,
            }}
          >
            <Illustration />
          </Box>
        )}
      </Box>
    </CardContent>
  );
};

export default DeviceCardContent;
