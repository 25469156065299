import React, { useState } from "react";
import { useTheme, Typography, LinearProgress } from "@mui/material";
import { DataGridPro, GridColDef, GridCellParams } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import DataTableSkeleton from "components/UI/DataTableSkeleton";
import NoAlarmsOverlay from "components/UI/NoAlarmsOverlay";
import AlarmStatusIcon from "features/dashboard/ActiveAlarmsCard/AlarmsDataTable/AlarmStatusIcon";
import { useAlarmsApiClient } from "hooks/use-api-client";
import { useTranslation } from "react-i18next";
import { LogLevel } from "types/Alarms";

const AlarmsTable: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const { getAlarms } = useAlarmsApiClient();
  const { data, isLoading, isFetching } = useQuery(
    ["AlarmsData", `Page: ${currentPage}`, `PageSize: ${pageSize}`],
    () =>
      getAlarms({
        page: currentPage + 1,
        pageSize: pageSize,
        onlyOpen: false,
        logLevels: ["Error", "Critical"],
      }),

    { keepPreviousData: true, refetchInterval: 30000 }
  );

  const columns: GridColDef[] = [
    {
      field: "errorCode",
      headerName: t("id")!,
      flex: 0.5,
    },
    {
      field: "device",
      headerName: t("device")!,
      renderCell: (params: GridCellParams) => params.value.name,
      flex: 1,
    },
    {
      field: "errorCategory",
      headerName: t("message")!,
      renderCell: (params: GridCellParams) => {
        const logLevel = params.row.logLevel as LogLevel;
        return <AlarmStatusIcon code={params.value} level={logLevel} />;
      },
      flex: 0.5,
    },
    {
      field: "message",
      headerName: t("message")!,
      renderCell: (params: GridCellParams) => (
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "normal",
            wordWrap: "initial",
          }}
        >
          {params.value}
        </Typography>
      ),
      flex: 2,
    },
    {
      field: "createdOn",
      headerName: t("began")!,
      renderCell: (params: GridCellParams) =>
        new Date(params.value).toLocaleString(),
      flex: 1,
    },
    {
      field: "closedOn",
      headerName: t("ended")!,
      renderCell: (params: GridCellParams) =>
        params.value && new Date(params.value).toLocaleString(),
      flex: 1,
    },
  ];

  return (
    <>
      {isLoading ? (
        <DataTableSkeleton columns={6} rows={6} />
      ) : (
        <DataGridPro
          localeText={theme.dataGridTranslations}
          components={{
            NoRowsOverlay: NoAlarmsOverlay,
            LoadingOverlay: () => (
              <LinearProgress color="inherit" variant="indeterminate" />
            ),
          }}
          loading={isFetching}
          rows={data?.results || []}
          columns={columns}
          autoPageSize
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          paginationMode="server"
          rowCount={data?.rowCount}
          page={currentPage}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          disableSelectionOnClick
          sx={{
            height: "95%",
          }}
        />
      )}
    </>
  );
};

export default AlarmsTable;
