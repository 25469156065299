import { Card, CardHeader, Typography, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "components/UI/ErrorBoundary";
import LocationEditorTable from "./LocationEditorTable";

const LocationEditorCard = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={<Typography variant="h6">{t("locationManager")}</Typography>}
      />
      <CardContent>
        <ErrorBoundary>
          <LocationEditorTable />
        </ErrorBoundary>
      </CardContent>
    </Card>
  );
};

export default LocationEditorCard;
