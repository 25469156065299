import { PaletteMode } from "@mui/material";
import { grey, lightBlue } from "@mui/material/colors";

const getLightPalette = (mode: string) => ({
  mode: mode as PaletteMode,
  primary: {
    main: grey[600],
  },
  secondary: {
    main: "#666666",
  },
  background: {
    default: grey[50],
  },
  frame: grey[200],
  chips: {
    highlighted: grey[400],
  },
  chartPrimary: {
    main: lightBlue[700],
  },
  battery: {
    shell: "#ADB8C1",
    default: "#768898",
    low: "#768898",
  },
  stateIndicator: {
    paper: grey[300],
    default: grey[500],
  },
  customBackground: {
    selected: grey[400],
    hover: grey[300],
  },
  text: {
    primary: grey[900],
    secondary: grey[600],
    disabled: grey[400],
  },
  drawerIcon: grey[900],
  devicesPage: {
    graphicsOpacity: 0.6,
  },
  icons: {
    battery: "#9EDE70",
    photovoltaic: "#FFD02F",
    inverter: "#7700CE",
    default: "#0A479E",
  },
  energyFlowIllustration: {
    dots: "#BFBFBF",
    negativ: "#FF4666",
    positiv: "#7DD240",
  },
});

const getDarkPalette = (mode: string) => ({
  mode: mode as PaletteMode,
  primary: {
    main: grey[300],
  },
  secondary: {
    main: "#666666",
  },
  background: {
    paper: grey[800],
    default: "#303030",
  },
  frame: grey[900],
  chips: {
    highlighted: grey[600],
  },
  chartPrimary: {
    main: lightBlue[700],
  },
  battery: {
    shell: "#ADB8C1",
    default: "#768898",
    low: "#768898",
  },
  stateIndicator: {
    paper: grey[700],
    default: grey[500],
  },
  customBackground: {
    selected: grey[500],
    hover: grey[600],
  },
  text: {
    primary: grey[50],
    secondary: grey[300],
    disabled: grey[500],
  },
  drawerIcon: grey[300],
  devicesPage: {
    graphicsOpacity: 0.9,
  },
  icons: {
    battery: "#9EDE70",
    photovoltaic: "#FFD02F",
    inverter: "#7700CE",
    default: "#0A479E",
  },
  energyFlowIllustration: {
    dots: "#BFBFBF",
    negativ: "#CC7A00",
    positiv: "#089191",
  },
});

export const createThemePalette = (mode: string) => {
  const palette =
    mode === "light" ? getLightPalette(mode) : getDarkPalette(mode);

  return palette;
};
