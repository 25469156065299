import { Divider, Box } from "@mui/material";
import SchulzLogo from "components/UI/SchulzLogo";
import React from "react";

const MenuSectionHeader: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: (theme) =>
            `calc(${theme.drawerHeight.header}px - ${theme.drawerHeight.divider}px)`,
          pb: 1,
        }}
      >
        <SchulzLogo variant="monotone" />
      </Box>
      <Divider
        sx={{
          borderBottomWidth: (theme) => `${theme.drawerHeight.divider}px`,
        }}
      />
    </>
  );
};

export default MenuSectionHeader;
