import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardActions,
  Button,
  Chip,
  Typography,
  Icon,
  Box,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AnimatedSettingsButton from "components/AnimatedIcons/AnimatedSettingsButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Device, Location } from "types/Devices";
import DeviceCardContent from "features/devices/DeviceCard/DeviceCardContent";
import ErrorBoundary from "components/UI/ErrorBoundary";
import WarningIcon from "@mui/icons-material/Warning";

interface DeviceCardProps {
  name: string;
  description?: string;
  location?: Location;
  device: Device;
}

const DeviceCard: React.FC<DeviceCardProps> = ({
  name,
  description,
  location,
  device,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const getDataError = (errorMessage: string | null) => {
    setErrorMessage(errorMessage);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Box sx={{ display: "flex", alignItems: "center", maxWidth: "80%" }}>
            <Typography variant="h5">{name}</Typography>
            {description && (
              <Tooltip
                title={<Typography variant="body2">{description}</Typography>}
              >
                <Icon color="disabled" sx={{ ml: 1 }}>
                  <InfoOutlinedIcon />
                </Icon>
              </Tooltip>
            )}
          </Box>
        }
      />
      <ErrorBoundary>
        <DeviceCardContent device={device} onDataError={getDataError} />
      </ErrorBoundary>

      <CardActions>
        <Button
          size="small"
          onClick={() =>
            navigate(
              `/devices/${device.id}?returnUrl=/devices&returnLinkName=devices`
            )
          }
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {t("details")}
        </Button>
        <AnimatedSettingsButton
          onClick={() =>
            navigate(
              `/devices/${device.id}/edit?returnUrl=/devices&returnLinkName=devicePage`
            )
          }
        />
        {location && (
          <Chip
            label={location.name}
            variant="outlined"
            size="small"
            sx={{ ml: 1 }}
          />
        )}
        {errorMessage && (
          <Tooltip
            title={<Typography variant="body2">{errorMessage}</Typography>}
          >
            <Icon
              color="warning"
              sx={{ ml: 2, display: "flex", alignItems: "center" }}
            >
              <WarningIcon />
            </Icon>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};

export default DeviceCard;
