import { Alert } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const NoSelectedTab = () => {
  const { t } = useTranslation();
  return (
    <>
      <Alert severity="info" variant="outlined" sx={{ mb: 2, mx: 2 }}>
        {t("thisPageOnlyForAdmins")}
      </Alert>
    </>
  );
};

export default NoSelectedTab;
