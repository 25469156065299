import React, { useState } from "react";
import { useNavigate } from "react-router";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth0 } from "@auth0/auth0-react";

interface ProfileButtonProps {
  name?: string;
  picture?: string;
}

const ProfileButton: React.FC<ProfileButtonProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const navigate = useNavigate();

  const menuClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuCloseHandler = (e: any) => {
    setAnchorEl(null);
  };

  const linkClickHandler = (path: string) => {
    setAnchorEl(null);
    navigate(path);
  };

  const { name, picture } = props;

  const { logout } = useAuth0();

  const menuItems = [
    {
      id: "profile",
      icon: <PersonIcon />,
      text: "Profile",
      action: linkClickHandler.bind(this, "/profile"),
    },
    {
      id: "settings",
      icon: <SettingsIcon />,
      text: "Settings",
      action: linkClickHandler.bind(this, "/settings"),
      appendDiver: true,
    },
    {
      id: "logout",
      icon: <LogoutIcon />,
      text: "Sign Out",
      action: () =>
        logout({
          returnTo: `${window.location.origin}/`,
        }),
    },
  ];

  return (
    <>
      <IconButton
        onClick={menuClickHandler}
        id="profile-button"
        aria-controls="profile-menu"
        aria-haspopup={true}
        aria-expanded={isOpen ? true : undefined}
      >
        <Avatar
          alt={name}
          src={picture}
          sx={{ width: "42px", height: "42px" }}
        />
      </IconButton>
      <Menu
        sx={{ width: "20rem", maxWidth: "100%" }}
        id="profile-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={menuCloseHandler}
        MenuListProps={{
          "aria-labelledby": "profile-button",
        }}
      >
        <MenuList>
          {menuItems.map((item) => {
            return (
              <div key={item.id}>
                <MenuItem onClick={() => item.action()}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.text}</ListItemText>
                </MenuItem>
                {item.appendDiver && <Divider />}
              </div>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default ProfileButton;
