import Typography from "@mui/material/Typography";
import LoginButton from "features/auth/LoginButton";

const HomePage = () => {
  return (
    <>
      <Typography variant="h6">Welcome</Typography>
      <LoginButton />
    </>
  );
};

export default HomePage;
