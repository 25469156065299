import { useAuth0 } from "@auth0/auth0-react";
import ProfileBlock from "components/UI/ProfileBlock";
import ProfileBlockTextItem from "components/UI/ProfileBlockTextItem";
import React from "react";

const UserProfileBlock = () => {
  const { user } = useAuth0();

  return (
    <ProfileBlock heading="Account">
      <ProfileBlockTextItem
        label="Username"
        value={user?.nickname ?? ""}
        key={"da0bb68d-d7f1-4d56-a586-88f92a5c39bd"}
      />
      <ProfileBlockTextItem
        label="Email"
        value={user?.email ?? ""}
        key={"4d33e4ef-3d40-4066-879b-908cb77019c6"}
      />
      {/* <ProfileBlockTextItem
        label="Tel-Number"
        value={user?.phone_number ?? ""}
        key={"3941b2bd-386b-4fef-bca5-58ee4fa122c6"}
      /> */}
      <ProfileBlockTextItem
        label="Full name"
        value={user?.name ?? ""}
        key={"c2e7e931-a91b-4e30-916b-6e50300dd3e7"}
      />
      {/* <ProfileBlockTextItem
        label="Group"
        value=""
        key={"2d49f770-d503-4dd1-9b88-2fd7844350d4"}
      /> */}
    </ProfileBlock>
  );
};

export default UserProfileBlock;
