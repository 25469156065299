import { SvgIcon, useTheme } from "@mui/material";

interface MockChargerCarSvgProps {
  isCarCharging: boolean;
  batteryLevel: number;
  numberPlate: string;
}

const MockChargerCarSvg: React.FC<MockChargerCarSvgProps> = ({
  isCarCharging,
  batteryLevel,
  numberPlate,
}) => {
  const batteryLevelDisplay = (26 / 100) * batteryLevel;
  const theme = useTheme();

  return (
    <>
      <SvgIcon
        viewBox="0 0 530 220"
        sx={{
          overflow: "visible",
          height: "180%",
          width: "180%",
          display: "flex",
        }}
      >
        {isCarCharging && (
          <g
            id="car"
            style={{ transformOrigin: "center" }}
            transform="scale(1) translate(-90, 0)"
            opacity={theme.palette.devicesPage.graphicsOpacity}
          >
            <defs>
              <clipPath id="clipPath973">
                <path d="m0 500h500v-500h-500z" />
              </clipPath>
              <clipPath id="clipPath1053">
                <path d="m373.26 149.4h33.559v-48.957h-33.559z" />
              </clipPath>
              <clipPath id="clipPath1097">
                <path d="m234.22 149.4h33.56v-48.957h-33.56z" />
              </clipPath>
              <clipPath id="clipPath1133">
                <path d="m127.13 248.39h171.42v-46.007h-171.42z" />
              </clipPath>
              <clipPath id="clipPath1149">
                <path d="m143.28 179.46h74.254v-24.96h-74.254z" />
              </clipPath>
              <clipPath id="clipPath1165">
                <path d="m317.73 202.91h21.612v-93.361h-21.612z" />
              </clipPath>
              <clipPath id="clipPath1181">
                <path d="m70.69 164.16h74.041v-7.38h-74.041z" />
              </clipPath>
              <clipPath id="clipPath1197">
                <path d="m389.4 208.26h15.951v-45.156h-15.951z" />
              </clipPath>
              <clipPath id="clipPath1221">
                <path d="m50.769 169.56h22.829v-15.419h-22.829z" />
              </clipPath>
              <clipPath id="clipPath1237">
                <path d="m244.76 241.87h160.07v-39.49h-160.07z" />
              </clipPath>
            </defs>
            <g transform="matrix(1.3333 0 0 -1.3333 0 220)">
              <g transform="rotate(-90 225.15 284.71)" />
              <path d="m356.53 79.211v33.123h-0.25v-33.123z" fill="#ececec" />
              <path d="m362.56 27.126v51.084h-0.25v-51.084z" fill="#ececec" />
              <g fill="#65717b">
                <g transform="translate(226.19 78.737)">
                  <path
                    d="m0 0c-0.038 0-0.076-4e-3 -0.114-5e-3v5e-3h-30.686c-13.209 0-23.916-17.331-23.916-38.708 0-21.378 10.707-38.708 23.916-38.708h30.686v4e-3c0.038 0 0.076-4e-3 0.114-4e-3 13.208 0 23.917 17.33 23.917 38.708 0 21.377-10.709 38.708-23.917 38.708"
                    fill="#65717b"
                  />
                </g>
                <g transform="translate(87.154 78.737)">
                  <path
                    d="m0 0c-0.039 0-0.076-4e-3 -0.114-5e-3v5e-3h-30.686c-13.208 0-23.916-17.331-23.916-38.708 0-21.378 10.708-38.708 23.916-38.708h30.686v4e-3c0.038 0 0.075-4e-3 0.114-4e-3 13.208 0 23.917 17.33 23.917 38.708 0 21.377-10.709 38.708-23.917 38.708"
                    fill="#65717b"
                  />
                </g>
                <g transform="translate(381.12 108.13)">
                  <path
                    d="m0 0 7.018-6.03c3.321-2.854 4.771-7.323 3.758-11.582l-1.603-6.742z"
                    fill="#65717b"
                  />
                </g>
              </g>
              <g>
                <g transform="translate(393.85 68.839)" fill="#adb8c1">
                  <path
                    d="m0 0c-1.217 13.205-4.672 26.107-10.219 38.152l-2.39 5.19h-58.322l30.225-87c10.633 2.649 20.486 7.781 28.753 14.973l0.696 0.606c8.048 7.006 12.236 17.454 11.257 28.079"
                    fill="#adb8c1"
                  />
                </g>
                <g transform="translate(316.45 1.3205)" fill="#9ca9b4">
                  <path
                    d="m0 0c-13.209 0-23.917 17.331-23.917 38.708 0 21.378 10.708 38.709 23.917 38.709h30.686v-77.417z"
                    fill="#9ca9b4"
                  />
                </g>
                <path
                  d="m371.17 40.029c0 21.378-10.708 38.708-23.917 38.708-13.208 0-23.916-17.33-23.916-38.708s10.708-38.708 23.916-38.708c13.209 0 23.917 17.33 23.917 38.708"
                  fill="#9ca9b4"
                />
              </g>
              <g transform="translate(-42.789, -84.892)">
                <g opacity=".2">
                  <g transform="translate(406.82 124.92)">
                    <path d="m0 0c0 13.519-7.512 24.478-16.779 24.478-9.268 0-16.78-10.959-16.78-24.478s7.512-24.479 16.78-24.479c9.267 0 16.779 10.96 16.779 24.479" />
                  </g>
                </g>
              </g>
              <g>
                <g transform="translate(141.85 25.181)" fill="#adb8c1">
                  <path
                    d="m0 0-57.515 92.307c-0.01-8e-3 45.504 46.011 144.91 46.011 86.052 0 137.59-28.419 152-51.318l-34.108-33.444c-33.028 0-32.821-35.018-37.35-49.744-0.702-2.284-2.782-3.812-5.207-3.812h-162.73"
                    fill="#adb8c1"
                  />
                </g>
                <g transform="translate(177.41 1.3205)" fill="#9ca9b4">
                  <path
                    d="m0 0c-13.209 0-23.917 17.331-23.917 38.708 0 21.378 10.708 38.709 23.917 38.709h30.686v-77.417z"
                    fill="#9ca9b4"
                  />
                </g>
                <g transform="translate(232.13 40.029)" fill="#9ca9b4">
                  <path
                    d="m0 0c0 21.378-10.708 38.708-23.917 38.708-13.208 0-23.916-17.33-23.916-38.708s10.708-38.708 23.916-38.708c13.209 0 23.917 17.33 23.917 38.708"
                    fill="#9ca9b4"
                  />
                </g>
              </g>
              <g transform="translate(-42.789, -84.892)">
                <g opacity=".2">
                  <g transform="translate(267.78 124.92)">
                    <path d="m0 0c0 13.519-7.512 24.478-16.78 24.478-9.267 0-16.779-10.959-16.779-24.478s7.512-24.479 16.779-24.479c9.268 0 16.78 10.96 16.78 24.479" />
                  </g>
                </g>
              </g>
              <g>
                <g transform="translate(166.11 32.801)" fill="#adb8c1">
                  <path
                    d="m0 0c2.926 17.292 20.174 45.936 41.993 45.936l21.147 38.751h-144.91c-9.24 0-52.444-15.608-68.174-31.338-11.011-11.011-12.153-22.088-13.446-50.291-0.263-5.759 5.027-10.706 11.391-10.709 53.456-0.024 118.16-0.207 141.71-0.279 5.199-0.016 9.509 3.3 10.292 7.93"
                    fill="#adb8c1"
                  />
                </g>
                <g transform="translate(-42.789, -84.892)" fill="#9ca9b4">
                  <g fill="#9ca9b4">
                    <g fill="#9ca9b4" opacity=".2">
                      <g transform="translate(318.26 109.55)" fill="#9ca9b4">
                        <path
                          d="m0 0c-0.103 0-0.206 0.03-0.297 0.092-0.24 0.163-0.303 0.491-0.139 0.732 0.205 0.301 20.466 30.81 20.466 92.009 0 0.292 0.237 0.528 0.528 0.528s0.527-0.236 0.527-0.528c0-61.555-20.442-92.299-20.649-92.602-0.102-0.15-0.268-0.231-0.436-0.231"
                          fill="#9ca9b4"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g transform="translate(-42.789, -84.892)" fill="#9ca9b4">
                  <g fill="#9ca9b4">
                    <g fill="#9ca9b4" opacity=".2">
                      <g transform="translate(389.93 163.1)" fill="#9ca9b4">
                        <path
                          d="m0 0c-0.137 0-0.273 0.053-0.376 0.158-0.204 0.208-0.201 0.542 7e-3 0.746 9.616 9.43 14.738 31.453 14.738 43.725 0 0.291 0.236 0.528 0.527 0.528 0.292 0 0.528-0.237 0.528-0.528 0-12.458-5.232-34.846-15.055-44.478-0.102-0.101-0.236-0.151-0.369-0.151"
                          fill="#9ca9b4"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g transform="translate(269.67 106.97)" fill="#65717b">
                  <path
                    d="m0 0h13.705c1.063 0 1.933 0.87 1.933 1.933s-0.87 1.933-1.933 1.933h-13.705c-1.063 0-1.933-0.87-1.933-1.933s0.87-1.933 1.933-1.933"
                    fill="#65717b"
                  />
                </g>
                <g transform="translate(329.82 106.97)" fill="#65717b">
                  <path
                    d="m0 0h13.705c1.063 0 1.933 0.87 1.933 1.933s-0.87 1.933-1.933 1.933h-13.705c-1.063 0-1.933-0.87-1.933-1.933s0.87-1.933 1.933-1.933"
                    fill="#65717b"
                  />
                </g>
              </g>
            </g>
            <g transform="translate(0 -446.67)" fill="#46525c">
              <g transform="matrix(1.3333 0 0 -1.3333 -57.052 779.86)">
                <g fill="#46525c">
                  <g fill="#46525c" opacity=".7">
                    <g transform="translate(148.42 162.84)" fill="#46525c">
                      <path
                        d="m0 0c13.717 5.194 55.119 19.783 66.413 15.989 11.295-3.795-16.053-17.331-22.935-19.13-6.883-1.799-11.41 1.802-21.72-3.182-10.309-4.983-38.364 0.034-21.758 6.323"
                        fill="#46525c"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(1.3333 0 0 -1.3333 -57.052 779.86)">
                <g fill="#46525c">
                  <g fill="#46525c" opacity=".2">
                    <g transform="translate(98.744 156.78)" fill="#46525c">
                      <path
                        d="m0 0c-0.04 0-0.079 5e-3 -0.118 0.014l-27.528 6.325c-0.283 0.065-0.46 0.348-0.395 0.632 0.064 0.283 0.346 0.461 0.632 0.396l27.439-6.305 45.37 5.166c0.288 0.025 0.551-0.175 0.583-0.465 0.034-0.289-0.174-0.55-0.463-0.583l-45.46-5.177c-0.02-2e-3 -0.04-3e-3 -0.06-3e-3"
                        fill="#46525c"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(1.3333 0 0 -1.3333 -57.052 779.86)">
                <g fill="#46525c">
                  <g fill="#46525c" opacity=".7">
                    <g transform="translate(70.241 162.33)" fill="#46525c">
                      <path
                        d="m0 0c-2.825 1.718-7.454 4.438-12.693 7.232-3.005-3.247-5.177-6.578-6.779-10.405 1.581-0.317 3.395-1.065 5.64-2.794 6.684-5.145 24.575-0.591 13.832 5.967"
                        fill="#46525c"
                      />
                    </g>
                  </g>
                </g>
              </g>

              <g
                transform="matrix(1.3333 0 0 -1.3333 157.58 624.55) scale(-1, 1) translate(115)"
                id="LicensePlate"
                width="87.18px"
                height="12px"
              >
                <path
                  d="m 0 0 h -99.313 c -2.389 0 -3.658 2.819 -2.075 10.607 l 2.565 2.9 c 0.527 0.594 1.282 0.934 2.075 0.934 h 70.748 c 0.793 0 1.548 -0.34 2.075 -0.934 l 2.565 -2.9 c 1.925 -7.607 -2.075 -10.607 -2.075 -10.607"
                  fill="#46525c"
                  opacity=".7"
                  id="LicensePlatePath"
                />
                <text
                  fill="#adb8c1"
                  fontSize="10px"
                  transform="rotate(180)"
                  x="60px"
                  y="-6px"
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  {numberPlate}
                </text>
              </g>
            </g>

            <g transform="translate(0 -446.67)" fill="#768797">
              <g transform="matrix(1.3333 0 0 -1.3333 -57.052 779.86)">
                <g fill="#768797">
                  <g fill="#768797" opacity=".3">
                    <g transform="translate(272.03 248.39)" fill="#768797">
                      <path
                        d="m0 0c-98.792 0-143.87-45.297-144.9-45.996l-0.01-0.011h99.957s42.976 37.152 71.462 45.058c-8.434 0.622-17.279 0.949-26.514 0.949"
                        fill="#768797"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(1.3333 0 0 -1.3333 -57.052 779.86)">
                <g fill="#768797">
                  <g fill="#768797" opacity=".3">
                    <g transform="translate(244.76 202.38)" fill="#768797">
                      <path
                        d="m0 0h139.69c7.141 0 14.161 1.843 20.381 5.352 0 0-22.604 20.535-68.111 29.388-18.704 3.638-36.434 5.05-41.881 4.698-13.002-6.852-32.915-21.26-50.076-39.438"
                        fill="#768797"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        )}

        <rect
          fill={theme.palette.background.paper}
          height={400}
          width={81}
          transform="translate(223, -120)"
        />

        <g
          id="carger"
          transform="scale(4) translate(55, -28)"
          opacity={theme.palette.devicesPage.graphicsOpacity}
        >
          <defs>
            <clipPath id="clipPath1261">
              <path d="m0 500h500v-500h-500z" />
            </clipPath>
            <clipPath id="clipPath1319">
              <path d="m338.02 254h31.54v-63.74h-31.54z" />
            </clipPath>
            <clipPath id="clipPath1333">
              <path d="m338.02 253.99h11.298v-63.739h-11.298z" />
            </clipPath>
            <clipPath id="clipPath1353">
              <path d="m348.31 223.67h14.505v-18.79h-14.505z" />
            </clipPath>
            <clipPath id="clipPath1369">
              <path d="m338.02 254h31.54v-8.874h-31.54z" />
            </clipPath>
          </defs>
          <g transform="translate(0 -204.4)">
            <path d="m3.7584 286.87h2.2338v-0.0882h-2.2338z" fill="#ececec" />
            <g transform="matrix(.35278 0 0 -.35278 -100.61 314.36)">
              <path d="m329.13 303.17h-42.022v-252.61h42.022z" fill="#768797" />
              <path d="m340.66 306.28h-49.957v-3.111h49.957z" fill="#46525c" />
              <g transform="translate(-45.677 -30.94)" fill="#46525c">
                <g fill="#46525c">
                  <g fill="#46525c" opacity=".5">
                    <path
                      d="m369.56 254h-31.54v-63.739h31.54z"
                      fill="#46525c"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-45.677 -30.94)" fill="#adb8c1">
                <g fill="#adb8c1">
                  <g fill="#adb8c1" opacity=".5">
                    <g transform="translate(338.03 253.99)" fill="#adb8c1">
                      <path d="m0 0v-63.74l11.298 54.867z" fill="#adb8c1" />
                    </g>
                  </g>
                </g>
              </g>
              <g transform="translate(229.26 172.01)" fill="#86929d">
                <path
                  d="m88.262 26.043 0.642 3.115h-8.605l-0.641-3.115h-3.335l-6.099-29.616h15.349l6.098 29.616z"
                  fill="#86929d"
                />
              </g>
              <g fill="#46525c">
                <g transform="translate(-95.611 -35.766)">
                  <g transform="translate(49.624 .94702)" fill="#46525c">
                    <g fill="#46525c" opacity=".39999">
                      <g transform="translate(362.78 221.99)" fill="#46525c">
                        <path
                          transform="skewX(12) translate(0, -17)"
                          d={`M 0 0 L 0 ${
                            isCarCharging ? batteryLevelDisplay : 0
                          } L -11 ${
                            isCarCharging ? batteryLevelDisplay : 0
                          } L -11 0 L 0 0`}
                          fill="#46525c"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g transform="translate(-45.677 -30.94)">
                  <g fill="#46525c">
                    <g fill="#46525c" opacity=".39999">
                      <g transform="translate(338.03 253.99)" fill="#46525c">
                        <path d="m0 0h31.54v-8.873h-20.242z" fill="#46525c" />
                      </g>
                    </g>
                  </g>
                </g>
                <path d="m329.13 303.17h15.12v-252.61h-15.12z" />
                <g
                  style={{ transformOrigin: "bottom" }}
                  transform="scale(1.4) translate(-13, -25)"
                >
                  <g transform="matrix(.092566 0 0 -.10402 297.4 292.45)">
                    <path
                      fill="#46525c"
                      d="m38.74 139.73-0.81 1.71c-0.05 0.09-0.14 0.16-0.21 0.16-0.05 0-0.09-0.02-0.16-0.05l-0.71-0.37c-0.74-0.37-1.5-0.58-2.22-0.58-1.29 0-2.06 0.63-2.06 1.64 0 0.83 0.37 1.22 2.01 2.03l1.23 0.6c2.29 1.16 3.26 2.52 3.26 4.6 0 2.82-1.97 4.67-4.94 4.67-1.36 0-3.35-0.46-4.39-1.04-0.16-0.07-0.25-0.19-0.25-0.3 0-0.04 0.02-0.14 0.07-0.23l0.71-1.94c0.05-0.12 0.12-0.18 0.21-0.18 0.05 0 0.14 0.02 0.21 0.07 1.32 0.72 2.47 1.06 3.44 1.06 1.27 0 2.01-0.67 2.01-1.85 0-1.25-0.32-1.55-3.03-2.91-1.34-0.67-1.99-1.09-2.47-1.62-0.72-0.74-1.04-1.59-1.04-2.75 0-2.66 1.89-4.32 4.92-4.32 1.41 0 3.01 0.42 4.07 1.09 0.16 0.11 0.21 0.19 0.21 0.32-0.02 0.08-0.02 0.08-0.06 0.19z"
                    />
                    <path
                      fill="#46525c"
                      d="m46.61 138.23c0.18 0 0.21 0.04 0.35 0.32l1.8 3.84c0.28 0.56 0.46 1.02 0.71 1.78l0.23 0.67c0.35-1.2 0.53-1.66 0.99-2.63l1.73-3.65c0.14-0.28 0.19-0.32 0.32-0.32h0.14c0.02 0.02 0.11 0 0.21 0.02l2.17 0.02c0.28 0.07 0.39 0.14 0.39 0.3 0 0.06-0.02 0.14-0.12 0.3l-4.42 8.66v5.85c0 0.41-0.05 0.46-0.46 0.46h-2.15c-0.42 0-0.46-0.05-0.46-0.46v-5.87l-4.5-8.68c-0.05-0.07-0.07-0.14-0.09-0.14-0.02-0.05-0.02-0.12-0.02-0.16 0-0.16 0.11-0.23 0.41-0.28"
                    />
                    <path
                      fill="#46525c"
                      d="m69.01 139.43-0.81 1.71c-0.04 0.09-0.14 0.16-0.21 0.16-0.04 0-0.09-0.02-0.16-0.05l-0.72-0.37c-0.74-0.37-1.5-0.58-2.22-0.58-1.29 0-2.06 0.63-2.06 1.64 0 0.83 0.37 1.23 2.01 2.03l1.23 0.6c2.29 1.15 3.26 2.52 3.26 4.6 0 2.82-1.96 4.67-4.94 4.67-1.36 0-3.35-0.46-4.39-1.04-0.16-0.07-0.25-0.19-0.25-0.3 0-0.05 0.03-0.14 0.07-0.23l0.72-1.94c0.05-0.11 0.11-0.18 0.21-0.18 0.05 0 0.14 0.02 0.21 0.07 1.32 0.72 2.47 1.06 3.44 1.06 1.27 0 2.01-0.67 2.01-1.85 0-1.25-0.32-1.55-3.03-2.91-1.34-0.67-1.99-1.08-2.47-1.62-0.71-0.74-1.04-1.6-1.04-2.75 0-2.66 1.89-4.32 4.92-4.32 1.41 0 3 0.42 4.07 1.09 0.16 0.11 0.21 0.19 0.21 0.32-0.01 0.07-0.01 0.07-0.06 0.19z"
                    />
                    <path
                      fill="#46525c"
                      d="m84.38 140.75c-0.07 0.32-0.14 0.37-0.49 0.37h-3.28v12.27c0 0.41-0.04 0.46-0.46 0.46h-2.19c-0.42 0-0.46-0.05-0.46-0.46v-12.27h-2.82c-0.42 0-0.49-0.04-0.49-0.46v-1.75c0-0.39 0.07-0.46 0.49-0.46h9.27c0.34 0 0.46 0.07 0.46 0.25"
                    />
                    <path
                      fill="#46525c"
                      d="m93.42 140.89v3.7h3.74c0.39 0 0.46 0.04 0.46 0.46v1.52c0 0.39-0.07 0.46-0.46 0.46h-3.74v4.37h4.69c0.41 0 0.46 0.07 0.46 0.46v1.53c0 0.41-0.05 0.46-0.46 0.46h-7.32c-0.42 0-0.46-0.05-0.46-0.46v-14.49c0-0.39 0.05-0.46 0.46-0.46h7.51c0.35 0 0.44 0.07 0.44 0.25 0 0.07 0 0.14-0.05 0.28l-0.44 1.54c-0.07 0.3-0.18 0.37-0.49 0.37h-4.34z"
                    />
                    <path
                      fill="#46525c"
                      d="m111.38 146.7 0.19-0.67c0.21-0.79 0.37-1.37 0.58-1.94l1.9-5.64c0.12-0.35 0.14-0.37 0.51-0.37h2.12c0.37 0 0.44 0.07 0.46 0.46l0.72 14.46v0.16c0 0.25-0.11 0.32-0.44 0.32h-2.03c-0.41 0-0.46-0.07-0.48-0.46l-0.14-7.92c-0.02-0.25-0.02-0.51-0.02-0.78 0-0.21 0-0.49 0.02-0.81v-0.74l0.02-0.9c-0.37 1.59-0.6 2.47-0.83 3.26l-1.46 4.6c-0.11 0.35-0.14 0.37-0.51 0.37h-1.36c-0.37 0-0.39-0.02-0.51-0.39l-1.25-4.37c-0.16-0.55-0.51-1.96-0.83-3.46l0.02 0.97v0.85 1 0.71l-0.18 7.62c0 0.42-0.07 0.46-0.46 0.46h-1.83c-0.35 0-0.44-0.07-0.44-0.32v-0.14l0.65-14.49c0.02-0.39 0.07-0.46 0.46-0.46h2.22c0.35 0 0.39 0.05 0.51 0.37l1.8 5.66c0.11 0.42 0.34 1.41 0.59 2.59z"
                    />
                    <path
                      fill="#46525c"
                      d="m133.48 140.75c-0.07 0.32-0.14 0.37-0.49 0.37h-3.26v12.27c0 0.41-0.05 0.46-0.46 0.46h-2.2c-0.42 0-0.46-0.05-0.46-0.46v-12.27h-2.82c-0.41 0-0.48-0.05-0.48-0.46v-1.76c0-0.39 0.07-0.46 0.48-0.46h9.27c0.35 0 0.46 0.07 0.46 0.26"
                    />
                    <path
                      fill="#46525c"
                      d="m147.78 140.52c-0.07 0.3-0.19 0.37-0.49 0.37h-4.8v3.7h3.74c0.39 0 0.46 0.04 0.46 0.46v1.52c0 0.39-0.07 0.46-0.46 0.46h-3.74v4.37h5.14c0.42 0 0.46 0.07 0.46 0.46v1.53c0 0.41-0.04 0.46-0.46 0.46h-7.77c-0.42 0-0.46-0.05-0.46-0.46v-14.49c0-0.39 0.05-0.46 0.46-0.46h7.51c0.35 0 0.44 0.07 0.44 0.25"
                    />
                    <path
                      fill="#46525c"
                      d="m163.36 139.62-0.92 1.66c-0.09 0.14-0.14 0.16-0.23 0.16-0.07 0-0.14-0.03-0.21-0.07-0.09-0.04-0.23-0.16-0.46-0.35-0.62-0.46-1.22-0.69-1.82-0.69-1.73 0-2.59 1.59-2.59 4.83 0 4.21 0.92 6.15 2.91 6.15 0.74 0 1.25-0.21 2.01-0.81 0.09-0.04 0.16-0.09 0.21-0.09 0.09 0 0.16 0.05 0.23 0.19l0.9 1.57c0.02 0.07 0.05 0.16 0.05 0.23 0 0.21-0.16 0.41-0.62 0.67-0.97 0.6-1.89 0.85-3.12 0.85-3.76 0-5.8-2.89-5.8-8.27 0-2.82 0.55-4.73 1.78-6.15 1.02-1.16 2.36-1.76 3.88-1.76 1.25 0 2.56 0.46 3.58 1.27 0.16 0.16 0.28 0.3 0.28 0.42-0.02 0.04-0.04 0.12-0.06 0.19z"
                    />
                    <path
                      fill="#46525c"
                      d="m171.31 144.73h4.19v-5.82c0-0.39 0.04-0.46 0.46-0.46h2.15c0.41 0 0.46 0.07 0.46 0.46v14.49c0 0.41-0.05 0.46-0.46 0.46h-2.15c-0.42 0-0.46-0.05-0.46-0.46v-6.36h-4.19v6.36c0 0.41-0.07 0.46-0.46 0.46h-2.17c-0.42 0-0.46-0.05-0.46-0.46v-14.5c0-0.39 0.05-0.46 0.46-0.46h2.17c0.39 0 0.46 0.07 0.46 0.46z"
                    />
                    <path
                      fill="#46525c"
                      d="m193.45 148.88-0.02-0.78v-2.03-7.16c0-0.39 0.05-0.46 0.46-0.46h1.69c0.41 0 0.46 0.05 0.46 0.46v14.49c0 0.41-0.05 0.46-0.46 0.46h-1.46c-0.4 0-0.44-0.05-0.65-0.44l-3.88-7.28c-0.81-1.5-1.04-2.01-1.43-3.16l0.02 0.76c0.02 0.67 0.04 0.95 0.04 1.55v8.11c0 0.41-0.07 0.46-0.46 0.46h-1.71c-0.41 0-0.46-0.05-0.46-0.46v-14.5c0-0.39 0.05-0.46 0.46-0.46h1.78c0.39 0 0.44 0.02 0.62 0.39l3.56 6.61c0.49 0.9 0.98 2.1 1.44 3.44z"
                    />
                    <path
                      fill="#46525c"
                      d="m205.02 138.44h-2.17c-0.42 0-0.46 0.07-0.46 0.46v12.73l0.01 1.76c0 0.41 0.04 0.46 0.46 0.46h2.11c0.42 0 0.46-0.05 0.46-0.46l0.05-14.49c0-0.39-0.05-0.46-0.46-0.46z"
                    />
                    <path
                      fill="#46525c"
                      d="m214.66 145.23 4.7-6.53c0.14-0.18 0.23-0.25 0.35-0.25 0.07 0 2.63 0.01 2.63 0.01 0.23 0.12 0.3 0.18 0.3 0.3 0 0.09-0.04 0.19-0.16 0.32l-5.16 6.89 5.74 7.21c0.12 0.14 0.16 0.25 0.16 0.32 0 0.14-0.02 0.19-0.3 0.33 0 0-2.96 0-2.98 0.02h-0.02c-0.09 0-0.16-0.07-0.3-0.26l-4.96-6.51v6.31c0 0.41-0.07 0.46-0.46 0.46h-2.17c-0.42 0-0.46-0.05-0.46-0.46v-14.49c0-0.39 0.04-0.46 0.46-0.46h2.17c0.39 0 0.46 0.07 0.46 0.46z"
                    />
                    <path
                      fill="#46525c"
                      d="m40.19 79.51h-22.79c-10.86 0-16.84 5.29-16.84 14.88 0 6.37 2.88 13.97 16.61 13.97h10.62c3.69 0 4 1.43 4 3.02 0 0.96 0 2.74-4.63 2.74h-24.99v11.35h25.51c13.92 0 16.84-7.85 16.84-14.43 0-4.89-1.38-8.48-4.09-10.68-2.54-2.13-6.46-3.17-11.94-3.17h-11.95c-3.01 0-3.25-1.11-3.25-2.56 0-2.33 0.76-3.77 4.52-3.77h24.76v-11.35z"
                    />
                    <path
                      fill="#46525c"
                      d="m81.58 79.51h-12.51c-9.86 0-21.6 4.12-21.6 23.72 0 8.39 2.32 14.31 7.1 18.11 2.12 1.73 4.42 2.88 7.02 3.49 1.51 0.43 3.85 0.64 7.26 0.64h15.16v-11.46h-15.39c-3.14 0.04-5.23-0.58-6.23-1.85-0.86-1.05-1.84-3.36-1.84-8.64 0-4.91 0.66-8.39 1.96-10.34 1.22-1.77 3.16-2.61 6.06-2.61h15.39v-11.06z"
                    />
                    <path
                      fill="#46525c"
                      d="m123.43 79.51h-9.95v17.11h-13.16v-17.11h-12.39v45.96h12.38v-17.56h13.16v17.56h12.32v-45.96z"
                    />
                    <path
                      fill="#46525c"
                      d="m171.32 79.51h-9.84v27.52c0 3.12-0.54 5.28-1.6 6.42-1.04 1.12-3.24 1.71-6.35 1.71-3.19 0-5.4-0.58-6.38-1.68-0.75-0.79-1.58-2.48-1.58-6.46v-27.51h-12.15v27.28c0 3.33 0.14 5.6 0.45 7.12 0.41 2.33 1.22 4.2 2.39 5.53 3.46 4.17 9.12 6.25 17.26 6.31 8.1-0.06 13.72-2.15 17.23-6.37 1.98-2.26 2.96-6.39 2.96-12.6v-27.27z"
                    />
                    <path
                      fill="#46525c"
                      d="m190.62 79.51h-9.78v26.88c0 3.49 0.22 5.84 0.71 7.63 0.52 2.29 1.77 4.55 3.57 6.41 1.91 2.18 4.3 3.65 7.03 4.28 1.79 0.49 4.46 0.75 8.05 0.75h6.17v-11.12h-6c-3.25 0-5.42-0.62-6.46-1.86l-0.1-0.12-0.11-0.1c-0.15-0.14-0.44-0.64-0.57-1.64l-0.15-4.18v-26.93zm-0.12 36.27c-0.02-0.02-0.04-0.06-0.06-0.09-0.05-0.05-0.11-0.08-0.15-0.13z"
                    />
                    <path
                      fill="#46525c"
                      d="m246.24 79.51h-36.99v11.52h22.17c-5.35 5.82-22.23 24.2-22.23 24.2v10.24h39.42v-11.81h-21.89c5.25-5.81 21.89-24.21 21.89-24.21v-9.94z"
                    />
                    <path
                      fill="#46525c"
                      d="m212.85 57.95h-174.95c-1.33 3.19-2.49 6.46-3.47 9.81h181.88c-0.98-3.35-2.13-6.62-3.46-9.81z"
                    />
                    <path
                      fill="#46525c"
                      d="m206.33 45.15c-0.86-1.42-1.77-2.81-2.7-4.17h-156.51c-0.93 1.37-1.84 2.76-2.7 4.17-0.65 1.07-1.28 2.16-1.89 3.26h165.69c-0.61-1.1-1.24-2.19-1.89-3.26z"
                    />
                    <path
                      fill="#46525c"
                      d="m192.52 27.55h-134.29c-1.49 1.5-2.93 3.04-4.31 4.63h142.92c-1.4-1.59-2.83-3.13-4.32-4.63z"
                    />
                    <path
                      fill="#46525c"
                      d="m179.94 16.93h-109.14c-1.4 0.99-2.78 2.02-4.13 3.08h117.4c-1.34-1.06-2.72-2.08-4.13-3.08z"
                    />
                    <path
                      fill="#46525c"
                      d="m164.7 8.17h-78.66c-1.36 0.62-2.7 1.27-4.02 1.95h86.7c-1.32-0.68-2.66-1.33-4.02-1.95z"
                    />
                    <path
                      fill="#46525c"
                      d="m142.12 1.12h-33.5c-2.16 0.39-4.3 0.85-6.41 1.38h46.31c-2.1-0.53-4.24-1-6.4-1.38z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(0 -204.4)">
            {isCarCharging && (
              <g transform="matrix(.11924 0 0 -.11924 10 249.73) scale(1.5)">
                <path
                  d="m0 0h-7.069l6.183 9.788h-7.767l-5.189-13.831h6.45l-3.932-12.491z"
                  fill="#46525c"
                />
              </g>
            )}
          </g>
        </g>
      </SvgIcon>
    </>
  );
};

export default MockChargerCarSvg;
