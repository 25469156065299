import { Card, CardContent, CardHeader } from "@mui/material";
import ErrorBoundary from "components/UI/ErrorBoundary";
import React from "react";
import { useTranslation } from "react-i18next";
import EnergyFlowDataTable from "./EnergyFlowDataTable";

const EnergyFlowCard = () => {
  const { t } = useTranslation();
  return (
    <Card variant="outlined">
      <CardHeader
        title={t("energyFlow")}
        titleTypographyProps={{ fontSize: 20, fontWeight: 500 }}
      />
      <CardContent>
        <ErrorBoundary>
          <EnergyFlowDataTable />
        </ErrorBoundary>
      </CardContent>
    </Card>
  );
};

export default EnergyFlowCard;
