import React from "react";
import { Card, CardHeader, CardActions, Chip, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import TimeAxisChart, { ChartVariant } from "./TimeAxisChart";
import TimeAxisChartMedia from "./TimeAxisChartMedia";
import { ChartDateType } from "types/Common";
import { DataPoint, MeasurementUnit } from "types/Measurements";

interface TimeAxisChartCardProps {
  dataPoints: DataPoint[];
  title: string;
  dateFormat: ChartDateType;
  variant: ChartVariant;
  measurementUnit: MeasurementUnit;
  deviceName?: string;
}

const TimeAxisChartCard: React.FC<TimeAxisChartCardProps> = (props) => {
  const {
    dataPoints,
    title,
    dateFormat,
    variant,
    measurementUnit,
    deviceName,
  } = props;

  return (
    <Card variant="outlined">
      <CardHeader title={title} />
      <TimeAxisChartMedia>
        <TimeAxisChart
          dataPoints={dataPoints}
          dateFormat={dateFormat}
          variant={variant}
          measurementUnit={measurementUnit}
        />
      </TimeAxisChartMedia>
      <CardActions>
        <IconButton aria-label="settings">
          <SettingsIcon />
        </IconButton>
        {deviceName && (
          <Chip
            label={deviceName}
            variant="outlined"
            size="small"
            sx={{ ml: 1 }}
          />
        )}
      </CardActions>
    </Card>
  );
};

export default TimeAxisChartCard;
