import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { NotificationData } from "components/UI/NotificationsBadge";
import { DeviceType, Organization, PagedResult } from "types/Common";
import { EnergyFlowDeviceState } from "types/Dashboard";

type SupportedHttpVerbs = "GET" | "POST" | "PUT" | "DELETE";

const backendApiUrl: string = process.env.REACT_APP_BACKEND_API_URL!;

const client = axios.create({
  baseURL: backendApiUrl,
});

export const useAxiosWithBearerToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  const request = async <TResponse, TData = void>(
    url: string,
    method: SupportedHttpVerbs,
    data?: TData,
    params?: any
  ) => {
    const token = await getAccessTokenSilently();

    const response = await client.request<TResponse>({
      url,
      method,
      data,
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  return request;
};

export const useApiClient = () => {
  const request = useAxiosWithBearerToken();

  const postTimeseriesDatabase = (orgId: string, bucketName: string) => {
    return request("/measurements/timeseries-databases", "POST", {
      organizationId: orgId,
      bucketName: bucketName,
    });
  };

  const getEnergyFlowData = () => {
    return request<EnergyFlowDeviceState[]>(
      "/measurements/energy-flow/summary",
      "GET"
    );
  };

  const updateLocation = (
    deviceId: string,
    deviceType: DeviceType,
    locationId: string
  ) => {
    return request("/locations/devices", "PUT", {
      deviceId: deviceId,
      deviceType: deviceType,
      locationId: locationId,
    });
  };

  const getNotifications = () => {
    return request<NotificationData[]>("/notifications", "GET");
  };

  const getOrganizationData = (orgId: string) => {
    return request<PagedResult<Organization>>(
      `/organizations?organizationId=${orgId}`,
      "GET"
    );
  };

  return {
    postTimeseriesDatabase,
    getEnergyFlowData,
    updateLocation,
    getNotifications,
    getOrganizationData,
    request,
  };
};
