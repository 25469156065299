import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "layouts";
import UserPage from "pages/UserPage";
import SettingsPage from "pages/Settings";
import HomePage from "pages/Home";
import DevicesPage from "pages/Devices";
import DeviceDetailsPage from "pages/DeviceDetails";
import LoginPage from "pages/Login";
import RequireAuth from "components/UI/RequireAuth";
import ImprintPage from "pages/Imprint";
import PageNotFound from "pages/PageNotFound";
import Organization from "pages/Organization";
import Dashboard from "pages/Dashboard";
import Alarms from "pages/Alarms";
// import DevicesManagerPage from "pages/DevicesManager/DevicesManagerPage";
import PrivacyPage from "pages/Privacy";
import InfluxBucketCard from "features/organization/InfluxBucketCard";
import LocationEditorCard from "features/organization/LocationEditorCard";
import NoSelectedTab from "features/organization/NoSelectedTab";
import Export from "pages/Export";

const RouterConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/imprint" element={<ImprintPage />} />{" "}
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route
          path="/alarms"
          element={
            <RequireAuth>
              <Alarms />
            </RequireAuth>
          }
        />
        <Route
          path="/organization"
          element={
            <RequireAuth>
              <Organization />
            </RequireAuth>
          }
        >
          <Route index element={<NoSelectedTab />} />
          <Route path="/organization/influx" element={<InfluxBucketCard />} />
          <Route
            path="/organization/locations"
            element={<LocationEditorCard />}
          />
        </Route>
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <UserPage />
            </RequireAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <SettingsPage />
            </RequireAuth>
          }
        />
        {/* <Route
          path="/device-manager"
          element={
            <RequireAuth>
              <DevicesManagerPage />
            </RequireAuth>
          }
        /> */}
        <Route
          path="/export"
          element={
            <RequireAuth>
              <Export />
            </RequireAuth>
          }
        />
        <Route path="devices">
          <Route
            index
            element={
              <RequireAuth>
                <DevicesPage />
              </RequireAuth>
            }
          />
          <Route path=":deviceId">
            <Route
              index
              element={
                <RequireAuth>
                  <DeviceDetailsPage />
                </RequireAuth>
              }
            />
            <Route
              path=":edit"
              element={<RequireAuth>{/* <DeviceDetailsPage /> */}</RequireAuth>}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default RouterConfig;
