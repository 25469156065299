import { SvgIcon, useTheme } from "@mui/material";
import React from "react";

const BatteryLargeShell = () => {
  const theme = useTheme();
  const color = theme.palette.battery.shell;
  return (
    <SvgIcon
      viewBox="0 0 234.59 78.1"
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <rect y=".1" width="224.4" height="78" rx="6.71" ry="6.71" fill={color} />
      <path
        d="M226.08,17.98c.17-.02,.34-.04,.52-.03,4.5,.04,8.08,8.74,7.99,19.44s-3.82,19.34-8.32,19.3"
        fill={color}
      />
    </SvgIcon>
  );
};

export default BatteryLargeShell;
