import React from "react";
import { useNavigationItems } from "hooks/use-navigation-items";
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  Toolbar,
} from "@mui/material";
import { useMobileBreakpoint } from "hooks/use-breakpoint";
import { useDrawer } from "hooks/use-drawer";
import { usePathname } from "hooks/use-pathname";
import { drawerListItemHeight } from "utils/constants/Layout";

const MinifiedNavBar = () => {
  const navBarItemsGroups = useNavigationItems();
  const isMobile = useMobileBreakpoint();
  const { toggleDrawer } = useDrawer();
  const pathname = usePathname();

  const clickHandler = (action: () => void) => {
    action();

    if (isMobile) {
      toggleDrawer();
    }
  };

  return (
    <>
      <Toolbar />
      {navBarItemsGroups.map((navBarItemsGroup) => {
        return (
          <React.Fragment key={navBarItemsGroup.id}>
            <List
              sx={{
                "&& .Mui-selected, && .Mui-selected:hover": {
                  borderRight: 1,
                  borderColor: (theme) => theme.palette.primary.light,
                },
              }}
            >
              {navBarItemsGroup.items.map((navBarItem) => {
                return (
                  <ListItemButton
                    disableGutters
                    key={navBarItem.id}
                    sx={{
                      height: (theme) => theme.spacing(drawerListItemHeight),
                      pl: (theme) => theme.spacing(2),
                    }}
                    selected={
                      !isMobile && pathname === navBarItem.pageInfo?.path
                    }
                    onClick={() => clickHandler(navBarItem.clickAction)}
                  >
                    <ListItemIcon>{navBarItem.icon}</ListItemIcon>
                  </ListItemButton>
                );
              })}
            </List>
            <Divider />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default MinifiedNavBar;
