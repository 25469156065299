import React from "react";
import { CardMedia } from "@mui/material";
import { useMobileBreakpoint } from "hooks/use-breakpoint";

interface TimeAxisChartMediaProps {
  children: React.ReactNode;
}

const TimeAxisChartMedia: React.FC<TimeAxisChartMediaProps> = ({
  children,
}) => {
  const isMobile = useMobileBreakpoint();
  const mediaHeight = isMobile ? 150 : 220;
  return <CardMedia sx={{ height: mediaHeight, m: 1 }}>{children}</CardMedia>;
};

export default TimeAxisChartMedia;
