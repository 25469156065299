import React from "react";
import { SvgIcon, useTheme } from "@mui/material";
import { MenuSectionItem } from "layouts/ResponsiveDrawer/MenuSectionList/MenuSectionList";
import DrawerButton from "components/UI/DrawerButton";

interface MenuLinkItemButtonProps {
  item: MenuSectionItem;
}

const MenuLinkItemButton: React.FC<MenuLinkItemButtonProps> = (props) => {
  const { item } = props;
  const theme = useTheme();

  return (
    <DrawerButton link={item.path} title={item.text}>
      <SvgIcon htmlColor={theme.palette.drawerIcon}>{<item.icon />}</SvgIcon>
    </DrawerButton>
  );
};

export default MenuLinkItemButton;
