import { useAxiosWithBearerToken } from "./useApiClient";
import { PagedResult } from "types/Common";
import { Alarm, LogLevel } from "types/Alarms";

export interface GetAlarmsOptions {
  page?: number;
  pageSize?: number;
  onlyOpen?: boolean;
  logLevels?: LogLevel[];
  deviceIds?: string[];
}

export const useAlarmsApiClient = () => {
  const request = useAxiosWithBearerToken();

  const getAlarms = (options?: GetAlarmsOptions) => {
    const page = options?.page ?? 1;
    const pageSize = options?.pageSize ?? 200;
    const onlyOpen = options?.onlyOpen ?? false;
    let uri = `/alarms?page=${page}&pageSize=${pageSize}&onlyOpen=${onlyOpen}`;

    const logLevels = options?.logLevels;

    if (logLevels && logLevels.length > 0) {
      uri += `&${logLevels.map((x) => `logLevels=${x}`).join("&")}`;
    }

    const deviceIds = options?.deviceIds;

    if (deviceIds && deviceIds.length > 0) {
      uri += `&${deviceIds.map((x) => `deviceIds=${x}`).join("&")}`;
    }

    return request<PagedResult<Alarm>>(uri, "GET");
  };

  return {
    getAlarms,
  };
};
