import * as React from "react";
import { Box } from "@mui/material";
import SchulzLogo from "components/UI/SchulzLogo";

const DrawerLogo = () => {
  return (
    <Box
      sx={{
        width: "70%",
        mx: (theme) => theme.spacing(1),
        mt: 2,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <SchulzLogo variant="monotone" />
    </Box>
  );
};

export default DrawerLogo;
