import { t } from "i18next";
import { useTranslation } from "react-i18next";

interface EnergyFlowSVGProps {
  posX: string;
  posY: string;
  value: {
    value: number;
    unit: string;
  };
}

const naming = (
  positivName: string,
  negativName: string,
  neutralName: string,
  value: number
) => {
  if (value > 0) {
    return positivName;
  }
  if (value < 0) {
    return negativName;
  }
  return neutralName;
};

export const Consumer: React.FC<EnergyFlowSVGProps> = ({
  posX,
  posY,
  value,
}) => {
  const { t } = useTranslation();
  return (
    <g transform={"translate(" + posX + "," + posY + ")"}>
      <text transform="translate(15,-22)" fontSize="11px" textAnchor="middle">
        {t("consumption")}
      </text>
      <text
        transform="translate(15,-5)"
        fontSize="14px"
        textAnchor="middle"
        fontWeight="bold"
      >
        {value.value} {value.unit}
      </text>
      <path
        d="M3.9,85.8c1.4-0.1,2.8-0.1,4.2-0.1c1-4.4,2.7-9,4.5-13.8c3.6-9.7,7.3-19.7,5.5-29.4l-4,0.8
        c1.6,8.4-1.9,17.9-5.3,27C6.9,75.4,5,80.7,3.9,85.8L3.9,85.8z"
        fill="#46525c"
      />
      <path
        d="M19.9,33l0.2,9.6c0,0.6-0.4,1.1-1,1.1l-6.5,0.1c-0.6,0-1-0.5-1-1.1l-0.2-9.6L19.9,33z"
        fill="#46525c"
      />
      <path />
      <path
        d="M7.5,0.8L5.9,0.8c-1,0-1.8,1.1-1.7,2.3l0.4,10.2l5.5-0.2L9.6,2.9C9.6,1.7,8.4,0.8,7.5,0.8z"
        fill="#adb8c1"
      />
      <path
        d="M22,0.8l-1.6,0.1c-1,0-1.7,1.1-1.7,2.3l0.4,10.2l4.9-0.2L23.6,3C23.6,1.7,23,0.8,22,0.8z"
        fill="#adb8c1"
      />
      <path
        d="M10.8,36c-5.4,0.1-9.7-3.6-9.8-8.2L0.7,14.7c0-1.7,1.4-2.9,3.3-2.9l21.4-0.4c2,0,3,1.2,3,2.8l0.3,13.1
        c0.1,4.6-3.7,8.4-9.1,8.5L10.8,36L10.8,36z"
        fill="#768898"
      />
    </g>
  );
};

export const Producer: React.FC<EnergyFlowSVGProps> = ({
  posX,
  posY,
  value,
}) => {
  return (
    <g transform={"translate(" + posX + "," + posY + ")"}>
      <text transform="translate(40,-22)" fontSize="11px" textAnchor="middle">
        {naming(t("production"), t("production"), t("idle"), value.value)}
      </text>
      <text
        transform="translate(40,-5)"
        fontSize="14px"
        textAnchor="middle"
        fontWeight="bold"
      >
        {value.value} {value.unit}
      </text>

      <g transform="translate(43,28)">
        <path d="M19.3,9h-3.4v17.9h3.4V9z" fill="#46525c" />
        <path
          d="M21.1,27.4C21.1,27.4,21.1,27.4,21.1,27.4L14,27.5c-0.2,0-0.3-0.1-0.3-0.3v-0.4c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1l7,0.1c0,0,0,0,0,0.1L21.1,27.4L21.1,27.4z"
          fill="#46525c"
        />
        <path
          d="M37.3,2.5l-27,0.4C10.1,2.9,10,3,9.9,3.1L2.5,18.4c-0.1,0.1,0,0.3,0.1,0.3l27,0.2c0.2,0,0.3-0.1,0.4-0.3l7.4-15.8C37.5,2.6,37.5,2.5,37.3,2.5z"
          fill="#adb8c1"
        />
        <path
          d="M18,3.8l-7.4,0.1c-0.2,0-0.3,0.1-0.4,0.3L8.6,7.7c-0.1,0.1,0,0.3,0.1,0.3l7.3-0.1c0.2,0,0.3-0.1,0.4-0.3l1.7-3.5C18.2,3.9,18.2,3.8,18,3.8L18,3.8z"
          fill="#768898"
        />
        <path
          d="M26.8,3.7l-7.5,0.1c-0.2,0-0.3,0.1-0.4,0.3l-1.7,3.5c-0.1,0.1,0,0.3,0.1,0.3l7.5-0.1c0.2,0,0.3-0.1,0.4-0.3L27,4C27.1,3.8,27,3.7,26.8,3.7L26.8,3.7z"
          fill="#768898"
        />
        <path
          d="M35.9,3.6l-7.7,0.1c-0.2,0-0.3,0.1-0.4,0.3l-1.7,3.6c-0.1,0.2,0,0.3,0.2,0.3l7.7-0.1c0.2,0,0.3-0.1,0.4-0.3L36,3.9C36.1,3.7,36,3.6,35.9,3.6L35.9,3.6z"
          fill="#768898"
        />
        <path
          d="M15.7,8.6l-7.3,0C8.2,8.7,8,8.8,7.9,9l-1.7,3.5c-0.1,0.1,0,0.3,0.1,0.3l7.3,0c0.2,0,0.3-0.1,0.4-0.3l1.7-3.6C15.9,8.8,15.8,8.6,15.7,8.6z"
          fill="#768898"
        />
        <path
          d="M24.5,8.6l-7.5,0c-0.2,0-0.3,0.1-0.4,0.3l-1.7,3.6c-0.1,0.1,0,0.3,0.1,0.3l7.5,0c0.2,0,0.3-0.1,0.4-0.3l1.7-3.6C24.8,8.7,24.7,8.6,24.5,8.6z"
          fill="#768898"
        />
        <path
          d="M33.6,8.5l-7.7,0c-0.2,0-0.3,0.1-0.4,0.3l-1.7,3.6c-0.1,0.2,0,0.3,0.2,0.3l7.7,0c0.2,0,0.3-0.1,0.4-0.3l1.7-3.6C33.8,8.7,33.7,8.5,33.6,8.5L33.6,8.5z"
          fill="#768898"
        />
        <path
          d="M13.4,13.5l-7.3,0c-0.1,0-0.3,0.1-0.4,0.3l-1.7,3.5c-0.1,0.1,0,0.3,0.1,0.3l7.3,0c0.2,0,0.3-0.1,0.4-0.3l1.7-3.6C13.6,13.6,13.5,13.5,13.4,13.5L13.4,13.5z"
          fill="#768898"
        />
        <path
          d="M22.2,13.5l-7.5,0c-0.2,0-0.3,0.1-0.4,0.3l-1.7,3.6c-0.1,0.1,0,0.3,0.1,0.3l7.5,0c0.2,0,0.3-0.1,0.4-0.3l1.7-3.6C22.4,13.6,22.4,13.5,22.2,13.5L22.2,13.5z"
          fill="#768898"
        />
        <path
          d="M31.2,13.5l-7.7,0c-0.2,0-0.3,0.1-0.4,0.3l-1.8,3.9l7.9,0c0.2,0,0.3-0.1,0.4-0.3l1.7-3.7C31.5,13.6,31.4,13.5,31.2,13.5L31.2,13.5z"
          fill="#768898"
        />
        <path
          d="M29.5,18.3L29.5,18.3l-5.2,0c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0.1-0.1h0l5.2,0h0c0.1,0,0.3-0.2,0.3-0.3l2.7-5.8c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1l-2.7,5.8C29.8,18.1,29.7,18.3,29.5,18.3L29.5,18.3z"
          fill="#adb8c1"
        />
        <path
          d="M7.9,8.3C7.9,8.3,7.9,8.3,7.9,8.3C7.8,8.3,7.8,8.3,7.9,8.3l2.3-4.9c0.1-0.2,0.3-0.2,0.5-0.3l5.7-0.1c0,0,0.1,0,0.1,0.1c0,0,0,0.1-0.1,0.1l-5.7,0.1c-0.1,0-0.3,0.1-0.4,0.2L7.9,8.3C7.9,8.3,7.9,8.3,7.9,8.3L7.9,8.3z"
          fill="#adb8c1"
        />
      </g>
      <g>
        <path d="M30.7,14.3h-5.8v32.2h5.8V14.3z" fill="#46525C" />
        <path
          d="M33.8,47.4c0,0.1,0,0.1,0,0.1l-12.1,0.1c-0.3,0-0.5-0.2-0.5-0.5v-0.7c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2l12,0.2c0.1,0,0,0.1,0,0.1L33.8,47.4L33.8,47.4z"
          fill="#46525C"
        />
        <path
          d="M61.7,2.6L15.4,3.3c-0.3,0-0.6,0.2-0.7,0.5L2,31.2c-0.1,0.3,0,0.5,0.2,0.5L48.5,32c0.3,0,0.6-0.2,0.7-0.5L62,3.1C62.1,2.9,61.9,2.6,61.7,2.6z"
          fill="#ADB8C1"
        />
        <path
          d="M28.6,5L16,5.2c-0.3,0-0.6,0.2-0.7,0.5L12.4,12c-0.1,0.3,0,0.5,0.2,0.5l12.6-0.1c0.3,0,0.6-0.2,0.7-0.5l2.9-6.4C29,5.2,28.8,5,28.6,5L28.6,5z"
          fill="#768898"
        />
        <path
          d="M43.7,4.8L30.9,5c-0.3,0-0.6,0.2-0.7,0.5l-2.9,6.4c-0.1,0.3,0,0.5,0.3,0.5l12.9-0.1c0.3,0,0.6-0.2,0.7-0.5L44,5.3C44.1,5,44,4.8,43.7,4.8L43.7,4.8z"
          fill="#768898"
        />
        <path
          d="M59.2,4.6L46.1,4.8c-0.3,0-0.6,0.2-0.7,0.5l-2.9,6.5c-0.1,0.3,0,0.5,0.3,0.5l13.1-0.1c0.3,0,0.6-0.2,0.7-0.5l2.9-6.5C59.6,4.9,59.5,4.6,59.2,4.6L59.2,4.6z"
          fill="#768898"
        />
        <path
          d="M24.6,13.7L12,13.8c-0.3,0-0.6,0.2-0.7,0.5l-2.9,6.3c-0.1,0.3,0,0.5,0.2,0.5l12.6,0c0.3,0,0.6-0.2,0.7-0.5l2.9-6.4C25,13.9,24.9,13.7,24.6,13.7z"
          fill="#768898"
        />
        <path
          d="M39.8,13.6l-12.9,0.1c-0.3,0-0.6,0.2-0.7,0.5l-2.9,6.4c-0.1,0.3,0,0.5,0.3,0.5l12.9,0c0.3,0,0.6-0.2,0.7-0.5l2.9-6.5C40.1,13.8,40,13.6,39.8,13.6z"
          fill="#768898"
        />
        <path
          d="M55.2,13.5l-13.1,0.1c-0.3,0-0.6,0.2-0.7,0.5l-2.9,6.5c-0.1,0.3,0,0.5,0.3,0.5l13.1,0c0.3,0,0.6-0.2,0.7-0.5l2.9-6.5C55.6,13.7,55.5,13.5,55.2,13.5L55.2,13.5z"
          fill="#768898"
        />
        <path
          d="M20.6,22.4L8,22.4c-0.3,0-0.6,0.2-0.7,0.5l-2.9,6.4c-0.1,0.3,0,0.5,0.2,0.5l12.6,0.1c0.3,0,0.6-0.2,0.7-0.5l2.9-6.4C21,22.7,20.9,22.4,20.6,22.4L20.6,22.4z"
          fill="#768898"
        />
        <path
          d="M35.8,22.4l-12.9,0c-0.3,0-0.6,0.2-0.7,0.5l-2.9,6.4c-0.1,0.3,0,0.5,0.3,0.5l12.9,0.1c0.3,0,0.6-0.2,0.7-0.5l2.9-6.5C36.2,22.7,36,22.4,35.8,22.4L35.8,22.4z"
          fill="#768898"
        />
        <path
          d="M51.2,22.4l-13.1,0c-0.3,0-0.6,0.2-0.7,0.5l-3.2,7L47.8,30c0.3,0,0.6-0.2,0.7-0.5l2.9-6.6C51.6,22.7,51.5,22.4,51.2,22.4L51.2,22.4z"
          fill="#768898"
        />
        <path
          d="M48.2,31L48.2,31l-9-0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0.1-0.1h0l9,0.1h0c0.2,0,0.5-0.3,0.6-0.5l4.7-10.5c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0,0.2L49,30.4C48.8,30.7,48.6,31,48.2,31L48.2,31z"
          fill="#ADB8C1"
        />
        <path
          d="M11.3,13.1C11.2,13.1,11.2,13.1,11.3,13.1c-0.1,0-0.1-0.1-0.1-0.2l4-8.7C15.3,4,15.7,3.8,16,3.8l9.8-0.1c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1L16,4c-0.2,0-0.5,0.1-0.6,0.3L11.3,13.1C11.3,13.1,11.3,13.1,11.3,13.1L11.3,13.1z"
          fill="#ADB8C1"
        />
      </g>
    </g>
  );
};
export const GridConnection: React.FC<EnergyFlowSVGProps> = ({
  posX,
  posY,
  value,
}) => {
  const { t } = useTranslation();
  return (
    <g transform={"translate(" + posX + "," + posY + ")"}>
      <text transform="translate(15,-22)" fontSize="11px" textAnchor="middle">
        {naming(t("demand"), t("delivery"), t("idle"), value.value)}
      </text>
      <text
        transform="translate(15,-5)"
        fontSize="14px"
        textAnchor="middle"
        fontWeight="bold"
      >
        {value.value} {value.unit}
      </text>

      <path
        d="M8.1,2.2H22c0.4,0,0.7,0.3,0.8,0.7l9.5,30.7c0.2,0.7-0.2,1.4-0.8,1.4H18.5c-0.6,0-1,0.7-0.8,1.4l7.9,24.5
        c0.4,1.1-0.9,1.9-1.5,1L2.5,26.2c-0.4-0.7,0-1.6,0.7-1.6h9.6c0.6,0,1-0.7,0.8-1.4L7.3,3.6C7.1,2.9,7.5,2.2,8.1,2.2L8.1,2.2z"
        fill="#768898"
      />
    </g>
  );
};
export const Battery: React.FC<EnergyFlowSVGProps> = ({
  posX,
  posY,
  value,
}) => {
  const { t } = useTranslation();
  return (
    <g transform={"translate(" + posX + "," + posY + ")"}>
      <text transform="translate(15,65)" fontSize="11px" textAnchor="middle">
        {naming(t("charge"), t("discharge"), t("idle"), value.value)}
      </text>
      <text
        transform="translate(15,82)"
        fontSize="14px"
        textAnchor="middle"
        fontWeight="bold"
      >
        {value.value} {value.unit}
      </text>

      <path
        d="M24,3.3H4c-1.4,0-2.5,0.9-2.5,2.1v43c0,1.2,1.1,2.1,2.5,2.1H24c1.4,0,2.5-0.9,2.5-2.1v-43
        C26.5,4.3,25.4,3.3,24,3.3z"
        fill="#adb8c1"
      />
      <path
        d="M20,0.5H8.2c-0.6,0-1.1,0.4-1.1,1V3h13.8V1.5C20.9,0.9,20.6,0.5,20,0.5L20,0.5z"
        fill="#768898"
      />
      <path
        d="M26.5,14v33.6c0,1.4-1.1,2.5-2.5,2.5H4c-1.4,0-2.5-1.1-2.5-2.5V14H26.5z"
        fill="#768898"
      />
      <path
        d="M19.3,25.3C19.2,25.3,19.2,25.3,19.3,25.3l-4.4,0c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1l1.8-5.9c0,0,0-0.1-0.1-0.1
        c0,0-0.1,0-0.1,0l-7.6,8.6c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1h3.9c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1l-1.6,6.8c0,0,0,0.1,0.1,0.1
        c0,0,0.1,0,0.1,0L19.3,25.3C19.3,25.4,19.3,25.4,19.3,25.3L19.3,25.3z"
        fill="#adb8c1"
      />
    </g>
  );
};
