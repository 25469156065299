import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPage: React.FC = () => {
  const { i18n } = useTranslation();

  var language = i18n.language;

  if (language === "de" || language === "de-DE") {
    return (
      <>
        <h1>Datenschutz&shy;erkl&auml;rung</h1>
        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>
        <p>
          Die folgenden Hinweise geben einen einfachen &Uuml;berblick
          dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
          diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
          denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
          Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
          unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
        </p>
        <h3>Datenerfassung auf dieser Website</h3>
        <h4>
          Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
        </h4>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
          &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
          Datenschutzerkl&auml;rung entnehmen.
        </p>
        <h4>Wie erfassen wir Ihre Daten?</h4>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie
          in ein Kontaktformular eingeben.
        </p>
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
          Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
          automatisch, sobald Sie diese Website betreten.
        </p>
        <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
          Analyse Ihres Nutzerverhaltens verwendet werden.
        </p>
        <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
          Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
          personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
          Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen.
          Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
          k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft
          widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten
          Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
        </p>
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
          sich jederzeit an uns wenden.
        </p>
        <h2>2. Hosting</h2>
        <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
        <h3>Externes Hosting</h3>
        <p>
          Diese Website wird extern gehostet. Die personenbezogenen Daten, die
          auf dieser Website erfasst werden, werden auf den Servern des Hosters
          / der Hoster gespeichert. Hierbei kann es sich v.&nbsp;a. um
          IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
          Daten, die &uuml;ber eine Website generiert werden, handeln.
        </p>
        <p>
          Das externe Hosting erfolgt zum Zwecke der Vertragserf&uuml;llung
          gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6
          Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
          &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
          Cookies oder den Zugriff auf Informationen im Endger&auml;t des
          Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
          Die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten,
          wie dies zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist
          und unsere Weisungen in Bezug auf diese Daten befolgen.
        </p>
        <p>Wir setzen folgende(n) Hoster ein:</p>
        <p>
          Microsoft Azure
          <br />
          Microsoft Corporation, One Microsoft Way, 98052-6399 Redmond WA,
          Vereinigte Staaten von Amerika (Microsoft Azure).
        </p>
        <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
        <h3>Datenschutz</h3>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
          Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
          vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
          sowie dieser Datenschutzerkl&auml;rung.
        </p>
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
          Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
          wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem
          Zweck das geschieht.
        </p>
        <p>
          Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
          (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
          aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
          durch Dritte ist nicht m&ouml;glich.
        </p>
        <h3>Hinweis zur verantwortlichen Stelle</h3>
        <p>
          Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <p>
          SCHULZ Systemtechnik GmbH <br />
          Schneiderkruger Stra&szlig;e 12
          <br />
          49429 Visbek
        </p>
        <p>
          Telefon: +49494445897157
          <br />
          E-Mail: Info@schulz.st
        </p>
        <p>
          Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
          Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
          E-Mail-Adressen o. &Auml;.) entscheidet.
        </p>
        <h3>Speicherdauer</h3>
        <p>
          Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt.
          Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
          Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
          gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen
          Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten
          haben (z.&nbsp;B. steuer- oder handelsrechtliche
          Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
          L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
        </p>
        <h3>
          Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
          dieser Website
        </h3>
        <p>
          Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
          wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit.
          a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
          Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle
          einer ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung
          personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung
          au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern
          Sie in die Speicherung von Cookies oder in den Zugriff auf
          Informationen in Ihr Endger&auml;t (z.&nbsp;B. via
          Device-Fingerprinting) eingewilligt haben, erfolgt die
          Datenverarbeitung zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1
          TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
          Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher
          Ma&szlig;nahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage
          des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre
          Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung
          erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
          Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
          Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die
          jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den
          folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.
        </p>
        <h3>Datenschutz&shy;beauftragter</h3>
        <p>Wir haben einen Datenschutzbeauftragten benannt.</p>
        <p>
          Schulz Systemtechnik GmbH
          <br />
          Datenschutzbeauftragte(r)
          <br />
          Schneiderkruger Str. 12
          <br />
          49429 Visbek
        </p>
        <p>
          Telefon: +494944458970
          <br />
          E-Mail: Datenschutz@schulz.st
        </p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
        <p>
          Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
          ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
          bereits erteilte Einwilligung jederzeit widerrufen. Die
          Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
        </p>
        <h3>
          Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
          sowie gegen Direktwerbung (Art. 21 DSGVO)
        </h3>
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE
          SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
          IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
          F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE
          JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
          ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH
          EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
          MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
          SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN,
          DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE
          VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG
          VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
        </p>
        <h3>
          Beschwerde&shy;recht bei der zust&auml;ndigen
          Aufsichts&shy;beh&ouml;rde
        </h3>
        <p>
          Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
          Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
          insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts,
          ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen
          Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
          anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an
          sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren
          Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
          &Uuml;bertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <h3>Auskunft, L&ouml;schung und Berichtigung</h3>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
        </p>
        <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>
        <p>
          Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
          jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der
          Verarbeitung besteht in folgenden F&auml;llen:
        </p>
        <ul>
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel
            Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
            Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten
            unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
            L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
            verlangen.
          </li>
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
            sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
            Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
            L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
        </ul>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
          eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
          Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
          Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
          nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
          wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union
          oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>
        <p>
          Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
          &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
          oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
          bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung
          erkennen Sie daran, dass die Adresszeile des Browsers von
          &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem
          Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p>
          Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
          die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten
          mitgelesen werden.
        </p>
        <h2>4. Datenerfassung auf dieser Website</h2>
        <h3>Server-Log-Dateien</h3>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          &uuml;bermittelt. Dies sind:
        </p>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>
        </ul>
        <p>
          Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird
          nicht vorgenommen.
        </p>
        <p>
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          der technisch fehlerfreien Darstellung und der Optimierung seiner
          Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst
          werden.
        </p>
        <h3>Registrierung auf dieser Website</h3>
        <p>
          Sie k&ouml;nnen sich auf dieser Website registrieren, um
          zus&auml;tzliche Funktionen auf der Seite zu nutzen. Die dazu
          eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des
          jeweiligen Angebotes oder Dienstes, f&uuml;r den Sie sich registriert
          haben. Die bei der Registrierung abgefragten Pflichtangaben
          m&uuml;ssen vollst&auml;ndig angegeben werden. Anderenfalls werden wir
          die Registrierung ablehnen.
        </p>
        <p>
          F&uuml;r wichtige &Auml;nderungen etwa beim Angebotsumfang oder bei
          technisch notwendigen &Auml;nderungen nutzen wir die bei der
          Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu
          informieren.
        </p>
        <p>
          Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
          zum Zwecke der Durchf&uuml;hrung des durch die Registrierung
          begr&uuml;ndeten Nutzungsverh&auml;ltnisses und ggf. zur Anbahnung
          weiterer Vertr&auml;ge (Art. 6 Abs. 1 lit. b DSGVO).
        </p>
        <p>
          Die bei der Registrierung erfassten Daten werden von uns gespeichert,
          solange Sie auf dieser Website registriert sind und werden
          anschlie&szlig;end gel&ouml;scht. Gesetzliche Aufbewahrungsfristen
          bleiben unber&uuml;hrt.
        </p>
      </>
    );
  }

  return (
    <>
      <h1>Privacy Policy</h1>
      <h2>1. An overview of data protection</h2>
      <h3>General information</h3>
      <p>
        The following information will provide you with an easy to navigate
        overview of what will happen with your personal data when you visit this
        website. The term &ldquo;personal data&rdquo; comprises all data that
        can be used to personally identify you. For detailed information about
        the subject matter of data protection, please consult our Data
        Protection Declaration, which we have included beneath this copy.
      </p>
      <h3>Data recording on this website</h3>
      <h4>
        Who is the responsible party for the recording of data on this website
        (i.e., the &ldquo;controller&rdquo;)?
      </h4>
      <p>
        The data on this website is processed by the operator of the website,
        whose contact information is available under section &ldquo;Information
        about the responsible party (referred to as the &ldquo;controller&rdquo;
        in the GDPR)&rdquo; in this Privacy Policy.
      </p>
      <h4>How do we record your data?</h4>
      <p>
        We collect your data as a result of your sharing of your data with us.
        This may, for instance be information you enter into our contact form.
      </p>
      <p>
        Other data shall be recorded by our IT systems automatically or after
        you consent to its recording during your website visit. This data
        comprises primarily technical information (e.g., web browser, operating
        system, or time the site was accessed). This information is recorded
        automatically when you access this website.
      </p>
      <h4>What are the purposes we use your data for?</h4>
      <p>
        A portion of the information is generated to guarantee the error free
        provision of the website. Other data may be used to analyze your user
        patterns.
      </p>
      <h4>What rights do you have as far as your information is concerned?</h4>
      <p>
        You have the right to receive information about the source, recipients,
        and purposes of your archived personal data at any time without having
        to pay a fee for such disclosures. You also have the right to demand
        that your data are rectified or eradicated. If you have consented to
        data processing, you have the option to revoke this consent at any time,
        which shall affect all future data processing. Moreover, you have the
        right to demand that the processing of your data be restricted under
        certain circumstances. Furthermore, you have the right to log a
        complaint with the competent supervising agency.
      </p>
      <p>
        Please do not hesitate to contact us at any time if you have questions
        about this or any other data protection related issues.
      </p>
      <h2>2. Hosting</h2>
      <p>
        We are hosting the content of our website at the following provider:
      </p>
      <h3>External Hosting</h3>
      <p>
        This website is hosted externally. Personal data collected on this
        website are stored on the servers of the host. These may include, but
        are not limited to, IP addresses, contact requests, metadata and
        communications, contract information, contact information, names, web
        page access, and other data generated through a web site.
      </p>
      <p>
        The external hosting serves the purpose of fulfilling the contract with
        our potential and existing customers (Art. 6(1)(b) GDPR) and in the
        interest of secure, fast, and efficient provision of our online services
        by a professional provider (Art. 6(1)(f) GDPR). If appropriate consent
        has been obtained, the processing is carried out exclusively on the
        basis of Art. 6 (1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the consent
        includes the storage of cookies or the access to information in the
        user's end device (e.g., device fingerprinting) within the meaning of
        the TTDSG. This consent can be revoked at any time.
      </p>
      <p>
        Our host(s) will only process your data to the extent necessary to
        fulfil its performance obligations and to follow our instructions with
        respect to such data.
      </p>
      <p>We are using the following host(s):</p>
      <p>
        Microsoft Azure
        <br />
        Microsoft Corporation, One Microsoft Way, 98052-6399 Redmond WA,
        Vereinigte Staaten von Amerika (Microsoft Azure).
      </p>
      <h2>3. General information and mandatory information</h2>
      <h3>Data protection</h3>
      <p>
        The operators of this website and its pages take the protection of your
        personal data very seriously. Hence, we handle your personal data as
        confidential information and in compliance with the statutory data
        protection regulations and this Data Protection Declaration.
      </p>
      <p>
        Whenever you use this website, a variety of personal information will be
        collected. Personal data comprises data that can be used to personally
        identify you. This Data Protection Declaration explains which data we
        collect as well as the purposes we use this data for. It also explains
        how, and for which purpose the information is collected.
      </p>
      <p>
        We herewith advise you that the transmission of data via the Internet
        (i.e., through e-mail communications) may be prone to security gaps. It
        is not possible to completely protect data against third-party access.
      </p>
      <h3>
        Information about the responsible party (referred to as the
        &ldquo;controller&rdquo; in the GDPR)
      </h3>
      <p>The data processing controller on this website is:</p>
      <p>
        SCHULZ Systemtechnik GmbH <br />
        Schneiderkruger Stra&szlig;e 12
        <br />
        49429 Visbek
      </p>
      <p>
        Phone: +49494445897157
        <br />
        E-mail: Info@schulz.st
      </p>
      <p>
        The controller is the natural person or legal entity that
        single-handedly or jointly with others makes decisions as to the
        purposes of and resources for the processing of personal data (e.g.,
        names, e-mail addresses, etc.).
      </p>
      <h3>Storage duration</h3>
      <p>
        Unless a more specific storage period has been specified in this privacy
        policy, your personal data will remain with us until the purpose for
        which it was collected no longer applies. If you assert a justified
        request for deletion or revoke your consent to data processing, your
        data will be deleted, unless we have other legally permissible reasons
        for storing your personal data (e.g., tax or commercial law retention
        periods); in the latter case, the deletion will take place after these
        reasons cease to apply.
      </p>
      <h3>
        General information on the legal basis for the data processing on this
        website
      </h3>
      <p>
        If you have consented to data processing, we process your personal data
        on the basis of Art. 6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if special
        categories of data are processed according to Art. 9 (1) DSGVO. In the
        case of explicit consent to the transfer of personal data to third
        countries, the data processing is also based on Art. 49 (1)(a) GDPR. If
        you have consented to the storage of cookies or to the access to
        information in your end device (e.g., via device fingerprinting), the
        data processing is additionally based on &sect; 25 (1) TTDSG. The
        consent can be revoked at any time. If your data is required for the
        fulfillment of a contract or for the implementation of pre-contractual
        measures, we process your data on the basis of Art. 6(1)(b) GDPR.
        Furthermore, if your data is required for the fulfillment of a legal
        obligation, we process it on the basis of Art. 6(1)(c) GDPR.
        Furthermore, the data processing may be carried out on the basis of our
        legitimate interest according to Art. 6(1)(f) GDPR. Information on the
        relevant legal basis in each individual case is provided in the
        following paragraphs of this privacy policy.
      </p>
      <h3>Designation of a data protection officer</h3>
      <p>We have appointed a data protection officer.</p>
      <p>
        Schulz Systemtechnik GmbH
        <br />
        Datenschutzbeauftragte(r)
        <br />
        Schneiderkruger Str. 12
        <br />
        49429 Visbek
      </p>
      <p>
        Phone: +494944458970
        <br />
        E-mail: Datenschutz@schulz.st
      </p>
      <h3>Revocation of your consent to the processing of data</h3>
      <p>
        A wide range of data processing transactions are possible only subject
        to your express consent. You can also revoke at any time any consent you
        have already given us. This shall be without prejudice to the lawfulness
        of any data collection that occurred prior to your revocation.
      </p>
      <h3>
        Right to object to the collection of data in special cases; right to
        object to direct advertising (Art. 21 GDPR)
      </h3>
      <p>
        IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F)
        GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR
        PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS
        ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE
        THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE
        CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE
        WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A
        POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE
        PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND
        FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING
        OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1)
        GDPR).
      </p>
      <p>
        IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT
        ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR
        AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING AT ANY TIME.
        THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH
        SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL
        SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES
        (OBJECTION PURSUANT TO ART. 21(2) GDPR).
      </p>
      <h3>Right to log a complaint with the competent supervisory agency</h3>
      <p>
        In the event of violations of the GDPR, data subjects are entitled to
        log a complaint with a supervisory agency, in particular in the member
        state where they usually maintain their domicile, place of work or at
        the place where the alleged violation occurred. The right to log a
        complaint is in effect regardless of any other administrative or court
        proceedings available as legal recourses.
      </p>
      <h3>Right to data portability</h3>
      <p>
        You have the right to demand that we hand over any data we automatically
        process on the basis of your consent or in order to fulfil a contract be
        handed over to you or a third party in a commonly used, machine readable
        format. If you should demand the direct transfer of the data to another
        controller, this will be done only if it is technically feasible.
      </p>
      <h3>Information about, rectification and eradication of data</h3>
      <p>
        Within the scope of the applicable statutory provisions, you have the
        right to at any time demand information about your archived personal
        data, their source and recipients as well as the purpose of the
        processing of your data. You may also have a right to have your data
        rectified or eradicated. If you have questions about this subject matter
        or any other questions about personal data, please do not hesitate to
        contact us at any time.
      </p>
      <h3>Right to demand processing restrictions</h3>
      <p>
        You have the right to demand the imposition of restrictions as far as
        the processing of your personal data is concerned. To do so, you may
        contact us at any time. The right to demand restriction of processing
        applies in the following cases:
      </p>
      <ul>
        <li>
          In the event that you should dispute the correctness of your data
          archived by us, we will usually need some time to verify this claim.
          During the time that this investigation is ongoing, you have the right
          to demand that we restrict the processing of your personal data.
        </li>
        <li>
          If the processing of your personal data was/is conducted in an
          unlawful manner, you have the option to demand the restriction of the
          processing of your data in lieu of demanding the eradication of this
          data.
        </li>
        <li>
          If we do not need your personal data any longer and you need it to
          exercise, defend or claim legal entitlements, you have the right to
          demand the restriction of the processing of your personal data instead
          of its eradication.
        </li>
        <li>
          If you have raised an objection pursuant to Art. 21(1) GDPR, your
          rights and our rights will have to be weighed against each other. As
          long as it has not been determined whose interests prevail, you have
          the right to demand a restriction of the processing of your personal
          data.
        </li>
      </ul>
      <p>
        If you have restricted the processing of your personal data, these data
        &ndash; with the exception of their archiving &ndash; may be processed
        only subject to your consent or to claim, exercise or defend legal
        entitlements or to protect the rights of other natural persons or legal
        entities or for important public interest reasons cited by the European
        Union or a member state of the EU.
      </p>
      <h3>SSL and/or TLS encryption</h3>
      <p>
        For security reasons and to protect the transmission of confidential
        content, such as purchase orders or inquiries you submit to us as the
        website operator, this website uses either an SSL or a TLS encryption
        program. You can recognize an encrypted connection by checking whether
        the address line of the browser switches from &ldquo;http://&rdquo; to
        &ldquo;https://&rdquo; and also by the appearance of the lock icon in
        the browser line.
      </p>
      <p>
        If the SSL or TLS encryption is activated, data you transmit to us
        cannot be read by third parties.
      </p>
      <h2>4. Recording of data on this website</h2>
      <h3>Server log files</h3>
      <p>
        The provider of this website and its pages automatically collects and
        stores information in so-called server log files, which your browser
        communicates to us automatically. The information comprises:
      </p>
      <ul>
        <li>The type and version of browser used</li>
        <li>The used operating system</li> <li>Referrer URL</li>
        <li>The hostname of the accessing computer</li>
        <li>The time of the server inquiry</li> <li>The IP address</li>
      </ul>
      <p>This data is not merged with other data sources.</p>
      <p>
        This data is recorded on the basis of Art. 6(1)(f) GDPR. The operator of
        the website has a legitimate interest in the technically error free
        depiction and the optimization of the operator&rsquo;s website. In order
        to achieve this, server log files must be recorded.
      </p>
      <h3>Registration on this website</h3>
      <p>
        You have the option to register on this website to be able to use
        additional website functions. We shall use the data you enter only for
        the purpose of using the respective offer or service you have registered
        for. The required information we request at the time of registration
        must be entered in full. Otherwise, we shall reject the registration.
      </p>
      <p>
        To notify you of any important changes to the scope of our portfolio or
        in the event of technical modifications, we shall use the e-mail address
        provided during the registration process.
      </p>
      <p>
        We shall process the data entered during the registration process on the
        basis of your consent (Art. 6(1)(a) GDPR).
      </p>
      <p>
        The data recorded during the registration process shall be stored by us
        as long as you are registered on this website. Subsequently, such data
        shall be deleted. This shall be without prejudice to mandatory statutory
        retention obligations.
      </p>
    </>
  );
};

export default PrivacyPage;
