import { createTheme, ThemeProvider } from "@mui/material";
import React, { useMemo, useState } from "react";
import "@fontsource/open-sans";
import { createThemePalette } from "context/ThemeContext/theme-palette";
import { createThemeStyles } from "context/ThemeContext/theme-styles";
import { useInitialThemeMode } from "hooks/use-initial-theme-mode";
import { useMobileBreakpoint } from "hooks/use-breakpoint";
import useDatagridTranslation from "hooks/use-datagrid-translation";
import { GridLocaleText } from "@mui/x-data-grid";
type ContainerMaxWidth = "md" | "lg" | "xl";

declare module "@mui/material/styles" {
  interface Theme {
    drawerWidth: number;
    drawerHeight: {
      header: number;
      footer: number;
      divider: number;
    };
    layoutContainerHeight: {
      header: {
        height: number;
        spacing: number;
      };
      footer: {
        height: number;
        spacing: number;
      };
    };
    containerMaxWidth: ContainerMaxWidth;
    dataGridTranslations: Partial<GridLocaleText>;
  }

  export interface Palette {
    frame: string;
    chips: { highlighted: string };
    chartPrimary: { main: string };
    battery: { shell: string; default: string; low: string };
    customBackground: { hover: string; selected: string };
    drawerIcon: string;
    stateIndicator: {
      paper: string;
      default: string;
    };
    devicesPage: {
      graphicsOpacity: number;
    };
    icons: {
      battery: string;
      photovoltaic: string;
      inverter: string;
      default: string;
    };
    energyFlowIllustration: {
      dots: string;
      negativ: string;
      positiv: string;
    };
  }

  interface PaletteOptions {
    frame: string;
    chips: { highlighted: string };
    chartPrimary: { main: string };
    battery: { shell: string; default: string; low: string };
    customBackground: { hover: string; selected: string };
    drawerIcon: string;
    stateIndicator: {
      paper: string;
      default: string;
    };
    devicesPage: {
      graphicsOpacity: number;
    };
    icons: {
      battery: string;
      photovoltaic: string;
      inverter: string;
      default: string;
    };
    energyFlowIllustration: {
      dots: string;
      negativ: string;
      positiv: string;
    };
  }

  interface ThemeOptions {
    drawerWidth?: number;
    drawerHeight?: {
      header?: number;
      footer?: number;
      divider?: number;
    };
    layoutContainerHeight: {
      header: {
        height: number;
        spacing: number;
      };
      footer: {
        height: number;
        spacing: number;
      };
    };
    containerMaxWidth?: ContainerMaxWidth;
    dataGridTranslations: Partial<GridLocaleText>;
  }
}

interface ThemeContextData {
  currentTheme: string;
  toggleDarkMode: () => void;
}

const ThemeContext = React.createContext<ThemeContextData>({
  currentTheme: "light",
  toggleDarkMode: () => {},
});

export const ThemeContextProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  let initialMode = useInitialThemeMode();
  const isMobile = useMobileBreakpoint();
  const [mode, setMode] = useState<string>(initialMode);
  const dataGridTranslations = useDatagridTranslation();

  const { palette, components } = useMemo(() => {
    const palette = createThemePalette(mode);
    const components = createThemeStyles(palette);
    return { palette, components };
  }, [mode]);

  const theme = createTheme({
    drawerWidth: isMobile ? 220 : 90,
    drawerHeight: {
      header: 56,
      footer: 94,
      divider: 2.5,
    },
    layoutContainerHeight: {
      header: {
        height: isMobile ? 56 : 64,
        spacing: 16,
      },
      footer: {
        height: 96,
        spacing: 16,
      },
    },
    containerMaxWidth: "xl",
    typography: {
      fontFamily: ["open sans", "Helvetica Neue", "Arial", "sans-serif"].join(
        ","
      ),
    },
    palette: palette,
    components: components,
    dataGridTranslations: dataGridTranslations,
  });

  const toggleDarkModeHandler = () => {
    setMode((prev) => {
      const newMode = prev === "dark" ? "light" : "dark";
      localStorage.setItem("palette-mode", newMode);
      return newMode;
    });
  };

  const contextValue = {
    toggleDarkMode: toggleDarkModeHandler,
    currentTheme: mode,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
