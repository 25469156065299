import { DateRange } from "@mui/lab/DateRangePicker/DateRangePicker";

const calcuateDiffDays = (maxDate: Date, minDate: Date): number => {
  const diffInMs = maxDate.getTime() - minDate.getTime();
  return Math.ceil(diffInMs / 1000 / 3600 / 24);
};

type MaybeDateString = string | null | undefined;

type MaybeDate = Date | null;

export const subtractDays = (date: Date, days: number) => {
  const newDate = new Date();
  newDate.setDate(date.getDate() - days);
  return newDate;
};

export const toDateOrNull = (maybeDateStr: MaybeDateString): MaybeDate => {
  return maybeDateStr ? new Date(maybeDateStr) : null;
};

export const minDateOrMaxPastDays = (
  minDate: MaybeDate,
  maxDate: MaybeDate,
  maxPastDays: number
): MaybeDate => {
  if (!minDate || !maxDate) {
    return null;
  }

  const diffDays = calcuateDiffDays(maxDate, minDate);

  if (diffDays <= maxPastDays) {
    return minDate;
  }

  return subtractDays(maxDate, maxPastDays);
};

export const getDateRangeUntilNow = (nDaysAgo: number): DateRange<Date> => {
  const now = new Date();
  const from = subtractDays(now, nDaysAgo);
  return [from, now];
};

export const toStartOfDay = (date: Date) => {
  const copy = new Date(date);
  copy.setHours(0);
  copy.setMinutes(0);
  copy.setSeconds(0);
  copy.setMilliseconds(0);
  return copy;
};

export const toStartOfMonth = (date: Date) => {
  const copy = new Date(date);
  copy.setDate(1);
  copy.setHours(0);
  copy.setMinutes(0);
  copy.setSeconds(0);
  copy.setMilliseconds(0);
  return copy;
};

export const toEndOfMonthOrNow = (date: Date) => {
  const lastDayOfMonth = new Date(2023, date.getMonth() + 1, 0);

  if (lastDayOfMonth.valueOf() > Date.now()) {
    return toStartOfDay(new Date(Date.now()));
  }

  return lastDayOfMonth;
};

export const toEndOfDay = (date: Date) => {
  const copy = new Date(date);
  copy.setHours(23);
  copy.setMinutes(59);
  copy.setSeconds(59);
  copy.setMilliseconds(999);
  return copy;
};

export const toUnixTimestamp = (date: Date) => {
  return Math.floor(date.getTime() / 1000);
};
