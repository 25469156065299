import React from "react";
import { Box } from "@mui/material";
import { DeviceType } from "types/Common";
import { EnergyFlowRole } from "types/Dashboard";
import AnimatedEnergyFlowArrow from "./AnimatedEnergyFlowArrow";
import { inRange } from "utils/helpers/math-helpers";

interface StateIndicatorProps {
  state: {
    deviceType: DeviceType;
    energyFlowRole: EnergyFlowRole;
    value: number;
  };
}

const StateIndicator: React.FC<StateIndicatorProps> = ({ state }) => {
  const threshold = 100;
  const { energyFlowRole, value } = state;

  const matchRole = (role: EnergyFlowRole) => {
    return energyFlowRole === role;
  };

  const getArrowProps = () => {
    const arrowDirection: "left" | "right" =
      (matchRole("Producer") && value > 0) ||
      (matchRole("Battery") && value < 0) ||
      (matchRole("GridConnection") && value > 0)
        ? "right"
        : "left";
    const arrowColor = arrowDirection === "right" ? "#089191" : "#CC7A00";
    return {
      arrowDirection,
      arrowColor,
    };
  };

  const { arrowDirection, arrowColor } = getArrowProps();

  return (
    <Box
      sx={{
        width: 100,
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!inRange(value, [-threshold, threshold]) && (
        <AnimatedEnergyFlowArrow
          arrowDirection={arrowDirection}
          arrowColor={arrowColor}
        />
      )}
    </Box>
  );
};

export default StateIndicator;
