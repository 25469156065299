import { SvgIcon, useTheme } from "@mui/material";

const PhotovoltaicSvg = () => {
  const theme = useTheme();
  return (
    <SvgIcon
      viewBox="10 40 278 189"
      sx={{
        overflow: "visible",
        height: "230%",
        width: "230%",
        display: "flex",
      }}
    >
      <g
        clipPath="url(#clip0_116_253)"
        opacity={theme.palette.devicesPage.graphicsOpacity}
      >
        <path
          d="M133.09 48.7765H106.25V183.906H133.09V48.7765Z"
          fill="#46525C"
        />
        <path
          d="M147.468 188.014C147.468 188.329 147.667 188.588 147.296 188.59L91.1678 189C89.9027 189.009 88.9969 188.001 88.9969 186.749V183.946C88.9969 183.326 89.3894 182.726 89.8391 182.286C90.2902 181.844 90.9689 181.599 91.5962 181.61L147.293 182.57C147.652 182.576 147.468 182.821 147.468 183.126V188.014H147.468Z"
          fill="#46525C"
        />
        <path
          d="M276.504 -0.000234292L61.9156 2.65938C60.7254 2.67392 59.3068 3.58226 58.7458 4.68771L0.234924 120.056C-0.334258 121.178 0.166786 122.094 1.35512 122.101L215.63 123.386C216.898 123.394 218.391 122.459 218.964 121.299L277.783 2.04035C278.347 0.897659 277.774 -0.0156761 276.504 -0.000234292Z"
          fill="#ADB8C1"
        />
        <path
          d="M123.145 9.94357L64.7551 10.5958C63.5627 10.6094 62.1413 11.5182 61.5799 12.6259L48.1371 39.154C47.5738 40.2658 48.083 41.1609 49.2746 41.1532L107.641 40.774C108.854 40.7663 110.296 39.8497 110.861 38.727L124.325 11.9442C124.888 10.8256 124.359 9.92995 123.144 9.94357H123.145Z"
          fill="#768898"
        />
        <path
          d="M193.35 9.15922L133.713 9.82503C132.495 9.83866 131.052 10.7574 130.49 11.877L117.023 38.6848C116.459 39.8084 116.988 40.7131 118.205 40.7054L177.82 40.318C179.06 40.3098 180.524 39.3833 181.09 38.2488L194.576 11.1807C195.14 10.0503 194.591 9.14514 193.35 9.15922H193.35Z"
          fill="#768898"
        />
        <path
          d="M265.071 8.35807L204.146 9.03842C202.902 9.0525 201.437 9.98082 200.874 11.1122L187.385 38.2061C186.82 39.3415 187.37 40.2558 188.613 40.2481L249.519 39.8525C250.786 39.8443 252.272 38.9078 252.838 37.761L266.345 10.4014C266.909 9.25869 266.338 8.34399 265.071 8.35807H265.071Z"
          fill="#768898"
        />
        <path
          d="M104.778 46.4665L46.4168 46.7954C45.2253 46.8017 43.8021 47.7096 43.238 48.8228L29.7329 75.4739C29.1669 76.5911 29.6734 77.4959 30.8649 77.495L89.2018 77.4418C90.4151 77.4405 91.8588 76.5253 92.4257 75.3976L105.953 48.4889C106.519 47.3649 105.992 46.4597 104.778 46.4665Z"
          fill="#768898"
        />
        <path
          d="M174.953 46.0714L115.342 46.407C114.125 46.4138 112.68 47.3313 112.115 48.4558L98.5853 75.3899C98.0184 76.5189 98.5444 77.4332 99.7609 77.4318L159.35 77.3778C160.589 77.3764 162.055 76.4513 162.623 75.3113L176.174 48.1152C176.74 46.9793 176.193 46.0641 174.953 46.0714Z"
          fill="#768898"
        />
        <path
          d="M246.647 45.6677L185.745 46.0106C184.501 46.0174 183.035 46.9448 182.469 48.0816L168.916 75.3036C168.349 76.4445 168.895 77.3687 170.138 77.3678L231.02 77.3124C232.286 77.3115 233.775 76.3759 234.343 75.2237L247.915 47.7332C248.482 46.5851 247.914 45.6604 246.647 45.6672L246.647 45.6677Z"
          fill="#768898"
        />
        <path
          d="M86.3254 83.1612L27.9931 83.1635C26.802 83.1635 25.3775 84.07 24.8106 85.1882L11.2429 111.964C10.6741 113.086 11.1779 114.001 12.3685 114.006L70.6759 114.282C71.8883 114.288 73.3337 113.374 73.9034 112.241L87.4947 85.2059C88.0625 84.0768 87.5388 83.1616 86.3254 83.1616V83.1612Z"
          fill="#768898"
        />
        <path
          d="M156.469 83.1589L96.8842 83.1612C95.6677 83.1612 94.2213 84.0773 93.6535 85.2077L80.0599 112.268C79.4903 113.403 80.0136 114.327 81.2296 114.333L140.791 114.615C142.03 114.621 143.498 113.697 144.068 112.551L157.684 85.2259C158.252 84.0845 157.708 83.1594 156.469 83.1598V83.1589Z"
          fill="#768898"
        />
        <path
          d="M228.134 83.1562L167.256 83.1585C166.013 83.1585 164.545 84.0845 163.976 85.2268L149.325 114.654L212.431 114.953C213.697 114.959 215.187 114.025 215.759 112.868L229.396 85.2449C229.965 84.0913 229.4 83.1562 228.133 83.1562H228.134Z"
          fill="#768898"
        />
        <path
          d="M214.246 119.225H214.233L172.74 119.006C172.476 119.004 172.262 118.789 172.263 118.524C172.265 118.26 172.479 118.047 172.743 118.047H172.745L214.238 118.267H214.246C215.241 118.267 216.347 117.114 216.885 116.026L238.568 72.0867C238.684 71.8496 238.972 71.752 239.21 71.8692C239.447 71.9863 239.545 72.2734 239.427 72.5109L217.744 116.45C217.083 117.789 215.708 119.226 214.246 119.226L214.246 119.225Z"
          fill="#ADB8C1"
        />
        <path
          d="M42.874 44.0817C42.8009 44.0817 42.7273 44.0654 42.6578 44.03C42.422 43.9105 42.3275 43.6226 42.447 43.3859L60.9784 6.82891C61.6261 5.54997 63.4141 4.9491 64.8009 4.9323L110.432 4.38684C110.677 4.37231 110.913 4.59531 110.917 4.86054C110.92 5.12487 110.708 5.34196 110.443 5.34514L64.8123 5.8906C63.6984 5.90422 62.2757 6.38928 61.8333 7.26219L43.3019 43.8188C43.2179 43.9854 43.0493 44.0813 42.874 44.0813V44.0817Z"
          fill="#ADB8C1"
        />
      </g>
    </SvgIcon>
  );
};

export default PhotovoltaicSvg;
