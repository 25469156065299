import { MeasurementUnit, MeasurementUnitHandler } from "types/Measurements";

class ElectricPotentialUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "V";
  }

  convertFromBaseValue(value: number): number {
    return value;
  }
}

class ElectricCurrentUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "A";
  }

  convertFromBaseValue(value: number): number {
    return value;
  }
}

class RelativeHumidityUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "%RH";
  }

  convertFromBaseValue(value: number): number {
    return value;
  }
}

class PowerUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "W";
  }

  convertFromBaseValue(value: number): number {
    return value;
  }
}

class EnergyUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "Wh";
  }

  convertFromBaseValue(value: number): number {
    return value / 3600;
  }
}

class RatioUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "%";
  }

  convertFromBaseValue(value: number): number {
    return value * 100;
  }
}

class TemperatureUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "°C";
  }

  convertFromBaseValue(value: number): number {
    return value - 273.15;
  }
}

class ReactivePowerUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "var";
  }

  convertFromBaseValue(value: number): number {
    return value;
  }
}

class ReactiveEnergyUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "varh";
  }

  convertFromBaseValue(value: number): number {
    return value;
  }
}

class ApparentPowerUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "VA";
  }

  convertFromBaseValue(value: number): number {
    return value;
  }
}

class ApparentEnergyUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "VAh";
  }

  convertFromBaseValue(value: number): number {
    return value;
  }
}

class FrequencyUnitHandler implements MeasurementUnitHandler {
  symbol: string;

  constructor() {
    this.symbol = "Hz";
  }

  convertFromBaseValue(value: number): number {
    return value;
  }
}

export const createMeasurementUnitHandler = (
  unit: MeasurementUnit
): MeasurementUnitHandler => {
  switch (unit) {
    case "Temperature": {
      return new TemperatureUnitHandler();
    }

    case "Ratio": {
      return new RatioUnitHandler();
    }

    case "Energy": {
      return new EnergyUnitHandler();
    }

    case "Power": {
      return new PowerUnitHandler();
    }

    case "RelativeHumidity": {
      return new RelativeHumidityUnitHandler();
    }

    case "ElectricCurrent": {
      return new ElectricCurrentUnitHandler();
    }

    case "ElectricPotential": {
      return new ElectricPotentialUnitHandler();
    }

    case "ReactivePower": {
      return new ReactivePowerUnitHandler();
    }

    case "ReactiveEnergy": {
      return new ReactiveEnergyUnitHandler();
    }

    case "ApparentPower": {
      return new ApparentPowerUnitHandler();
    }

    case "ApparentEnergy": {
      return new ApparentEnergyUnitHandler();
    }

    case "Frequency": {
      return new FrequencyUnitHandler();
    }
  }

  // TODO throw error on unknown measurement!
  return new TemperatureUnitHandler();
};
