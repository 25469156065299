import React from "react";
import Drawer from "@mui/material/Drawer";
import { useDrawer } from "hooks/use-drawer";

const MobileDrawer: React.FC<{ children: React.ReactNode }> = (props) => {
  const { isOpen, toggleDrawer } = useDrawer();

  return (
    <Drawer
      variant="temporary"
      open={isOpen}
      onClose={toggleDrawer}
      ModalProps={{ keepMounted: true }} //Better open performance on mobile
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: (theme) => `calc(${theme.drawerWidth}px * 1.2)`,
          maxWidth: "90%",
        },
      }}
    >
      {props.children}
    </Drawer>
  );
};

export default MobileDrawer;
