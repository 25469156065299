import { SvgIcon, useTheme } from "@mui/material";

const PhotovoltaicSvg = () => {
  const theme = useTheme();
  return (
    <SvgIcon
      viewBox="60 100 248 350"
      sx={{
        overflow: "visible",
        height: "270%",
        width: "220%",
        display: "flex",
      }}
    >
      <g opacity={theme.palette.devicesPage.graphicsOpacity}>
        <path
          d="M8.56693 90.0942C7.21526 90.0942 6.11914 88.9984 6.11914 87.6467V69.9011C6.11914 68.5494 7.21499 67.4536 8.56693 67.4536C9.91888 67.4536 11.0147 68.5494 11.0147 69.9011V87.6467C11.0147 88.9984 9.91888 90.0942 8.56693 90.0942Z"
          fill="#768898"
        />
        <path
          d="M17.7457 77.55C17.7457 78.733 16.7869 79.6917 15.604 79.6917H2.14168C0.958727 79.6917 0 78.733 0 77.55C0 76.3671 0.959002 75.4083 2.14168 75.4083H15.6037C16.7867 75.4083 17.7454 76.3673 17.7454 77.55H17.7457Z"
          fill="#768898"
        />
        <path
          d="M17.7457 87.9526C17.7457 89.1355 16.7869 90.0943 15.604 90.0943H2.14168C0.958727 90.0943 0 89.1355 0 87.9526C0 86.7696 0.959002 85.8109 2.14168 85.8109H15.6037C16.7867 85.8109 17.7454 86.7696 17.7454 87.9526H17.7457Z"
          fill="#768898"
        />
        <path
          d="M238.035 90.0942C236.684 90.0942 235.588 88.9984 235.588 87.6467V69.9011C235.588 68.5494 236.683 67.4536 238.035 67.4536C239.387 67.4536 240.483 68.5494 240.483 69.9011V87.6467C240.483 88.9984 239.387 90.0942 238.035 90.0942Z"
          fill="#768898"
        />
        <path
          d="M247.214 77.55C247.214 78.733 246.255 79.6917 245.072 79.6917H231.61C230.427 79.6917 229.469 78.733 229.469 77.55C229.469 76.3671 230.427 75.4083 231.61 75.4083H245.072C246.255 75.4083 247.214 76.3673 247.214 77.55Z"
          fill="#768898"
        />
        <path
          d="M247.214 87.9526C247.214 89.1355 246.255 90.0943 245.072 90.0943H231.61C230.427 90.0943 229.469 89.1355 229.469 87.9526C229.469 86.7696 230.427 85.8109 231.61 85.8109H245.072C246.255 85.8109 247.214 86.7696 247.214 87.9526Z"
          fill="#768898"
        />
        <path
          d="M234.976 176.374C233.624 176.374 232.528 175.278 232.528 173.926V156.181C232.528 154.829 233.624 153.733 234.976 153.733C236.327 153.733 237.424 154.829 237.424 156.181V173.926C237.424 175.278 236.328 176.374 234.976 176.374Z"
          fill="#768898"
        />
        <path
          d="M244.154 163.83C244.154 165.013 243.195 165.972 242.013 165.972H228.551C227.368 165.972 226.409 165.013 226.409 163.83C226.409 162.647 227.368 161.688 228.551 161.688H242.013C243.196 161.688 244.154 162.647 244.154 163.83Z"
          fill="#768898"
        />
        <path
          d="M244.154 174.233C244.154 175.416 243.195 176.374 242.013 176.374H228.551C227.368 176.374 226.409 175.415 226.409 174.233C226.409 173.05 227.368 172.091 228.551 172.091H242.013C243.196 172.091 244.154 173.05 244.154 174.233Z"
          fill="#768898"
        />
        <path
          d="M8.56693 176.374C7.21526 176.374 6.11914 175.278 6.11914 173.926V156.181C6.11914 154.829 7.21499 153.733 8.56693 153.733C9.91888 153.733 11.0147 154.829 11.0147 156.181V173.926C11.0147 175.278 9.91888 176.374 8.56693 176.374Z"
          fill="#768898"
        />
        <path
          d="M17.7457 163.83C17.7457 165.013 16.7869 165.972 15.604 165.972H2.14168C0.958727 165.972 0 165.013 0 163.83C0 162.647 0.959002 161.688 2.14168 161.688H15.6037C16.7867 161.688 17.7454 162.647 17.7454 163.83H17.7457Z"
          fill="#768898"
        />
        <path
          d="M17.7457 174.233C17.7457 175.416 16.7869 176.374 15.604 176.374H2.14168C0.958727 176.374 0 175.415 0 174.233C0 173.05 0.959002 172.091 2.14168 172.091H15.6037C16.7867 172.091 17.7454 173.05 17.7454 174.233H17.7457Z"
          fill="#768898"
        />
        <path
          d="M199.981 350C198.791 350 197.747 349.13 197.564 347.917L145.761 5.14017L100.252 10.18L50.6951 347.907C50.4986 349.244 49.2574 350.168 47.9181 349.974C46.5804 349.777 45.6555 348.534 45.8517 347.196L95.6832 7.59977C95.8448 6.49843 96.7293 5.64522 97.8354 5.52267L147.56 0.0159625C148.866 -0.133521 150.054 0.783164 150.25 2.0829L202.404 347.186C202.606 348.523 201.686 349.77 200.349 349.972C200.225 349.99 200.102 350 199.981 350L199.981 350Z"
          fill="#768898"
        />
        <path
          d="M241.514 72.9606H7.23988C6.1237 72.9606 5.14876 72.2052 4.86985 71.1242C4.59094 70.0437 5.07869 68.9108 6.05555 68.3706L95.0765 19.149C96.2583 18.4959 97.7485 18.9232 98.4028 20.1069C99.0567 21.2902 98.6281 22.7792 97.4451 23.4335L16.7252 68.0653H232.524L148.701 18.769C147.536 18.0837 147.147 16.5836 147.832 15.4183C148.517 14.2529 150.018 13.8646 151.183 14.5488L242.755 68.4027C243.707 68.9627 244.166 70.0902 243.876 71.1558C243.586 72.2214 242.619 72.9606 241.515 72.9606H241.514Z"
          fill="#768898"
        />
        <path
          d="M237.843 158.629H7.23981C6.25992 158.629 5.37429 158.044 4.98904 157.143C4.60379 156.242 4.79339 155.198 5.47074 154.489L87.3157 68.8904C88.2496 67.9133 89.7989 67.8787 90.7763 68.8126C91.7534 69.7472 91.7883 71.2967 90.8541 72.2738L12.9669 153.733H232.221L156.3 72.1724C155.379 71.1829 155.434 69.634 156.424 68.7132C157.413 67.7913 158.963 67.8473 159.883 68.8368L239.634 154.513C240.298 155.226 240.476 156.265 240.087 157.158C239.698 158.051 238.817 158.629 237.843 158.629Z"
          fill="#768898"
        />
        <path
          d="M157.53 69.0858C156.909 69.0858 156.287 68.8508 155.81 68.3796L96.3853 9.69664C95.4235 8.74698 95.4136 7.19747 96.3635 6.23544C97.3138 5.27342 98.8635 5.26325 99.825 6.21401L159.25 64.897C160.212 65.8466 160.222 67.3961 159.272 68.3582C158.793 68.8429 158.162 69.0861 157.531 69.0861L157.53 69.0858Z"
          fill="#768898"
        />
        <path
          d="M89.4935 69.0858C88.906 69.0858 88.3168 68.8756 87.8478 68.4494C86.8473 67.5404 86.7736 65.9923 87.6829 64.9918L146.019 0.802402C146.928 -0.197818 148.475 -0.27146 149.476 0.637531C150.476 1.54652 150.55 3.09466 149.641 4.09516L91.3051 68.2848C90.8223 68.816 90.159 69.0861 89.4932 69.0861L89.4935 69.0858Z"
          fill="#768898"
        />
        <path
          d="M60.0374 266.271C59.3993 266.271 58.7621 266.023 58.2823 265.53C57.3398 264.561 57.3615 263.012 58.3307 262.069L166.96 156.419L86.6325 72.1994C85.6993 71.2211 85.7361 69.6716 86.7144 68.7387C87.6921 67.8067 89.2416 67.8418 90.175 68.8206L172.175 154.795C173.102 155.766 173.073 157.303 172.111 158.239L61.7438 265.578C61.2681 266.041 60.6523 266.272 60.0374 266.272V266.271Z"
          fill="#768898"
        />
        <path
          d="M186.313 265.66C185.698 265.66 185.083 265.43 184.607 264.966L74.2403 157.627C73.2777 156.691 73.2489 155.154 74.1755 154.183L156.176 68.2087C157.109 67.2299 158.658 67.1933 159.636 68.1268C160.615 69.0597 160.651 70.6092 159.718 71.5874L79.3909 155.808L188.02 261.457C188.989 262.4 189.011 263.949 188.068 264.919C187.589 265.412 186.951 265.66 186.313 265.66Z"
          fill="#768898"
        />
        <path
          d="M187.858 269.998H59.9678C58.6161 269.998 57.52 268.902 57.52 267.55C57.52 266.198 58.6159 265.102 59.9678 265.102H187.858C189.21 265.102 190.306 266.198 190.306 267.55C190.306 268.902 189.21 269.998 187.858 269.998H187.858Z"
          fill="#768898"
        />
        <path
          d="M48.7863 346.487C48.3081 346.487 47.8253 346.348 47.4013 346.057C46.2874 345.29 46.0054 343.767 46.7715 342.653L99.3688 266.16C100.135 265.046 101.658 264.763 102.773 265.53C103.887 266.296 104.168 267.82 103.402 268.934L50.8051 345.427C50.3306 346.117 49.5647 346.487 48.7863 346.487Z"
          fill="#768898"
        />
        <path
          d="M199.906 346.487C199.127 346.487 198.362 346.117 197.887 345.427L145.29 268.934C144.524 267.82 144.806 266.296 145.92 265.53C147.034 264.763 148.558 265.046 149.323 266.16L201.921 342.653C202.687 343.767 202.405 345.29 201.291 346.056C200.867 346.348 200.384 346.487 199.906 346.487Z"
          fill="#768898"
        />
      </g>
    </SvgIcon>
  );
};

export default PhotovoltaicSvg;
