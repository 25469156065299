import { addOpacity } from "utils/helpers/converters";

export const createThemeStyles = (palette: any) => {
  const components = {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "&& > fieldset": {
              borderColor: palette.secondary.main,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: palette.text.primary,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: palette.customBackground.hover,
          },
          "&&.Mui-selected": {
            backgroundColor: palette.customBackground.selected,
          },
        },
      },
    },
    MuiDateRangePickerDay: {
      styleOverrides: {
        root: {
          "&&.MuiDateRangePickerDay-rangeIntervalDayHighlight": {
            background: palette.customBackground.hover,
          },
          "&& .MuiButtonBase-root": {
            "&.Mui-selected": {
              background: palette.customBackground.selected,
              "&:hover": {
                background: palette.customBackground.selected,
              },
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: addOpacity(palette.customBackground.hover, 0.4),
            borderColor: palette.text.primary,
          },
          color: palette.text.primary,
          borderColor: palette.secondary.main,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
            {
              outline: "none",
            },
          "& .MuiDataGrid-columnHeader": {
            fontSize: 15,
            color: palette.text.secondary,
          },
          "& .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
          },
        },
      },
    },
  };

  return components;
};
