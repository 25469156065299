import {
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

interface EditToolbarProps {
  setNewRow: (newRow: GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  t: (key: string) => string;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  pageIsFull: boolean;
}

const EditToolbar = (props: EditToolbarProps) => {
  const { setNewRow, setRowModesModel, t, setPageSize, pageIsFull } = props;

  const handleClick = () => {
    const id = `temporaryNewId-${crypto.randomUUID()}`;
    pageIsFull && setPageSize((prev) => prev + 1);
    setNewRow([
      {
        id: id,
        name: "",
        longitude: undefined,
        latitude: undefined,
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: {
        mode: GridRowModes.Edit,
        fieldToFocus: "name",
      },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        {t("addLocation")}
      </Button>
    </GridToolbarContainer>
  );
};

export default EditToolbar;
