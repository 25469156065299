import { Box, LinearProgress, useTheme } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridPreProcessEditCellProps,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CopyField from "components/UI/CopyField";
import { useDevicesApiClient } from "hooks/use-api-client";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Location, LocationData } from "types/Devices";
import { useAuth0 } from "@auth0/auth0-react";
import {
  handleCancelClick,
  handleDeleteClick,
  handleEditClick,
  handleRowEditStart,
  handleRowEditStop,
  handleSaveClick,
} from "./eventHandlers";
import EditToolbar from "./EditToolbar";

const dataGridNameValidation = (value?: string) => {
  const isValid = value && value.trim().length > 0;
  return isValid;
};

const dataGridCoordinateValidation = (
  coordType: "latitude" | "longitude",
  value?: number
) => {
  if (value && isNaN(value)) {
    return false;
  }

  if (coordType === "latitude") {
    return value && value >= -90 && value <= 90;
  }

  if (coordType === "longitude") {
    return value && value >= -180 && value <= 180;
  }

  return false;
};

const LocationEditorTable = () => {
  const targetPageSize = 4;

  const queryClient = useQueryClient();
  const theme = useTheme();
  const { user } = useAuth0();
  const { t } = useTranslation();
  const { getLocations, createLocation, updateLocation, deleteLocation } =
    useDevicesApiClient();

  // Is needed because the PageSize has to be increased by one temporarily when an empty line for entering a new location is displayed
  const [pageSize, setPageSize] = useState(targetPageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageIsFull, setPageIsFull] = useState(false);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [newRow, setNewRow] = useState<GridRowsProp>();
  const [newRowTouched, setNewRowTouched] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const { data, isLoading, isFetching } = useQuery(
    ["getLocations", `Page: ${currentPage}`],
    () => getLocations(currentPage + 1, targetPageSize),
    { keepPreviousData: true }
  );
  const {
    isSuccess: isCreated,
    isLoading: isCreating,
    mutate: mutateCreate,
    reset: resetCreate,
  } = useMutation((newLocationData: LocationData) =>
    createLocation(newLocationData)
  );
  const {
    isSuccess: isUpdated,
    isLoading: isUpdating,
    mutate: mutateUpdate,
  } = useMutation((updatedLocationData: Location) =>
    updateLocation(updatedLocationData.id, {
      name: updatedLocationData.name,
      longitude: updatedLocationData.longitude,
      latitude: updatedLocationData.latitude,
      organizationId: updatedLocationData.organizationId,
    })
  );
  const {
    isSuccess: isDeleted,
    isLoading: isDeleting,
    mutate: mutateDelete,
  } = useMutation((id: string) => deleteLocation(id));

  const invalidateQuery = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ["getLocations"],
      }),
    [queryClient]
  );

  useEffect(() => {
    if (isCreated) {
      setNewRow(undefined);
      invalidateQuery();
      resetCreate();
    }

    if (isUpdated || isDeleted) {
      invalidateQuery();
    }

    if (data) {
      setRows(newRow ? [...data.results, ...newRow] : data.results);
      if (targetPageSize === data.results.length) {
        setPageIsFull(true);
      } else {
        setPageIsFull(false);
      }
    }
  }, [
    isCreated,
    resetCreate,
    invalidateQuery,
    queryClient,
    isUpdated,
    isDeleted,
    newRow,
    pageSize,
    currentPage,
    data,
  ]);

  const columns: GridColumns = [
    {
      field: "name",
      headerName: t("name")!,
      flex: 0.8,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        setNewRowTouched(true);
        const { props } = params;
        const isValid = dataGridNameValidation(props.value);
        return { ...props, error: !isValid };
      },
    },
    {
      field: "longitude",
      headerName: t("longitude")!,
      flex: 1,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        setNewRowTouched(true);
        const { props } = params;
        const isValid = dataGridCoordinateValidation("longitude", props.value);
        return { ...props, error: !isValid };
      },
    },
    {
      field: "latitude",
      headerName: t("latitude")!,
      flex: 1,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        setNewRowTouched(true);
        const { props } = params;
        const isValid = dataGridCoordinateValidation("latitude", props.value);
        return { ...props, error: !isValid };
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("actions")!,
      width: 100,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              disabled={newRow && !newRowTouched}
              onClick={handleSaveClick(id, setRowModesModel, rowModesModel)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(
                id,
                setRowModesModel,
                rowModesModel,
                rows,
                setNewRow,
                setPageSize,
                setNewRowTouched,
                pageIsFull
              )}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id, setRowModesModel, rowModesModel)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id, mutateDelete)}
          />,
        ];
      },
    },
  ];

  const processRowUpdate = (row: GridRowModel) => {
    const payload = {
      name: row.name,
      longitude: +row.longitude,
      latitude: +row.latitude,
      organizationId: user?.org_id,
    };
    if (row.isNew) {
      mutateCreate(payload);
      pageIsFull && setPageSize((oldSize) => oldSize - 1);
      setNewRow(undefined);
    } else {
      mutateUpdate({
        ...payload,
        id: row.id,
      });
    }
    const updatedRow = { ...row, isNew: false };
    setNewRowTouched(false);
    return updatedRow;
  };

  return (
    <DataGridPro
      localeText={theme.dataGridTranslations}
      components={{
        LoadingOverlay: () => (
          <LinearProgress color="inherit" variant="indeterminate" />
        ),
        Toolbar: EditToolbar,
        DetailPanelExpandIcon: ExpandMoreIcon,
        DetailPanelCollapseIcon: ExpandLessIcon,
      }}
      componentsProps={{
        toolbar: { setNewRow, setRowModesModel, t, setPageSize, pageIsFull },
      }}
      experimentalFeatures={{ newEditingApi: true }}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      loading={
        isLoading || isFetching || isCreating || isUpdating || isDeleting
      }
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={[pageSize]}
      pagination
      paginationMode="server"
      rowCount={newRow && data ? data?.rowCount + 1 : data?.rowCount ?? 1}
      page={currentPage}
      onPageChange={(newPage) => setCurrentPage(newPage)}
      disableSelectionOnClick
      getDetailPanelContent={({ row }) => (
        <Box sx={{ p: 1 }}>
          <CopyField title="id" content={row.id} />
          <CopyField title="organizationId" content={row.organizationId} />
        </Box>
      )}
      getDetailPanelHeight={() => "auto"}
      sx={{
        height: 400,
      }}
    />
  );
};

export default LocationEditorTable;
