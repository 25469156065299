import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import MenuLinkItemButton from "layouts/ResponsiveDrawer/MenuSectionList/MenuLinkItemButton";
import useAnimatedDrawerButtons from "hooks/use-animated-drawer-buttons";
import styles from "./MenuSectionBody.module.css";
import { MenuSection } from "layouts/ResponsiveDrawer/MenuSectionList";
import RoleGuard from "features/auth/RoleGuard";

const getMenuItems = (section: MenuSection) => {
  const items = section.links.map((link) => {
    return (
      <Grid
        sx={{ display: "flex", justifyContent: "center", my: 1 }}
        item
        xs={12}
        key={link.id}
      >
        <MenuLinkItemButton item={link} />
      </Grid>
    );
  });
  return <RoleGuard permittedRoles={section.permission}>{items}</RoleGuard>;
};

const getMenuSections = (menuSections: MenuSection[]) => {
  const sections = menuSections.map((section, index) => {
    const items = getMenuItems(section);

    useAnimatedDrawerButtons();

    return (
      <Grid item xs={12} key={section.id}>
        <Grid container>{items}</Grid>
        <RoleGuard permittedRoles={["Admin"]}>
          {/* wenn eine Line vorhanden sein soll um zwei bereiche getrennt werden sollen bei usern geht das so nicht mehr  */}
          {menuSections.length > index + 1 && (
            <Divider
              sx={{
                borderBottomWidth: (theme) => `${theme.drawerHeight.divider}px`,
                my: 2,
              }}
            />
          )}
        </RoleGuard>
      </Grid>
    );
  });

  return sections;
};

interface MenuSectionBodyProps {
  menuSections: MenuSection[];
}

const MenuSectionBody: React.FC<MenuSectionBodyProps> = ({ menuSections }) => {
  const menuSectionItems = getMenuSections(menuSections);

  return (
    <Box
      className={styles.removeScrollbar}
      sx={{
        height: (theme) =>
          `calc(100% - ${theme.drawerHeight.header}px - ${theme.drawerHeight.divider}px - ${theme.drawerHeight.footer}px)`,
        overflowY: "scroll",
      }}
    >
      <Grid container sx={{ py: "10px" }}>
        {menuSectionItems}
      </Grid>
    </Box>
  );
};

export default MenuSectionBody;
