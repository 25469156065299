import ErrorBoundary from "components/UI/ErrorBoundary";
import RouterConfig from "features/router/RouterConfig";

const App = () => {
  return (
    <ErrorBoundary>
      <RouterConfig />
    </ErrorBoundary>
  );
};

export default App;
