type DecimalPlaces = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type MinMaxStats = {
  min: number;
  max: number;
  range: number;
  absMax: number;
};

// Chart min (if < 0) and max (if > 0) will be extended by
// 'extendFactorPerc'% to give
const extendFactorPerc = 0.15;

const getDecimalPlaces = (max: number) => {
  let decimalPlaces: DecimalPlaces;

  if (max < 0.4) {
    decimalPlaces = 2;
  } else if (max < 0.04) {
    decimalPlaces = 3;
  } else if (max < 4) {
    decimalPlaces = 1;
  } else {
    decimalPlaces = 0;
  }

  return decimalPlaces;
};

const roundDecimalPlaces = (num: number, decimalPlaces: DecimalPlaces) => {
  if (decimalPlaces === 0) {
    return Math.round(num);
  }

  const factor = Math.pow(10, decimalPlaces);
  return Math.round((num + Number.EPSILON) * factor) / factor;
};

export const calculateYTicksAndDomain = (
  minMaxStats: MinMaxStats,
  divideBy: number
) => {
  const decimalPlaces = getDecimalPlaces(minMaxStats.absMax / divideBy);

  const min = minMaxStats.min / divideBy;
  const max = minMaxStats.max / divideBy;
  const absMax = minMaxStats.absMax / divideBy;
  const range = minMaxStats.range / divideBy;

  // case: all data points are postive
  // - domain will be from 0 to max*extendFactor
  // - y ticks will be max/4, max/2, 3max/4 and max
  if (min >= 0) {
    return {
      yDomain: [0, max * (1 + extendFactorPerc)],
      yTicks: [max * 0.25, max * 0.5, max * 0.75, max].map((v) =>
        roundDecimalPlaces(v, decimalPlaces)
      ),
    };
  }

  // case: all data points will be negative or zero
  // - domain will be from min*extendFactor to 0
  // - y ticks will be min, 3min/4, min/2 and min/4
  if (max <= 0) {
    return {
      yDomain: [min * (1 + extendFactorPerc), 0],
      yTicks: [min, min * 0.75, min * 0.5, min * 0.25].map((v) =>
        roundDecimalPlaces(v, decimalPlaces)
      ),
    };
  }

  // fallback case: data points have negative and positive values
  // - domain will be [min*extendValue, max*extendValue] where extendValue is absMax*'extendFactorPerc'
  // - y ticks will be min, min + range/3, min + 2range/3, max
  const extendValue = absMax * extendFactorPerc;
  return {
    yDomain: [min - extendValue, max + extendValue],
    yTicks: [min, min + range * 0.33, min + range * 0.66, max].map((v) =>
      roundDecimalPlaces(v, decimalPlaces)
    ),
  };
};

export const calculateUnitScale = (max: number, siUnit: string) => {
  let divideBy = 1;
  let unit = siUnit;

  const prefixRules = [
    {
      prefix: "T",
      value: 1000 ** 4,
    },
    {
      prefix: "G",
      value: 1000 ** 3,
    },
    {
      prefix: "M",
      value: 1000 ** 2,
    },
    {
      prefix: "k",
      value: 1000,
    },
  ];

  for (let i = 0; i < prefixRules.length; i++) {
    const rule = prefixRules[i];

    if (max >= rule.value || max <= -rule.value) {
      divideBy = rule.value;
      unit = `${rule.prefix}${unit}`;
      break;
    }
  }

  return {
    divideBy,
    unit,
  };
};

export const getMinMaxStats = (data: { value: number }[]): MinMaxStats => {
  let len = data.length,
    min = Infinity,
    max = -Infinity;

  while (len--) {
    const value = data[len].value;
    if (value < min) {
      min = value;
    }
    if (value > max) {
      max = value;
    }
  }

  return {
    min,
    max,
    range: max - min,
    absMax: Math.max(max, Math.abs(min)),
  };
};
