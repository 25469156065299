import React from "react";
import { Divider, Paper, Typography } from "@mui/material";
import { LiveSummaryMeasurement } from "types/Devices";
import { useTranslation } from "react-i18next";
import { roundDecimalPlaces } from "utils/helpers/math-helpers";
import { addOpacity } from "utils/helpers/converters";
import {
  JouleConverter,
  PercentConverter,
  UnitConverter,
  WattConverter,
} from "utils/helpers/unit-helpers";

class DefaultConverter implements UnitConverter {
  convertFromBaseUnit = (value: number, unit: string) => {
    return {
      value: roundDecimalPlaces(value, 2),
      unit,
    };
  };
}

const createConverter = (symbol: string) => {
  switch (symbol) {
    case "":
      return new PercentConverter();

    case "J":
      return new JouleConverter();

    case "W":
      return new WattConverter();

    default:
      return new DefaultConverter();
  }
};

const measurementUnitConversion = (value: number, unit: string) => {
  const converter = createConverter(unit);
  return converter.convertFromBaseUnit(value, unit);
};

interface MeasurementDisplayProps {
  data: LiveSummaryMeasurement;
}

const MeasurementDisplay: React.FC<MeasurementDisplayProps> = ({ data }) => {
  const { t } = useTranslation();

  const convertedMeasurement =
    data.measurement &&
    measurementUnitConversion(data.measurement.value, data.measurement.unit);

  return (
    <Paper
      sx={{
        padding: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        background: (theme) => addOpacity(theme.palette.background.paper, 0.8),
      }}
      variant="outlined"
    >
      <Typography variant={"h6"} color="text.secondary">
        {t(data.key)}
      </Typography>
      <Divider flexItem />
      <Typography variant={"body1"} sx={{ mt: 1 }}>
        {`${convertedMeasurement?.value} ${convertedMeasurement?.unit}`}
      </Typography>
    </Paper>
  );
};

export default MeasurementDisplay;
