import { SvgIcon, useTheme } from "@mui/material";

const PhotovoltaicSvg = () => {
  const theme = useTheme();
  return (
    <SvgIcon
      viewBox="80 140 600 600"
      sx={{
        overflow: "visible",
        height: "250%",
        width: "250%",
      }}
    >
      <g
        transform="matrix(1.3333333,0,0,-1.3333333,0,600)"
        id="g8"
        opacity={theme.palette.devicesPage.graphicsOpacity}
      >
        <path
          d="M 377.06391,426.89424 H 229.7367 V 17.58465 h 147.32721 c 31.57963,0 57.20436,25.624729 57.20436,57.244326 V 369.64991 c 0,31.6196 -25.62473,57.24433 -57.20436,57.24433"
          fill="#adb8c1"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path420"
        />
        <path
          d="m 82.416158,426.89424 c -31.586292,0 -57.211021,-25.62473 -57.211021,-57.24433 V 74.828976 c 0,-31.619597 25.624729,-57.244326 57.211021,-57.244326 H 229.7367 V 426.89424 H 82.416158"
          fill="#adb8c1"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path422"
        />
        <path
          d="m 82.416158,395.41452 c -14.201175,0 -25.744626,-11.56343 -25.744626,-25.76461 V 74.828976 c 0,-14.201175 11.543451,-25.757948 25.744626,-25.757948 H 377.06391 c 14.19451,0 25.73797,11.556773 25.73797,25.757948 V 369.64991 c 0,14.20118 -11.54346,25.76461 -25.73797,25.76461 z M 377.06391,43.342599 H 82.416158 c -17.34515,0 -31.466395,14.127905 -31.466395,31.486377 V 369.64991 c 0,17.36514 14.121245,31.48638 31.466395,31.48638 H 377.06391 c 17.34515,0 31.46639,-14.12124 31.46639,-31.48638 V 74.828976 c 0,-17.358472 -14.12124,-31.486377 -31.46639,-31.486377"
          fill={theme.palette.background.paper}
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path424"
        />
        <path
          d="m 72.68449,152.4825 v 211.43232 c 0,9.48522 7.686752,17.17863 17.165304,17.17863 H 369.61695 c 9.48521,0 17.16531,-7.69341 17.16531,-17.17863 V 152.4825 H 72.68449"
          fill={theme.palette.background.paper}
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path426"
        />
        <path
          d="m 224.51451,192.44162 h 10.45105 l -5.11562,16.30604 h -0.19317 z m 12.70912,-7.28043 h -15.00049 l -3.11734,-9.97813 h -9.6917 l 15.59998,46.28704 h 9.5052 l 15.54003,-46.28704 h -9.69837 l -3.13731,9.97813"
          fill="#768898"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path428"
        />
        <path
          d="m 90.955514,283.05072 c -0.959178,0 -1.898374,0.47959 -2.437912,1.35883 -0.819299,1.35218 -0.406319,3.11067 0.945856,3.94329 44.448612,27.32994 95.651442,41.77757 148.073232,41.77757 46.2937,0 92.22771,-11.43021 132.81963,-33.04504 1.3988,-0.73937 1.93168,-2.47122 1.18565,-3.87002 -0.74603,-1.3988 -2.47788,-1.92502 -3.86336,-1.17899 -39.77928,21.1752 -84.78075,32.36562 -130.14192,32.36562 -51.35603,0 -101.52641,-14.15455 -145.08912,-40.93162 -0.459606,-0.28642 -0.9725,-0.41964 -1.492056,-0.41964"
          fill="#768898"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path430"
        />
        <path
          d="m 111.68444,339.14936 c 0,1.80512 -0.40632,3.23723 -1.20564,4.283 -0.81264,1.05243 -1.95166,1.57865 -3.4304,1.57865 -1.45875,0 -2.59111,-0.52622 -3.39043,-1.57865 -0.79265,-1.04577 -1.19231,-2.47788 -1.19231,-4.283 v -8.8058 c 0,-1.81844 0.41298,-3.25055 1.21896,-4.30964 0.81263,-1.06576 1.945,-1.59197 3.39709,-1.59197 1.47873,0 2.6111,0.52621 3.41041,1.57865 0.79266,1.05909 1.19232,2.49786 1.19232,4.32296 z m 3.39709,-8.11306 c 0,-2.85089 -0.71273,-5.10896 -2.16482,-6.76754 -1.44543,-1.65858 -3.39043,-2.4912 -5.835,-2.4912 -2.43125,0 -4.36959,0.83262 -5.82834,2.4912 -1.465417,1.65858 -2.191462,3.91665 -2.191462,6.76754 v 7.40033 c 0,2.82425 0.719384,5.07566 2.164812,6.74756 1.44543,1.6719 3.39043,2.50452 5.82168,2.50452 2.44458,0 4.39624,-0.83262 5.84833,-2.50452 1.45875,-1.6719 2.1848,-3.92331 2.1848,-6.74756 v -7.40033"
          fill="#768898"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path432"
        />
        <path
          d="m 171.72637,336.08531 h -15.62663 v 2.33134 l 7.90657,9.43193 c 1.3988,1.7052 2.34466,3.06404 2.85089,4.06985 0.49957,1.0058 0.75269,2.03159 0.75269,3.05072 0,1.16567 -0.33305,2.12485 -1.01913,2.86421 -0.67275,0.74603 -1.62527,1.11904 -2.82425,1.11904 -1.47873,0 -2.61109,-0.45294 -3.39043,-1.36549 -0.77267,-0.91256 -1.159,-2.05158 -1.159,-3.41042 h -3.28386 l -0.04,0.0999 c -0.0599,2.05824 0.62614,3.79675 2.05158,5.22219 1.43877,1.42545 3.37711,2.13151 5.82168,2.13151 2.25141,0 4.02323,-0.61947 5.30879,-1.86507 1.29889,-1.2456 1.93834,-2.85755 1.93834,-4.8292 0,-1.32553 -0.35969,-2.61776 -1.09906,-3.89 -0.72604,-1.27224 -1.84508,-2.85755 -3.33714,-4.77591 l -6.34124,-7.4403 0.0266,-0.0866 h 11.46352 v -2.65773"
          fill="#768898"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path434"
        />
        <path
          d="m 295.18734,352.53123 c -1.07242,0 -1.99163,-0.23313 -2.75098,-0.68607 -0.77267,-0.45295 -1.35884,-1.07242 -1.75849,-1.84509 v -1.74517 c 0,-2.13151 0.46626,-3.8234 1.39214,-5.06234 0.92587,-1.23894 2.05823,-1.85841 3.41041,-1.85841 1.41879,0 2.51118,0.53954 3.28386,1.61862 0.75935,1.07907 1.14568,2.48454 1.14568,4.20973 0,1.63859 -0.41298,2.94414 -1.23894,3.90998 -0.82596,0.9725 -1.98497,1.45875 -3.48368,1.45875 z m 0.81264,12.04969 c 0.99248,0 1.97164,-0.0933 2.94414,-0.29308 0.96584,-0.19317 1.76516,-0.43963 2.39795,-0.73937 l -0.72605,-2.6111 c -0.65277,0.28642 -1.34551,0.51956 -2.07156,0.6994 -0.72604,0.17319 -1.57864,0.26644 -2.54448,0.26644 -1.57865,0 -2.85756,-0.67276 -3.84338,-2.02493 -0.98582,-1.34552 -1.47873,-3.05738 -1.47873,-5.12228 v -1.95167 c 0.6994,0.69941 1.53202,1.25893 2.49786,1.65858 0.95918,0.40632 1.99829,0.60615 3.09734,0.60615 2.17148,0 3.88335,-0.72604 5.14893,-2.17813 1.25892,-1.45209 1.89171,-3.36379 1.89171,-5.72843 0,-2.5778 -0.70606,-4.64269 -2.11818,-6.18138 -1.41213,-1.53868 -3.31716,-2.31135 -5.71511,-2.31135 -2.32468,0 -4.27634,0.88591 -5.84167,2.65772 -1.57198,1.77182 -2.35132,4.18975 -2.35132,7.25379 v 5.79504 c 0,2.95081 0.83929,5.38872 2.51119,7.3204 1.67856,1.92502 3.74346,2.8842 6.20136,2.8842"
          fill="#768898"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path436"
        />
        <path
          d="m 356.10186,346.56301 c 0,1.20564 -0.37968,2.20478 -1.13903,2.99077 -0.75269,0.786 -1.68522,1.17899 -2.80427,1.17899 -1.23227,0 -2.2514,-0.37967 -3.04406,-1.13236 -0.79265,-0.75935 -1.18565,-1.77182 -1.18565,-3.0374 0,-1.2789 0.393,-2.32468 1.20564,-3.11733 0.80597,-0.79266 1.8251,-1.19231 3.05738,-1.19231 1.11904,0 2.04492,0.39965 2.7976,1.19231 0.73937,0.79265 1.11239,1.83843 1.11239,3.11733 z m 0.70606,-11.71664 c 0,1.37216 -0.44629,2.50453 -1.33886,3.3971 -0.89257,0.8859 -1.99829,1.33219 -3.3105,1.33219 -1.4321,0 -2.61109,-0.44629 -3.54363,-1.33219 -0.93253,-0.89257 -1.3988,-2.02494 -1.3988,-3.3971 0,-1.41878 0.46627,-2.55114 1.38548,-3.40375 0.93253,-0.8526 2.12485,-1.28556 3.59026,-1.28556 1.31221,0 2.41127,0.43296 3.29718,1.29222 0.87924,0.85927 1.31887,1.99163 1.31887,3.39709 z m 2.69769,11.76993 c 0,-1.31887 -0.37302,-2.47122 -1.10572,-3.47036 -0.73937,-0.99915 -1.71853,-1.74518 -2.93749,-2.24475 1.43211,-0.49291 2.5778,-1.28556 3.44372,-2.36464 0.85927,-1.08574 1.29223,-2.338 1.29223,-3.75678 0,-2.31802 -0.75269,-4.10982 -2.25807,-5.3754 -1.49872,-1.27225 -3.42373,-1.90504 -5.74841,-1.90504 -2.46456,0 -4.47617,0.63279 -6.0415,1.90504 -1.56532,1.26558 -2.34465,3.05738 -2.34465,5.3754 0,1.41878 0.44628,2.67104 1.32553,3.75678 0.87924,1.07908 2.07156,1.87839 3.57027,2.38462 -1.29222,0.48625 -2.31135,1.22562 -3.05738,2.22477 -0.74603,0.99914 -1.1257,2.15149 -1.1257,3.47036 0,2.19812 0.70606,3.88334 2.11819,5.04235 1.40546,1.17233 3.25055,1.75183 5.52193,1.75183 2.14484,0 3.90333,-0.58616 5.28215,-1.75183 1.37882,-1.17233 2.0649,-2.85756 2.0649,-5.04235"
          fill="#768898"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path438"
        />
        <path
          d="m 222.5029,351.21902 h 7.14721 v 11.80989 l -0.0933,0.02 -0.33971,-0.86593 z m 10.5443,0 h 3.47703 v -2.66438 h -3.47703 v -5.81502 h -3.39709 v 5.81502 h -10.79742 v 1.91836 l 10.60425,17.45172 h 3.59026 v -16.7057"
          fill="#768898"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path440"
        />
        <path
          d="m 188.99159,152.6357 -83.10885,128.82971 c -1.71187,2.65106 -5.27549,3.41707 -7.906569,1.7052 -2.664386,-1.71852 -3.417075,-5.26216 -1.705207,-7.91988 L 175.46317,152.50248 c 4.50947,0.0866 9.0256,0.14654 13.52842,0.13322"
          fill="#768898"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path442"
        />
        <path
          d="m 252.87023,99.621085 c 0,-12.64251 -10.24456,-22.893734 -22.88707,-22.893734 -12.63585,0 -22.87375,10.251224 -22.87375,22.893734 0,12.649175 10.2379,22.900395 22.87375,22.900395 12.64251,0 22.88707,-10.25122 22.88707,-22.900395"
          fill="#768898"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path444"
        />
        <path
          d="m 232.30784,102.37206 h 10.2379 v -4.569418 h -10.2379 V 86.012735 h -5.07566 v 11.789907 h -10.30451 v 4.569418 h 10.30451 v 10.8707 h 5.07566 v -10.8707"
          fill="#adb8c1"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="0.66609645"
          id="path446"
        />
      </g>
    </SvgIcon>
  );
};

export default PhotovoltaicSvg;
