import React from "react";
import IconButton from "@mui/material/IconButton";
import { keyframes } from "@mui/system";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";
import { Tooltip } from "@mui/material";

const rotate = keyframes`
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-120deg);
  }`;

interface AnimatedSettingsButtonProps {
  onClick?: () => void;
}

const AnimatedSettingsButton: React.FC<AnimatedSettingsButtonProps> = (
  props
) => {
  const { t } = useTranslation();
  return (
    <Tooltip arrow title={t("settings")}>
      <IconButton
        aria-label="settings"
        onClick={props.onClick}
        sx={[
          {
            "&:hover": {
              animation: `${rotate} 300ms 0s forwards`,
            },
          },
        ]}
      >
        <SettingsIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AnimatedSettingsButton;
