import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "components/UI/LoadingSpinner";
import DevicesGrid from "features/devices/DevicesGrid";
import DevicesPageFilter from "features/devices/DevicesPageFilter";
import { useDevicesApiClient } from "hooks/use-api-client";
import { useMemo } from "react";
import { Device } from "types/Devices";

const getAvailableDeviceKinds = (devices: Device[]) => {
  const kinds = devices.map((device) => device.kind);
  const uniqueKinds = kinds.filter(
    (kind, index) => kinds.indexOf(kind) === index
  );

  return uniqueKinds;
};

const DevicesPageFilterAndGrid = () => {
  const { getDevices } = useDevicesApiClient();
  const { data, isLoading } = useQuery(["devicesPageComponents"], () =>
    getDevices({
      page: 1,
      pageSize: 200,
      onlyTopTier: true,
    })
  );
  const availableDeviceKinds = useMemo(
    () => getAvailableDeviceKinds(data?.results ?? []),
    [data?.results]
  );

  if (!isLoading && data !== undefined) {
    return (
      <>
        <DevicesPageFilter componentOptions={availableDeviceKinds} />
        <DevicesGrid devices={data.results} />
      </>
    );
  }
  return <LoadingSpinner />;
};

export default DevicesPageFilterAndGrid;
