import React, { useEffect } from "react";
import { TextField, Typography, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiClient } from "hooks/use-api-client";
import { CreateTimeseriesDatabase } from "types/Common";

interface CreateInfluxBucketFormProps {
  orgId: string;
}

const CreateInfluxBucketForm: React.FC<CreateInfluxBucketFormProps> = ({
  orgId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { postTimeseriesDatabase } = useApiClient();
  const { isSuccess, isLoading, mutate, isError } = useMutation(
    (bucket: CreateTimeseriesDatabase) => {
      return postTimeseriesDatabase(bucket.organizationId, bucket.bucketName);
    }
  );

  const validationSchema = yup.object({
    bucketName: yup
      .string()
      .max(32, t("bucketName32CharactersError")!)
      .required(t("bucketNameRequiredError")!),
  });

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(["bucketData"]);
    }
  }, [isSuccess, queryClient, isError]);

  return (
    <Formik
      initialValues={{
        bucketName: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        mutate({ organizationId: orgId, bucketName: values.bucketName });
      }}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        isValid,
        dirty,
      }) => (
        <Form>
          <Typography color={"text.disabled"} sx={{ mb: 2 }}>{`${t(
            "organizationId"
          )}: ${orgId}`}</Typography>

          <TextField
            fullWidth
            id="bucketNameField-6ecffeac"
            name="bucketName"
            label="Bucket-Name"
            value={values.bucketName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.bucketName && Boolean(errors.bucketName)}
            helperText={touched.bucketName && errors.bucketName}
            sx={{ mb: 2 }}
          />
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="outlined"
              disabled={!isValid || !dirty}
            >
              {t("create").toUpperCase()}
            </LoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CreateInfluxBucketForm;
