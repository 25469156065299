import { SvgIcon, useTheme } from "@mui/material";

const PhotovoltaicSvg = () => {
  const theme = useTheme();
  return (
    <SvgIcon
      viewBox="0 0 424.08 550.87"
      sx={{
        overflow: "visible",
        height: "200%",
        width: "200%",
      }}
    >
      <g
        opacity={theme.palette.devicesPage.graphicsOpacity}
        transform="translate(0,-30 )"
      >
        <g fill="#768797">
          <path d="m281.61 450.06h-140.36c-5.591 0-10.119 5.1665-10.119 11.579 0 6.3852 4.5276 11.584 10.119 11.584h140.36c5.5545 0 10.059-5.1985 10.059-11.584 0-6.4125-4.5048-11.579-10.059-11.579" />
          <path d="m281.61 482.41h-140.36c-5.591 0-10.119 5.2259-10.119 11.588 0 6.408 4.5276 11.606 10.119 11.606h15.002l15.135 30.744s7.2888 9.9086 11.036 10.995c3.7198 1.0954 46.933 5.874 60.328 0 0 0 6.5404-1.8804 11.62-11.762 3.9571-7.5444 12.245-23.035 15.956-29.977h11.282c5.5545 0 10.059-5.1985 10.059-11.606 0-6.3624-4.5048-11.588-10.059-11.588" />
          <path d="m281.61 417.7h-140.36c-5.591 0-10.119 5.1848-10.119 11.584 0 6.4399 4.5276 11.584 10.119 11.584h140.36c5.5545 0 10.059-5.1438 10.059-11.584 0-6.3989-4.5048-11.584-10.059-11.584" />
        </g>
        <g fill="#adb8c1">
          <path d="m259.61 213.85c1.4058-2.8754 1.7024-5.0662 0.79874-6.5266-0.88089-1.4605-1.7983-2.2045-2.7384-2.2045-2.0767 0-4.9292 2.8845-6.303 5.6001-2.0173 3.9982-1.8074 8.973-1.0771 13.254 4.4454-3.1949 7.5809-6.5906 9.3198-10.123" />
          <path d="m199.31 208.14c0.99039-2.0904 1.2916-6.0292 0.28309-7.6357-0.21462-0.33786-0.43356-0.50662-0.9904-0.50662-0.39252 0-1.0908 0.10509-2.1588 0.53855-2.5833 1.068-4.8242 3.414-6.1843 6.44-2.4966 5.5317-2.7932 10.821-0.94022 15.244 0.35145-0.4336 0.70745-0.85806 1.068-1.2916 3.3227-4.0438 6.7731-8.22 8.9228-12.789" />
          <path d="m154.54 270.43c18.991 40.94 35.408 86.567 50.141 139.39h21.282c13.829-49.575 29.092-92.697 46.654-131.72-13.971-6.4856-24.829-18.663-30.876-34.527-9.6394 3.916-19.475 5.3263-29.963 4.2811-8.7996-0.89-16.212-2.9895-22.067-6.2756-5.6686 6.6955-19.073 21.164-35.171 28.859" />
          <path d="m122.72 244.89-0.92649-0.0685c-1.7618 0-3.8247 0.69375-4.2127 2.2227-0.77591 3.0488 0.7257 5.8283 2.1223 7.6175 3.4413 4.4226 9.863 7.7498 14.934 7.7498 0.42446 0 0.84434-0.024 1.2323-0.0777-1.1274-2.3231-2.2912-4.628-3.4504-6.9283-2.3597-4.8562-5.34-9.8402-9.6987-10.516" />
          <path d="m303.68 249.13-0.064-0.0273c-0.45185 0-1.8758 0.58877-5.1711 5.9516l-0.26015 0.42902c-2.0675 4.0575-4.1214 8.2199-6.2483 12.643 4.6371-0.48379 8.512-2.0995 10.849-4.6234 2.0538-2.168 2.9712-5.0661 2.7339-8.5942-0.2694-4.0164-1.2916-5.518-1.8393-5.7781" />
          <path d="m211.4 1.1768c-104.4 0-189.05 84.641-189.05 189.07 0 76.115 44.988 141.7 109.85 171.65v36.732c0 6.1752 5.0114 11.187 11.177 11.187h47.403c-14.45-51.264-30.488-95.549-48.977-135.09-10.648 1.9945-20.087-0.56139-27.859-7.5171-5.3993-4.8425-10.566-13.231-9.895-21.091 0.42446-4.838 2.9712-8.8863 7.4304-11.68 3.1812-1.9991 6.5404-3.026 9.9543-3.026 10.447 0 18.763 9.4659 22.693 17.563 1.529 2.994 3.0169 6.02 4.4957 9.0552 10.872-5.1757 21.967-14.167 31.054-25.157-2.5924-3.6148-4.24-7.8228-4.8836-12.538-1.1136-8.0374 2.8982-19.909 9.1191-27.038 6.6727-7.6722 15.217-8.9867 22.834-3.4687 4.24 3.0808 6.7046 6.9055 7.2569 11.378 1.3282 10.31-7.6083 21.218-14.149 29.178l-0.86717 1.068c1.8302 0.82154 3.8384 1.4651 5.9972 1.9078 11.342 2.3596 21.511 2.0858 28.987-0.85347 1.3829-0.54314 2.7612-1.1137 4.1077-1.7207-0.5477-2.7795-0.97216-5.5454-1.2232-8.2245-1.1365-11.757 2.1269-20.315 10.274-26.928 3.1264-2.5468 7.1748-3.989 11.168-3.989 3.884 0 7.2615 1.3829 9.7763 3.989 4.4044 4.6234 6.3624 9.8356 5.8557 15.545-1.0543 11.374-11.524 20.05-15.974 23.259-1.3145 0.94018-2.6426 1.8576-4.0255 2.7339 3.4277 9.507 10.612 23.669 24.158 29.005 2.7612-5.8375 5.591-11.616 8.4435-17.211l0.70289-1.1364c2.4737-3.7608 8.9319-12.204 16.883-12.204 2.921 0 5.6321 1.1136 8.0875 3.2816 7.6084 6.7184 7.9004 18.083 4.8243 26.38-3.8384 10.319-14.016 16.499-27.211 16.499-1.3738 0-2.7613-0.0729-4.1579-0.19614-17.152 37.845-32.163 79.844-45.805 128.22h39.562c6.198 0 11.209-5.0114 11.209-11.187v-36.732c64.828-29.963 109.85-95.536 109.85-171.65 0-104.43-84.655-189.07-189.07-189.07" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PhotovoltaicSvg;
