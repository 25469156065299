import { useAxiosWithBearerToken } from "./useApiClient";
import { DeviceType, PagedResult } from "types/Common";
import {
  Device,
  LiveSummaryMeasurement,
  Location,
  LocationData,
} from "types/Devices";

export interface GetDevicesOptions {
  page: number;
  pageSize?: number;
  includeChildren?: boolean;
  includeAlarms?: boolean;
  onlyTopTier: boolean;
  kinds?: DeviceType[];
}

export const useDevicesApiClient = () => {
  const request = useAxiosWithBearerToken();

  const getDevices = (options?: GetDevicesOptions) => {
    return request<PagedResult<Device>>("/devices", "GET", undefined, {
      page: options?.page ?? 1,
      pageSize: options?.pageSize ?? 200,
      includeChildren: options?.includeChildren ?? false,
      includeAlarms: options?.includeAlarms ?? false,
      onlyTopTier: options?.onlyTopTier ?? true,
      kinds: options?.kinds,
    });
  };

  const getDeviceById = (deviceId: string) => {
    return request<Device>(`/devices/${deviceId}`, "GET");
  };

  const getDeviceLiveSummary = (deviceId: string) => {
    return request<LiveSummaryMeasurement[]>(
      `devices/${deviceId}/summary/live`,
      "GET"
    );
  };

  const updateDeviceLocation = (deviceId: string, locationId: string) => {
    return request<Device>(
      `/devices/${deviceId}/locations/${locationId}`,
      "PUT"
    );
  };

  const getLocations = (page: number = 1, pageSize: number = 200) => {
    return request<PagedResult<Location>>("/locations", "GET", undefined, {
      page,
      pageSize,
    });
  };

  const createLocation = (data: LocationData) => {
    return request<Location, LocationData>("/locations", "POST", data);
  };

  const updateLocation = (id: string, data: LocationData) => {
    return request<Location, LocationData>(`/locations/${id}`, "PUT", data);
  };

  const deleteLocation = (id: string) => {
    return request(`/locations/${id}`, "DELETE");
  };

  return {
    getDevices,
    getDeviceById,
    getDeviceLiveSummary,
    updateDeviceLocation,
    getLocations,
    createLocation,
    updateLocation,
    deleteLocation,
  };
};
