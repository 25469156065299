import React from "react";
import { Role } from "types/Common";
import { useRoles } from "hooks/use-roles";

const intersect = (a: Role[], b: Role[]): Role[] => {
  const set = new Set(a.filter((value) => b.includes(value)));
  return Array.from(set);
};

interface RoleGuardProps {
  children?: React.ReactNode;
  permittedRoles: Role[];
}

const RoleGuard: React.FC<RoleGuardProps> = ({ children, permittedRoles }) => {
  const myRoles = useRoles();

  if (intersect(myRoles, permittedRoles).length === 0) {
    return null;
  }

  return <>{children}</>;
};

export default RoleGuard;
