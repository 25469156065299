import React, { useContext } from "react";
import { DeviceType } from "types/Common";
import LocationFilterContext from "context/LocationFilterContext";
import DevicesFilterContext from "context/DevicesFilterContext";
import DeviceCard from "features/devices/DeviceCard";
import { Device, Location } from "types/Devices";
import { useAuth0 } from "@auth0/auth0-react";
import MockCharger from "../DevicesPageFilterAndGrid/MockCharger";
import { Masonry } from "@mui/lab";
import Box from "@mui/material/Box";

const filterDevicesByLocations = (
  devices: Device[],
  locations: Location[] | null
) => {
  if (locations === null || locations.length === 0) {
    return devices;
  }

  const locationIds = locations.map((x) => x.id);

  return devices.filter((device) => {
    if (device.location) {
      return locationIds.indexOf(device.location.id) >= 0;
    }
    return false;
  });
};

const filterDevicesByComponents = (
  devices: Device[],
  components: DeviceType[]
) => {
  if (components.length === 0) {
    return devices;
  }

  return devices.filter((device) => {
    return components.indexOf(device.kind) >= 0;
  });
};

const sortDevicesByKindAndLocationAlphabeticaly = (devices: Device[]) => {
  return devices.sort((a, b) => {
    if (a.kind === b.kind) {
      if (a.location && b.location) {
        return a.location.name.localeCompare(b.location.name);
      }
    }

    return a.kind.localeCompare(b.kind);
  });
};

interface DevicesGridProps {
  devices: Device[];
}

const DevicesGrid: React.FC<DevicesGridProps> = ({ devices }) => {
  const { selectedLocations } = useContext(LocationFilterContext);
  const { selectedDevices } = useContext(DevicesFilterContext);
  /* --- Mock --- */
  const { user } = useAuth0();
  const isSchulzSystemtechnik = user?.org_id === "org_1QZvsjXyTIUwiMfD";
  /* --- Mock --- */

  const sortedDevices = sortDevicesByKindAndLocationAlphabeticaly(devices);
  const devicesFilteredByLocations = filterDevicesByLocations(
    sortedDevices,
    selectedLocations
  );
  const filteredDevices = filterDevicesByComponents(
    devicesFilteredByLocations,
    selectedDevices
  );

  const devicesCards = filteredDevices.map((device) => {
    return (
      // <Grid item xs={12} lg={4} key={device.id}>
      <DeviceCard
        key={device.id}
        name={device.name}
        description={device.description}
        location={device.location}
        device={device}
      />
      /* </Masonry> */
    );
  });

  return (
    // see https://stackoverflow.com/questions/70922760/material-ui-masonry-remove-space-on-right-side
    // for the WHY mr: -2
    <Box sx={{ mt: 2, mr: -2 }}>
      <Masonry columns={{ xs: 1, lg: 3 }} spacing={2}>
        {devicesCards}
        {isSchulzSystemtechnik && (
          <>
            <MockCharger
              name="Ladesäule 01"
              description={`Kundennummer: ${Math.floor(Math.random() * 10000)}`}
            />
            <MockCharger
              name="Ladesäule 02"
              description={`Kundennummer: ${Math.floor(Math.random() * 10000)}`}
            />
          </>
        )}
      </Masonry>
    </Box>
  );
};

export default DevicesGrid;
