import { useAuth0 } from "@auth0/auth0-react";
import { useMobileBreakpoint } from "hooks/use-breakpoint";
import { useNavigate } from "react-router-dom";
import { NavBarItemsGroup } from "types/Common";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";

export const useNavigationItems = () => {
  const getCurrentUserNavBarItemsGroup = () => {
    return {
      header: user?.email,
      id: "current-user-nav-bar-items-group",
      items: [
        {
          id: "profile-nav-bar-item",
          title: "Profile",
          icon: <PersonIcon />,
          clickAction: () => navigate("/profile"),
        },
        {
          id: "settings-nav-bar-item",
          title: "Settings",
          icon: <SettingsIcon />,
          clickAction: () => navigate("/settings"),
        },
        {
          id: "logout-nav-bar-item",
          title: "Sign Out",
          icon: <LogoutIcon />,
          clickAction: () =>
            logout({
              returnTo: `${window.location.origin}/`,
            }),
        },
      ],
    };
  };

  const getStartNavBarItemsGroup = () => {
    return {
      id: "start-nav-bar-items-group",
      items: [
        {
          id: "dashboard-nav-bar-item",
          title: "Dashboard",
          icon: <DashboardIcon />,
          clickAction: () => navigate("/dashboard"),
        },
        {
          id: "devices-nav-bar-item",
          title: "Devices",
          icon: <ListAltIcon />,
          clickAction: () => navigate("/devices"),
        },
      ],
    };
  };

  const isMobile = useMobileBreakpoint();
  const { user, isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();

  const navItemsGroups: NavBarItemsGroup[] = [getStartNavBarItemsGroup()];

  if (isMobile && isAuthenticated) {
    navItemsGroups.push(getCurrentUserNavBarItemsGroup());
  }

  return navItemsGroups;
};
