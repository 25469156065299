import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import { useMobileBreakpoint, useSmallBreakpoint } from "hooks/use-breakpoint";
import ImprintLinks from "./FooterLinks";
import ImprintCopyright from "./ImprintCopyright";
import SchulzLogo from "components/UI/SchulzLogo";

const ImprintLogo = (logoSize: string): React.ReactNode => {
  return (
    <Grid
      item
      xs={12}
      sm={1}
      sx={{
        display: "flex",
        justifyContent: { xs: "center", sm: "left" },
        alignItems: "center",
        pl: { sm: "1rem" },
      }}
    >
      <Box
        sx={{
          width: logoSize,
          pt: "0.3rem",
        }}
      >
        <SchulzLogo variant="monotone" />
      </Box>
    </Grid>
  );
};

const Footer = () => {
  const isMobile = useMobileBreakpoint();
  const isSmall = useSmallBreakpoint();
  const isMedium = !isMobile && !isSmall;
  const logoSize = "4rem";

  const content = (
    <Box
      sx={{
        mt: (theme) => `${theme.layoutContainerHeight.footer.spacing}px`,
        mr: { sm: 3 },
        ml: { sm: 3 },
        mb: { xs: 2, sm: 0 },
        paddingBottom: 0,
        minHeight: (theme) => `${theme.layoutContainerHeight.footer.height}px`,
      }}
    >
      <Divider sx={{ mb: 2 }} />
      <footer>
        <Grid
          container
          sx={(theme) => ({
            backgroundColor: theme.palette.frame,
            minHeight: { sm: "4rem" },
          })}
        >
          {isMedium && ImprintLogo(logoSize)}
          {isMobile ? (
            <>
              <ImprintLinks />
              <ImprintCopyright />
            </>
          ) : (
            <>
              <ImprintCopyright />
              <ImprintLinks />
            </>
          )}
        </Grid>
      </footer>
    </Box>
  );

  return !isMobile ? (
    <Box
      component={"footer"}
      sx={(theme) => ({ ml: `${theme.drawerWidth}px` })}
    >
      {content}
    </Box>
  ) : (
    <footer style={{ paddingLeft: "4vw", paddingRight: "4vw" }}>
      {content}
    </footer>
  );
};

export default Footer;
