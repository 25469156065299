import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

interface TextFieldOutputProps {
  error: boolean;
  label: string;
  value: string | undefined;
}

const TextFieldOutput: React.FC<TextFieldOutputProps> = ({
  error,
  label,
  value,
}) => {
  return (
    <Box>
      <Typography
        component="p"
        color={`${error ? "error" : "text.secondary"}`}
        sx={{
          fontSize: `${value ? "0.75rem" : "1rem"}`,
          fontWeight: "200",
          fontFamily: "sans-serif",
          mt: `${value ? 0 : "2.075rem"}`,
          mb: "0.1rem",
        }}
      >
        {label}
      </Typography>
      <Typography component="p" color={`${error ? "error" : "text.primary"}`}>
        {value}
      </Typography>
      <Divider color={`${error ? "error" : "grey"}`} />
    </Box>
  );
};

export default TextFieldOutput;
