import React, { useContext, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import LocationFilterContext from "context/LocationFilterContext";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (
  id: string,
  selectedIds: readonly string[],
  theme: Theme
) => {
  return {
    fontWeight:
      selectedIds.indexOf(id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

interface MultipleSelectChipProps<TData extends { id: string }> {
  data: TData[];
  display: (item: TData) => string;
  onSelectedItemsChange?: (selectedItems: TData[]) => void;
  label: string;
  defaultLabel?: string;
}

const MultipleSelectChip = <TData extends { id: string }>(
  props: React.PropsWithChildren<MultipleSelectChipProps<TData>>
) => {
  const { data, label, defaultLabel, display, onSelectedItemsChange } = props;

  const theme = useTheme();
  const { t } = useTranslation();
  const { selectedLocations } = useContext(LocationFilterContext);

  const selectedLocationIdsArray = selectedLocations?.map(
    (location) => location.id
  );

  const [selectedIds, setSelectedIds] = useState<string[]>(
    selectedLocationIdsArray ? selectedLocationIdsArray : []
  );

  const processNewSelectedIds = (newSelectedIds: string[]) => {
    var selectedItems = data.filter(
      (item) => newSelectedIds.indexOf(item.id) !== -1
    );

    if (onSelectedItemsChange) {
      onSelectedItemsChange(selectedItems);
    }

    setSelectedIds(newSelectedIds);
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedIds>) => {
    const {
      target: { value },
    } = event;

    // On autofill we get a the stringified value.
    const newSelectedIds = typeof value === "string" ? value.split(",") : value;
    processNewSelectedIds(newSelectedIds);
  };

  const displayFromId = (id: string) => {
    const item = data.find((x) => x.id === id)!;
    return display(item);
  };

  const clearHandler = () => {
    processNewSelectedIds([]);
  };

  const inputLabel = selectedIds.length > 0 ? label : defaultLabel ?? label;
  const disableClear = selectedIds.length === 0;

  return (
    <Box sx={{ display: "flex" }}>
      <FormControl fullWidth size="small">
        <InputLabel id="multiple-chip-label" color="secondary">
          {inputLabel}
        </InputLabel>
        <Select
          labelId="multiple-chip-label"
          displayEmpty
          id="multiple-chip"
          multiple
          value={selectedIds}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={inputLabel} />}
          MenuProps={MenuProps}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                pt: 0.25,
                pb: 0.25,
              }}
            >
              {selected.map((value) => {
                const displayName = displayFromId(value);
                return <Chip key={value} label={displayName} size="small" />;
              })}
            </Box>
          )}
        >
          {data.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              style={getStyles(item.id, selectedIds, theme)}
            >
              {display(item)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        disabled={disableClear}
        aria-label="clear-notifications"
        variant="text"
        onClick={clearHandler}
        color="inherit"
        sx={{ ml: 1 }}
      >
        {t("clear")}
      </Button>
    </Box>
  );
};

export default MultipleSelectChip;
