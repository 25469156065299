import { SvgIcon, useTheme } from "@mui/material";
import React from "react";

interface BatteryLargeStateOfChargeProps {
  stateOfCharge: number;
}

const BatteryLargeStateOfCharge: React.FC<BatteryLargeStateOfChargeProps> = ({
  stateOfCharge,
}) => {
  const theme = useTheme();

  const color =
    stateOfCharge > 20
      ? theme.palette.battery.default
      : theme.palette.battery.low;

  const soc = (213 / 100) * stateOfCharge + 6;

  return (
    <SvgIcon
      viewBox="0 0 234.59 78.1"
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <path d={`M 5.72 0.1 H ${soc} V 78.1 H 5.72 V 5.77 Z`} fill={color} />
      <path
        d="M5.72,.1h1.13V78.1h-1.13c-3.13,0-5.67-2.54-5.67-5.67V5.77C.05,2.64,2.59,.1,5.72,.1Z"
        fill={stateOfCharge > 0 ? color : theme.palette.battery.shell}
      />
      <path
        d="M223.57,0h1.13V78h-1.13c-3.13,0-5.67-2.54-5.67-5.67V5.67C217.9,2.54,220.44,0,223.57,0Z"
        transform="translate(442.6 78) rotate(-180)"
        fill={stateOfCharge === 100 ? color : theme.palette.battery.shell}
      />
    </SvgIcon>
  );
};

export default BatteryLargeStateOfCharge;
