import React, { useState } from "react";
import { DeviceType } from "types/Common";

type DevicesFilterContextModel = {
  selectedDevices: DeviceType[];
  setSelectedDevices: (maybeDevices: DeviceType[]) => void;
};

const DevicesFilterContext = React.createContext<DevicesFilterContextModel>({
  selectedDevices: [],
  setSelectedDevices: (maybeDevices) => {},
});

export const DevicesFilterContextProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const [selectedDevices, setSelectedDevices] = useState<DeviceType[]>([]);

  var context = {
    selectedDevices,
    setSelectedDevices,
  };

  return (
    <DevicesFilterContext.Provider value={context}>
      {props.children}
    </DevicesFilterContext.Provider>
  );
};

export default DevicesFilterContext;
