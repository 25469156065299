import React from "react";
import LocationsFilterSelect from "./LocationsFilterSelect";
import { useDevicesApiClient } from "hooks/use-api-client";
import { useQuery } from "@tanstack/react-query";

const LocationFilter: React.FC = () => {
  const { getLocations } = useDevicesApiClient();
  const { data } = useQuery(["getLocations", "devicesPageFilter"], () =>
    getLocations()
  );

  return <LocationsFilterSelect availableLocations={data?.results ?? []} />;
};

export default LocationFilter;
