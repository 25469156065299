import React from "react";
import ExpandedNavBar from "./ExpandedNavBar";
import MinifiedNavBar from "./MinifiedNavBar";
import { useDrawer } from "hooks/use-drawer";

const NavBar: React.FC = (props) => {
  const { isOpen } = useDrawer();
  return isOpen ? <ExpandedNavBar /> : <MinifiedNavBar />;
};

export default NavBar;
