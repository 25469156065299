import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import ThemeContext from "context/ThemeContext";

const VisualSettingsCard = () => {
  const themeContext = useContext(ThemeContext);
  const isInDarkMode = Boolean(themeContext.currentTheme === "dark");

  const darkModeToggleHandler = () => {
    themeContext.toggleDarkMode();
  };

  return (
    <Paper sx={{ p: 1 }}>
      <Typography variant="h6" margin={1}>
        Visual Presentation
      </Typography>
      <FormControlLabel
        sx={{ m: 1 }}
        control={
          <Switch
            onChange={darkModeToggleHandler}
            checked={isInDarkMode}
            color="default"
          />
        }
        label="Dark Mode"
      />
      {/* </FormGroup> */}
    </Paper>
  );
};

export default VisualSettingsCard;
