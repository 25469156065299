import React, { useState } from "react";
import { Typography, Box, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";

interface CopyContentFieldProps {
  label: string;
  content: string;
}

const CopyContentField: React.FC<CopyContentFieldProps> = ({
  label,
  content,
}) => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);

  const onButtonClick = () => {
    navigator.clipboard.writeText(content);
    setIsClicked(true);
  };

  const onTooltipClose = () => {
    setIsClicked(false);
  };

  return (
    <Box
      sx={{
        marginBottom: 1,
        padding: 1,
        border: 1,
        borderRadius: 1,
        borderColor: (theme) => theme.palette.text.disabled,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Typography color="text.secondary" sx={{ mr: 1, ml: 1 }}>
          {label}
        </Typography>
        <Typography color={"text.secondary"} sx={{ wordBreak: "break-word" }}>
          {content}
        </Typography>
      </Box>
      <Tooltip
        title={t("copied")}
        open={isClicked}
        arrow
        leaveDelay={1000}
        onClose={onTooltipClose}
      >
        <IconButton
          sx={{ color: (theme) => theme.palette.text.disabled }}
          onClick={onButtonClick}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CopyContentField;
