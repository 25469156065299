import React, { Suspense } from "react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import LoadingSpinner from "components/UI/LoadingSpinner";
import en_US from "features/i18n/languages/en_US.json";
import de_DE from "features/i18n/languages/de_DE.json";

interface I18nProviderProps {
  children: React.ReactNode;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      de: { translation: de_DE },
      en: { translation: en_US },
    },
    fallbackLng: "en",
  });

const I18nProvider: React.FC<I18nProviderProps> = ({ children }) => {
  return <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>;
};

export default I18nProvider;
export { i18n };
