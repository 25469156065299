import { Chip } from "@mui/material";
import React, { useContext } from "react";
import DevicesFilterContext from "context/DevicesFilterContext";
import { useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { DeviceType } from "types/Common";

interface ComponentsFilterProps {
  componentOptions: DeviceType[];
}

const isButtonSelected = (
  componentOptions: DeviceType[],
  selectedDevices: DeviceType[],
  chipDeleteHandler: (
    event: React.MouseEvent<HTMLElement>,
    componentName: DeviceType
  ) => void,
  chipClickHandler: (event: React.MouseEvent) => void,
  theme: Theme,
  t: (key: DeviceType) => string
) => {
  return componentOptions.map((componentType) => {
    return (
      <React.Fragment key={componentType}>
        {selectedDevices.length !== 0 &&
        selectedDevices.findIndex((selectedDevice) => {
          return selectedDevice === componentType;
        }) >= 0 ? (
          <Chip
            variant="outlined"
            label={t(componentType)}
            aria-label={componentType}
            onDelete={(e) => {
              chipDeleteHandler(e, componentType);
            }}
            sx={{
              margin: 0.5,
              background: theme.palette.chips.highlighted,
              color: "#fff",
            }}
          />
        ) : (
          <Chip
            variant="outlined"
            label={t(componentType)}
            aria-label={componentType}
            onClick={chipClickHandler}
            sx={{ margin: 0.5 }}
          />
        )}
      </React.Fragment>
    );
  });
};

const ComponentsFilter: React.FC<ComponentsFilterProps> = (props) => {
  const { t } = useTranslation();
  const { selectedDevices, setSelectedDevices } =
    useContext(DevicesFilterContext);
  const componentOptions = props.componentOptions;
  const theme = useTheme();

  const chipClickHandler = (event: React.MouseEvent) => {
    const name = (event.target as HTMLSpanElement).parentElement?.getAttribute(
      "aria-label"
    ) as DeviceType;

    if (selectedDevices === null) {
      setSelectedDevices([`${name}`]);
    } else {
      setSelectedDevices([...selectedDevices, `${name}`]);
    }
  };

  const chipDeleteHandler = (
    event: React.MouseEvent<HTMLElement>,
    componentName: string
  ) => {
    if (selectedDevices !== null) {
      const index = selectedDevices.findIndex((device) => {
        return device === componentName;
      });
      setSelectedDevices([
        ...selectedDevices.slice(0, index),
        ...selectedDevices.slice(index + 1),
      ]);
    }
  };

  const chips = isButtonSelected(
    componentOptions,
    selectedDevices,
    chipDeleteHandler,
    chipClickHandler,
    theme,
    t
  );

  return <>{chips}</>;
};

export default ComponentsFilter;
