import React from "react";
import { Grid } from "@mui/material";
import ActiveAlarmsCard from "features/dashboard/ActiveAlarmsCard";
import MeasurementsOverviewCard from "features/dashboard/MeasurementsOverviewCard";
import EnergyFlowCard from "features/dashboard/EnergyFlowCard";
import EnergyFlowIllustrationCard from "features/dashboard/EnergyFlowIllustrationCard";

const Dashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} xl={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EnergyFlowCard />
          </Grid>
          <Grid item xs={12}>
            <MeasurementsOverviewCard />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} xl={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} xl={6}>
            <EnergyFlowIllustrationCard />
          </Grid>
          <Grid item xs={12} md={12} xl={6}>
            <ActiveAlarmsCard />
          </Grid>
          <Grid item xs={12} md={12} xl={6}>
            {/* Verbrauchsprognose */}
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            {/* Summendaten */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
