import React, { useState } from "react";
import { Location } from "types/Devices";

type MaybeLocations = Location[] | null;

type LocationFilterContextModel = {
  selectedLocations: MaybeLocations;
  setSelectedLocations: (maybeLocations: MaybeLocations) => void;
};

const LocationFilterContext = React.createContext<LocationFilterContextModel>({
  selectedLocations: null,
  setSelectedLocations: (maybeLocations) => {},
});

export const LocationFilterContextProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const [selectedLocations, setSelectedLocations] =
    useState<MaybeLocations>(null);

  var context = {
    selectedLocations,
    setSelectedLocations,
  };

  return (
    <LocationFilterContext.Provider value={context}>
      {props.children}
    </LocationFilterContext.Provider>
  );
};

export default LocationFilterContext;
