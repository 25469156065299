import React from "react";
import { Stack, Typography } from "@mui/material";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useTranslation } from "react-i18next";

const NoTimeseriesData = () => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <QueryStatsIcon color="disabled" sx={{ fontSize: 128 }} />
      <Typography color="GrayText" variant="h4" component="p">
        {t("noTimeseriesData")}
      </Typography>
    </Stack>
  );
};

export default NoTimeseriesData;
