import { Grid, Button, Stack, Box } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useState, useEffect } from "react";
import { getDateRangeUntilNow } from "services/date-helpers";
import DateSelect from "components/inputs/DateSelect";
import { useQuery } from "@tanstack/react-query";
import ChipsSelect from "components/inputs/ChipsSelect";
import Divider from "@mui/material/Divider/Divider";
import DataTableSkeleton from "components/UI/DataTableSkeleton";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarExportContainer,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { useTheme } from "@mui/material";
import {
  GetDataExportResponse,
  MeasurementAvailabilityStatus,
} from "types/Measurements";
import { useTranslation } from "react-i18next";
import DayMonthRadioGroup from "./DayMonthRadioGroup";
import { useMeasurementsApiClient } from "hooks/use-api-client";
import { JouleConverter, PercentConverter } from "utils/helpers/unit-helpers";

const GridToolbarExport = () => (
  <GridToolbarExportContainer>
    <GridCsvExportMenuItem
      options={{
        delimiter: ";",
        utf8WithBom: true,
      }}
    />
    <GridPrintExportMenuItem />
  </GridToolbarExportContainer>
);

const monthNames = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

type MeasurementAvailabilityStatusWithId = {
  id: string;
} & MeasurementAvailabilityStatus;

const HistoricalDataGrid = () => {
  const initialRange = getDateRangeUntilNow(7);
  const [range, setRange] = useState<DateRange<Date>>(initialRange);

  const [measurementsWithIds, setMeasurementsWithIds] = useState<
    MeasurementAvailabilityStatusWithId[]
  >([]);

  const [rows, setRows] = useState<GetDataExportResponse | null>(null);

  const [selectedDataSummarizationRange, setSelectedDataSummarizationRange] =
    useState<"daily" | "monthly">("daily");

  const [selectedDataPoints, setSelectedDataPoints] = useState<
    MeasurementAvailabilityStatusWithId[] | null
  >(null);

  const { getDataExportStatus, getDataExport } = useMeasurementsApiClient();

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const { data, isLoading } = useQuery(["DataPoints"], getDataExportStatus);
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    const jouleConverter = new JouleConverter();
    const percentConverter = new PercentConverter();

    const dateTimeColumn: GridColDef = {
      field: "timestamp",
      headerName: t("date")!,
      renderCell: (params: GridCellParams) => {
        const date = new Date(params.value);

        if (selectedDataSummarizationRange === "monthly") {
          return monthNames[date.getMonth()] + " " + date.getFullYear();
        }

        const dateString = date.toLocaleDateString();
        return dateString;
      },
      flex: 1,
    };

    if (selectedDataPoints) {
      const columns = selectedDataPoints.map<GridColDef>((dataPoint) => ({
        field: dataPoint.id,
        headerName: t(`${dataPoint.measurement}`).replace(/\u00AD/g, ""),
        valueGetter: (params) => params.row,
        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value && params.value.rowData[dataPoint.id]) {
            const value = params.value.rowData[dataPoint.id].value;
            const unit = params.value.rowData[dataPoint.id].unit;
            const convertedMeasurement =
              unit === "J"
                ? jouleConverter.convertFromBaseUnit(value, "J")
                : percentConverter.convertFromBaseUnit(value, "%");
            return `${convertedMeasurement.value} ${convertedMeasurement.unit}`;
          }
          return "";
        },
        flex: 1,
      }));
      setColumns([dateTimeColumn, ...columns]);
    } else {
      setColumns([]);
    }

    if (data) {
      const availableMeasurements = data.filter(
        (dataPoint) => dataPoint.isAvailable
      );
      const measurementsWithIds = availableMeasurements.map((dataPoint) => {
        return {
          ...dataPoint,
          id: dataPoint.measurement as string,
        };
      });
      setMeasurementsWithIds(measurementsWithIds);
    }
  }, [selectedDataPoints, data, t, selectedDataSummarizationRange]);

  const handleDataExportClick = async () => {
    const every = selectedDataSummarizationRange === "daily" ? "Day" : "Month";
    console.log(range[0]!);
    const response = await getDataExport(
      selectedDataPoints!.map((x) => x.measurement),
      every,
      range[0]!,
      range[1]!
    );

    setRows(response);
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <DateSelect
          onChange={setRange}
          range={range}
          dataSummarizationRange={selectedDataSummarizationRange}
        />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <DayMonthRadioGroup
            selectedValue={selectedDataSummarizationRange}
            onValueChange={setSelectedDataSummarizationRange}
            sx={{ ml: 3 }}
          />
          <Button
            variant="outlined"
            onClick={handleDataExportClick}
            disabled={!range[0] || !selectedDataPoints}
          >
            Daten generieren
          </Button>
        </Stack>
      </Box>

      <Box>
        {data && data.length > 1 && (
          <Grid item xs={12}>
            <ChipsSelect
              options={measurementsWithIds}
              selected={selectedDataPoints}
              display={(x) => t(x.measurement)}
              onSelectedOptionsChange={(selectedDataPoints) =>
                setSelectedDataPoints(selectedDataPoints)
              }
            />
          </Grid>
        )}
      </Box>

      <Box>
        <Divider sx={{ my: 2 }} />
      </Box>

      <Box sx={{ flex: 1 }}>
        <>
          {isLoading ? (
            <DataTableSkeleton columns={4} rows={6} />
          ) : (
            <DataGridPro
              localeText={theme.dataGridTranslations}
              rows={rows?.rows || []}
              columns={columns}
              autoPageSize
              getRowId={(row) => row.timestamp.toString()}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbarExport,
              }}
              sx={{
                height: "100%",
                overflowX: "scroll",
              }}
            />
          )}
        </>
      </Box>
    </Box>
  );
};

export default HistoricalDataGrid;
