import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, styled } from "@mui/material/styles";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

interface ChipData {
  id: string;
  label: string;
  selected: boolean;
}

interface ChipsSelectProps<TData extends { id: string }> {
  options: TData[];
  selected: TData[] | null;
  display: (data: TData) => string;
  onSelectedOptionsChange: (newSelected: TData[] | null) => void;
}

const ChipsSelect = <TData extends { id: string }>(
  props: ChipsSelectProps<TData>
) => {
  const { options, selected, display, onSelectedOptionsChange } = props;

  const chipData: ChipData[] = options.map((option) => {
    return {
      id: option.id,
      label: display(option),
      selected: selected != null && !!selected.find((d) => d.id === option.id),
    };
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const deselectHandler = (id: string) => () => {
    const filtered = selected?.filter((opt) => opt.id !== id);

    let newSelectedOptions: TData[] | null;

    newSelectedOptions =
      !filtered || filtered.length === 0 ? null : [...filtered];

    onSelectedOptionsChange(newSelectedOptions);
  };

  const selectHandler = (id: string) => () => {
    const newSelectedOption = options.find((opt) => opt.id === id);

    if (!newSelectedOption) {
      return;
    }

    let newSelectedOptions: TData[];
    if (selected === null) {
      newSelectedOptions = [newSelectedOption];
    } else {
      newSelectedOptions = selected.concat([newSelectedOption]);
    }

    onSelectedOptionsChange(newSelectedOptions);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0,
        mb: 0,
      }}
      component="ul"
    >
      {chipData.map((data) => {
        return (
          <ListItem key={data.id}>
            <Chip
              color={data.selected ? "secondary" : "default"}
              variant={data.selected ? "filled" : "outlined"}
              size={isMobile ? "small" : "medium"}
              clickable={!data.selected}
              onClick={data.selected ? undefined : selectHandler(data.id)}
              label={data.label}
              onDelete={data.selected ? deselectHandler(data.id) : undefined}
            />
          </ListItem>
        );
      })}
    </Box>
  );
};

export default ChipsSelect;
