import React from "react";
import { Typography, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";

const NoAlarmsOverlay = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CheckCircleIcon
        sx={{ fontSize: 70, color: "success.main", opacity: 0.5, mr: 2 }}
      />
      <Typography>{t("noAlarms")}</Typography>
    </Box>
  );
};

export default NoAlarmsOverlay;
