import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const LoginPage: React.FC = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const { loginWithRedirect } = useAuth0();

  console.log(window.location.origin);

  useEffect(() => {
    const redirectLogin = async () => {
      await loginWithRedirect({
        organization: query.get("organization") ?? undefined,
        organizationName: query.get("organization_name"),
        invitation: query.get("invitation") ?? undefined,
      });
    };

    redirectLogin();
  }, [loginWithRedirect, query]);

  console.log("organization", query.get("organization"));
  console.log("invitation", query.get("invitation"));

  return <p>Redirecting log in...</p>;
};

export default LoginPage;
