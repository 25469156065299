import { useAuth0 } from "@auth0/auth0-react";
import { PagedResult, Organization } from "types/Common";
import ProfileBlock from "components/UI/ProfileBlock";
import ProfileBlockTextItem from "components/UI/ProfileBlockTextItem";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "hooks/use-api-client";
import { useTranslation } from "react-i18next";

const tryGetDisplayNameOrFallback = (data?: PagedResult<Organization>) => {
  return data?.results && data.results.length > 0
    ? data.results[0].displayName
    : "Failed to load org details";
};

const OrganizationBlock = () => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const { getOrganizationData } = useApiClient();
  const { isLoading, data, error } = useQuery<PagedResult<Organization>, Error>(
    ["getOrganizationData"],
    () => getOrganizationData(user?.org_id)
  );

  return (
    <ProfileBlock heading={t("organization")!}>
      <ProfileBlockTextItem
        label="Org.Name"
        value={tryGetDisplayNameOrFallback(data)}
        loading={isLoading}
        error={error}
      />
      {/* <ProfileBlockTextItem label="Address" value="Schneiderkruger Str. 12" />
      <ProfileBlockTextItem label="Zip-Code / City" value="49429 Visbek" /> */}
    </ProfileBlock>
  );
};

export default OrganizationBlock;
