import React from "react";
import { Toolbar, Container, useTheme, Box } from "@mui/material";
import { useMobileBreakpoint } from "hooks/use-breakpoint";

const Main: React.FC<{ children: React.ReactNode }> = (props) => {
  const theme = useTheme();
  const isMobile = useMobileBreakpoint();

  const content = (
    <>
      <Toolbar />
      <Container
        maxWidth={theme.containerMaxWidth}
        sx={{
          pt: (theme) => `${theme.layoutContainerHeight.header.spacing}px`,
        }}
      >
        {props.children}
      </Container>
    </>
  );

  return !isMobile ? (
    <Box component={"main"} sx={(theme) => ({ ml: `${theme.drawerWidth}px` })}>
      {content}
    </Box>
  ) : (
    <main>{content}</main>
  );
};

export default Main;
