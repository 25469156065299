import { SvgIcon, useTheme } from "@mui/material";
import React from "react";
import BatteryLargeShell from "./BatteryLargeShell";
import BatteryLargeStateOfCharge from "./BatteryLargeStateOfCharge";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

interface AnimatedtBatteryLargeProps {
  stateOfCharge?: number;
  charging?: boolean;
}
const AnimatedBatteryLarge: React.FC<AnimatedtBatteryLargeProps> = (props) => {
  const theme = useTheme();
  const { stateOfCharge, charging } = props;

  return (
    <SvgIcon
      overflow="visible"
      viewBox="5 0 234.59 78.1"
      sx={{
        height: "100%",
        width: "200%",
        display: "flex",
      }}
    >
      <g
        style={{ transformOrigin: "center" }}
        transform="rotate(-90)"
        opacity={theme.palette.devicesPage.graphicsOpacity}
      >
        <BatteryLargeShell />
        {stateOfCharge ? (
          <BatteryLargeStateOfCharge stateOfCharge={stateOfCharge} />
        ) : (
          <g style={{ transformOrigin: "center" }} transform="rotate(90)">
            <QuestionMarkIcon color="action" />
          </g>
        )}
        {charging ? (
          <g style={{ transformOrigin: "center" }} transform="rotate(90)">
            <BoltOutlinedIcon color="action" />
          </g>
        ) : (
          charging === undefined && (
            <g style={{ transformOrigin: "center" }} transform="rotate(90)">
              <QuestionMarkIcon color="action" />
            </g>
          )
        )}
      </g>
    </SvgIcon>
  );
};

export default AnimatedBatteryLarge;
