import { Paper, Skeleton } from "@mui/material";

const DeviceCardContentSkeleton = () => {
  return (
    <>
      <Paper
        sx={{
          padding: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <Skeleton width={"146px"} height={"74px"} />
      </Paper>

      <Paper
        sx={{
          padding: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        variant="outlined"
      >
        <Skeleton width={"146px"} height={"74px"} />
      </Paper>
    </>
  );
};

export default DeviceCardContentSkeleton;
