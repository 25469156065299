import React from "react";
import { Box, TextField } from "@mui/material";
import { LocalizationProvider, deDE } from "@mui/x-date-pickers-pro";
import {
  DateRangePicker,
  DateRange,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import // toStartOfDay,
// // toEndOfDay,
// // toStartOfMonth,
// // toEndOfMonthOrNow,
// "services/date-helpers";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers-pro";
import de from "date-fns/locale/de";

interface DateSelectProps {
  range: DateRange<Date>;
  onChange: (range: DateRange<Date>) => void;
  dataSummarizationRange?: "daily" | "monthly";
}

const DateSelect: React.FC<DateSelectProps> = ({
  range,
  onChange,
  dataSummarizationRange,
}) => {
  const { t } = useTranslation();
  const isMonthly = dataSummarizationRange === "monthly";
  const inputFormat = isMonthly ? "MM.yyyy" : "dd.MM.yyyy";
  const mask = isMonthly ? "__.____" : "__.__.____";

  const DaySelect = () => {
    return (
      <DateRangePicker
        value={range}
        autoFocus={true}
        disableFuture
        inputFormat={inputFormat}
        mask={mask}
        onAccept={(newValue: DateRange<Date>) => {
          if (newValue[0] && newValue[1]) {
            onChange([newValue[0], newValue[1]]);
          }
        }}
        onChange={() => {}}
        renderInput={(startProps: any, endProps: any) => (
          <>
            <TextField size="small" {...startProps} />
            <Box sx={{ mx: 1 }}> {t("to").toLowerCase()} </Box>
            <TextField size="small" {...endProps} />
          </>
        )}
      />
    );
  };

  const MonthSelect = () => {
    return (
      <>
        <DatePicker
          value={range[0]}
          onChange={(value) => {
            if (value) {
              onChange([value, range[1]]);
            }
          }}
          renderInput={(startProps: any) => (
            <TextField size="small" {...startProps} />
          )}
          views={["year", "month"]}
          openTo="month"
          disableFuture
        />
        <Box sx={{ mx: 1 }}> {t("to").toLowerCase()} </Box>
        <DatePicker
          value={range[1]}
          onChange={(value) => {
            if (value) {
              onChange([range[0], value]);
            }
          }}
          renderInput={(endProps: any) => (
            <TextField size="small" {...endProps} />
          )}
          views={["year", "month"]}
          openTo="month"
          disableFuture
        />
      </>
    );
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={{
        ...deDE.components.MuiLocalizationProvider.defaultProps.localeText,
        start: t("from") as string,
        end: t("to") as string,
      }}
      adapterLocale={de}
    >
      {isMonthly ? <MonthSelect /> : <DaySelect />}
    </LocalizationProvider>
  );
};

export default DateSelect;
