import { PagedResult } from "types/Common";
import {
  GetDataExportResponse,
  EnergySavingSummary,
  Every,
  ExportableMeasurement,
  GridOperatorRegulationResponse,
  MeasurementAvailabilityStatus,
  TimeseriesMetadata,
  TimeseriesMetadataPermission,
  TimeseriesMetadataPermissionData,
} from "types/Measurements";
import {
  TimeseriesData,
  TimeseriesDataInfos,
  TimeseriesDatabase,
} from "types/Measurements";
import { useAxiosWithBearerToken } from "./useApiClient";

export interface GetTimeseriesMetadataOptions {
  page?: number;
  pageSize?: number;
  deviceIds?: string[];
  fields?: string[];
}

export const useMeasurementsApiClient = () => {
  const request = useAxiosWithBearerToken();

  const getTimeseriesDatabases = () => {
    return request<PagedResult<TimeseriesDatabase>>(
      "/measurements/timeseries-databases",
      "GET"
    );
  };

  const getTimeseriesDataInfoByDevice = (deviceId: string) => {
    return request<TimeseriesDataInfos>(
      `/devices/${deviceId}/timeseries-data/infos`,
      "GET"
    );
  };

  const getTimeseriesDataByDevice = (
    deviceId: string,
    from: Date,
    to: Date
  ) => {
    return request<TimeseriesData[]>(
      `/devices/${deviceId}/timeseries-data?from=${from.toISOString()}&to=${to.toISOString()}`,
      "GET"
    );
  };

  const getEnergySavingSummary = () => {
    return request<EnergySavingSummary>(
      "measurements/energy-saving/summary",
      "GET"
    );
  };

  const updateTimeseriesMetadataPermission = (
    id: string,
    data: TimeseriesMetadataPermissionData
  ) => {
    return request<
      TimeseriesMetadataPermission,
      TimeseriesMetadataPermissionData
    >(`/measurements/timeseries-metadata/permissions/${id}`, "PUT", data);
  };

  const getTimeseriesMetadata = (options?: GetTimeseriesMetadataOptions) => {
    const page = options?.page ?? 1;
    const pageSize = options?.page ?? 200;

    let uri = `/measurements/timeseries-metadata?page=${page}&pageSize=${pageSize}`;

    const deviceIds = options?.deviceIds;

    if (deviceIds && deviceIds.length > 0) {
      uri += `&${deviceIds.map((x) => `deviceIds=${x}`).join("&")}`;
    }

    const fields = options?.fields;

    if (fields && fields.length) {
      uri += `&${fields.map((x) => `fields=${x}`).join("&")}`;
    }

    return request<PagedResult<TimeseriesMetadata>>(uri, "GET");
  };

  const updateTimeseriesMetadataCustomFieldName = (
    id: string,
    customFieldName: string
  ) => {
    return request<TimeseriesMetadata>(
      `/measurements/timeseries-metadata/${id}/customFieldName/${customFieldName}`,
      "PUT"
    );
  };

  const getGridOperatorRegulation = () => {
    return request<GridOperatorRegulationResponse>(
      "measurements/grid-operator-regulation",
      "GET"
    );
  };

  const getDataExportStatus = () => {
    return request<MeasurementAvailabilityStatus[]>(
      "measurements/export/availability",
      "GET"
    );
  };

  const getDataExport = (
    measurements: ExportableMeasurement[],
    every: Every,
    from: Date,
    to?: Date
  ) => {
    let uri = `/measurements/export?`;

    if (measurements.length > 0) {
      uri += `${measurements.map((x) => `measurements=${x}`).join("&")}`;
    }

    uri += `&from=${from.toISOString()}`;

    if (to) {
      uri += `&to=${to.toISOString()}`;
    }

    uri += `&every=${every}`;

    return request<GetDataExportResponse>(uri, "GET");
  };

  return {
    getTimeseriesDataInfoByDevice,
    getTimeseriesDataByDevice,
    getTimeseriesDatabases,
    getEnergySavingSummary,
    getTimeseriesMetadata,
    updateTimeseriesMetadataCustomFieldName,
    updateTimeseriesMetadataPermission,
    getGridOperatorRegulation,
    getDataExportStatus,
    getDataExport,
  };
};
