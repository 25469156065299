import React from "react";
import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface ImprinLinkProps {
  to: string;
  children: React.ReactNode;
}

const FooterLink: React.FC<ImprinLinkProps> = (props) => {
  return (
    <MuiLink
      color={"text.primary"}
      sx={{
        fontSize: { xs: "0.9rem" },
        mx: { xs: "1rem", sm: "0.5rem", md: "2rem" },
        mb: { xs: 1, sm: 0 },
      }}
      component={RouterLink}
      to={props.to}
      role={"FooterLink"}
    >
      {props.children}
    </MuiLink>
  );
};

export default FooterLink;
