import { Grid } from "@mui/material";
import MeasurementCard from "./MeasurementCard";
import { DecimalPlaces } from "utils/helpers/math-helpers";
import { useQuery } from "@tanstack/react-query";
import { useMeasurementsApiClient } from "hooks/use-api-client";
import { useTranslation } from "react-i18next";
import {
  EnergySavingResponseStatus,
  GridOperatorRegulationResponseStatus,
} from "types/Measurements";

const MeasurementsOverviewCard = () => {
  const { t } = useTranslation();
  const { getEnergySavingSummary, getGridOperatorRegulation } =
    useMeasurementsApiClient();
  const { data, isLoading } = useQuery(
    ["EnergySavingSummary"],
    getEnergySavingSummary,
    {
      // refetch every 30s
      refetchInterval: 30000,
    }
  );

  const {
    data: gridOperatorRegulationResponse,
    isLoading: isLoadingGridOperatorRegulation,
  } = useQuery(["GridOperatorRegulation"], getGridOperatorRegulation, {
    refetchInterval: 30000,
  });

  const statusToError = (status?: EnergySavingResponseStatus) => {
    switch (status) {
      case "InvalidPhotovoltaics": {
        return t("invalidPhotovoltaics");
      }

      case "IssuesWithMeasurements": {
        return t("issuesWithMeasurements");
      }

      case "MissingGridConnection": {
        return t("missingGridConnection");
      }

      case "MissingPhotovoltaics": {
        return t("missingPhotovoltaics");
      }
    }

    return undefined;
  };

  const gridOperatorStatusToError = (
    status?: GridOperatorRegulationResponseStatus
  ) => {
    switch (status) {
      case "IssuesWithMeasurements": {
        return t("issuesWithMeasurements");
      }

      case "MissingPgsController": {
        return t("missingPgsController");
      }
    }

    return undefined;
  };

  const measurements = [
    {
      key: "selfConsumptionRate",
      title: t("selfConsumptionRate"),
      decimalPlaces: 0,
      valueUnit: data?.energyConsumptionRate,
      info: t("basedOnLast15Minutes"),
    },
    {
      key: "selfSufficiencyLevel",
      title: t("selfSufficiencyLevel"),
      decimalPlaces: 0,
      valueUnit: data?.selfSufficiencyLevel,
      info: t("basedOnLast15Minutes"),
    },
  ];

  return (
    <Grid container spacing={2}>
      {measurements.map((item) => {
        return (
          <Grid item xs={12} sm={6} key={item.key}>
            <MeasurementCard
              title={item.title}
              valueWithUnit={item.valueUnit}
              decimalPlaces={item.decimalPlaces as DecimalPlaces}
              isLoading={isLoading}
              error={statusToError(data?.status)}
              info={item.info}
            />
          </Grid>
        );
      })}
      <Grid item xs={12} sm={6} key="LOL123">
        <MeasurementCard
          title={t("gridOperatorRegulation")}
          valueWithUnit={gridOperatorRegulationResponse?.value}
          decimalPlaces={0}
          isLoading={isLoadingGridOperatorRegulation}
          error={gridOperatorStatusToError(
            gridOperatorRegulationResponse?.status
          )}
          info={t("currentValue")}
        />
      </Grid>
    </Grid>
  );
};

export default MeasurementsOverviewCard;
