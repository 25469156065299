import { IconButton } from "@mui/material";
import { keyframes } from "@mui/system";
import React, { useState } from "react";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

interface AnimatedExpandMoreButtonProps {
  onClick: () => void;
}

const turnUpside = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }`;

const turnDownside = keyframes`
0% {
  transform: rotate(180deg);
}

100% {
  transform: rotate(0deg);
}`;

const AnimatedExpandMoreButton: React.FC<AnimatedExpandMoreButtonProps> = (
  props
) => {
  const [animateIcon, setAnimateIcon] = useState<boolean | null>(null);

  const onClickHandler = () => {
    props.onClick();
    if (!animateIcon) {
      setAnimateIcon(true);
    } else {
      setAnimateIcon(false);
    }
  };

  return (
    <IconButton onClick={onClickHandler}>
      {
        <ExpandMoreOutlinedIcon
          sx={{
            animation: animateIcon
              ? `${turnUpside} 200ms 0s ease forwards`
              : `${turnDownside} 200ms 0s ease forwards`,
          }}
        />
      }
    </IconButton>
  );
};

export default AnimatedExpandMoreButton;
