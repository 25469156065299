import React, { PropsWithChildren } from "react";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

interface BreadcrumbLinkProps {
  color: string;
  url: string;
  content: string;
}

const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = (props) => {
  const { color, url, content } = props;
  const navigate = useNavigate();

  return (
    <Link
      underline="hover"
      color={color}
      href={url}
      onClick={(e) => {
        e.preventDefault();
        navigate(url);
      }}
    >
      {content}
    </Link>
  );
};

export interface DeviceDetailsBreadcrumbsProps {
  deviceId: string;
  /**
   * Indicating whether the parent component is still loading the device data
   */
  loading: boolean;
  /**
   * "details": shows an edit IconButton to navigate to /devices/:id/edit
   * "edit": shows the edit link but no IconButton
   */
  variant: "details" | "edit";
  /**
   * The content of the device details breadcrumb link
   */
  content?: string;
}

const DeviceDetailsBreadcrumbs = (
  props: PropsWithChildren<DeviceDetailsBreadcrumbsProps>
) => {
  const { deviceId, variant, content, loading } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const editClickHandler = () => {
    // TODO: implement page ;-)
    navigate(`devices/${deviceId}/edit`);
  };

  // console.log(showEdit);

  return (
    <Box component="div" role="presentation" sx={{ mb: 2 }}>
      <Stack direction="row" spacing={1} alignItems="center" height={24}>
        <Breadcrumbs aria-label="breadcrumb">
          <BreadcrumbLink
            color="inherit"
            url="/devices"
            content={t("devices")}
          />
          {loading && <Skeleton variant="text" width={120} />}
          {!loading && (
            <BreadcrumbLink
              color="text.primary"
              url={`/devices/${deviceId}`}
              content={content ?? deviceId}
            />
          )}
          {variant === "edit" && (
            <BreadcrumbLink
              color="text.primary"
              url={`/devices/${deviceId}/edit`}
              content="Edit"
            />
          )}
        </Breadcrumbs>
        {variant === "details" && !loading && (
          <IconButton onClick={editClickHandler}>
            <EditIcon />
          </IconButton>
        )}
        {props.children}
      </Stack>
    </Box>
  );
};

export default DeviceDetailsBreadcrumbs;
