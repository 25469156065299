import { SvgIcon, useTheme } from "@mui/material";

const PhotovoltaicSvg = () => {
  const theme = useTheme();
  return (
    <SvgIcon
      viewBox="100 190 512 512"
      sx={{
        overflow: "visible",
        height: "300%",
        width: "300%",
      }}
    >
      <g opacity={theme.palette.devicesPage.graphicsOpacity}>
        <path
          fill="#ADB8C1"
          d="M478.13,478.555H33.87c-13.072,0-23.67-10.597-23.67-23.67V238.237
	c0-13.073,10.598-24.067,23.67-24.067h444.26c13.073,0,23.67,10.995,23.67,24.067v216.647
	C501.801,467.958,491.203,478.555,478.13,478.555z"
        />
        <g>
          <path
            fill="#768898"
            d="M478.13,203.97h-16.107v-3.259c0-15.747-12.811-28.558-28.558-28.558s-28.558,12.811-28.558,28.558
		v3.259H107.092v-3.259c0-3.325-0.577-6.516-1.626-9.486l123.892-45.886c7.378,6.524,17.063,10.496,27.663,10.496
		c12.869,0,24.395-5.847,32.072-15.021l60.754,26.415c1.325,0.575,2.704,0.849,4.061,0.849c3.937,0,7.688-2.294,9.359-6.135
		c2.246-5.166-0.121-11.174-5.287-13.42l-59.994-26.084c0.558-2.72,0.852-5.536,0.852-8.418c0-19.541-13.473-35.995-31.618-40.559
		V33.445c0-5.633-4.567-10.199-10.199-10.199c-5.633,0-10.199,4.566-10.199,10.199v48.956c0,5.633,4.566,10.199,10.199,10.199
		c11.782,0,21.369,9.564,21.414,21.337l-14.288-6.212c-5.165-2.245-11.174,0.122-13.42,5.287c-2.246,5.166,0.121,11.174,5.287,13.42
		l12.688,5.517c-3.363,2.199-7.371,3.489-11.68,3.489c-11.811,0-21.418-9.608-21.418-21.418c0-5.633-4.566-10.199-10.199-10.199
		s-10.199,4.566-10.199,10.199c0,4.881,0.849,9.566,2.393,13.924L90.772,174.915c-3.711-1.768-7.861-2.761-12.238-2.761
		c-15.747,0-28.558,12.811-28.558,28.558v3.259H33.87C15.194,203.97,0,219.342,0,238.236v216.648c0,18.676,15.194,33.87,33.87,33.87
		h444.261c18.676,0,33.869-15.194,33.869-33.87V238.236C512,219.342,496.806,203.97,478.13,203.97z M425.307,200.712
		c0-4.499,3.66-8.159,8.159-8.159c4.499,0,8.159,3.659,8.159,8.159v3.259h-16.319V200.712z M70.375,200.712
		c0-4.499,3.66-8.159,8.159-8.159s8.159,3.659,8.159,8.159v3.259H70.375V200.712z M478.13,224.368
		c7.302,0,13.471,6.351,13.471,13.868v216.648c0,7.428-6.043,13.471-13.471,13.471H33.87c-7.428,0-13.471-6.043-13.471-13.471
		V238.236c0-7.518,6.168-13.868,13.471-13.868H478.13z"
          />
          <path
            fill="#768898"
            d="M93.323,253.238c-5.633,0-10.199,4.566-10.199,10.199v166.247c0,5.633,4.566,10.199,10.199,10.199
		c5.633,0,10.199-4.566,10.199-10.199V263.437C103.522,257.804,98.956,253.238,93.323,253.238z"
          />
          <path
            fill="#768898"
            d="M173.896,253.238c-5.633,0-10.199,4.566-10.199,10.199v166.247c0,5.633,4.566,10.199,10.199,10.199
		c5.633,0,10.199-4.566,10.199-10.199V263.437C184.096,257.804,179.529,253.238,173.896,253.238z"
          />
          <path
            fill="#768898"
            d="M255.49,253.238c-5.633,0-10.199,4.566-10.199,10.199v166.247c0,5.633,4.566,10.199,10.199,10.199
		c5.632,0,10.199-4.566,10.199-10.199V263.437C265.689,257.804,261.122,253.238,255.49,253.238z"
          />
          <path
            fill="#768898"
            d="M337.084,253.238c-5.632,0-10.199,4.566-10.199,10.199v166.247c0,5.633,4.567,10.199,10.199,10.199
		c5.632,0,10.199-4.566,10.199-10.199V263.437C347.283,257.804,342.716,253.238,337.084,253.238z"
          />
          <path
            fill="#768898"
            d="M418.677,253.238c-5.632,0-10.199,4.566-10.199,10.199v166.247c0,5.633,4.567,10.199,10.199,10.199
		c5.632,0,10.199-4.566,10.199-10.199V263.437C428.876,257.804,424.309,253.238,418.677,253.238z"
          />
          <path
            fill="#768898"
            d="M379.437,179.473l7.068,3.061c1.321,0.572,2.695,0.842,4.048,0.842c3.942,0,7.697-2.3,9.365-6.148
		c2.238-5.169-0.138-11.174-5.307-13.412l-7.068-3.061c-5.167-2.24-11.172,0.136-13.412,5.306
		C371.893,171.23,374.268,177.236,379.437,179.473z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PhotovoltaicSvg;
