import React, { useEffect, useState } from "react";
import { getAgeInMinutes } from "utils/helpers/date-helpers";
import AgeChip from "./AgeChip";

const updateIntervalInMilliseconds = 5000;

interface AgeIndicatorProps {
  age: number;
}

const AgeIndicator: React.FC<AgeIndicatorProps> = ({ age }) => {
  const [dataAge, setDataAge] = useState(getAgeInMinutes(age, Date.now()));

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const ageInMinutes = getAgeInMinutes(age, now);
      setDataAge(ageInMinutes);
    }, updateIntervalInMilliseconds);

    return () => clearInterval(interval);
  }, [age]);

  return <AgeChip dataAge={dataAge} />;
};

export default AgeIndicator;
