import { Paper, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import RoleGuard from "features/auth/RoleGuard";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";

const Organization = () => {
  const { t } = useTranslation();
  const path = window.location.pathname;
  const [currentTab, setCurrentTab] = useState<string | boolean>(
    path === "/organization" ? false : path
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <RoleGuard permittedRoles={["Admin"]}>
      <Typography variant="h5" component="h1" marginBottom={2}>
        {t("organization")}
      </Typography>
      <Paper variant="outlined">
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="organization tab group"
        >
          <Tab
            label={t("influx")}
            value="/organization/influx"
            to="influx"
            component={Link}
          />
          <Tab
            label={t("locations")}
            value="/organization/locations"
            to="locations"
            component={Link}
          />
        </Tabs>
        <Outlet />
      </Paper>
    </RoleGuard>
  );
};

export default Organization;
