import { Card, CardContent, CardHeader, Collapse, Grid } from "@mui/material";
import React, { useState } from "react";
import ComponentsFilter from "./ComponentsFilter";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import LocationFilter from "./LocationFilter";
import { useMobileBreakpoint } from "hooks/use-breakpoint";
import AnimatedExpandMoreButton from "components/AnimatedIcons/AnimatedExpandMoreButton";
import { DeviceType } from "types/Common";

interface DevicesPageFilterProps {
  componentOptions: DeviceType[];
}

const DevicesPageFilter: React.FC<DevicesPageFilterProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMobileBreakpoint();
  const componentOptions = props.componentOptions;

  return (
    <Card variant="outlined" elevation={0}>
      <CardHeader
        avatar={<FilterAltOutlinedIcon color="secondary" />}
        title="Filter-Menu"
        titleTypographyProps={{ fontSize: "1rem" }}
        action={
          isMobile ? (
            <AnimatedExpandMoreButton
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
            />
          ) : undefined
        }
      />
      <Collapse in={!isMobile || isExpanded}>
        <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid container>
            <Grid item xs={12} marginBottom={"0.7rem"}>
              <ComponentsFilter componentOptions={componentOptions} />
            </Grid>
            <Grid item xs={12}>
              <LocationFilter />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default DevicesPageFilter;
