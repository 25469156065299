import { GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import React from "react";
import ForwardIcon from "@mui/icons-material/Forward";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CustomFooter = () => {
  const navigate = useNavigate();
  return (
    <GridFooterContainer>
      <IconButton onClick={() => navigate("/alarms")}>
        <ForwardIcon />
      </IconButton>
      <GridFooter
        sx={{
          border: "none",
        }}
      />
    </GridFooterContainer>
  );
};
