import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import styles from "./animate-Drawer-Buttons.module.css";

const useAnimatedDrawerButtons = () => {
  const isSignedIn = useAuth0().isAuthenticated;

  useEffect(() => {
    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const animateButtons = async () => {
      const sessionExists = sessionStorage.getItem("existingSession");

      if (!sessionExists) {
        const allDrawerButtons = Array.from(
          document.getElementsByClassName("animatableDrawerButton")
        );

        await sleep(1000);

        for (let i = 0; i < allDrawerButtons.length; i++) {
          allDrawerButtons[i].classList.add(styles.drawerButtonAnimation);
          await sleep(200);
        }

        sessionStorage.setItem("existingSession", "true");
      }
    };

    if (isSignedIn) {
      animateButtons();
    }
  }, [isSignedIn]);
};

export default useAnimatedDrawerButtons;
