import React from "react";
import { useTranslation } from "react-i18next";
import { TimeseriesDatabase } from "types/Measurements";
import CopyContentField from "features/organization/InfluxBucketCard/CopyContentField/CopyContentField";

interface InfluxDataDisplayProps {
  bucketData: TimeseriesDatabase;
}

const InfluxDataDisplay: React.FC<InfluxDataDisplayProps> = ({
  bucketData,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CopyContentField label={`${t("id")}: `} content={bucketData.id} />
      <CopyContentField
        label={`${t("bucketName")}: `}
        content={bucketData.bucketName}
      />
      <CopyContentField
        label={`${t("bucketId")}: `}
        content={bucketData.bucketId}
      />
      <CopyContentField
        label={`${t("organizationId")}: `}
        content={bucketData.organizationId}
      />
    </>
  );
};

export default InfluxDataDisplay;
