import { Grid } from "@mui/material";
import FooterLink from "./FooterLink/FooterLink";
import { useTranslation } from "react-i18next";

const FooterLinks = () => {
  const { t } = useTranslation();

  const links = [
    {
      to: "/imprint",
      label: t("siteNotice"),
    },
    {
      to: "/privacy",
      label: t("privacy"),
    },
    // {
    //   to: "/dashboard",
    //   label: "Feedback",
    // },
  ];
  return (
    <Grid
      item
      xs={12}
      sm={4}
      md={6}
      sx={{
        display: "flex",
        justifyContent: { xs: "center", sm: "left" },
        alignItems: "center",
        mt: { xs: 1, sm: 0 },
      }}
    >
      {links.map((link, index) => {
        return (
          <FooterLink to={link.to} key={index}>
            {link.label}
          </FooterLink>
        );
      })}
    </Grid>
  );
};

export default FooterLinks;
