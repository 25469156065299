import { LiveSummaryMeasurement } from "types/Devices";

export const checkAndSetErrors = (
  data: LiveSummaryMeasurement[],
  onDataError: (errorMessage: string | null) => void,
  t: (key: string) => string
) => {
  if (data.find((data) => data.device === undefined)) {
    onDataError(t("deviceNotFound"));
  } else if (data.find((data) => data.measurement === undefined)) {
    onDataError(t("measurementNotFound"));
  } else if (
    data.find(
      (data) =>
        new Date(data.measurement?.timestamp as string).getTime() <
        Date.now() - 600000
    )
  ) {
    onDataError(t("oldMeasurementWarning"));
  } else {
    onDataError(null);
  }
};
