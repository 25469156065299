import React, { useState } from "react";
import { Typography, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface CopyFieldProps {
  title: string;
  content: string;
}

const CopyField: React.FC<CopyFieldProps> = ({ content, title }) => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);

  const onButtonClick = (content: string) => {
    navigator.clipboard.writeText(content);
    setIsClicked(true);
  };

  const onTooltipClose = () => {
    setIsClicked(false);
  };

  return (
    <Typography sx={{ display: "flex", alignItems: "center" }}>
      {`${t(title)}: ${content}`}
      <Tooltip
        title={t("copied")}
        open={isClicked}
        arrow
        leaveDelay={1000}
        onClose={onTooltipClose}
      >
        <IconButton
          sx={{ color: (theme) => theme.palette.text.disabled, ml: 1 }}
          onClick={() => onButtonClick(content)}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </Typography>
  );
};

export default CopyField;
