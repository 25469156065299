import React from "react";
import { Drawer } from "@mui/material";

const DesktopDrawer: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <Drawer
      sx={(theme) => ({
        width: theme.drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: theme.drawerWidth,
          backgroundColor: theme.palette.frame,
          border: 0,
          boxSizing: "border-box",
        },
      })}
      variant="permanent"
      anchor="left"
    >
      {props.children}
    </Drawer>
  );
};

export default DesktopDrawer;
