import { SvgIcon, useTheme } from "@mui/material";
import React from "react";

interface AnimatedEnergyFlowArrowProps {
  arrowDirection: "left" | "right";
  arrowColor: string;
}

const AnimatedEnergyFlowArrow: React.FC<AnimatedEnergyFlowArrowProps> = ({
  arrowColor,
  arrowDirection,
}) => {
  const arrowSize = "60%";
  const animationDuration = "1s";
  const splines = "0.23 0.55 0.46 0.8; 0.23 0.55 0.46 0.8";
  const theme = useTheme();
  const defaultArrowColor = theme.palette.text.disabled;
  const arrowProps = [
    { id: "arrow1Scale", x: 18.6, begin: "arrow2Scale.begin+0.3s" },
    { id: "arrow2Scale", x: 33.6, begin: "arrow3Scale.begin+0.3s" },
    { id: "arrow3Scale", x: 48.6, begin: "arrow4Scale.begin+0.3s" },
    { id: "arrow4Scale", x: 63.6, begin: "0s; arrow1Scale.end+2s" },
  ];

  return (
    <SvgIcon
      viewBox="0 0 63.6 37.8"
      sx={{
        height: arrowSize,
        width: arrowSize,
        overflow: "visible",
      }}
    >
      <g
        style={{ transformOrigin: "center" }}
        transform={arrowDirection === "right" ? "rotate(180)" : undefined}
      >
        {arrowProps.map((arrow) => {
          return (
            <path
              key={arrow.id}
              color={defaultArrowColor}
              style={{ transformOrigin: "center" }}
              d={`M ${arrow.x} 37.8 l -17.55 -17.55 q -0.25 -0.25 -0.35 -0.5 q -0.1 -0.25 -0.1 -0.55 q 0 -0.3 0.1 -0.55 q 0.1 -0.25 0.35 -0.5 l 17.55 -17.55 q 0.55 -0.55 1.4 -0.55 q 0.85 0 1.4 0.55 q 0.6 0.6 0.6 1.425 q 0 0.825 -0.6 1.425 l -15.75 15.75 l 15.75 15.75 q 0.6 0.6 0.575 1.425 q -0.025 0.825 -0.575 1.375 q -0.6 0.6 -1.425 0.6 q -0.825 0 -1.375 -0.55 z`}
            >
              <animateTransform
                id={arrow.id}
                attributeName="transform"
                begin={arrow.begin}
                type="scale"
                values="1;1.1;1"
                dur={animationDuration}
                repeatCount="1"
                calcMode="spline"
                keySplines={splines}
              />
              <animate
                attributeName="fill"
                begin={`${arrow.id}.begin`}
                dur={animationDuration}
                values={`${defaultArrowColor}; ${arrowColor}; ${defaultArrowColor}`}
                repeatCount="1"
              />
            </path>
          );
        })}
      </g>
    </SvgIcon>
  );
};

export default AnimatedEnergyFlowArrow;
