import { SvgIcon } from "@mui/material";
import { Box } from "@mui/system";
import { useApiClient } from "hooks/use-api-client";
import { useQuery } from "@tanstack/react-query";
import EnergyFlowAnimation from "./EnergyFlowAnimation";
import prepareData, { PreparedData } from "./prepareData";
import { Battery, Consumer, GridConnection, Producer } from "./EnergyFlowSVGs";
import LoadingSpinner from "components/UI/LoadingSpinner";
import { WattConverter } from "utils/helpers/unit-helpers";

const EnergyFlowIllustration = () => {
  const { getEnergyFlowData } = useApiClient();
  const { data, isLoading } = useQuery(["EnergyFlowData"], getEnergyFlowData, {
    refetchInterval: 30000,
  });
  const preparedData: PreparedData[] = prepareData(data);
  const isComponentExisting = (name: string): boolean => {
    return preparedData.find((x) => x.energyFlowRole === name)!.exists;
  };
  const wattConverter = new WattConverter();
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <SvgIcon
        viewBox="0 0 308 362"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        <g>
          <g>
            {isComponentExisting("Battery") && (
              <Battery
                posX="140"
                posY="275"
                value={wattConverter.convertFromBaseUnit(
                  preparedData[3].value,
                  "W"
                )}
              />
            )}
            {isComponentExisting("GridConnection") && (
              <GridConnection
                posX="30"
                posY="148"
                value={wattConverter.convertFromBaseUnit(
                  preparedData[2].value,
                  "W"
                )}
              />
            )}
            {isComponentExisting("Producer") && (
              <Producer
                posX="115"
                posY="35"
                value={wattConverter.convertFromBaseUnit(
                  preparedData[1].value,
                  "W"
                )}
              />
            )}

            {isComponentExisting("Consumer") && (
              <Consumer
                posX="245"
                posY="145"
                value={wattConverter.convertFromBaseUnit(
                  preparedData[0].value,
                  "W"
                )}
              />
            )}
            {data && (
              <EnergyFlowAnimation
                midXPos={154.2953}
                midYPos={177.3102}
                data={preparedData}
              />
            )}
          </g>
        </g>
      </SvgIcon>
    </Box>
  );
};

export default EnergyFlowIllustration;
