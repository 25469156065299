import { Grid } from "@mui/material";
import OrganizationBlock from "features/profile/OrganizationBlock";
import ProfileHeader from "features/profile/ProfileHeader";
import UserProfileBlock from "features/profile/UserProfileBlock";
import AnimatedLogoutButton from "features/profile/AnimatedLogoutButton";

const UserPage = () => {
  return (
    <Grid container marginTop={"2%"} columnSpacing={5}>
      <ProfileHeader />

      <OrganizationBlock />

      <UserProfileBlock />

      <AnimatedLogoutButton />
    </Grid>
  );
};

export default UserPage;
