import { EnergyFlowDeviceState, EnergyFlowRole } from "types/Dashboard";

export interface PreparedData {
  energyFlowRole: EnergyFlowRole;
  value: number;
  missingValue: boolean;
  exists: boolean;
}
const prepareData = (data?: EnergyFlowDeviceState[]): PreparedData[] => {
  const preparedData: PreparedData[] = [
    {
      energyFlowRole: "Consumer",
      value: 0,
      missingValue: false,
      exists: false,
    },
    {
      energyFlowRole: "Producer",
      value: 0,
      missingValue: false,
      exists: false,
    },
    {
      energyFlowRole: "GridConnection",
      value: 0,
      missingValue: false,
      exists: false,
    },
    {
      energyFlowRole: "Battery",
      value: 0,
      missingValue: false,
      exists: false,
    },
  ];
  if (data != null) {
    data.forEach((item) => {
      const index = preparedData.findIndex(
        (x) => x.energyFlowRole === item.energyFlowRole
      );
      preparedData[index].exists = true;
      item.measurements.forEach((measurement) => {
        measurement?.value == null
          ? (preparedData[index].missingValue = true)
          : (preparedData[index].value += measurement.value);
      });
    });
  }

  return preparedData;
};

export default prepareData;
