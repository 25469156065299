import { FormControl, Paper, Typography } from "@mui/material";
import React from "react";
import LanguageSelector from "components/UI/LanguageSelector";

const LanguageCard = () => {
  return (
    <Paper sx={{ p: 1 }}>
      <Typography variant="h6" margin={1}>
        Locale
      </Typography>
      <FormControl fullWidth>
        <LanguageSelector variant="outlined" />
      </FormControl>
    </Paper>
  );
};

export default LanguageCard;
