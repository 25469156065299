import React from "react";
import MobileDrawer from "./MobileDrawer";
import { useMobileBreakpoint } from "hooks/use-breakpoint";
import NavBar from "./NavBar";
import MenuSectionList from "./MenuSectionList";
import DesktopDrawer from "./DesktopDrawer";
import DrawerLogo from "./DrawerLogo";
import { styled } from "@mui/material/styles";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingBottom: theme.spacing(4),
  justifyContent: "center",
}));

const ResponsiveDrawer = () => {
  const isMobile = useMobileBreakpoint();
  const navBar = <NavBar />;

  return isMobile ? (
    <MobileDrawer>
      <DrawerHeader>
        <DrawerLogo />
      </DrawerHeader>
      {navBar}
    </MobileDrawer>
  ) : (
    <DesktopDrawer>
      <MenuSectionList />
    </DesktopDrawer>
  );
};

export default ResponsiveDrawer;
