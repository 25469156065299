import { GridLocaleText } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

const useDatagridTranslation = () => {
  const { t } = useTranslation();

  const translations: Partial<GridLocaleText> = {
    noRowsLabel: t("noRowsAvailable")!,
    noResultsOverlayLabel: t("noResultsOverlayLabel")!,
    errorOverlayDefaultLabel: t("errorOverlayDefaultLabel")!,
    toolbarColumnsLabel: t("toolbarColumnsLabel")!,
    toolbarFiltersLabel: t("toolbarFiltersLabel")!,
    columnsPanelTextFieldLabel: t("columnsPanelTextFieldLabel")!,
    columnsPanelTextFieldPlaceholder: t("columnsPanelTextFieldPlaceholder")!,
    columnsPanelDragIconLabel: t("columnsPanelDragIconLabel")!,
    filterPanelDeleteIconLabel: t("filterPanelDeleteIconLabel")!,
    filterPanelLinkOperator: t("filterPanelLinkOperator")!,
    filterPanelInputLabel: t("filterPanelInputLabel")!,
    filterPanelInputPlaceholder: t("filterPanelInputPlaceholder")!,
    filterOperatorContains: t("filterOperatorContains")!,
    filterOperatorEquals: t("filterOperatorEquals")!,
    filterOperatorStartsWith: t("filterOperatorStartsWith")!,
    filterOperatorEndsWith: t("filterOperatorEndsWith")!,
    filterOperatorIs: t("filterOperatorIs")!,
    filterOperatorNot: t("filterOperatorNot")!,
    filterOperatorAfter: t("filterOperatorAfter")!,
    filterOperatorOnOrAfter: t("filterOperatorOnOrAfter")!,
    filterOperatorBefore: t("filterOperatorBefore")!,
    filterOperatorOnOrBefore: t("filterOperatorOnOrBefore")!,
    filterOperatorIsEmpty: t("filterOperatorIsEmpty")!,
    filterOperatorIsNotEmpty: t("filterOperatorIsNotEmpty")!,
    filterOperatorIsAnyOf: t("filterOperatorIsAnyOf")!,
    filterValueAny: t("filterValueAny")!,
    filterValueTrue: t("filterValueTrue")!,
    filterValueFalse: t("filterValueFalse")!,
    columnMenuLabel: t("columnMenuLabel")!,
    columnMenuShowColumns: t("columnMenuShowColumns"),
    columnMenuFilter: t("columnMenuFilter"),
    columnMenuHideColumn: t("columnMenuHideColumn"),
    columnMenuUnsort: t("columnMenuUnsort"),
    columnMenuSortAsc: t("columnMenuSortAsc"),
    columnMenuSortDesc: t("columnMenuSortDesc"),
    columnHeaderFiltersLabel: t("columnHeaderFiltersLabel")!,
    columnHeaderSortIconLabel: t("columnHeaderSortIconLabel")!,
    checkboxSelectionHeaderName: t("checkboxSelectionHeaderName")!,
    checkboxSelectionSelectAllRows: t("checkboxSelectionSelectAllRows")!,
    checkboxSelectionUnselectAllRows: t("checkboxSelectionUnselectAllRows")!,
    checkboxSelectionSelectRow: t("checkboxSelectionSelectRow")!,
    checkboxSelectionUnselectRow: t("checkboxSelectionUnselectRow")!,
    booleanCellTrueLabel: t("booleanCellTrueLabel")!,
    booleanCellFalseLabel: t("booleanCellFalseLabel")!,
    actionsCellMore: t("actionsCellMore")!,
    pinToLeft: t("pinToLeft")!,
    pinToRight: t("pinToRight")!,
    unpin: t("unpin")!,
    treeDataGroupingHeaderName: t("treeDataGroupingHeaderName")!,
    treeDataExpand: t("treeDataExpand")!,
    treeDataCollapse: t("treeDataCollapse")!,
    groupingColumnHeaderName: t("groupingColumnHeaderName")!,
    detailPanelToggle: t("detailPanelToggle")!,
    expandDetailPanel: t("expandDetailPanel")!,
    collapseDetailPanel: t("collapseDetailPanel")!,
    rowReorderingHeaderName: t("rowReorderingHeaderName")!,
    aggregationMenuItemHeader: t("aggregationMenuItemHeader")!,
    aggregationFunctionLabelSum: t("aggregationFunctionLabelSum")!,
    aggregationFunctionLabelAvg: t("aggregationFunctionLabelAvg")!,
    aggregationFunctionLabelMin: t("aggregationFunctionLabelMin")!,
    aggregationFunctionLabelMax: t("aggregationFunctionLabelMax")!,
    aggregationFunctionLabelSize: t("aggregationFunctionLabelSize")!,
    toolbarDensity: t("toolbarDensity"),
    toolbarColumns: t("toolbarColumns"),
    toolbarFiltersTooltipHide: t("toolbarFiltersTooltipHide"),
    toolbarFiltersTooltipShow: t("toolbarFiltersTooltipShow"),
    columnsPanelShowAllButton: t("columnsPanelShowAllButton"),
    columnsPanelHideAllButton: t("columnsPanelHideAllButton"),
    filterPanelOperators: t("filterPanelOperators"),
    filterPanelOperatorAnd: t("filterPanelOperatorAnd"),
    filterPanelOperatorOr: t("filterPanelOperatorOr"),
    filterPanelColumns: t("filterPanelColumns"),
  };

  return translations;
};

export default useDatagridTranslation;
