import { Avatar, Grid, Link, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useRoles } from "hooks/use-roles";
import { useTranslation } from "react-i18next";

const ProfileHeader: React.FC = () => {
  const { user } = useAuth0();
  const myRoles = useRoles();
  const { t } = useTranslation();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={2}
        display={"flex"}
        justifyContent={"center"}
        mb={3}
      >
        <Avatar
          src={user?.picture}
          alt={user?.name}
          sx={{
            width: "7rem",
            height: "7rem",
          }}
        ></Avatar>
      </Grid>

      <Grid
        item
        xs={12}
        sm={10}
        display={"flex"}
        justifyContent={"left"}
        flexDirection={"column"}
        sx={{
          marginTop: {
            xs: "3rem",
            sm: 0,
          },
        }}
      >
        <Typography gutterBottom component="h1" variant="h4" mb={"0.3rem"}>
          {user?.name}
        </Typography>
        <Typography component="p" variant="h5">
          <Link href="#" underline="hover" sx={{ color: "#0645AD" }}>
            {user?.email}
          </Link>
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          sx={{
            color: "#8d8d8d",
          }}
        >
          {t("roles")}: {myRoles.map((r) => t(r)).join(", ")}
        </Typography>
      </Grid>
    </>
  );
};

export default ProfileHeader;
