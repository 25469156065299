import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Skeleton } from "@mui/material";
import React, { useMemo } from "react";

interface DataTableSkeletopnProps {
  columns: number;
  rows: number;
}

const DataTableSkeleton: React.FC<DataTableSkeletopnProps> = ({
  columns,
  rows,
}) => {
  const columnsData = useMemo(() => {
    let columnsData: GridColDef[] = [];
    for (let i = 0; i < columns; i++) {
      columnsData.push({
        field: `${i}`,
        renderHeader: () => <Skeleton variant="text" width={100} />,
        renderCell: () => <Skeleton variant="text" width={100} />,
        flex: 1,
      });
    }
    return columnsData;
  }, [columns]);

  const rowsData = useMemo(() => {
    let rowsData = [];
    for (let i = 0; i < rows; i++) {
      rowsData.push({
        id: `${i}`,
      });
    }
    return rowsData;
  }, [rows]);

  return (
    <DataGrid
      rows={rowsData}
      columns={columnsData}
      pageSize={rows}
      rowsPerPageOptions={[rows]}
      autoHeight
      disableSelectionOnClick
    />
  );
};

export default DataTableSkeleton;
