import { Card, CardHeader, CardContent, Box } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useTheme } from "@mui/material";
import HistoricalDataGrid from "features/historicalData/HistoricalDataGrid/HistoricalDataGrid";

const Archive = () => {
  const theme = useTheme();
  const layoutContainerHeight = theme.layoutContainerHeight;
  const mainContainerHeight = `calc(100vh - ${layoutContainerHeight.header.height}px - ${layoutContainerHeight.header.spacing}px - ${layoutContainerHeight.footer.height}px - ${layoutContainerHeight.footer.spacing}px)`;

  return (
    <Box sx={{ height: mainContainerHeight }}>
      <Card
        variant="outlined"
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <CardHeader
          avatar={<FilterAltOutlinedIcon color="secondary" />}
          title="Filter-Menu"
          titleTypographyProps={{ fontSize: "1rem" }}
        />
        <CardContent
          sx={{
            paddingTop: 1,
            paddingBottom: 0,
            flex: 1,
          }}
        >
          <HistoricalDataGrid />
        </CardContent>
      </Card>
    </Box>
  );
};

export default Archive;
