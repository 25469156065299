import { Divider, Box } from "@mui/material";
import React from "react";
import MenuLinkItemButton from "../MenuLinkItemButton";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";

const MenuSectionFooter = () => {
  const { t } = useTranslation();
  return (
    <>
      <Divider
        sx={{ borderBottomWidth: (theme) => `${theme.drawerHeight.divider}px` }}
      />
      <Box
        sx={{
          height: (theme) => `${theme.drawerHeight.footer}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MenuLinkItemButton
          item={{
            id: "settings-d60b89e9",
            text: t("settings"),
            path: "/settings",
            icon: SettingsIcon,
          }}
        />
      </Box>
    </>
  );
};

export default MenuSectionFooter;
