export const createRange = (end: number) => {
  // code taken from https://stackoverflow.com/questions/3746725
  return Array.from(Array(end).keys());
};

export const distinctBy = <T, TKey>(
  arr: T[],
  keySelector: (item: T) => TKey
) => {
  const map = new Map<TKey, T>();

  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];
    const key = keySelector(element);

    if (map.has(key)) {
      continue;
    }

    map.set(key, element);
  }

  return Array.from(map.values());
};

export const contains = <T>(arr: T[], predicate: (item: T) => boolean) => {
  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];

    if (predicate(element)) {
      return true;
    }
  }

  return false;
};
