import { SvgIcon, useTheme } from "@mui/material";

const PhotovoltaicSvg = () => {
  const theme = useTheme();
  return (
    <SvgIcon
      viewBox="80 70 285 285"
      sx={{
        overflow: "visible",
        height: "300%",
        width: "300%",
      }}
    >
      <g
        transform="translate(0,-11.249983)"
        id="layer1"
        opacity={theme.palette.devicesPage.graphicsOpacity}
      >
        <g id="g13771">
          <path
            d="m 206.16022,245.28577 -41.04894,23.69895 -85.521516,-49.44488 41.052196,-23.70223 85.51826,49.44816"
            fill="#768898"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="#adb8c1"
            strokeWidth="0.33016083"
            strokeOpacity="1"
            id="path3852"
          />
          <path
            d="m 206.16022,262.5532 -41.04894,23.69895 v -17.26743 l 41.04894,-23.69895 v 17.26743"
            fill="#768898"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="#adb8c1"
            strokeWidth="0.33016083"
            strokeOpacity="1"
            id="path3854"
          />
          <path
            d="m 79.589764,219.53984 85.521516,49.44488 v 17.26743 L 79.589764,236.87334 v -17.3335"
            fill="#768898"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="#adb8c1"
            strokeWidth="0.33016083"
            strokeOpacity="1"
            id="path3856"
          />
          <path
            d="m 167.79551,264.0918 29.85317,-17.23112 V 66.556538 l -55.01146,6.081593 25.15829,191.453669"
            fill="#adb8c1"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="#768898"
            strokeWidth="0.33016083"
            strokeOpacity="1"
            id="path3858"
          />
          <path
            d="m 118.18553,20.485908 79.46315,46.07063 -29.85317,17.224474 -26.28079,-5.688614 -53.175706,-40.36882 29.846516,-17.23767"
            fill="#adb8c1"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="#768898"
            strokeWidth="0.33016083"
            strokeOpacity="1"
            id="path3860"
          />
          <path
            d="M 88.339014,37.723578 167.79551,83.781012 V 264.0918 L 88.339014,218.031 V 37.723578"
            fill="#adb8c1"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="#768898"
            strokeWidth="0.33016083"
            strokeOpacity="1"
            id="path3862"
          />
          <path
            d="M 93.796554,49.573055 162.3346,89.311273 V 234.64799 L 93.796554,194.91314 V 49.573055"
            fill="#4c4c54"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.33016083"
            id="path3864"
          />
          <path
            d="M 93.489484,49.751342 162.02762,89.48956 V 234.82627 L 93.489484,195.08815 V 49.751342"
            fill="#768898"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.33016083"
            id="path3866"
          />
          <path
            d="m 155.83045,95.719679 c 0.35938,0.207767 0.65044,0.03744 0.65044,-0.369677 0,-0.415534 -0.29106,-0.914363 -0.65044,-1.122598 l -15.58358,-9.003439 c -0.35658,-0.20496 -0.65044,-0.03744 -0.65044,0.379971 0,0.412727 0.29386,0.918107 0.65044,1.11923 l 15.58358,8.996887"
            fill="#adb8c1"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.33016083"
            id="path3868"
          />
          <path
            d="m 140.24687,90.087125 c -0.35658,-0.20496 -0.65044,-0.03744 -0.65044,0.373419 0,0.408984 0.29386,0.918107 0.65044,1.11923 l 15.58358,8.996886 c 0.35938,0.20122 0.65044,0.0374 0.65044,-0.36968 0,-0.412722 -0.29106,-0.911551 -0.65044,-1.1225 l -15.58358,-8.996887"
            fill="#adb8c1"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.33016083"
            id="path3870"
          />
          <path
            d="m 140.24687,94.943733 c -0.35658,-0.207767 -0.65044,-0.03744 -0.65044,0.37342 0,0.412727 0.29386,0.914363 0.65044,1.125874 l 15.58358,8.993613 c 0.35938,0.19841 0.65044,0.0374 0.65044,-0.37623 0,-0.41554 -0.29106,-0.90781 -0.65044,-1.11595 l -15.58358,-9.000165"
            fill="#adb8c1"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.33016083"
            id="path3872"
          />
          <path
            d="m 140.24687,99.797159 c -0.35658,-0.204959 -0.65044,-0.03744 -0.65044,0.376231 0,0.40898 0.29386,0.91436 0.65044,1.1225 l 15.58358,8.99361 c 0.35938,0.21151 0.65044,0.0468 0.65044,-0.37342 0,-0.41273 -0.29106,-0.91155 -0.65044,-1.11595 l -15.58358,-9.003439"
            fill="#adb8c1"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.33016083"
            id="path3874"
          />
          <path
            d="m 140.06858,128.54757 c -0.0936,0.14506 -0.46233,0.16472 -1.17866,-0.25737 l -24.92384,-14.3818 c -0.7328,-0.41928 -1.09611,-0.86476 -1.19523,-1.12588 -0.0955,-0.25456 -0.0749,-0.7328 0.33037,-1.20177 l 12.09384,-14.659109 c 0.36687,-0.436125 0.80861,-0.554982 1.22152,-0.306972 0.41554,0.237716 0.86196,0.864761 1.22499,1.716795 l 12.10039,28.631556 c 0.39588,0.93121 0.42583,1.43294 0.32662,1.58474"
            fill="#adb8c1"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.33016083"
            id="path3878"
          />
          <path
            d="m 128.02152,108.40589 -2.87528,0.35128 2.41135,-8.43957 -5.75358,10.41744 2.87527,-0.34781 -2.40521,8.43957 5.74745,-10.42048"
            fill="#768898"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.30522853"
            id="path3880"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PhotovoltaicSvg;
