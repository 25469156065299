export type DecimalPlaces = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const roundDecimalPlaces = (
  num: number,
  decimalPlaces: DecimalPlaces
) => {
  if (decimalPlaces === 0) {
    return Math.round(num);
  }
  const factor = Math.pow(10, decimalPlaces);
  return Math.round((num + Number.EPSILON) * factor) / factor;
};

export const inRange = (value: number, range: [number, number]) => {
  return value >= range[0] && value <= range[1];
};
