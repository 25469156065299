import { Grid, Typography } from "@mui/material";
import React from "react";

const ImprintCopyright = () => {
  return (
    <Grid
      item
      xs={12}
      sm={7}
      md={5}
      sx={{
        display: "flex",
        justifyContent: { xs: "center", sm: "left" },
        alignItems: "center",
        pl: { sm: "1rem" },
        mt: 0,
        mb: { xs: 1, sm: 0 },
      }}
    >
      <Typography
        noWrap
        sx={{
          fontSize: { xs: "0.7rem", sm: "0.9rem" },
          color: "text.secondary",
        }}
      >
        © 2022 Copyright
      </Typography>
      <Typography noWrap sx={{ fontSize: { xs: "0.7rem", sm: "0.9rem" } }}>
        &nbsp;Schulz Systemtechnik GmbH
      </Typography>
    </Grid>
  );
};

export default ImprintCopyright;
