import useTheme from "@mui/material/styles/useTheme";
import { EnergyFlowRole } from "types/Dashboard";
import { PreparedData } from "./prepareData";

const calculateDotPositions = (
  numberOfDots: number,
  spaceBetweenDots: number
) => {
  const arrayOfDots: number[] = [];

  for (let i = 0; i < numberOfDots; i++) {
    arrayOfDots.push(spaceBetweenDots * i);
  }
  return arrayOfDots;
};

interface DotAnimationProps {
  dotPosition: number[];
  isOnline: boolean;
  name: string;
  colorOfAnimation: string;
  colorOfDots: string;
  rOfDots: number;
}

const DotAnimation: React.FC<DotAnimationProps> = ({
  dotPosition,
  isOnline,
  name,
  colorOfAnimation,
  colorOfDots,
  rOfDots,
}) => {
  return (
    <>
      {dotPosition.map((dotXPosition) => {
        return (
          <circle
            key={`${name}${dotXPosition}`}
            cx={dotXPosition}
            cy={0}
            r={rOfDots}
            fill={colorOfDots}
          >
            {isOnline && (
              <>
                <animate
                  id={`${name}${dotXPosition}`}
                  attributeName="r"
                  begin={calcBegin(dotXPosition, name, dotPosition)}
                  values={rOfDots + ";6;" + rOfDots}
                  keyTimes="0;0.5;1"
                  dur={"0.7s"}
                  repeatCount="1"
                />
                <animate
                  attributeName="fill"
                  begin={`${name}${dotXPosition}.begin`}
                  dur={"0.7s"}
                  values={`${colorOfDots}; ${colorOfAnimation}; ${colorOfDots}`}
                  repeatCount="1"
                />
              </>
            )}
          </circle>
        );
      })}
    </>
  );
};

const calcBegin = (
  dotXPosition: number,
  name: string,
  dotPositions: number[]
): string => {
  const index = dotPositions.findIndex((obj) => obj === dotXPosition);
  const begin = `${dotXPosition === 0 ? "0s; " : ""}${name}${
    index - 1 < 0
      ? `${dotPositions[dotPositions.length - index - 2]}.begin+2s`
      : `${dotPositions[index - 1]}.begin+0.15s`
  }`;

  return begin;
};

interface EnergyFlowAnimationProps {
  midXPos: number;
  midYPos: number;
  data: PreparedData[];
}

const EnergyFlowAnimation: React.FC<EnergyFlowAnimationProps> = ({
  midXPos,
  midYPos,
  data,
}) => {
  const spaceBetweenDots = 14;
  const countOfDots = 6;
  const rOfMid = 6;
  const rOfDots = 3.5;
  const theme = useTheme();

  const animatedDots = data.map((device) => {
    const matchRole = (role: EnergyFlowRole) => {
      return device.energyFlowRole === role;
    };
    const lenghtOfLine = (countOfDots - 1) * spaceBetweenDots;

    /* const getTextPosition = () => {
      let rotation = 0;
      let position = { x: 0, y: 0 };
      switch (device.energyFlowRole) {
        case "Producer": {
          rotation = device.value > 0 ? -90 : 90;
          position = { x: 30, y: lenghtOfLine / 2 };
          break;
        }
        case "Consumer": {
          rotation = device.value > 0 ? 0 : 180;
          position = { x: lenghtOfLine / 2, y: 0 };
          break;
        }
        case "GridConnection": {
          rotation = device.value > 0 ? 0 : 180;
          position = { x: -lenghtOfLine / 2, y: 0 };
          break;
        }
        case "Battery": {
          rotation = device.value > 0 ? -90 : 90;
          position = { x: 30, y: -lenghtOfLine / 2 };
          break;
        }
      }
      return { rotation, position };
    };
    const textPosition = getTextPosition(); */

    const animatedDotColor =
      (matchRole("Producer") && device.value > 0) ||
      (matchRole("Battery") && device.value < 0) ||
      (matchRole("GridConnection") && device.value > 0)
        ? theme.palette.energyFlowIllustration.positiv
        : theme.palette.energyFlowIllustration.negativ;

    if (device.exists) {
      return (
        <g
          transform={positioningInSVG(
            +midXPos,
            +midYPos,
            device.energyFlowRole,
            device.value,
            lenghtOfLine
          )}
          key={device.energyFlowRole}
        >
          {/* <g id="backdrop"> */}
          {/* Alles hier drin kann geblured werden */}
          <DotAnimation
            dotPosition={calculateDotPositions(countOfDots, spaceBetweenDots)}
            isOnline={device.value === 0 ? false : true}
            name={device.energyFlowRole}
            colorOfAnimation={animatedDotColor}
            colorOfDots={theme.palette.energyFlowIllustration.dots}
            rOfDots={rOfDots}
          />
          {/* </g> */}

          {/* <clipPath id="clip">
            <rect
              id="rect"
              x={textPosition.position.x}
              y={textPosition.position.y}
              width="50"
              height="30"
              transform={`rotate(${textPosition.rotation}) translate(-25,-15)`}
            />
          </clipPath>

          <filter id="blur" width="200%" height="200%" x="-50%" y="-50%">
            <feFlood
              flood-color={theme.palette.background.paper}
              result="neutral"
            />
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="4" // Blur-Stärke
              result="blurred"
            />
            <feMerge>
              <feMergeNode in="neutral" />
              <feMergeNode in="blurred" />
            </feMerge>
          </filter>

          <g style={{ clipPath: "url(#clip)" }}>
            <use xlinkHref="#backdrop" style={{ filter: "url(#blur)" }} />
          </g>
          <use xlinkHref="#rect" style={{ fill: "none", stroke: "black" }} />

          <text
            x={textPosition.position.x}
            y={textPosition.position.y}
            textAnchor="middle"
            dominantBaseline="central"
            fill={theme.palette.energyFlowIllustration.dots}
            fontSize="12px"
            transform={`rotate(${textPosition.rotation})`}
          >
            {device.value}
          </text> */}
        </g>
      );
    } else {
      return <g> </g>;
    }
  });

  return (
    <g>
      <circle
        key={"mid"}
        cx={midXPos}
        cy={midYPos}
        r={rOfMid.toString()}
        fill={theme.palette.energyFlowIllustration.dots}
      />
      {animatedDots}
    </g>
  );
};

const positioningInSVG = (
  midXPos: number,
  midYPos: number,
  energyFlowRole: EnergyFlowRole,
  value: number,
  lengthOfAnimation: number
): string => {
  if (energyFlowRole === "Consumer") {
    return transformString(
      midXPos + 15 + (value > 0 ? 0 : lengthOfAnimation),
      midYPos,
      value > 0 ? 0 : 180
    );
  }
  if (energyFlowRole === "Producer") {
    return transformString(
      midXPos,
      midYPos - 15 - (value > 0 ? lengthOfAnimation : 0),
      value > 0 ? 90 : 270
    );
  }
  if (energyFlowRole === "GridConnection") {
    return transformString(
      midXPos - 15 - (value > 0 ? lengthOfAnimation : 0),
      midYPos,
      value > 0 ? 0 : 180
    );
  }
  if (energyFlowRole === "Battery") {
    return transformString(
      midXPos,
      midYPos + 15 + (value > 0 ? 0 : lengthOfAnimation),
      value > 0 ? 90 : 270
    );
  }

  return "";
};

const transformString = (
  xPos: number,
  yPos: number,
  rotate: number
): string => {
  return "translate(" + xPos + "," + yPos + ") rotate(" + rotate + ")";
};

export default EnergyFlowAnimation;
