import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Skeleton,
  Tooltip,
  Icon,
} from "@mui/material";
import { ValueWithUnit } from "types/Common";
import { roundDecimalPlaces, DecimalPlaces } from "utils/helpers/math-helpers";
import { useTranslation } from "react-i18next";
import WarningIcon from "@mui/icons-material/Warning";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface MeasurementCardHeaderActionProps {
  content: string;
  variant: "warning" | "info";
}

const MeasurementCardHeaderAction: React.FC<
  MeasurementCardHeaderActionProps
> = (props) => {
  const { content, variant } = props;

  let color: "warning" | "disabled";
  let icon: JSX.Element;

  if (variant === "warning") {
    color = "warning";
    icon = <WarningIcon />;
  } else {
    color = "disabled";
    icon = <InfoOutlinedIcon />;
  }

  return (
    <Tooltip title={<Typography variant="body2">{content}</Typography>}>
      <Icon color={color} sx={{ ml: 2, display: "flex", alignItems: "center" }}>
        {icon}
      </Icon>
    </Tooltip>
  );
};

interface MeasurementCardProps {
  title: string;
  valueWithUnit?: ValueWithUnit;
  decimalPlaces: DecimalPlaces;
  isLoading: boolean;
  error?: string;
  info: string;
}

const MeasurementCard = (props: MeasurementCardProps) => {
  const { title, valueWithUnit, decimalPlaces, isLoading, error, info } = props;

  const { t } = useTranslation();

  let displayValue;
  if (valueWithUnit) {
    displayValue = `${roundDecimalPlaces(
      100 * valueWithUnit.value,
      decimalPlaces
    )} %`;
  } else {
    displayValue = t("na");
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title={title}
        sx={{ textAlign: "center" }}
        titleTypographyProps={{ variant: "h6" }}
        action={
          <MeasurementCardHeaderAction
            variant={error ? "warning" : "info"}
            content={error ?? info}
          />
        }
      />
      <CardContent>
        {!isLoading && (
          <Typography
            variant="h5"
            component="h1"
            textAlign="center"
            fontSize={32}
            fontWeight="600"
          >
            {displayValue}
          </Typography>
        )}

        {props.isLoading && <Skeleton variant="rectangular" height={32} />}
      </CardContent>
    </Card>
  );
};

export default MeasurementCard;
