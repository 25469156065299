import { SvgIcon } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material";
import { hexToDec, rgbaToRgb } from "utils/helpers/converters";
import { useMobileBreakpoint } from "hooks/use-breakpoint";
import React from "react";

const Animated404Screen = () => {
  const isMobile = useMobileBreakpoint();
  const theme = useTheme();
  const muiMain = theme.palette.primary.main;
  const muiBackground = theme.palette.background.default;
  const red = hexToDec(muiMain.substring(1, 3));
  const green = hexToDec(muiMain.substring(3, 5));
  const blue = hexToDec(muiMain.substring(5));

  const main = `rgb(${red},${green},${blue})`;
  const dark1 = `rgb(${red - 60},${green - 60},${blue - 60})`;
  const light1 = rgbaToRgb(red, green, blue, 0.6, 250, 220, 220);
  const light2 = rgbaToRgb(red, green, blue, 0.5, 250, 220, 220);
  const light3 = rgbaToRgb(red, green, blue, 0.3, 250, 220, 220);
  const shirt = rgbaToRgb(red, green, blue, 0.7, 250, 250, 50);

  return (
    <Box
      sx={{
        minHeight: `calc(100vh - 12rem)`,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <SvgIcon
        viewBox="0 0 1920 1080"
        sx={{
          height: "calc(100vh - 13rem)",
          width: "90%",
          display: "flex",
        }}
      >
        <g id="RedBackground">
          <path
            opacity={0.4}
            fill={light3}
            enable-background="new    "
            d="M1662.94,533.45c0.03,21.81-2.55,43.54-7.68,64.74
		c-4.84,20.06-12.09,39.45-21.59,57.77c0.12,91.3-73.79,165.42-165.1,165.54c-0.14,0-0.28,0-0.43,0H623.93
		c-79.57-0.05-152.34-44.83-188.24-115.84l0,0c-14.87-29.43-22.6-61.95-22.54-94.93c-20.65-36.99-32.67-81.24-32.67-128.82
		c0-129.4,88.83-234.3,198.42-234.3c3.88,0,7.74,0.15,11.56,0.4C659.22,111.5,814.31,16.29,994.51,16.29
		c110.78,0,212.04,35.96,289.62,95.38c28.52-18.73,61.35-29.43,96.3-29.43c109.58,0,198.42,104.91,198.42,234.3
		c0,8.28-0.37,16.45-1.12,24.51C1629.24,383.37,1662.94,453.76,1662.94,533.45z"
          />
          <path
            fill={muiBackground}
            d="M1627.84,699.68c-19.72,71.94-85.1,121.82-159.69,121.82H623.93c-79.57-0.05-152.34-44.83-188.24-115.84
		l0,0c0,0,122.36-169.25,440.14-58.68c263.83,91.8,385.51,4.99,419.27-27.14c7.38-7,17.31-10.67,27.47-10.18
		c64.65,3.06,288.6,18.73,305.31,89.99L1627.84,699.68z"
          />
        </g>

        <g id="PV2">
          <g id="Stand">
            <path
              fill={dark1}
              d="M1741.2,781.66h-28.04c-0.85,0-1.53-0.69-1.53-1.53V616.33c0-0.85,0.69-1.53,1.53-1.53h28.04
			c0.85,0,1.53,0.69,1.53,1.53v163.79C1742.73,780.97,1742.05,781.66,1741.2,781.66z"
            />
            <path
              fill={dark1}
              d="M1762.53,786.04c0,0.39-0.6,0.7-1.05,0.7l-69.13,0.5c-1.54,0.01-3.35-1.22-3.35-2.74v-3.42
			c0-0.36,0.18-0.72,0.42-1.06c0.75-1.03,2.46-1.8,3.73-1.78l68.26,1.16c0.44,0.01,1.12,0.31,1.12,0.68V786.04z"
            />
          </g>
          <g id="Surface">
            <g>
              <path
                fill={light3}
                d="M1919.74,559.25c0.69-1.39-0.01-2.51-1.56-2.49l-261.63,3.24c-1.45,0.02-3.18,1.13-3.86,2.47
				l-71.34,140.69c-0.69,1.37-0.08,2.49,1.37,2.49l261.25,1.57c1.55,0.01,3.37-1.13,4.06-2.55L1919.74,559.25z"
              />
              <g>
                <path
                  fill={main}
                  d="M1731.2,568.89l-71.19,0.8c-1.45,0.02-3.19,1.12-3.87,2.48l-16.39,32.35c-0.69,1.36-0.07,2.45,1.39,2.44
					l71.16-0.46c1.48-0.01,3.24-1.13,3.93-2.5l16.42-32.66C1733.32,569.96,1732.68,568.87,1731.2,568.89z"
                />
                <path
                  fill={main}
                  d="M1816.79,567.93l-72.71,0.81c-1.48,0.02-3.24,1.14-3.93,2.5l-16.42,32.69
					c-0.69,1.37-0.04,2.47,1.44,2.46l72.68-0.47c1.51-0.01,3.3-1.14,3.99-2.52l16.44-33.01
					C1818.97,569.02,1818.3,567.92,1816.79,567.93z"
                />
                <path
                  fill={main}
                  d="M1904.24,566.95l-74.28,0.83c-1.52,0.02-3.3,1.15-3.99,2.53l-16.45,33.04c-0.69,1.38-0.02,2.5,1.5,2.49
					l74.26-0.48c1.54-0.01,3.36-1.15,4.05-2.55l16.47-33.36C1906.48,568.05,1905.78,566.94,1904.24,566.95z"
                />
                <path
                  fill={main}
                  d="M1708.8,613.43l-71.16,0.4c-1.45,0.01-3.19,1.11-3.88,2.47l-16.47,32.5c-0.69,1.36-0.07,2.47,1.38,2.46
					l71.13-0.06c1.48,0,3.24-1.12,3.93-2.49l16.49-32.81C1710.92,614.52,1710.28,613.42,1708.8,613.43z"
                />
                <path
                  fill={main}
                  d="M1794.36,612.94l-72.68,0.41c-1.48,0.01-3.25,1.13-3.93,2.5l-16.5,32.84c-0.69,1.38-0.05,2.49,1.43,2.49
					l72.65-0.07c1.51,0,3.3-1.13,3.99-2.52l16.52-33.17C1796.54,614.05,1795.87,612.94,1794.36,612.94z"
                />
                <path
                  fill={main}
                  d="M1881.77,612.45l-74.25,0.42c-1.52,0.01-3.3,1.14-3.99,2.53l-16.52,33.2c-0.69,1.39-0.03,2.52,1.49,2.52
					l74.23-0.07c1.54,0,3.36-1.14,4.05-2.55l16.55-33.52C1884.01,613.57,1883.32,612.44,1881.77,612.45z"
                />
                <path
                  fill={main}
                  d="M1686.3,658.17l-71.12,0c-1.45,0-3.19,1.11-3.88,2.47l-16.54,32.65c-0.69,1.37-0.08,2.48,1.37,2.49
					l71.09,0.34c1.48,0.01,3.24-1.11,3.94-2.49l16.57-32.97C1688.42,659.29,1687.78,658.17,1686.3,658.17z"
                />
                <path
                  fill={main}
                  d="M1771.83,658.17l-72.65,0c-1.48,0-3.25,1.12-3.94,2.5l-16.57,33c-0.69,1.38-0.06,2.51,1.43,2.52
					l72.62,0.34c1.51,0.01,3.3-1.12,4-2.52l16.6-33.32C1774,659.3,1773.34,658.17,1771.83,658.17z"
                />
                <path
                  fill={main}
                  d="M1859.2,658.17l-74.22,0c-1.52,0-3.31,1.13-4,2.52l-16.76,33.68c-0.51,1.02,0.23,2.21,1.37,2.22
					l74.48,0.35c1.54,0.01,3.36-1.13,4.06-2.54l16.63-33.68C1861.43,659.31,1860.75,658.17,1859.2,658.17z"
                />
              </g>
            </g>
            <path
              fill={light2}
              d="M1842.27,702.28c0,0-0.01,0-0.01,0l-50.59-0.27c-0.39,0-0.71-0.32-0.7-0.71c0-0.39,0.32-0.7,0.71-0.7
			c0,0,0,0,0,0l50.59,0.27c0,0,0.01,0,0.01,0c1.16,0,2.47-1.37,3.11-2.66l26.44-53.58c0.17-0.35,0.6-0.49,0.95-0.32
			c0.35,0.17,0.49,0.6,0.32,0.95l-26.44,53.58C1845.82,700.49,1844.1,702.28,1842.27,702.28z"
            />
            <path
              fill={light2}
              d="M1633.33,610.64c-0.11,0-0.22-0.02-0.32-0.08c-0.35-0.18-0.49-0.6-0.31-0.95l22.59-44.58
			c0.81-1.61,3.04-2.36,4.77-2.38l55.63-0.67c0.39-0.01,0.71,0.31,0.72,0.7c0,0.39-0.31,0.71-0.7,0.72l-55.63,0.67
			c-1.32,0.02-3.01,0.58-3.52,1.61l-22.59,44.58C1633.83,610.5,1633.59,610.64,1633.33,610.64z"
            />
          </g>
        </g>
        <g id="PV1_00000129207271873566370650000012035090691686560131_">
          <g id="Stand_00000092421168056951372800000011051548242183030701_">
            <path
              fill={dark1}
              d="M1519.26,934.43h-36.75c-1.11,0-2.01-0.9-2.01-2.01V717.76c0-1.11,0.9-2.01,2.01-2.01h36.75
			c1.11,0,2.01,0.9,2.01,2.01v214.66C1521.27,933.53,1520.37,934.43,1519.26,934.43z"
            />
            <path
              fill={dark1}
              d="M1547.22,940.17c0,0.5-0.78,0.92-1.38,0.92l-90.6,0.65c-2.02,0.01-4.39-1.6-4.39-3.6v-4.48
			c0-0.48,0.23-0.95,0.55-1.38c0.98-1.34,3.22-2.36,4.89-2.34l89.45,1.52c0.57,0.01,1.47,0.4,1.47,0.89V940.17z"
            />
          </g>
          <g id="Surface_00000009589191805808069680000016690629517029173928_">
            <g>
              <path
                fill={light3}
                d="M1753.24,642.95c0.9-1.83-0.02-3.29-2.04-3.26l-342.88,4.25c-1.9,0.02-4.17,1.47-5.06,3.24l-93.49,184.38
				c-0.91,1.79-0.11,3.26,1.79,3.27l342.38,2.05c2.03,0.01,4.41-1.48,5.33-3.34L1753.24,642.95z"
              />
              <g>
                <path
                  fill={main}
                  d="M1506.15,655.58l-93.3,1.04c-1.91,0.02-4.18,1.47-5.07,3.24l-21.48,42.4c-0.9,1.78-0.09,3.21,1.82,3.2
					l93.26-0.61c1.94-0.01,4.24-1.48,5.15-3.27l21.51-42.8C1508.94,656.99,1508.09,655.56,1506.15,655.58z"
                />
                <path
                  fill={main}
                  d="M1618.33,654.33l-95.29,1.06c-1.95,0.02-4.25,1.49-5.15,3.28l-21.52,42.84c-0.9,1.8-0.06,3.24,1.89,3.23
					l95.26-0.62c1.98-0.01,4.32-1.49,5.22-3.31l21.55-43.26C1621.19,655.76,1620.31,654.31,1618.33,654.33z"
                />
                <path
                  fill={main}
                  d="M1732.93,653.05l-97.35,1.09c-1.99,0.02-4.33,1.51-5.23,3.31l-21.55,43.3c-0.9,1.81-0.03,3.28,1.96,3.26
					l97.32-0.63c2.02-0.01,4.4-1.51,5.3-3.34l21.58-43.72C1735.87,654.49,1734.96,653.03,1732.93,653.05z"
                />
                <path
                  fill={main}
                  d="M1476.81,713.95l-93.25,0.53c-1.9,0.01-4.18,1.46-5.08,3.24l-21.58,42.59c-0.9,1.79-0.1,3.23,1.81,3.23
					l93.21-0.08c1.94,0,4.24-1.46,5.15-3.27l21.62-43C1479.59,715.39,1478.74,713.94,1476.81,713.95z"
                />
                <path
                  fill={main}
                  d="M1588.94,713.32l-95.25,0.54c-1.94,0.01-4.25,1.48-5.16,3.27l-21.62,43.04
					c-0.91,1.8-0.07,3.27,1.88,3.26l95.21-0.09c1.98,0,4.32-1.48,5.23-3.3l21.65-43.46
					C1591.79,714.77,1590.92,713.31,1588.94,713.32z"
                />
                <path
                  fill={main}
                  d="M1703.49,712.68l-97.31,0.55c-1.99,0.01-4.33,1.49-5.23,3.31l-21.66,43.51c-0.91,1.82-0.03,3.3,1.95,3.3
					l97.28-0.09c2.02,0,4.4-1.5,5.31-3.34l21.69-43.93C1706.42,714.14,1705.52,712.67,1703.49,712.68z"
                />
                <path
                  fill={main}
                  d="M1447.32,772.6l-93.21,0c-1.9,0-4.18,1.45-5.08,3.24l-21.68,42.79c-0.91,1.79-0.1,3.25,1.8,3.26
					l93.17,0.44c1.94,0.01,4.25-1.45,5.16-3.26l21.72-43.21C1450.1,774.06,1449.26,772.6,1447.32,772.6z"
                />
                <path
                  fill={main}
                  d="M1559.4,772.59l-95.21,0c-1.94,0-4.25,1.46-5.16,3.27l-21.72,43.25c-0.91,1.81-0.07,3.29,1.87,3.3
					l95.17,0.45c1.98,0.01,4.32-1.47,5.24-3.3l21.76-43.67C1562.25,774.07,1561.38,772.59,1559.4,772.59z"
                />
                <path
                  fill={main}
                  d="M1673.91,772.59l-97.27,0c-1.99,0-4.33,1.48-5.24,3.31l-21.97,44.14c-0.66,1.33,0.3,2.9,1.79,2.91
					l97.6,0.46c2.02,0.01,4.4-1.48,5.32-3.33l21.79-44.15C1676.84,774.08,1675.93,772.59,1673.91,772.59z"
                />
              </g>
            </g>
            <path
              fill={light2}
              d="M1651.72,830.4c-0.01,0-0.01,0-0.02,0l-66.3-0.35c-0.51,0-0.92-0.42-0.92-0.93c0-0.51,0.42-0.92,0.93-0.92
			c0,0,0,0,0,0l66.3,0.35c0,0,0.01,0,0.01,0c1.53,0,3.24-1.8,4.07-3.49l34.65-70.22c0.23-0.46,0.78-0.65,1.24-0.42
			c0.46,0.23,0.65,0.78,0.42,1.24l-34.65,70.22C1656.38,828.05,1654.12,830.4,1651.72,830.4z"
            />
            <path
              fill={light2}
              d="M1377.89,710.3c-0.14,0-0.28-0.03-0.42-0.1c-0.46-0.23-0.64-0.79-0.41-1.25l29.61-58.42
			c1.07-2.1,3.99-3.09,6.25-3.12l72.91-0.87c0.51-0.01,0.93,0.4,0.94,0.92c0.01,0.51-0.4,0.93-0.92,0.94l-72.91,0.87
			c-1.73,0.02-3.94,0.76-4.62,2.1l-29.61,58.42C1378.56,710.12,1378.23,710.3,1377.89,710.3z"
            />
          </g>
        </g>

        <g id="WholeTurbineLeft">
          <polygon
            fill="#FFFFFF"
            points="690.81,609.02 662.63,609.02 671.58,277.19 681.85,277.19 		"
          />
          <g id="TurbineLeft">
            {isMobile ? (
              <animateTransform
                attributeName="transform"
                begin="0s"
                type="rotate"
                from="25 677.88 273.09"
                to="385 677.88 273.09"
                dur="40s"
                repeatCount="indefinite"
              />
            ) : (
              <animateTransform
                attributeName="transform"
                begin="mouseover"
                type="rotate"
                from="0 677.88 273.09"
                to="360 677.88 273.09"
                dur="3s"
                restart="whenNotActive"
                calcMode="spline"
                keyTimes="0;1"
                keySplines="0 0 0.58 1"
                repeatCount="1"
              />
            )}

            <path
              fill="#FFFFFF"
              d="M691.57,220.8c-0.89,4.91-3.91,15.28-13.69,15.02c-7.49-0.35-13.3-6.67-13.02-14.16l2.81-101.63
			l0.91-33.14c0,0,4.84-9.17,9.66,31.94v0.15l13.39,97.53C691.84,217.93,691.81,219.38,691.57,220.8z"
            />
            <rect
              x="670.7"
              y="230.65"
              fill="#FFFFFF"
              width="14.36"
              height="30.73"
            />
            <rect
              x="672.46"
              y="254.08"
              fill="#FFFFFF"
              width="11.05"
              height="13.49"
            />
            <path
              fill={main}
              d="M678.28,118.98c-5.02-1.46-8.45-0.35-10.59,1.05l0.91-33.14c0,0,4.84-9.17,9.66,31.94L678.28,118.98z"
            />
            <path
              fill={main}
              d="M685.06,239.83v21.52H670.7v-21.09C675.25,238.29,680.39,238.13,685.06,239.83z"
            />
            <path
              fill="#FFFFFF"
              d="M718.88,308.32c-4.02-2.95-12.02-10.21-7.5-18.89c3.59-6.59,11.82-9.04,18.43-5.49l90.11,47.08
			l29.39,15.35c0,0,6.11,8.37-32.95-5.31h-0.15l-93.47-30.71C721.36,309.88,720.05,309.19,718.88,308.32z"
            />

            <rect
              x="695.04"
              y="269.6"
              transform="matrix(0.4384 -0.8988 0.8988 0.4384 138.2611 791.1917)"
              fill="#FFFFFF"
              width="14.36"
              height="30.73"
            />

            <rect
              x="683.33"
              y="271.83"
              transform="matrix(0.4384 -0.8988 0.8988 0.4384 136.5052 775.5952)"
              fill="#FFFFFF"
              width="11.05"
              height="13.49"
            />
            <path
              fill={main}
              d="M816.22,341.01c3.52-3.88,4.02-7.44,3.7-9.99l29.39,15.35c0,0,6.11,8.37-32.95-5.31L816.22,341.01z"
            />
            <path
              fill={main}
              d="M704.62,294.12l-19.36-9.45l6.3-12.9l18.97,9.25C710.31,285.98,708.19,290.67,704.62,294.12z"
            />
            <path
              fill="#FFFFFF"
              d="M636.88,308.32c4.04-2.95,12.03-10.21,7.51-18.89c-3.59-6.59-11.83-9.04-18.44-5.49l-90.11,47.08
			l-29.38,15.35c0,0-6.12,8.37,32.94-5.31h0.15l93.48-30.71C634.4,309.88,635.71,309.19,636.88,308.32z"
            />

            <rect
              x="638.18"
              y="277.78"
              transform="matrix(0.8988 -0.4384 0.4384 0.8988 -58.7763 315.336)"
              fill="#FFFFFF"
              width="30.73"
              height="14.36"
            />

            <rect
              x="660.16"
              y="273.05"
              transform="matrix(0.8988 -0.4384 0.4384 0.8988 -54.6231 320.5459)"
              fill="#FFFFFF"
              width="13.49"
              height="11.05"
            />
            <path
              fill={main}
              d="M539.54,341.01c-3.51-3.88-4.01-7.44-3.7-9.99l-29.38,15.35c0,0-6.12,8.37,32.94-5.31L539.54,341.01z"
            />
            <path
              fill={main}
              d="M651.14,294.12l19.36-9.45l-6.29-12.9l-18.98,9.25C645.46,285.98,647.57,290.67,651.14,294.12z"
            />
          </g>
          <circle fill={main} cx="677.88" cy="273.09" r="9.39" />
        </g>

        <g id="WholeTurbineRight">
          <polygon
            fill="#FFFFFF"
            points="1333.34,609.02 1305.03,614 1314.11,277.19 1324.39,277.19 		"
          />
          <g id="TurbineRight">
            {isMobile ? (
              <animateTransform
                id="test"
                attributeName="transform"
                begin="0s"
                type="rotate"
                from="40 1320.42 273.09"
                to="-320 1320.42 273.09"
                dur="70s"
                repeatCount="indefinite"
              />
            ) : (
              <animateTransform
                attributeName="transform"
                begin="mouseover"
                type="rotate"
                from="0 1320.42 273.09"
                to="-360 1320.42 273.09"
                dur="4"
                restart="whenNotActive"
                calcMode="spline"
                keyTimes="0;1"
                keySplines="0 0 0.58 1"
                repeatCount="1"
              />
            )}

            <path
              fill="#FFFFFF"
              d="M1334.11,220.8c-0.89,4.91-3.91,15.28-13.69,15.02c-7.49-0.35-13.31-6.66-13.03-14.16l2.82-101.63
			l0.91-33.14c0,0,4.84-9.17,9.66,31.94v0.15l13.37,97.53C1334.37,217.93,1334.35,219.38,1334.11,220.8z"
            />
            <rect
              x="1313.23"
              y="230.65"
              fill="#FFFFFF"
              width="14.36"
              height="30.73"
            />
            <rect
              x="1314.99"
              y="254.08"
              fill="#FFFFFF"
              width="11.05"
              height="13.49"
            />
            <path
              fill={main}
              d="M1320.81,118.98c-5.03-1.46-8.45-0.35-10.59,1.05l0.91-33.14c0,0,4.84-9.17,9.66,31.94L1320.81,118.98z"
            />
            <path
              fill={main}
              d="M1327.59,239.83v21.52h-14.36v-21.09C1317.79,238.29,1322.93,238.13,1327.59,239.83z"
            />
            <path
              fill="#FFFFFF"
              d="M1361.38,308.32c-4.02-2.95-12.03-10.21-7.51-18.89c3.59-6.59,11.83-9.04,18.44-5.49l90.11,47.08
			l29.38,15.35c0,0,6.12,8.37-32.94-5.31h-0.15l-93.48-30.71C1363.85,309.88,1362.55,309.19,1361.38,308.32z"
            />

            <rect
              x="1337.57"
              y="269.6"
              transform="matrix(0.4384 -0.8988 0.8988 0.4384 499.1279 1368.693)"
              fill="#FFFFFF"
              width="14.36"
              height="30.73"
            />

            <rect
              x="1325.87"
              y="271.83"
              transform="matrix(0.4384 -0.8988 0.8988 0.4384 497.3705 1353.1064)"
              fill="#FFFFFF"
              width="11.05"
              height="13.49"
            />
            <path
              fill={main}
              d="M1458.75,341.01c3.52-3.88,4.02-7.44,3.7-9.99l29.38,15.35c0,0,6.12,8.37-32.94-5.31L1458.75,341.01z"
            />
            <path
              fill={main}
              d="M1347.16,294.12l-19.41-9.45l6.29-12.9l18.98,9.25C1352.81,285.98,1350.71,290.66,1347.16,294.12z"
            />
            <path
              fill="#FFFFFF"
              d="M1279.41,308.32c4.02-2.95,12.03-10.21,7.51-18.89c-3.59-6.59-11.83-9.04-18.44-5.49l-90.11,47.08
			l-29.38,15.35c0,0-6.12,8.37,32.94-5.31h0.15l93.48-30.71C1276.94,309.88,1278.24,309.19,1279.41,308.32z"
            />

            <rect
              x="1280.72"
              y="277.79"
              transform="matrix(0.8988 -0.4384 0.4384 0.8988 6.2508 597.0064)"
              fill="#FFFFFF"
              width="30.73"
              height="14.36"
            />

            <rect
              x="1302.69"
              y="273.04"
              transform="matrix(0.8988 -0.4384 0.4384 0.8988 10.4074 602.2101)"
              fill="#FFFFFF"
              width="13.49"
              height="11.05"
            />
            <path
              fill={main}
              d="M1182.07,341.01c-3.52-3.88-4.02-7.44-3.7-9.99l-29.38,15.35c0,0-6.12,8.37,32.94-5.31L1182.07,341.01z"
            />
            <path
              fill={main}
              d="M1293.67,294.12l19.36-9.45l-6.29-12.9l-18.98,9.25C1287.99,285.98,1290.1,290.67,1293.67,294.12z"
            />
          </g>
          <circle fill={main} cx="1320.42" cy="273.09" r="9.39" />
        </g>

        <g id="PaperPlane">
          <animateTransform
            id="delay1"
            attributeName="transform"
            begin="0s; movePlane.end"
            type="translate"
            values="2000, 2000; 2100, 2100"
            dur="10s"
            repeatCount="1"
          />
          <animateTransform
            id="delay2"
            attributeName="transform"
            begin="0s; scalePlane.end"
            type="scale"
            values="0.1;0.1"
            dur="10s"
            repeatCount="1"
            additive="sum"
          />
          <animateTransform
            id="movePlane"
            attributeName="transform"
            begin="delay1.end"
            type="translate"
            values="2000,1021;1950,927; 1900,843; 1850,767; 1800,700; 1750,641; 1700,589; 1650,544; 1600,506; 1550,475; 1500,450; 1450,430; 1400,415; 1350,405; 1300,399; 1250,397; 1200,398; 1150,403; 1100,410; 1050,419; 1000,430; 950,442; 900,455; 850,469; 800,482; 750,496; 700,508; 650,520; 600,530; 550,537; 500,543; 450,545; 400,545; 350,540; 300,532; 250,519; 200,501; 150,478; 100,448; 50,413; 0,371; -50,322; -100,266; -150,202; -200,160; "
            dur="9s"
            repeatCount="1"
          />
          <animateTransform
            id="scalePlane"
            attributeName="transform"
            begin="delay2.end"
            type="scale"
            values="0.1;0.07;0.05;0.04;0.02;"
            dur="9s"
            repeatCount="1"
            additive="sum"
          />
          <animateTransform
            id="rotatePlane1"
            attributeName="transform"
            begin="delay2.end + 2s"
            type="rotate"
            from="0"
            to="-20"
            dur="3s"
            repeatCount="1"
            additive="sum"
          />
          <animateTransform
            attributeName="transform"
            begin="rotatePlane1.end"
            type="rotate"
            from="-20"
            to="0"
            dur="4s"
            repeatCount="1"
            additive="sum"
          />
          <polygon
            fill={light2}
            points="1631.27,1202.18 1515.65,1559.75 1323.19,1327.72 	"
          />
          <path
            fill={main}
            d="M89.71,477l691.75,1159.26c13.24,22.19,34.95,38.04,60.12,43.9c25.17,5.86,51.65,1.22,73.33-12.85
		l1137.76-738.38c13.03-8.45,19.62-23.97,16.68-39.21c-2.95-15.25-14.85-27.18-30.09-30.17L89.71,477z"
          />
          <polygon
            fill={light2}
            points="1631.14,1202.18 89.71,477 1447.9,1321.92 1420.05,1422.44 	"
          />
          <polygon
            fill={light2}
            points="1338.71,1392.08 1515.65,1559.75 1447.9,1321.92 	"
          />
        </g>

        <g id="WholeTurbineFront">
          <polygon
            fill="#FFFFFF"
            points="1024.59,820.49 982.94,820.49 996.17,330 1011.36,330 		"
          />
          <g id="TurbineFront">
            {isMobile ? (
              <animateTransform
                attributeName="transform"
                begin="0s"
                type="rotate"
                from="0 1005.48 323.96"
                to="360 1005.48 323.96"
                dur="80s"
                repeatCount="indefinite"
              />
            ) : (
              <animateTransform
                attributeName="transform"
                begin="mouseover"
                type="rotate"
                from="0 1005.48 323.96"
                to="360 1005.48 323.96"
                dur="2s"
                restart="whenNotActive"
                calcMode="spline"
                keyTimes="0;1"
                keySplines="0 0 0.58 1"
                repeatCount="1"
              />
            )}

            <path
              fill="#FFFFFF"
              d="M1025.73,246.65c-1.32,7.26-5.8,22.6-20.25,22.22c-11.03-0.28-19.53-9.91-19.24-20.94l4.16-150.22
			l1.36-48.99c0,0,7.15-13.55,14.27,47.21v0.22l19.78,144.08C1026.13,242.36,1026.1,244.53,1025.73,246.65z"
            />
            <rect
              x="994.87"
              y="261.23"
              fill="#FFFFFF"
              width="21.23"
              height="45.41"
            />
            <rect
              x="997.48"
              y="295.86"
              fill="#FFFFFF"
              width="16.33"
              height="19.93"
            />
            <path
              fill={main}
              d="M1006.07,96.16c-7.43-2.16-12.49-0.52-15.69,1.56l1.36-48.99c0,0,7.15-13.55,14.27,47.21L1006.07,96.16z"
            />
            <path
              fill={main}
              d="M1016.1,274.81v31.84h-21.23v-31.21C1001.61,272.52,1009.2,272.29,1016.1,274.81z"
            />
            <path
              fill="#FFFFFF"
              d="M1066.09,376.03c-5.95-4.37-17.77-15.11-11.1-27.94c5.09-9.79,17.47-13.22,27.25-8.12l133.19,69.59
			l43.44,22.69c0,0,9.04,12.33-48.7-7.85l-0.21-0.07l-138.17-45.4C1069.75,378.28,1067.83,377.29,1066.09,376.03z"
            />

            <rect
              x="1030.84"
              y="318.79"
              transform="matrix(0.4384 -0.8988 0.8988 0.4384 277.9783 1127.8503)"
              fill="#FFFFFF"
              width="21.23"
              height="45.41"
            />

            <rect
              x="1013.54"
              y="322.08"
              transform="matrix(0.4384 -0.8988 0.8988 0.4384 275.3799 1104.7948)"
              fill="#FFFFFF"
              width="16.33"
              height="19.93"
            />
            <path
              fill={main}
              d="M1209.96,424.33c5.2-5.74,5.94-11.01,5.47-14.76l43.44,22.69c0,0,9.04,12.33-48.7-7.85L1209.96,424.33z"
            />
            <path
              fill={main}
              d="M1045.01,355.03l-28.62-13.96l9.3-19.06l28.03,13.68C1053.4,343.01,1050.28,349.93,1045.01,355.03z"
            />
            <path
              fill="#FFFFFF"
              d="M944.88,376.03c5.95-4.37,17.77-15.11,11.1-27.94c-5.09-9.79-17.47-13.22-27.25-8.12l-133.19,69.59
			l-43.44,22.69c0,0-9.04,12.33,48.7-7.85l0.21-0.07l138.17-45.39C941.22,378.28,943.14,377.3,944.88,376.03z"
            />

            <rect
              x="946.81"
              y="330.88"
              transform="matrix(0.8988 -0.4384 0.4384 0.8988 -51.5801 459.5671)"
              fill="#FFFFFF"
              width="45.41"
              height="21.23"
            />

            <rect
              x="979.29"
              y="323.87"
              transform="matrix(0.8988 -0.4384 0.4384 0.8988 -45.439 467.2656)"
              fill="#FFFFFF"
              width="19.93"
              height="16.33"
            />
            <path
              fill={main}
              d="M801.01,424.33c-5.2-5.74-5.94-11.01-5.47-14.76l-43.44,22.69c0,0-9.04,12.33,48.7-7.85L801.01,424.33z"
            />
            <path
              fill={main}
              d="M965.96,355.03l28.62-13.96l-9.3-19.06l-28.03,13.68C957.57,343.01,960.69,349.93,965.96,355.03z"
            />
          </g>

          <circle fill={main} cx="1005.48" cy="323.96" r="13.88" />
        </g>

        <g id="Plants">
          <g>
            <g>
              <path
                fill={light3}
                d="M588.33,462.47c5.01-4.18,10.81-5.73,15.68-4.2s8.29,5.97,9.23,12.01
				c34.51,221.81-120.36,364.48-179.86,410.3c-5.31,4.09-11.34,5.25-16.18,3.11c-4.84-2.14-7.89-7.31-8.2-13.88
				C398.36,643.79,535.23,506.83,588.33,462.47z"
              />
              <g>
                <path
                  fill={dark1}
                  d="M600.01,486.26c-2.63,8.32-5.36,16.58-8.15,24.83l-4.23,12.36l-4.34,12.32
					c-5.78,16.43-11.82,32.77-18.02,49.05c-12.42,32.55-25.77,64.74-39.78,96.65l-10.73,23.83c-0.88,1.99-1.8,3.97-2.75,5.93
					l-2.81,5.9l-5.62,11.8l-5.63,11.8l-5.91,11.66l-5.91,11.67c-2,3.87-3.87,7.81-6,11.62l-12.47,23l-3.12,5.75l-3.3,5.65l-6.6,11.3
					l-6.6,11.3l-1.65,2.82l-1.75,2.77l-3.51,5.52l-14.06,22.09c-0.78,1.22-2.41,1.59-3.63,0.81c-1.22-0.78-1.59-2.41-0.81-3.63
					l0.01-0.02l0.06-0.09l14.2-21.82l3.55-5.46l1.77-2.73l1.67-2.79l6.68-11.18l6.68-11.18l3.34-5.59l3.16-5.7l12.65-22.79
					c2.16-3.77,4.06-7.68,6.09-11.52l6.01-11.57l6.01-11.57l5.73-11.71l5.72-11.72l2.86-5.86c0.97-1.95,1.9-3.91,2.8-5.89
					l10.95-23.68c14.34-31.69,27.74-63.81,40.38-96.23c12.58-32.43,24.49-65.17,35.11-98.28l0-0.01c0.17-0.53,0.74-0.82,1.27-0.65
					C599.88,485.17,600.18,485.73,600.01,486.26z"
                />
                <path
                  fill={dark1}
                  d="M565.02,585.88c-0.25,0-0.51-0.09-0.72-0.26c-14.99-12.72-21.79-56.21-22.07-58.05
					c-0.09-0.61,0.32-1.17,0.93-1.26c0.61-0.09,1.17,0.32,1.26,0.93c0.07,0.44,6.96,44.52,21.32,56.7c0.47,0.39,0.53,1.09,0.13,1.56
					C565.64,585.75,565.33,585.88,565.02,585.88z"
                />
                <path
                  fill={dark1}
                  d="M565.02,585.88c-0.47,0-0.91-0.3-1.06-0.78c-0.18-0.58,0.14-1.2,0.72-1.39
					c0.27-0.09,27.54-8.79,44.16-23.93c0.45-0.41,1.15-0.38,1.57,0.07c0.41,0.45,0.38,1.15-0.07,1.57
					c-16.98,15.47-44.71,24.32-44.99,24.41C565.24,585.86,565.12,585.88,565.02,585.88z"
                />
                <path
                  fill={dark1}
                  d="M528.14,672.86c-0.31,0-0.63-0.13-0.85-0.39c-20.41-24.18-23.9-64.63-23.94-65.04
					c-0.05-0.61,0.4-1.14,1.01-1.19c0.62-0.05,1.14,0.4,1.19,1.01c0.03,0.4,3.47,40.16,23.42,63.79c0.39,0.47,0.33,1.17-0.13,1.56
					C528.64,672.78,528.39,672.86,528.14,672.86z"
                />
                <path
                  fill={dark1}
                  d="M529.45,673.57c-0.5,0-0.78-0.01-0.84-0.01c-0.61-0.03-1.08-0.55-1.06-1.16
					c0.03-0.61,0.55-1.08,1.15-1.06c0.3,0.02,29.4,0.98,59.97-24.65c0.47-0.39,1.17-0.33,1.56,0.14c0.39,0.47,0.33,1.17-0.14,1.56
					c-16.33,13.69-32.14,19.8-42.54,22.52C538.31,673.32,531.71,673.57,529.45,673.57z"
                />
                <path
                  fill={dark1}
                  d="M471.49,788.46c-0.53,0-1-0.38-1.09-0.92c-0.11-0.6,0.3-1.18,0.9-1.28c0.36-0.06,36-6.5,64.98-27.28
					c0.5-0.36,1.19-0.24,1.55,0.25c0.36,0.5,0.24,1.19-0.25,1.55c-29.4,21.08-65.53,27.6-65.89,27.66
					C471.62,788.45,471.55,788.46,471.49,788.46z"
                />
                <path
                  fill={dark1}
                  d="M471.49,788.46c-0.35,0-0.7-0.17-0.91-0.48c-0.21-0.3-20.83-30.79-22.55-71.38
					c-0.03-0.61,0.45-1.13,1.06-1.15c0.61-0.02,1.13,0.45,1.15,1.06c1.7,39.96,21.96,69.92,22.16,70.22
					c0.35,0.51,0.22,1.19-0.29,1.54C471.93,788.39,471.71,788.46,471.49,788.46z"
                />
              </g>
            </g>
            <g>
              <path
                fill={light3}
                d="M639.62,832.61c3.54,0.75,6.2,2.75,7.21,5.4c1,2.65,0.24,5.66-2.07,8.14
				c-84.85,91.07-200.11,72.47-240.47,62.24c-3.61-0.91-6.18-3.14-6.9-5.98s0.48-5.95,3.24-8.33
				C495.49,811.96,602.09,824.68,639.62,832.61z"
              />
              <g>
                <path
                  fill={dark1}
                  d="M633.57,846.51c-9.06,3.41-18.16,6.63-27.32,9.72c-9.14,3.13-18.33,6.12-27.54,9.03
					c-18.44,5.79-37.05,11.04-55.75,15.95c-18.71,4.89-37.61,9.06-56.62,12.73l-14.31,2.5l-3.58,0.63l-3.6,0.51l-7.19,1.03
					c-9.57,1.55-19.25,2.34-28.9,3.41c-1.45,0.16-2.75-0.88-2.91-2.33s0.88-2.75,2.33-2.91c0.03,0,0.06-0.01,0.09-0.01l0.11-0.01
					c19.14-1.39,38.26-3.66,57.23-6.89c18.96-3.33,37.85-7.16,56.57-11.73c18.73-4.54,37.32-9.68,55.79-15.24
					c18.45-5.58,36.81-11.57,54.87-18.28l0.01-0.01c0.52-0.19,1.1,0.07,1.29,0.6C634.36,845.74,634.1,846.31,633.57,846.51z"
                />
                <path
                  fill={dark1}
                  d="M578.49,865.71c-0.01,0-0.03,0-0.04,0c-0.61-0.02-1.09-0.54-1.07-1.15
					c0.41-11.07,17.16-29.08,17.87-29.84c0.42-0.45,1.12-0.47,1.57-0.05c0.44,0.42,0.47,1.12,0.05,1.57
					c-0.17,0.18-16.89,18.15-17.27,28.4C579.57,865.24,579.08,865.71,578.49,865.71z"
                />
                <path
                  fill={dark1}
                  d="M604.3,876.85c-0.06,0-0.12,0-0.18-0.02c-12.67-2.12-25.7-10.95-26.25-11.32
					c-0.51-0.34-0.64-1.03-0.29-1.54s1.04-0.64,1.54-0.29c0.13,0.09,13.19,8.93,25.37,10.97c0.6,0.1,1.01,0.67,0.91,1.27
					C605.3,876.47,604.83,876.85,604.3,876.85z"
                />
                <path
                  fill={dark1}
                  d="M527.94,879.45c-0.07,0-0.15-0.01-0.22-0.02c-0.6-0.12-0.99-0.71-0.86-1.31
					c3.57-17.34,19.61-32.36,20.29-32.99c0.45-0.42,1.15-0.39,1.57,0.06c0.41,0.45,0.39,1.15-0.06,1.57
					c-0.16,0.15-16.2,15.18-19.63,31.81C528.91,879.09,528.45,879.45,527.94,879.45z"
                />
                <path
                  fill={dark1}
                  d="M559.49,897.85c-0.07,0-0.15-0.01-0.23-0.02c-22.34-4.68-31.95-17.82-32.35-18.37
					c-0.35-0.5-0.24-1.19,0.26-1.55c0.5-0.36,1.19-0.24,1.55,0.25c0.09,0.13,9.56,13,31,17.49c0.6,0.13,0.98,0.71,0.86,1.31
					C560.46,897.49,560,897.85,559.49,897.85z"
                />
                <path
                  fill={dark1}
                  d="M492.54,913.49c-0.1,0-0.21-0.01-0.31-0.04c-19.37-5.62-34.68-19.21-34.83-19.34
					c-0.46-0.41-0.5-1.11-0.09-1.57c0.41-0.46,1.11-0.5,1.57-0.09c0.15,0.13,15.1,13.39,33.97,18.87c0.59,0.17,0.93,0.79,0.76,1.37
					C493.46,913.17,493.02,913.49,492.54,913.49z"
                />
                <path
                  fill={dark1}
                  d="M458.14,894.39c-0.11,0-0.22-0.02-0.33-0.05c-0.59-0.18-0.91-0.8-0.73-1.39
					c0.25-0.8,6.32-19.77,23.53-34.52c0.47-0.4,1.16-0.34,1.56,0.12c0.4,0.47,0.34,1.16-0.12,1.56
					c-16.73,14.35-22.8,33.31-22.86,33.5C459.05,894.08,458.61,894.39,458.14,894.39z"
                />
              </g>
            </g>
            <g>
              <path
                fill={dark1}
                d="M674.82,597.1c5.07-1.85,10.02-1.42,13.38,1.14c3.37,2.56,4.77,6.97,3.81,11.94
				C656.55,792.46,495.97,859.96,436.81,878.9c-5.28,1.69-10.3,0.9-13.46-2.12c-3.16-3.02-4.09-7.9-2.48-13.1
				C476.08,684.81,621.05,616.7,674.82,597.1z"
              />
              <g>
                <path
                  fill={light3}
                  d="M677.51,619.12c-4.38,5.74-8.83,11.41-13.32,17.04l-6.76,8.43l-6.84,8.37
					c-9.11,11.17-18.39,22.19-27.78,33.12c-18.8,21.85-38.22,43.16-58.07,64.07L549.7,765.7c-5.04,5.15-10.22,10.17-15.33,15.26
					l-7.69,7.6l-7.87,7.42l-7.87,7.42c-2.64,2.46-5.2,5-7.93,7.36l-16.16,14.41l-4.04,3.6l-4.16,3.47l-8.3,6.95l-8.3,6.96
					l-2.07,1.74l-2.13,1.67l-4.28,3.32l-17.13,13.29c-1.15,0.89-2.8,0.68-3.69-0.47c-0.89-1.15-0.68-2.8,0.47-3.69
					c0.01-0.01,0.03-0.02,0.04-0.03l0.07-0.06c23.12-16.99,45.47-35.07,66.92-54.22c2.74-2.33,5.32-4.83,7.98-7.25l7.93-7.31
					l7.93-7.31l7.76-7.49c5.15-5.02,10.37-9.96,15.46-15.05l15.18-15.34c20.07-20.61,39.53-41.83,58.48-63.49
					c18.91-21.68,37.39-43.8,54.95-66.57l0.01-0.01c0.34-0.44,0.97-0.52,1.42-0.18C677.76,618.05,677.84,618.68,677.51,619.12z"
                />
                <path
                  fill={light3}
                  d="M622.47,686.94c-0.38,0-0.76-0.2-0.96-0.56c-8.13-14.17-1.19-49.99-0.89-51.5c0.12-0.6,0.7-1,1.3-0.87
					c0.6,0.12,0.99,0.7,0.87,1.3c-0.07,0.36-7.08,36.52,0.64,49.97c0.3,0.53,0.12,1.21-0.41,1.51
					C622.85,686.89,622.66,686.94,622.47,686.94z"
                />
                <path
                  fill={light3}
                  d="M625.68,686.99c-1.88,0-3.05-0.04-3.26-0.05c-0.61-0.03-1.09-0.54-1.06-1.15
					c0.02-0.61,0.53-1.09,1.15-1.06c0.24,0.01,23.86,0.89,41-6.2c0.57-0.23,1.21,0.03,1.45,0.6s-0.03,1.21-0.6,1.45
					C650.42,686.34,632.86,686.99,625.68,686.99z"
                />
                <path
                  fill={light3}
                  d="M569.34,744.26c-0.45,0-0.87-0.28-1.04-0.72c-9.11-24.61-0.72-56.12-0.36-57.44
					c0.16-0.59,0.77-0.94,1.36-0.78c0.59,0.16,0.94,0.77,0.78,1.36c-0.09,0.32-8.56,32.16,0.3,56.1c0.21,0.57-0.08,1.21-0.66,1.43
					C569.59,744.24,569.46,744.26,569.34,744.26z"
                />
                <path
                  fill={light3}
                  d="M591.97,748.51c-13.75,0-22.27-3.42-22.84-3.65c-0.57-0.23-0.83-0.88-0.6-1.45
					c0.23-0.57,0.88-0.84,1.45-0.6c0.22,0.09,22.54,8.99,53.45-2.34c0.58-0.21,1.21,0.08,1.42,0.66c0.21,0.58-0.08,1.21-0.66,1.42
					C611.78,747.11,600.8,748.51,591.97,748.51z"
                />
                <path
                  fill={light3}
                  d="M515.33,819.89c-13.23,0-22.57-1.59-22.73-1.62c-0.6-0.1-1.01-0.68-0.9-1.28
					c0.11-0.6,0.68-1.01,1.28-0.9c0.29,0.05,29.79,5.05,58.14-2.97c0.59-0.16,1.2,0.18,1.37,0.77c0.17,0.59-0.18,1.2-0.77,1.37
					C538.92,818.88,525.89,819.89,515.33,819.89z"
                />
                <path
                  fill={light3}
                  d="M492.79,818.29c-0.49,0-0.94-0.33-1.07-0.83c-0.08-0.29-7.57-29.86,2.51-61.98
					c0.18-0.59,0.81-0.91,1.39-0.73c0.58,0.18,0.91,0.81,0.73,1.39c-9.89,31.52-2.56,60.47-2.48,60.76c0.15,0.59-0.2,1.2-0.79,1.35
					C492.98,818.28,492.88,818.29,492.79,818.29z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                fill={light3}
                d="M103.98,515.43c-5.01-4.18-10.81-5.73-15.68-4.2c-4.88,1.53-8.29,5.97-9.23,12.01
				c-34.51,221.81,120.36,364.48,179.86,410.3c5.31,4.09,11.34,5.25,16.18,3.11c4.84-2.14,7.89-7.31,8.2-13.88
				C293.95,696.74,157.08,559.78,103.98,515.43z"
              />
              <g>
                <path
                  fill={dark1}
                  d="M94.23,538.62c10.62,33.11,22.53,65.84,35.11,98.28c12.64,32.42,26.03,64.54,40.38,96.23l10.95,23.68
					c0.9,1.98,1.83,3.94,2.8,5.89l2.86,5.86l5.72,11.72l5.73,11.71l6.01,11.57l6.01,11.57c2.03,3.84,3.93,7.75,6.09,11.52
					l12.65,22.79l3.16,5.7l3.34,5.59l6.68,11.18l6.68,11.18l1.67,2.79l1.77,2.73l3.55,5.46l14.2,21.82l0.06,0.09
					c0.79,1.22,0.45,2.85-0.77,3.64c-1.22,0.79-2.85,0.45-3.64-0.77l-0.01-0.02l-14.06-22.09l-3.51-5.52l-1.75-2.77l-1.65-2.82
					l-6.6-11.3l-6.6-11.3l-3.3-5.65l-3.12-5.75l-12.47-23c-2.13-3.81-4-7.75-6-11.62l-5.91-11.67l-5.91-11.66l-5.63-11.8l-5.62-11.8
					l-2.81-5.9c-0.95-1.96-1.87-3.93-2.75-5.93l-10.73-23.83c-14-31.91-27.35-64.1-39.78-96.65c-6.2-16.28-12.24-32.62-18.02-49.05
					l-4.34-12.32l-4.23-12.36c-2.8-8.25-5.53-16.51-8.15-24.83c-0.17-0.53,0.13-1.1,0.66-1.26c0.53-0.17,1.1,0.13,1.26,0.66
					L94.23,538.62z"
                />
                <path
                  fill={dark1}
                  d="M127.29,638.83c-0.31,0-0.63-0.13-0.85-0.39c-0.39-0.47-0.34-1.17,0.13-1.56
					c14.37-12.18,21.25-56.25,21.32-56.7c0.09-0.61,0.66-1.02,1.26-0.93c0.61,0.09,1.02,0.66,0.93,1.26
					c-0.28,1.84-7.08,45.34-22.07,58.05C127.8,638.74,127.55,638.83,127.29,638.83z"
                />
                <path
                  fill={dark1}
                  d="M127.29,638.83c-0.11,0-0.22-0.02-0.33-0.05c-0.28-0.09-28.01-8.94-44.99-24.41
					c-0.45-0.41-0.49-1.11-0.07-1.57c0.41-0.45,1.11-0.49,1.57-0.07c16.63,15.15,43.89,23.84,44.16,23.93
					c0.58,0.18,0.91,0.81,0.72,1.39C128.2,638.53,127.76,638.83,127.29,638.83z"
                />
                <path
                  fill={dark1}
                  d="M164.18,725.81c-0.25,0-0.51-0.09-0.71-0.26c-0.47-0.39-0.53-1.09-0.13-1.56
					c19.96-23.64,23.39-63.39,23.42-63.79c0.05-0.61,0.58-1.07,1.19-1.01c0.61,0.05,1.06,0.59,1.01,1.19
					c-0.03,0.4-3.52,40.86-23.94,65.04C164.8,725.68,164.49,725.81,164.18,725.81z"
                />
                <path
                  fill={dark1}
                  d="M163.09,726.51c-4.4,0-32.08-1.02-60.89-25.18c-0.47-0.39-0.53-1.09-0.14-1.56
					c0.39-0.47,1.09-0.53,1.56-0.14c30.62,25.67,59.68,24.67,59.97,24.65c0.61-0.03,1.13,0.44,1.16,1.06
					c0.03,0.61-0.44,1.13-1.06,1.16C163.67,726.51,163.47,726.51,163.09,726.51z"
                />
                <path
                  fill={dark1}
                  d="M220.82,841.41c-0.06,0-0.13-0.01-0.19-0.02c-0.36-0.06-36.48-6.58-65.89-27.66
					c-0.5-0.36-0.61-1.05-0.25-1.55c0.35-0.5,1.05-0.61,1.55-0.25c29,20.79,64.62,27.22,64.98,27.28c0.6,0.11,1.01,0.68,0.9,1.28
					C221.82,841.04,221.35,841.41,220.82,841.41z"
                />
                <path
                  fill={dark1}
                  d="M220.82,841.41c-0.22,0-0.43-0.06-0.63-0.19c-0.51-0.35-0.63-1.04-0.29-1.54
					c0.2-0.3,20.47-30.28,22.16-70.22c0.03-0.61,0.54-1.08,1.15-1.06c0.61,0.03,1.09,0.54,1.06,1.15
					c-1.72,40.59-22.34,71.08-22.55,71.38C221.52,841.24,221.17,841.41,220.82,841.41z"
                />
              </g>
            </g>
            <g>
              <path
                fill={dark1}
                d="M17.49,650.05c-5.07-1.85-10.02-1.42-13.38,1.14s-4.77,6.97-3.81,11.94
				c35.46,182.28,196.04,249.78,255.21,268.72c5.28,1.69,10.3,0.9,13.46-2.12s4.09-7.9,2.48-13.1
				C216.23,737.76,71.26,669.65,17.49,650.05z"
              />
              <g>
                <path
                  fill={light3}
                  d="M16.41,670.86c17.56,22.77,36.04,44.89,54.95,66.57c18.95,21.66,38.41,42.88,58.48,63.49l15.18,15.34
					c5.09,5.08,10.31,10.03,15.46,15.05l7.76,7.49l7.93,7.31l7.93,7.31c2.66,2.42,5.24,4.92,7.98,7.25
					c21.44,19.15,43.79,37.23,66.92,54.23l0.06,0.05c1.17,0.86,1.42,2.51,0.56,3.68s-2.51,1.42-3.68,0.56
					c-0.02-0.01-0.04-0.03-0.05-0.04l-17.13-13.29l-4.28-3.32l-2.13-1.67l-2.07-1.74l-8.3-6.96l-8.3-6.95l-4.16-3.47l-4.04-3.6
					l-16.16-14.41c-2.73-2.37-5.29-4.91-7.93-7.36l-7.87-7.42l-7.87-7.42l-7.69-7.6c-5.11-5.09-10.29-10.11-15.33-15.26
					l-15.04-15.54c-19.85-20.91-39.27-42.22-58.07-64.07c-9.39-10.93-18.68-21.95-27.78-33.12l-6.84-8.37l-6.76-8.43
					c-4.49-5.63-8.94-11.3-13.32-17.04c-0.34-0.44-0.25-1.07,0.19-1.41c0.44-0.34,1.07-0.25,1.41,0.18L16.41,670.86z"
                />
                <path
                  fill={light3}
                  d="M69.84,739.89c-0.19,0-0.38-0.05-0.55-0.15c-0.53-0.3-0.71-0.98-0.41-1.51
					c7.72-13.46,0.71-49.61,0.64-49.97c-0.12-0.6,0.27-1.18,0.87-1.3c0.6-0.12,1.18,0.27,1.3,0.87c0.3,1.52,7.24,37.34-0.89,51.5
					C70.6,739.7,70.23,739.89,69.84,739.89z"
                />
                <path
                  fill={light3}
                  d="M66.63,739.95c-7.18,0-24.74-0.65-38.69-6.42c-0.57-0.23-0.83-0.88-0.6-1.45
					c0.23-0.57,0.88-0.84,1.45-0.6c17.14,7.09,40.77,6.21,41,6.2c0.61-0.02,1.13,0.45,1.15,1.06c0.03,0.61-0.45,1.13-1.06,1.15
					C69.68,739.9,68.51,739.95,66.63,739.95z"
                />
                <path
                  fill={light3}
                  d="M122.97,797.21c-0.13,0-0.26-0.02-0.38-0.07c-0.58-0.21-0.87-0.85-0.66-1.43
					c8.87-23.95,0.38-55.78,0.3-56.09c-0.16-0.59,0.19-1.2,0.78-1.36c0.59-0.16,1.2,0.19,1.36,0.78c0.36,1.33,8.75,32.84-0.36,57.44
					C123.85,796.94,123.43,797.21,122.97,797.21z"
                />
                <path
                  fill={light3}
                  d="M100.34,801.47c-8.82,0-19.8-1.41-32.22-5.96c-0.58-0.21-0.87-0.85-0.66-1.42s0.85-0.87,1.42-0.66
					c30.96,11.35,53.23,2.43,53.45,2.34c0.57-0.23,1.21,0.04,1.45,0.6c0.23,0.57-0.04,1.21-0.6,1.45
					C122.61,798.05,114.09,801.47,100.34,801.47z"
                />
                <path
                  fill={light3}
                  d="M176.98,872.84c-10.56,0-23.59-1.01-36.39-4.63c-0.59-0.17-0.93-0.78-0.77-1.37
					c0.17-0.59,0.78-0.93,1.37-0.77c28.34,8.02,57.85,3.02,58.14,2.97c0.61-0.1,1.18,0.3,1.28,0.9c0.11,0.6-0.3,1.18-0.9,1.28
					C199.55,871.26,190.2,872.84,176.98,872.84z"
                />
                <path
                  fill={light3}
                  d="M199.52,871.25c-0.09,0-0.19-0.01-0.28-0.04c-0.59-0.15-0.95-0.76-0.79-1.35
					c0.07-0.29,7.4-29.27-2.48-60.76c-0.18-0.58,0.14-1.2,0.73-1.39c0.58-0.18,1.2,0.14,1.39,0.73
					c10.08,32.12,2.59,61.68,2.51,61.98C200.46,870.92,200.01,871.25,199.52,871.25z"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Battery">
          <g>
            <g>
              <g>
                <path
                  fill={dark1}
                  d="M458.81,965.02c0,14.08-11.42,25.5-25.5,25.5H231.95c-14.08,0-25.5-11.42-25.5-25.5v-517.1
					c0-14.08,11.42-25.5,25.5-25.5h201.36c14.08,0,25.5,11.42,25.5,25.5V965.02z"
                />
                <path
                  fill={main}
                  d="M391.71,392.45H273.16c-5.99,0-11.41,4.85-11.41,11.54v18.43h139.43v-18.43
					C401.19,397.3,397.71,392.45,391.71,392.45z"
                />
              </g>

              <path
                fill={main}
                d="M458.81,622.92v341.96c0,14.08-11.28,25.64-25.36,25.64H232.09c-14.08,0-25.64-11.56-25.64-25.64V622.92
				H458.81z"
              />
            </g>
            <path
              fill={muiBackground}
              d="M385.22,775.53c-0.18-0.35-0.55-0.5-0.94-0.5h-43.17c-0.33,0-0.64-0.22-0.84-0.48
			c-0.2-0.26-0.27-0.63-0.19-0.95l18.06-70.48c0.13-0.49-0.11-1.01-0.57-1.23c-0.46-0.22-1.01-0.09-1.31,0.32l-77.01,103.25
			c-0.24,0.32-0.28,0.82-0.1,1.18c0.18,0.36,0.55,0.66,0.95,0.66h39.61c0.32,0,0.62,0.07,0.82,0.32c0.2,0.25,0.28,0.54,0.22,0.85
			l-16.44,81.61c-0.1,0.5,0.16,0.98,0.63,1.18s1,0.04,1.29-0.38l78.92-114.28C385.38,776.27,385.4,775.88,385.22,775.53z"
            />
          </g>
          <g>
            <g>
              <path
                fill="#D89EAE"
                d="M625.05,988.22L478.93,706.4c-0.82-1.58-0.2-3.54,1.38-4.36c1.58-0.82,3.54-0.2,4.36,1.38l146.12,281.82
				c0.82,1.58,0.2,3.54-1.38,4.36c-0.46,0.24-0.97,0.36-1.49,0.36C626.71,989.97,625.61,989.3,625.05,988.22z"
              />
              <path
                fill="#D89EAE"
                d="M555.64,990.37l-124.9-286.97c-0.71-1.63,0.04-3.54,1.68-4.25c1.63-0.71,3.54,0.04,4.25,1.68
				l124.9,286.97c0.34,0.79,0.36,1.67,0.04,2.47c-0.31,0.8-0.92,1.43-1.72,1.78c-0.41,0.18-0.84,0.27-1.29,0.27
				C557.31,992.32,556.15,991.56,555.64,990.37z"
              />
              <path
                fill="#D89EAE"
                d="M457.3,758.03h50.66c1.12,0,2.02-0.9,2.02-2.02s-0.9-2.02-2.02-2.02H457.3c-1.12,0-2.02,0.9-2.02,2.02
				C455.29,757.13,456.19,758.03,457.3,758.03z"
              />
              <path
                fill="#D89EAE"
                d="M470.84,789.42h50.66c1.12,0,2.02-0.9,2.02-2.02c0-1.12-0.9-2.02-2.02-2.02h-50.66
				c-1.12,0-2.02,0.9-2.02,2.02C468.82,788.51,469.73,789.42,470.84,789.42z"
              />
              <path
                fill="#D89EAE"
                d="M488.7,827h53.89c1.12,0,2.02-0.9,2.02-2.02s-0.9-2.02-2.02-2.02H488.7c-1.12,0-2.02,0.9-2.02,2.02
				C486.68,826.09,487.58,827,488.7,827z"
              />
              <path
                fill="#D89EAE"
                d="M443.84,727.59h48.33c1.12,0,2.02-0.9,2.02-2.02s-0.9-2.02-2.02-2.02h-48.33c-1.12,0-2.02,0.9-2.02,2.02
				C441.82,726.69,442.73,727.59,443.84,727.59z"
              />
              <path
                fill="#D89EAE"
                d="M506.47,870.23h58.55c1.12,0,2.02-0.9,2.02-2.02s-0.9-2.02-2.02-2.02h-58.55c-1.12,0-2.02,0.9-2.02,2.02
				C504.45,869.33,505.36,870.23,506.47,870.23z"
              />
              <path
                fill="#D89EAE"
                d="M520.23,903.77h62.8c1.12,0,2.02-0.9,2.02-2.02s-0.9-2.02-2.02-2.02h-62.8c-1.12,0-2.02,0.9-2.02,2.02
				C518.21,902.86,519.12,903.77,520.23,903.77z"
              />
            </g>
            <g>
              <path
                fill="#0E066B"
                d="M532.58,863c6.47-5.63,20.36-10.14,20.36-10.14l8.36,0.18c2.12,2.39,3.08,7.01,3.49,9.91
				c-1.57,0.94-4.52,1.77-9.93,2.03C544.85,865.46,536.76,863.99,532.58,863z"
              />
              <path
                fill="#0E066B"
                d="M532.58,863c4.18,1,12.26,2.46,22.28,1.98c5.41-0.26,8.36-1.08,9.93-2.03c0.03,0.23,0.06,0.46,0.09,0.67
				c0.15,1.19-0.59,2.31-1.73,2.66c-7.64,2.34-24.43,1.59-30.98,1.18c-1.4-0.09-2.13-1.73-1.23-2.81
				C531.4,864.09,531.96,863.54,532.58,863z"
              />
            </g>
            <path
              fill="#F4B6B3"
              d="M560.62,845.82v6.65c0,1.28-0.89,2.38-2.14,2.65c-1.29,0.28-3.02,0.46-4.68,0.05
			c-1.04-0.26-1.81-1.16-1.93-2.22l-0.84-7.12L560.62,845.82L560.62,845.82z"
            />
            <g>
              <path
                fill="#0E066B"
                d="M518.34,811.76c2.59-5.77,1.85-8.37,6.99-7.79c0.62,0.07,1.13,0.19,1.56,0.34c0.32,0.95,0.34,2.01,0,3.01
				c-1.3,3.74-4.01,10.12-8.99,16.4c-1.05,1.32-2.65,2.08-4.33,2.11l-5.66,0.09C513.28,821.63,516.19,816.55,518.34,811.76z"
              />
              <path
                fill="#0E066B"
                d="M507.55,817.36c4.34-3.06,5.97-15.55,5.97-15.55l6.7-3.41l5.05,3.66c0.78,0.58,1.33,1.37,1.63,2.25
				c-0.43-0.15-0.94-0.27-1.56-0.34c-5.13-0.58-4.39,2.02-6.99,7.79c-2.15,4.79-5.07,9.87-10.43,14.16l-3.89,0.07
				c-1.75,0.03-2.76-1.97-1.7-3.36C503.65,820.88,505.47,818.83,507.55,817.36z"
              />
            </g>
            <path
              fill="#F4B6B3"
              d="M516.17,792.11l4.04,6.29c0.51,0.8,0.34,1.85-0.41,2.43c-1.3,1.02-3.54,2.16-6.29,0.98l-6.49-4.45
			L516.17,792.11z"
            />
            <path
              fill="#0E066B"
              d="M546.52,725.73c0,0,2.13,5.99,5.21,27.05s5.11,36.37,6.79,44.72c1.54,7.64,3.96,35.14,3.82,47.16
			c-0.02,1.61-1.35,2.9-2.97,2.85l-7.96-0.25c-1.23-0.04-2.26-0.93-2.47-2.14c-2.1-12.38-16.66-76.27-25.43-94.65
			c0,0-23.35,6.6-29.17,9.25c0,0,7.52,3.01,22.95,31.93c0,0-6.66,5.29-11.38,6.39c0,0-33.75-27.99-37.31-30.92
			s-6.81-7.98,2.28-17.17c9.1-9.19,37.02-23.69,37.02-23.69L546.52,725.73z"
            />
            <path
              fill="#0E066B"
              d="M524.81,751.12c0.06,0,0.12-0.01,0.17-0.04l8.33-3.21c3.58-1.71,5.9-5.37,5.92-9.33
			c0-0.22-0.18-0.4-0.4-0.41c-0.26,0-0.4,0.18-0.41,0.4c-0.02,3.66-2.16,7.04-5.46,8.61l-8.33,3.21c-0.2,0.1-0.29,0.34-0.19,0.54
			C524.52,751.04,524.66,751.12,524.81,751.12z"
            />
            <path
              fill="#F4B6B3"
              d="M471.02,723.96c0,0-8.77-6.64-12.76-5.39c-3.99,1.25-6.93,4.99-6.93,4.99h8.82c0,0,2.1,4.04,0,4.04h-3.11
			C457.03,727.59,465.26,736.22,471.02,723.96z"
            />
            <path
              fill="#B58392"
              d="M485.87,661.36c0,0-10.29,40.69-24.23,57.05c0,0,3.77,4.3,9.38,5.55c0,0,21.4-18.75,27.91-31.79
			C505.45,679.14,485.87,661.36,485.87,661.36z"
            />
            <path
              fill="#F4B6B3"
              d="M499.62,622.62l10.22,18.7h-16.13c0,0-1.3-8.55-5.54-13.7L499.62,622.62z"
            />
            <g>
              <path
                fill="#F4B6B3"
                d="M484.13,600.35c0,0-5.12,11.77-5.47,17.94c-0.34,6.18-1.68,10.18,5.47,11.43
				c7.14,1.24,13.16-2.31,16.42-8.47c0,0,5.11-9.48,3.96-13.36C503.36,604.01,484.13,600.35,484.13,600.35z"
              />
              <path
                fill="#0E066B"
                d="M498.1,616.84h-0.9c0,0,3.72-7.63,0.45-8.97c-3.27-1.34-16.22-3.98-19.01-8.57
				c-2.69-4.43-0.29-10.15,3.3-11.75c0.23-0.1,0.5,0.01,0.61,0.23l0.3,0.56c0.2,0.39,0.77,0.33,0.89-0.09l0,0
				c0.11-0.4,0.65-0.48,0.87-0.13c0.86,1.37,3.17,3.61,9.38,4.75c8.81,1.63,14.63,5.19,14.78,11.28c0.01,0.39,0.42,0.6,0.75,0.4
				c0.65-0.4,1.69-0.58,3.08,0.59c2.42,2.03,1.43,7.31-3.87,11.65c-0.25,0.21-0.25,0.6,0.02,0.78c0.96,0.63,2.39,2.12-1.32,4.1
				c-3.8,2.03-6.05,1.81-6.89,1.59c-0.23-0.06-0.38-0.27-0.37-0.5l0.33-5.4c0.02-0.28-0.2-0.52-0.48-0.52H498.1L498.1,616.84z"
              />
              <path
                fill="#F4B6B3"
                d="M497.88,617.96c0,0,1.95-7.52,5.32-5.41c3.37,2.11-1.75,8.42-4.46,8.7L497.88,617.96z"
              />
            </g>
            <path
              fill={shirt}
              d="M512.11,639.86c0,0-4.56-7.49-34.37-5.66c-15.5,0.95-50.35-12.55-58.11-19.34c0,0-5.06-0.03-9.72,4.85
			c0,0,32.2,33.98,69.73,38.32c0,0,28.49,58.71,28.28,68.23c0,0,16.91,11.35,39.85,3.22c0,0,0.7-16.32-8.25-38.86
			C527.89,661.36,512.11,639.86,512.11,639.86z"
            />
            <path
              fill={shirt}
              d="M498.6,660.11c0.21,0,0.39-0.16,0.4-0.37c0.02-0.22-0.15-0.42-0.37-0.44c-0.14-0.01-14.46-1.18-16.69-1.43
			c-0.22-0.02-0.42,0.13-0.45,0.36c-0.03,0.22,0.13,0.42,0.36,0.45c2.24,0.25,16.57,1.42,16.71,1.43
			C498.57,660.11,498.58,660.11,498.6,660.11z"
            />
            <g>
              <path
                fill="#F4B6B3"
                d="M410.49,619.06c-0.26,0.95,3.22,2.26,5.09-1.66c0-1.85,0.84-9.78-0.32-11.24
				c-6.16-7.78-17.9-17.39-17.9-17.39c-1.85,1.97-0.2,3.77-0.2,3.77l5.73,5.55c-1.08,2.13-2.42,3.94-2.99,6.49
				c-0.43,1.93-0.05,3.96,0.98,5.66c1.03,1.71,2.44,3.93,3.75,5.66C407.12,619.16,410.49,619.06,410.49,619.06z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill="#170E85"
                d="M221.11,838.77c-0.14,0.26-8.77,6.61-8.77,6.61s1.91,13.64,9.64,19.02c8.51,5.92,20.52-4.88,20.52-4.88
				s9.56,2.27,14.65-17.63c1.17-4.57-5.57-4.95-13.7-4.11C232.99,838.87,221.11,838.77,221.11,838.77z"
              />
              <path
                fill={shirt}
                d="M204.98,885.29c3.38-2.32,7.11-4.07,11.07-5.15c1.49-0.4,9.51,1.93,14.47,1.82
				c6.81-0.16,13.74-3.26,19.36-1.86c5.57,1.39,10.14,3.2,12.78,5.08c7.89,5.61,12.52,16.07,15.34,26.43
				c-5.34,5.49-12.07,7.92-12.07,7.92l-1.24,50.37l-29.78,15.24l-33.99-17.07l1.22-49.22c0,0-10.79-5.03-11.59-6.85
				C189.75,910.17,195.46,891.84,204.98,885.29z"
              />
              <path
                fill="#A23E35"
                d="M191.04,911.78c0,0-14.47,40.1,18.52,56.59c6.85-0.91,4.21-12.28,4.21-12.28s-14.33-3.17-10.03-36.62
				C199.79,916.19,191.04,911.78,191.04,911.78z"
              />
              <path
                fill="#A23E35"
                d="M277.3,911.78c0,0,9.95,41.86-18.52,56.59c-6.85-0.91-4.21-12.28-4.21-12.28s11.55-5.69,10.03-36.62
				C268.56,916.19,277.3,911.78,277.3,911.78z"
              />
              <path
                fill="#B58392"
                d="M261.21,971.18h-56.08c-2.54,0-4.62-2.08-4.62-4.62v-34.87c0-2.54,2.08-4.62,4.62-4.62h56.08
				c2.54,0,4.62,2.08,4.62,4.62v34.87C265.82,969.1,263.75,971.18,261.21,971.18z"
              />
              <g>
                <path
                  fill="#170E85"
                  d="M294.47,984.25c-7.47-1.72-24.75-0.79-24.75-0.79l-0.7,1.9l-1.16,9.46c0,0,16.45,2.97,24.56,2.96
					c0.83,0,1.58-0.13,2.28-0.34c0.25-0.22,0.49-0.45,0.72-0.7C298.85,992.98,297.85,987.46,294.47,984.25z"
                />
                <path
                  fill="#A23E35"
                  d="M264.76,982.93l2.93,0.13c1.57,0.07,3.05,0.83,4.11,2.12c1.06,1.29,1.6,3,1.51,4.74
					c-0.07,1.39-0.64,2.69-1.58,3.61c-0.94,0.92-2.17,1.39-3.42,1.3l-4.48-0.32L264.76,982.93z"
                />
              </g>
              <g>
                <path
                  fill="#170E85"
                  d="M166.4,983.34c7.51-1.49,24.76-0.03,24.76-0.03l0.66,1.92l0.93,9.49c0,0-16.52,2.46-24.62,2.2
					c-0.83-0.03-1.58-0.17-2.26-0.41c-0.24-0.23-0.48-0.47-0.7-0.72C161.81,991.93,162.94,986.44,166.4,983.34z"
                />
                <path
                  fill="#A23E35"
                  d="M196.14,982.93l-2.93,0.03c-1.57,0.02-3.07,0.74-4.16,2c-1.09,1.26-1.68,2.95-1.63,4.69
					c0.04,1.39,0.58,2.71,1.49,3.66c0.92,0.95,2.14,1.45,3.39,1.4l4.48-0.18L196.14,982.93z"
                />
              </g>
              <path
                fill="#170E85"
                d="M296.56,954.6c-3.39-7.8-11.68-9.07-18.73-5.42c-0.12,0.16-44.81,23.06-84.21,33.1
				c1.13,3.62,1.71,7.46,1.84,11.25c0.02,0.84,0.01,1.68-0.04,2.49c30.22-1.55,63.02-11.63,91.2-20.49
				C295.34,972.72,300.32,963.25,296.56,954.6z"
              />
              <path
                fill="#170E85"
                d="M264.97,994.13c-0.12-4.36,0.73-8.42,2.54-12.18c0.05-0.17,0.11-0.34,0.17-0.51
				c-2.25-0.7-5.16-1.64-8.98-2.94c-27.98-9.58-49.33-19.25-69.7-30.38c-7.91-4.32-16.91-1.43-19.45,7.94
				c-2.26,8.36,2.71,15.5,10.24,18.01c22.55,7.5,10.81,5.42,18.18,7.79c19.78,6.12,44.4,11.69,67.04,13.21
				C265,994.76,264.98,994.44,264.97,994.13z"
              />
              <path
                fill="#A23E35"
                d="M216.05,880.14c0,0,3.01,13.04,17.62,12.77c14.88-0.27,16.21-12.81,16.21-12.81l-9.72-2.08l-0.81-10.83
				l-14.62,1.08l-0.27,10.29L216.05,880.14z"
              />
              <path
                fill="#A23E35"
                d="M226.77,836.5c0,0-16.76,32.46,3.12,40.34c14.02,5.55,22.81-21.56,20.19-28.9
				c-4.05-11.35-6.72-10.01-6.72-10.01L226.77,836.5z"
              />
              <path
                fill="#170E85"
                d="M254.12,833.54c8.28,2.28,1.2,20.23-11.26,15.98s-17.36-4.18-19.3-0.76c-1.94,3.42-2.36,15.58-6.57,9.57
				c-4.62-6.59-7.2-19.81-1.53-24.07c5.06-3.81,6.24-9.71,12.32-9.51c8.06,0.27,14.29-3.3,17.93-0.72
				C249.88,826.99,248.03,831.87,254.12,833.54z"
              />
              <path
                fill="#A23E35"
                d="M222.79,851.56c0,0-3.57-3.85-4.15,0.79c-0.57,4.64,1.27,10.6,3.42,7.33
				C224.21,856.41,222.79,851.56,222.79,851.56z"
              />
            </g>
          </g>
        </g>

        <g id="_x34_04_00000146470187619447516120000002859471530575858569_">
          <g id="Background_Complete">
            <g>
              <path
                fill="#EBEBEB"
                d="M1123.13,1001.39c5.26-2.95,12.01-3.06,17.36-0.29c5.36,2.78,9.15,8.35,9.77,14.36
				C1139.17,1015.47,1128.25,1012.88,1123.13,1001.39"
              />
              <g>
                <path
                  fill="#EBEBEB"
                  d="M1136.04,990.03c7.42,2.43,16.01,0.89,22.11-3.98c6.1-4.86,9.53-12.9,8.81-20.67
					C1152.89,968.15,1139.68,974.16,1136.04,990.03"
                />
                <g>
                  <path
                    fill="#E0E0E0"
                    d="M1087.79,1008.79c-0.01-0.02,0.23-0.11,0.69-0.27c0.52-0.18,1.19-0.41,2.02-0.68
						c1.77-0.57,4.31-1.4,7.44-2.49c6.26-2.16,14.85-5.3,24.11-9.34c4.62-2.03,8.98-4.09,12.87-6.07c3.89-2.01,7.33-3.93,10.15-5.65
						c2.8-1.77,5.03-3.25,6.44-4.43c1.45-1.14,2.16-1.89,2.21-1.83c0.01,0.01-0.15,0.2-0.5,0.55c-0.35,0.34-0.83,0.88-1.57,1.46
						c-1.39,1.24-3.6,2.79-6.38,4.6c-2.81,1.78-6.23,3.74-10.12,5.77c-3.9,2.01-8.25,4.09-12.88,6.12
						c-9.28,4.04-17.91,7.13-24.21,9.2c-3.15,1.04-5.72,1.81-7.5,2.32c-0.85,0.24-1.53,0.43-2.05,0.58
						C1088.05,1008.76,1087.8,1008.81,1087.79,1008.79z"
                  />
                </g>
              </g>
              <g>
                <path
                  fill="#E0E0E0"
                  d="M1137.36,1007.84c-0.05,0.08-1.34-0.81-3.56-2.01c-2.21-1.2-5.39-2.63-9.08-3.64
					c-3.7-1.01-7.17-1.39-9.67-1.48c-2.51-0.1-4.07,0.02-4.08-0.09c0-0.04,0.38-0.1,1.09-0.18c0.7-0.09,1.73-0.14,3-0.14
					c2.54-0.01,6.06,0.32,9.81,1.34c3.74,1.03,6.95,2.55,9.12,3.84c1.09,0.65,1.94,1.22,2.51,1.65
					C1137.09,1007.56,1137.38,1007.81,1137.36,1007.84z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#EBEBEB"
                d="M875.9,958.5c-2.67,7.12-1.53,15.55,2.96,21.7c4.49,6.15,12.16,9.81,19.76,9.43
				C896.52,975.81,891.2,962.68,875.9,958.5"
              />
              <g>
                <path
                  fill="#E0E0E0"
                  d="M873.74,951.72c0.09-0.02,0.47,1.51,1.26,3.92c0.79,2.4,2.07,5.67,3.9,9.09
					c1.83,3.4,3.84,6.28,5.41,8.28c1.57,2,2.63,3.16,2.56,3.23c-0.03,0.02-0.32-0.24-0.83-0.74s-1.22-1.26-2.04-2.23
					c-1.66-1.94-3.75-4.82-5.6-8.26s-3.07-6.78-3.78-9.24c-0.35-1.23-0.59-2.24-0.72-2.94
					C873.75,952.11,873.69,951.73,873.74,951.72z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#EBEBEB"
                d="M656.57,681.56c-4.14,11.03-2.36,24.09,4.59,33.61c6.95,9.52,18.84,15.19,30.61,14.61
				C688.51,708.37,680.28,688.05,656.57,681.56"
              />
              <g>
                <path
                  fill="#E0E0E0"
                  d="M653.22,671.06c0.03-0.01,0.22,0.57,0.52,1.64c0.29,1.06,0.74,2.59,1.34,4.47
					c1.21,3.74,3.17,8.82,6,14.11c2.84,5.29,5.98,9.75,8.44,12.82c1.22,1.54,2.25,2.77,2.97,3.59c0.72,0.83,1.11,1.31,1.08,1.34
					c-0.03,0.02-0.47-0.4-1.24-1.19c-0.78-0.78-1.85-1.96-3.13-3.48c-2.55-3.03-5.76-7.47-8.63-12.8
					c-2.85-5.33-4.77-10.48-5.88-14.27c-0.56-1.9-0.95-3.45-1.17-4.53C653.29,671.67,653.18,671.06,653.22,671.06z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#EBEBEB"
                d="M693.66,900c-5.93,4.77-9.22,12.61-8.48,20.18c0.74,7.57,5.48,14.63,12.22,18.17
				C702.59,925.4,704.7,911.41,693.66,900"
              />
              <g>
                <path
                  fill="#E0E0E0"
                  d="M695.24,893.07c0.09,0.03-0.36,1.54-0.9,4.02c-0.54,2.48-1.11,5.94-1.27,9.8
					c-0.15,3.86,0.11,7.36,0.45,9.88c0.33,2.51,0.66,4.06,0.56,4.08c-0.03,0.01-0.15-0.36-0.34-1.05c-0.19-0.69-0.41-1.7-0.63-2.96
					c-0.44-2.51-0.78-6.06-0.61-9.97c0.16-3.91,0.8-7.41,1.45-9.88c0.32-1.24,0.63-2.23,0.87-2.9
					C695.05,893.43,695.21,893.06,695.24,893.07z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#EBEBEB"
                d="M897.34,622.49c6.8,3.39,15.31,3.11,21.88-0.72c6.57-3.83,11.01-11.1,11.4-18.69
				C916.66,603.75,903.08,607.69,897.34,622.49"
              />
              <g>
                <path
                  fill="#E0E0E0"
                  d="M890.37,623.95c-0.01-0.1,1.55-0.31,4.03-0.86c2.48-0.54,5.86-1.48,9.44-2.94
					c3.58-1.47,6.64-3.18,8.79-4.53s3.41-2.29,3.47-2.22c0.02,0.03-0.26,0.3-0.82,0.76c-0.55,0.46-1.37,1.08-2.43,1.8
					c-2.11,1.45-5.18,3.24-8.79,4.73c-3.62,1.48-7.06,2.37-9.58,2.81c-1.26,0.22-2.28,0.36-3,0.42
					C890.77,623.97,890.38,623.99,890.37,623.95z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#EBEBEB"
                d="M868.89,620.5c9.8-2.11,18.34-9.38,21.96-18.73s2.22-20.48-3.61-28.64
				C873.41,585.29,862.63,600.53,868.89,620.5"
              />
              <g>
                <path
                  fill="#E0E0E0"
                  d="M862.91,627.72c-0.03-0.03,0.31-0.43,0.92-1.13c0.63-0.69,1.51-1.72,2.57-3.02
					c2.12-2.59,4.85-6.33,7.34-10.79c2.48-4.47,4.23-8.76,5.31-11.93c0.55-1.58,0.95-2.88,1.22-3.78c0.27-0.9,0.44-1.39,0.47-1.38
					c0.03,0.01-0.05,0.52-0.26,1.44c-0.2,0.92-0.55,2.24-1.04,3.84c-0.99,3.22-2.7,7.57-5.19,12.07c-2.51,4.5-5.31,8.24-7.53,10.78
					c-1.11,1.27-2.04,2.26-2.72,2.92C863.32,627.4,862.93,627.76,862.91,627.72z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#EBEBEB"
                d="M958.92,996.46c2.07,4.08,6.28,7,10.83,7.52c4.55,0.52,9.31-1.4,12.23-4.93
				C974.99,994.37,967.01,991.38,958.92,996.46"
              />
              <g>
                <path
                  fill="#E0E0E0"
                  d="M955.03,994.66c0.09-0.16,3.39,1.76,7.9,2.9c4.5,1.17,8.32,1.08,8.32,1.25c0,0.07-0.94,0.19-2.49,0.14
					c-1.55-0.03-3.68-0.26-5.97-0.85c-2.28-0.59-4.27-1.42-5.64-2.12C955.78,995.28,955,994.73,955.03,994.66z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#EBEBEB"
                d="M1060.66,602.41c2.82,3.61,7.52,5.66,12.08,5.28c4.57-0.37,8.86-3.17,11.04-7.2
				C1076.02,597.26,1067.61,595.86,1060.66,602.41"
              />
              <g>
                <path
                  fill="#E0E0E0"
                  d="M1056.5,601.4c0.05-0.18,3.67,1.08,8.31,1.32c4.64,0.29,8.37-0.56,8.41-0.37
					c0.01,0.07-0.89,0.36-2.41,0.63c-1.53,0.26-3.67,0.45-6.03,0.32s-4.47-0.56-5.95-0.99
					C1057.34,601.87,1056.47,601.47,1056.5,601.4z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#EBEBEB"
                d="M1324.32,636.17c7.33,2.02,15.62,0.12,21.34-4.89c5.71-5.02,8.67-12.99,7.62-20.51
				C1339.7,614.09,1327.12,620.57,1324.32,636.17"
              />
              <g>
                <path
                  fill="#E0E0E0"
                  d="M1317.76,638.94c-0.03-0.09,1.46-0.6,3.79-1.61c2.33-1.01,5.47-2.58,8.69-4.7
					c3.23-2.13,5.9-4.39,7.76-6.14c1.84-1.73,2.91-2.9,2.97-2.84c0.03,0.02-0.21,0.34-0.66,0.9c-0.45,0.56-1.14,1.33-2.04,2.24
					c-1.79,1.82-4.46,4.17-7.73,6.32c-3.27,2.14-6.48,3.68-8.86,4.59c-1.2,0.46-2.17,0.79-2.85,0.99
					C1318.15,638.88,1317.77,638.97,1317.76,638.94z"
                />
              </g>
            </g>
            <g>
              <path
                fill="#EBEBEB"
                d="M1350.29,970.68c12.88-2.97,24-12.75,28.59-25.17c4.59-12.4,2.51-27.06-5.34-37.71
				C1355.53,924.15,1341.64,944.47,1350.29,970.68"
              />
              <g>
                <path
                  fill="#E0E0E0"
                  d="M1342.54,980.34c-0.03-0.02,0.41-0.56,1.22-1.49c0.82-0.92,1.98-2.29,3.35-4.02
					c1.38-1.72,2.94-3.84,4.59-6.26c1.61-2.43,3.3-5.17,4.91-8.13c1.58-2.97,2.93-5.89,4.06-8.58c1.11-2.7,2.01-5.17,2.68-7.28
					c0.68-2.11,1.19-3.82,1.5-5.02c0.33-1.2,0.53-1.85,0.56-1.84s-0.09,0.68-0.35,1.9c-0.25,1.22-0.7,2.95-1.33,5.08
					c-0.63,2.13-1.49,4.63-2.58,7.36c-1.11,2.72-2.46,5.66-4.05,8.65c-1.61,2.99-3.33,5.73-4.97,8.15
					c-1.68,2.41-3.28,4.52-4.71,6.21c-1.43,1.7-2.62,3.04-3.5,3.92C1343.07,979.89,1342.57,980.36,1342.54,980.34z"
                />
              </g>
            </g>
          </g>
          <g id="Capa_8">
            <g>
              <path
                fill={light1}
                d="M1201.3,832.31c-6.94-38.69-40.15-72.47-79.32-75.66c-21.35-1.73-42.8,4.99-64.06,2.43
				c-17.83-2.15-34.77-11.08-46.62-24.58c-17.54-19.97-23.51-48.39-42.09-67.41c-25.62-26.21-70.33-26.63-101.49-7.34
				c-31.17,19.28-50.01,54.09-58.64,89.7l-0.34,10.83c-1.88,36.94,7.84,79.89,29.28,112.81c21.72,33.36,36,47.14,75.57,71.13
				c33.61,20.37,99.41,29.33,138.7,30.27c39.29,0.94,79.65-10.77,109.2-36.68C1191.04,911.9,1208.24,871,1201.3,832.31z"
              />
              <g opacity="0.8">
                <path
                  fill={muiBackground}
                  d="M1201.3,832.31c-6.94-38.69-40.15-72.47-79.32-75.66c-21.35-1.73-42.8,4.99-64.06,2.43
					c-17.83-2.15-34.77-11.08-46.62-24.58c-17.54-19.97-23.51-48.39-42.09-67.41c-25.62-26.21-70.33-26.63-101.49-7.34
					c-31.17,19.28-50.01,54.09-58.64,89.7l-0.34,10.83c-1.88,36.94,7.84,79.89,29.28,112.81c21.72,33.36,36,47.14,75.57,71.13
					c33.61,20.37,99.41,29.33,138.7,30.27c39.29,0.94,79.65-10.77,109.2-36.68C1191.04,911.9,1208.24,871,1201.3,832.31z"
                />
              </g>
            </g>
            <g>
              <ellipse
                fill={light1}
                cx="1221.03"
                cy="967.7"
                rx="18.93"
                ry="18.93"
              />
              <ellipse
                opacity="0.8"
                fill={muiBackground}
                enable-background="new    "
                cx="1221.03"
                cy="967.7"
                rx="18.93"
                ry="18.93"
              />
            </g>
          </g>
          <g id="_x34_04">
            <g id="0">
              <g>
                <path
                  fill={main}
                  d="M1088.19,822.05c0.36,37.79-7.88,66.67-24.72,86.67c-16.85,20-39.91,30.13-69.18,30.4
					c-29.41,0.29-52.79-9.41-70.14-29.07c-17.35-19.66-26.21-48.38-26.58-86.18l-0.64-66.47c-0.36-37.66,7.88-66.54,24.72-86.67
					c16.84-20.12,39.96-30.32,69.37-30.61c29.27-0.29,52.59,9.47,69.94,29.27s26.22,48.52,26.58,86.18L1088.19,822.05z
					 M1038.99,748.6c-0.23-23.79-4.39-41.38-12.48-52.72c-8.09-11.36-19.79-16.96-35.09-16.81c-15.3,0.14-26.86,5.97-34.66,17.48
					c-7.8,11.51-11.6,29.16-11.37,52.95l0.78,80.75c0.23,23.94,4.42,41.61,12.59,53.03c8.15,11.43,19.89,17.06,35.2,16.91
					c15.3-0.14,26.82-5.97,34.56-17.48c7.74-11.51,11.49-29.29,11.26-53.36L1038.99,748.6z"
                />
              </g>
              <g>
                <path
                  fill={dark1}
                  d="M1088.19,822.05c0.36,37.79-7.88,66.67-24.72,86.67c-16.85,20-39.91,30.13-69.18,30.4
					c-29.41,0.29-52.79-9.41-70.14-29.07c-17.35-19.66-26.21-48.38-26.58-86.18l-0.64-66.47c-0.36-37.66,7.88-66.54,24.72-86.67
					c16.84-20.12,39.96-30.32,69.37-30.61c29.27-0.29,52.59,9.47,69.94,29.27s26.22,48.52,26.58,86.18L1088.19,822.05z
					 M1038.99,748.6c-0.23-23.79-4.39-41.38-12.48-52.72c-8.09-11.36-19.79-16.96-35.09-16.81c-15.3,0.14-26.86,5.97-34.66,17.48
					c-7.8,11.51-11.6,29.16-11.37,52.95l0.78,80.75c0.23,23.94,4.42,41.61,12.59,53.03c8.15,11.43,19.89,17.06,35.2,16.91
					c15.3-0.14,26.82-5.97,34.56-17.48c7.74-11.51,11.49-29.29,11.26-53.36L1038.99,748.6z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1116.13,821.78c0.36,37.79-7.88,66.67-24.72,86.67c-16.85,20-39.91,30.13-69.18,30.4
					c-29.41,0.29-52.79-9.41-70.14-29.07c-17.35-19.66-26.21-48.38-26.58-86.18l-0.64-66.47c-0.36-37.66,7.88-66.54,24.72-86.67
					c16.84-20.12,39.96-30.32,69.37-30.61c29.27-0.29,52.59,9.47,69.94,29.27c17.35,19.8,26.22,48.52,26.58,86.18L1116.13,821.78z
					 M1066.93,748.34c-0.23-23.79-4.39-41.38-12.48-52.72c-8.09-11.36-19.79-16.96-35.09-16.81c-15.3,0.14-26.86,5.97-34.66,17.48
					c-7.8,11.51-11.6,29.16-11.37,52.95l0.78,80.75c0.23,23.94,4.42,41.61,12.59,53.03c8.15,11.43,19.89,17.06,35.2,16.91
					c15.3-0.14,26.82-5.97,34.56-17.48c7.74-11.51,11.49-29.29,11.26-53.36L1066.93,748.34z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M925.09,761.1c0.05,0.14-6.43,2.72-14.49,5.75c-8.07,3.03-14.64,5.37-14.7,5.21
					c-0.05-0.14,6.43-2.72,14.5-5.75C918.46,763.29,925.04,760.95,925.09,761.1z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M926.7,787.37c0.05,0.14-6.43,2.72-14.49,5.75c-8.07,3.03-14.64,5.37-14.7,5.21
					c-0.05-0.14,6.43-2.72,14.5-5.75C920.06,789.55,926.65,787.21,926.7,787.37z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M928.32,813.63c0.05,0.14-6.43,2.72-14.49,5.75c-8.07,3.03-14.64,5.37-14.7,5.21
					c-0.05-0.14,6.43-2.72,14.5-5.75C921.68,815.81,928.26,813.48,928.32,813.63z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M929.93,839.89c0.05,0.14-6.43,2.72-14.49,5.75c-8.07,3.03-14.64,5.37-14.7,5.21
					c-0.05-0.14,6.43-2.72,14.5-5.75C923.29,842.08,929.88,839.74,929.93,839.89z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M931.54,866.16c0.01,0.03-0.34,0.21-1.01,0.49c-0.66,0.29-1.62,0.68-2.81,1.15
					c-2.38,0.95-5.7,2.23-9.36,3.61s-7,2.6-9.43,3.45c-1.21,0.43-2.2,0.77-2.88,0.99c-0.68,0.22-1.06,0.33-1.09,0.29
					c-0.01-0.03,0.34-0.21,1.01-0.49c0.66-0.29,1.62-0.68,2.81-1.15c2.38-0.95,5.7-2.23,9.36-3.61s7-2.6,9.42-3.45
					c1.21-0.43,2.2-0.77,2.88-0.99C931.14,866.22,931.52,866.11,931.54,866.16z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M939.04,890.18c0.03,0.08-1.24,0.63-3.33,1.46c-2.07,0.83-4.96,1.95-8.17,3.16
					c-3.2,1.21-6.11,2.26-8.22,3c-2.12,0.74-3.44,1.16-3.47,1.09c-0.03-0.08,1.24-0.63,3.33-1.46c2.07-0.83,4.96-1.95,8.17-3.16
					c3.19-1.21,6.1-2.26,8.22-3C937.69,890.52,939.02,890.1,939.04,890.18z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M955.1,912.22c0.03,0.08-1.1,0.57-2.95,1.33c-1.84,0.75-4.42,1.74-7.27,2.82
					c-2.85,1.08-5.44,2.01-7.33,2.67c-1.89,0.66-3.06,1.02-3.09,0.94c-0.03-0.08,1.1-0.57,2.95-1.33c1.84-0.75,4.42-1.75,7.27-2.82
					c2.85-1.08,5.44-2.01,7.33-2.67C953.89,912.52,955.06,912.14,955.1,912.22z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M981.78,926.67c0.03,0.08-1.1,0.57-2.95,1.33c-1.85,0.76-4.42,1.75-7.27,2.82
					c-2.85,1.08-5.44,2.01-7.33,2.67c-1.89,0.66-3.06,1.02-3.09,0.94c-0.03-0.08,1.1-0.57,2.95-1.33c1.84-0.75,4.42-1.74,7.27-2.82
					c2.85-1.08,5.44-2.01,7.33-2.67C980.57,926.96,981.74,926.6,981.78,926.67z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1062.25,870.62c0.07,0.14-8.26,4.03-18.6,8.68c-10.34,4.65-18.78,8.3-18.84,8.15
					c-0.07-0.14,8.26-4.03,18.6-8.68C1053.75,874.14,1062.18,870.48,1062.25,870.62z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1067.71,848.12c0.07,0.14-6.82,3.38-15.35,7.22c-8.55,3.84-15.53,6.84-15.6,6.69
					c-0.07-0.14,6.82-3.38,15.37-7.22C1060.67,850.98,1067.65,847.98,1067.71,848.12z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1070.23,822.85c0.07,0.14-6.82,3.38-15.35,7.22c-8.55,3.84-15.53,6.84-15.6,6.69
					c-0.07-0.14,6.82-3.38,15.37-7.22C1063.18,825.7,1070.16,822.7,1070.23,822.85z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1072.74,797.56c0.07,0.14-6.82,3.38-15.35,7.22c-8.55,3.84-15.53,6.84-15.6,6.69
					c-0.07-0.14,6.82-3.38,15.37-7.22C1065.69,800.42,1072.67,797.42,1072.74,797.56z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1075.26,772.29c0.07,0.14-6.82,3.38-15.35,7.22c-8.55,3.84-15.53,6.84-15.6,6.69
					c-0.07-0.14,6.82-3.38,15.37-7.22C1068.21,775.14,1075.2,772.14,1075.26,772.29z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1070.23,749.78c0.07,0.14-6.82,3.38-15.35,7.22c-8.55,3.84-15.53,6.84-15.6,6.69
					c-0.07-0.14,6.82-3.38,15.37-7.22C1063.18,752.63,1070.16,749.63,1070.23,749.78z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1070.23,725.54c0.07,0.14-6.82,3.38-15.35,7.22c-8.55,3.84-15.53,6.84-15.6,6.69
					c-0.07-0.14,6.82-3.38,15.37-7.22C1063.18,728.4,1070.16,725.4,1070.23,725.54z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1067.71,702.73c0.07,0.14-6.82,3.38-15.35,7.22c-8.55,3.84-15.53,6.84-15.6,6.69
					c-0.07-0.14,6.82-3.38,15.37-7.22C1060.67,705.58,1067.65,702.58,1067.71,702.73z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1046.04,686.82c0.07,0.14-4.09,2.15-9.3,4.49c-5.2,2.34-9.47,4.12-9.53,3.97
					c-0.07-0.14,4.09-2.15,9.3-4.49C1041.7,688.46,1045.97,686.68,1046.04,686.82z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1034.45,677.09c0.07,0.14-4.09,2.15-9.3,4.49c-5.2,2.34-9.47,4.12-9.53,3.97
					c-0.07-0.14,4.09-2.15,9.3-4.49C1030.11,678.72,1034.38,676.95,1034.45,677.09z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M926.33,729.26c0.05,0.14-6.43,2.72-14.49,5.75c-8.07,3.03-14.64,5.37-14.7,5.21
					c-0.05-0.15,6.43-2.72,14.5-5.75C919.7,731.45,926.28,729.12,926.33,729.26z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M934.41,696.51c0.05,0.14-6.98,2.93-15.72,6.21c-8.74,3.28-15.86,5.83-15.91,5.67
					c-0.05-0.14,6.98-2.93,15.72-6.21S934.35,696.36,934.41,696.51z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M955.51,664.09c0.05,0.14-8.87,3.63-19.93,7.79c-11.06,4.16-20.07,7.41-20.13,7.25
					c-0.05-0.14,8.87-3.63,19.93-7.79C946.44,667.2,955.46,663.95,955.51,664.09z"
                />
              </g>
              <g>
                <path
                  fill={muiBackground}
                  d="M1111.4,870.99c0,0.01-0.16-0.04-0.46-0.15c-0.32-0.12-0.76-0.3-1.32-0.52
					c-0.58-0.22-1.34-0.37-2.22-0.63c-0.9-0.12-1.93-0.37-3.11-0.37c-4.69-0.31-11.71,1.4-18.5,6.49c-3.45,2.46-6.66,5.83-9.8,9.69
					c-3.15,3.85-6.23,8.21-9.73,12.61c-3.5,4.38-7.53,8.82-12.64,12.26c-5.08,3.45-11.08,5.94-17.49,7.09
					c-6.42,1.19-12.91,0.85-18.87-0.63c-5.98-1.44-11.44-3.79-16.53-6.14c-5.09-2.36-9.86-4.77-14.42-6.75
					c-4.55-2.02-8.97-3.48-13.08-4.46c-4.13-0.94-7.95-1.35-11.3-1.31c-3.36,0.03-6.22,0.55-8.51,1.15
					c-2.26,0.69-3.96,1.42-5.06,1.99c-0.54,0.29-0.94,0.5-1.25,0.66c-0.29,0.14-0.43,0.22-0.44,0.21c0-0.01,0.13-0.1,0.41-0.26
					c0.3-0.18,0.71-0.41,1.23-0.71c1.09-0.61,2.79-1.37,5.05-2.1c2.29-0.65,5.18-1.2,8.56-1.26c3.38-0.08,7.24,0.31,11.4,1.23
					c4.15,0.95,8.6,2.4,13.18,4.41c4.6,1.96,9.36,4.37,14.45,6.71c5.09,2.33,10.51,4.65,16.44,6.07c5.89,1.45,12.29,1.78,18.64,0.61
					c6.33-1.14,12.26-3.6,17.29-6.99c5.06-3.38,9.05-7.77,12.56-12.13c3.5-4.37,6.6-8.71,9.78-12.58c3.17-3.86,6.44-7.25,9.93-9.71
					c6.88-5.09,14.02-6.76,18.75-6.39c1.2,0.02,2.23,0.29,3.14,0.43c0.88,0.26,1.64,0.45,2.22,0.68c0.56,0.24,0.99,0.43,1.31,0.57
					C1111.26,870.91,1111.42,870.99,1111.4,870.99z"
                />
              </g>
              <g>
                <path
                  fill={muiBackground}
                  d="M1118.33,797.74c0.01,0.05-0.78,0.16-2.13,0.61c-1.35,0.46-3.23,1.35-5.3,2.83
					c-2.1,1.46-4.37,3.5-7.04,5.61c-2.69,2.1-5.87,4.12-9.43,5.81c-7.11,3.42-14.26,4.75-19.38,5c-1.28,0.11-2.45,0.07-3.47,0.09
					c-1.02-0.04-1.9-0.05-2.62-0.11c-0.66-0.07-1.21-0.12-1.65-0.16c-0.37-0.04-0.57-0.08-0.57-0.1c0-0.02,0.2-0.02,0.58-0.02
					c0.44,0.01,0.99,0.03,1.65,0.05c0.75,0,1.61,0.01,2.61,0.02c1.01-0.05,2.17-0.04,3.45-0.18c5.08-0.35,12.13-1.73,19.17-5.13
					c3.51-1.67,6.67-3.65,9.35-5.71c2.67-2.07,4.98-4.09,7.13-5.53c2.13-1.46,4.07-2.32,5.47-2.72c0.7-0.19,1.24-0.31,1.62-0.35
					C1118.12,797.73,1118.32,797.72,1118.33,797.74z"
                />
              </g>
              <g>
                <path
                  fill={muiBackground}
                  d="M974.96,823.17c0,0.02-0.21-0.01-0.6-0.09c-0.38-0.09-0.98-0.18-1.7-0.38c-1.46-0.43-3.53-1.2-5.88-2.58
					c-2.35-1.37-4.94-3.33-7.68-5.61c-2.75-2.27-5.7-4.88-9.07-7.21c-3.38-2.32-6.96-3.97-10.42-4.77
					c-3.45-0.86-6.66-1.04-9.34-0.89c-2.68,0.13-4.83,0.58-6.28,0.95c-1.46,0.37-2.25,0.66-2.27,0.6c-0.01-0.02,0.19-0.1,0.56-0.25
					c0.37-0.14,0.92-0.36,1.65-0.57c1.45-0.44,3.6-0.95,6.31-1.15c2.71-0.2,5.98-0.07,9.49,0.78c3.52,0.79,7.19,2.47,10.61,4.82
					c3.41,2.36,6.35,4.99,9.09,7.28c2.72,2.3,5.26,4.28,7.55,5.67c2.29,1.4,4.31,2.23,5.74,2.71
					C974.16,822.91,974.97,823.11,974.96,823.17z"
                />
              </g>
              <g>
                <path
                  fill={muiBackground}
                  d="M975.16,719.42c0.01,0.03-0.64,0.27-1.83,0.67c-0.59,0.21-1.33,0.44-2.2,0.65
					c-0.87,0.22-1.84,0.5-2.94,0.7c-1.1,0.23-2.3,0.47-3.62,0.64c-1.31,0.22-2.71,0.32-4.19,0.45c-2.96,0.19-6.24,0.19-9.66-0.12
					c-3.41-0.34-6.64-0.95-9.52-1.69c-1.43-0.41-2.8-0.77-4.04-1.23c-1.26-0.41-2.4-0.87-3.44-1.3c-1.05-0.4-1.95-0.87-2.77-1.24
					s-1.49-0.74-2.03-1.04c-1.1-0.61-1.69-0.97-1.67-1c0.02-0.03,0.65,0.25,1.78,0.8c0.56,0.29,1.24,0.61,2.06,0.95
					c0.81,0.35,1.72,0.79,2.78,1.16c1.04,0.41,2.18,0.85,3.44,1.22c1.24,0.44,2.6,0.78,4.02,1.16c2.86,0.7,6.06,1.3,9.44,1.62
					c3.39,0.31,6.63,0.31,9.57,0.16c1.47-0.12,2.86-0.2,4.16-0.4c1.31-0.14,2.5-0.36,3.6-0.56c1.11-0.18,2.07-0.43,2.94-0.63
					c0.87-0.18,1.6-0.38,2.21-0.55C974.47,719.55,975.15,719.39,975.16,719.42z"
                />
              </g>
              <g>
                <path
                  fill={muiBackground}
                  d="M1112.1,719.15c-0.01,0.01-0.18-0.07-0.47-0.22c-0.35-0.2-0.77-0.44-1.27-0.74
					c-0.54-0.33-1.22-0.72-2.04-1.11c-0.81-0.4-1.71-0.91-2.78-1.31c-0.53-0.22-1.08-0.45-1.65-0.68s-1.2-0.41-1.83-0.63
					c-1.25-0.46-2.66-0.78-4.1-1.16c-2.94-0.64-6.22-1.09-9.69-1.14c-3.47-0.01-6.76,0.38-9.71,0.97c-1.46,0.35-2.86,0.65-4.13,1.09
					c-0.64,0.21-1.26,0.37-1.84,0.59c-0.57,0.23-1.13,0.45-1.66,0.66c-1.08,0.38-1.98,0.88-2.8,1.26c-0.82,0.37-1.5,0.75-2.05,1.08
					c-0.5,0.29-0.93,0.52-1.28,0.71c-0.3,0.15-0.46,0.23-0.47,0.22s0.13-0.12,0.41-0.32c0.34-0.22,0.75-0.48,1.24-0.81
					c0.54-0.35,1.21-0.77,2.02-1.17c0.81-0.41,1.71-0.93,2.79-1.35c0.54-0.22,1.09-0.45,1.67-0.69s1.21-0.42,1.85-0.64
					c1.27-0.47,2.69-0.78,4.16-1.15c2.97-0.61,6.31-1.03,9.82-1.02c3.51,0.05,6.84,0.53,9.8,1.2c1.47,0.4,2.88,0.74,4.14,1.22
					c0.64,0.23,1.26,0.42,1.83,0.67c0.57,0.25,1.12,0.49,1.65,0.72c1.06,0.43,1.96,0.98,2.77,1.39c0.81,0.42,1.47,0.83,2,1.21
					c0.48,0.33,0.89,0.6,1.22,0.83C1111.97,719.01,1112.11,719.12,1112.1,719.15z"
                />
              </g>
              <g>
                <path
                  fill={muiBackground}
                  d="M1044.99,644.59c0.01,0.02-0.26,0.18-0.79,0.47c-0.58,0.32-1.35,0.74-2.33,1.26
					c-2.03,1.09-4.97,2.63-8.66,4.47c-7.35,3.63-17.69,8.6-29.92,11.7c-6.11,1.5-12.09,2.32-17.55,2.44
					c-5.45,0.12-10.37-0.37-14.41-1.24c-4.04-0.86-7.21-1.94-9.33-2.85c-1.08-0.42-1.85-0.86-2.41-1.1
					c-0.55-0.26-0.82-0.42-0.81-0.43c0.01-0.02,0.31,0.1,0.87,0.33c0.57,0.22,1.36,0.63,2.45,1c2.13,0.85,5.3,1.87,9.32,2.66
					c4.02,0.81,8.9,1.26,14.32,1.11c5.41-0.15,11.35-0.97,17.42-2.47c12.15-3.07,22.48-7.97,29.87-11.51
					c3.64-1.8,6.62-3.26,8.74-4.3c1-0.48,1.79-0.88,2.38-1.16C1044.7,644.7,1044.99,644.58,1044.99,644.59z"
                />
              </g>
            </g>

            <g>
              <g>
                <path
                  fill={main}
                  d="M824.68,835.82l36.69,1.6l-1.7,38.88l-36.69-1.6l-2.82,64.61l-48.26-2.11l2.82-64.61l-124.63-5.44
					l0.1-29.61l132.8-190.75l49.85,2.17L824.68,835.82z M698.46,830.31l77.97,3.4l5.15-117.85l-1.17-0.45l-4.36,8.4L698.46,830.31z"
                />
              </g>
              <g>
                <path
                  fill={dark1}
                  d="M824.68,835.82l36.69,1.6l-1.7,38.88l-36.69-1.6l-2.82,64.61l-48.26-2.11l2.82-64.61l-124.63-5.44l0.1-29.61
					l132.8-190.75l49.85,2.17L824.68,835.82z M698.46,830.31l77.97,3.4l5.15-117.85l-1.17-0.45l-4.36,8.4L698.46,830.31z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M855.37,837.16l36.69,1.6l-1.7,38.88l-36.69-1.6l-2.82,64.61l-48.26-2.11l2.82-64.61l-124.63-5.44
					l0.1-29.61l132.8-190.75l49.85,2.17L855.37,837.16z M729.13,831.65l77.97,3.4l5.15-117.85l-1.17-0.45l-4.36,8.4L729.13,831.65z"
                />
              </g>
              <g>
                <g>
                  <path
                    fill={light1}
                    d="M681.49,844.51c0.01,0.03-0.41,0.27-1.19,0.67s-1.92,0.94-3.34,1.61c-2.83,1.33-6.77,3.09-11.16,4.95
						c-4.39,1.85-8.41,3.45-11.34,4.55c-1.46,0.55-2.65,0.99-3.48,1.27c-0.82,0.29-1.3,0.43-1.31,0.38
						c-0.01-0.03,0.42-0.25,1.23-0.59c0.91-0.38,2.05-0.87,3.41-1.44c3-1.25,6.91-2.89,11.26-4.71c4.32-1.84,8.23-3.52,11.22-4.8
						c1.35-0.57,2.49-1.05,3.4-1.44C681.02,844.65,681.48,844.48,681.49,844.51z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M699.36,811.65c0.02,0.03-0.57,0.34-1.66,0.88c-1.09,0.53-2.66,1.26-4.61,2.16
						c-3.91,1.79-9.35,4.17-15.4,6.73c-6.05,2.56-11.55,4.8-15.55,6.35c-2,0.78-3.63,1.39-4.76,1.8s-1.77,0.61-1.78,0.57
						c-0.01-0.03,0.59-0.32,1.7-0.78c1.2-0.5,2.78-1.16,4.69-1.98c4.05-1.7,9.47-3.98,15.47-6.51c5.99-2.55,11.41-4.85,15.45-6.56
						c1.91-0.81,3.49-1.48,4.69-1.99C698.72,811.85,699.33,811.61,699.36,811.65z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M721.16,782.34c0.01,0.03-0.6,0.35-1.73,0.88s-2.78,1.27-4.82,2.16c-4.08,1.79-9.77,4.17-16.07,6.73
						c-6.31,2.56-12.05,4.8-16.22,6.35c-2.09,0.78-3.79,1.39-4.96,1.8c-1.17,0.41-1.83,0.61-1.85,0.57
						c-0.01-0.03,0.61-0.32,1.78-0.78c1.24-0.5,2.9-1.16,4.89-1.98c4.21-1.7,9.89-3.98,16.16-6.51c6.26-2.55,11.92-4.85,16.12-6.56
						c2-0.81,3.64-1.48,4.88-1.99C720.49,782.55,721.14,782.31,721.16,782.34z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M808.51,816.19c0.01,0.03-0.41,0.26-1.2,0.65c-0.78,0.38-1.92,0.91-3.35,1.55
						c-2.83,1.28-6.78,2.99-11.18,4.77c-4.4,1.78-8.43,3.3-11.35,4.36c-1.46,0.53-2.66,0.94-3.48,1.21
						c-0.82,0.27-1.3,0.41-1.31,0.36c-0.01-0.03,0.43-0.24,1.23-0.57c0.91-0.37,2.06-0.83,3.41-1.38c3.01-1.2,6.94-2.78,11.28-4.51
						c4.34-1.78,8.25-3.38,11.24-4.61c1.36-0.55,2.5-1.01,3.41-1.38C808.04,816.31,808.49,816.15,808.51,816.19z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M804.96,876.48c0.01,0.03-0.4,0.26-1.15,0.65c-0.76,0.38-1.87,0.91-3.24,1.55
						c-2.74,1.28-6.57,2.99-10.84,4.76s-8.17,3.3-11.01,4.36c-1.42,0.53-2.58,0.94-3.38,1.22c-0.8,0.27-1.25,0.41-1.27,0.37
						c-0.01-0.03,0.41-0.24,1.19-0.57c0.89-0.37,2-0.83,3.31-1.38c2.92-1.21,6.73-2.78,10.94-4.51c4.19-1.78,7.99-3.38,10.9-4.61
						c1.31-0.55,2.41-1.01,3.31-1.38C804.51,876.6,804.95,876.45,804.96,876.48z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M804.45,897.62c0.01,0.03-0.4,0.26-1.15,0.65c-0.76,0.38-1.87,0.91-3.24,1.55
						c-2.74,1.28-6.57,2.99-10.84,4.76c-4.27,1.78-8.17,3.3-11.01,4.36c-1.42,0.53-2.58,0.94-3.38,1.22
						c-0.8,0.27-1.25,0.41-1.27,0.37c-0.02-0.03,0.41-0.24,1.19-0.57c0.89-0.37,2-0.83,3.31-1.38c2.92-1.21,6.73-2.78,10.94-4.51
						c4.19-1.78,7.99-3.38,10.9-4.61c1.31-0.55,2.41-1.01,3.31-1.38C803.99,897.74,804.44,897.58,804.45,897.62z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M803.95,918.75c0.01,0.03-0.4,0.26-1.15,0.65c-0.76,0.38-1.87,0.91-3.24,1.55
						c-2.74,1.28-6.57,2.99-10.84,4.76s-8.17,3.3-11.01,4.36c-1.42,0.53-2.58,0.94-3.38,1.22c-0.8,0.27-1.25,0.41-1.27,0.37
						c-0.01-0.03,0.41-0.24,1.19-0.57c0.89-0.37,2-0.83,3.31-1.38c2.92-1.21,6.73-2.78,10.94-4.51c4.19-1.78,7.99-3.38,10.9-4.61
						c1.31-0.55,2.41-1.01,3.31-1.38C803.49,918.87,803.93,918.72,803.95,918.75z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M809.08,793.86c0.01,0.03-0.41,0.26-1.2,0.65c-0.78,0.38-1.92,0.91-3.35,1.55
						c-2.83,1.28-6.78,2.99-11.18,4.77c-4.4,1.78-8.43,3.3-11.35,4.36c-1.46,0.53-2.66,0.94-3.48,1.21
						c-0.82,0.27-1.3,0.41-1.31,0.36c-0.01-0.04,0.43-0.24,1.23-0.57c0.91-0.37,2.06-0.83,3.41-1.38c3.01-1.2,6.94-2.78,11.28-4.51
						c4.34-1.78,8.25-3.38,11.24-4.61c1.36-0.55,2.5-1.01,3.41-1.38C808.61,793.98,809.06,793.83,809.08,793.86z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M809.66,771.54c0.01,0.03-0.41,0.26-1.2,0.65c-0.78,0.38-1.92,0.91-3.35,1.55
						c-2.83,1.28-6.78,2.99-11.18,4.77c-4.4,1.78-8.43,3.3-11.35,4.36c-1.46,0.53-2.66,0.94-3.48,1.21
						c-0.82,0.27-1.3,0.41-1.31,0.36c-0.01-0.03,0.43-0.24,1.23-0.57c0.91-0.37,2.06-0.83,3.41-1.38c3.01-1.2,6.94-2.78,11.28-4.51
						c4.34-1.78,8.25-3.38,11.24-4.61c1.36-0.55,2.5-1.01,3.41-1.38C809.18,771.66,809.63,771.51,809.66,771.54z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M810.23,749.22c0.01,0.03-0.38,0.25-1.13,0.61s-1.83,0.87-3.18,1.48c-2.7,1.22-6.45,2.85-10.65,4.55
						c-4.19,1.7-8.03,3.14-10.82,4.14c-1.39,0.5-2.52,0.89-3.31,1.15c-0.79,0.25-1.23,0.38-1.24,0.34
						c-0.01-0.03,0.41-0.23,1.16-0.55c0.88-0.35,1.96-0.8,3.25-1.32c2.88-1.14,6.61-2.64,10.74-4.29c4.12-1.69,7.85-3.23,10.7-4.4
						c1.28-0.52,2.37-0.97,3.25-1.32C809.78,749.33,810.2,749.17,810.23,749.22z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M812.75,728.35c0.05,0.14-2.4,1.2-5.5,2.35c-3.09,1.14-5.64,1.95-5.7,1.81c-0.05-0.14,2.4-1.2,5.5-2.35
						C810.14,729.01,812.7,728.2,812.75,728.35z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M742.93,751.96c0.01,0.03-0.6,0.35-1.73,0.88s-2.78,1.27-4.82,2.16c-4.08,1.79-9.77,4.17-16.07,6.73
						c-6.31,2.56-12.05,4.8-16.22,6.35c-2.09,0.78-3.79,1.39-4.96,1.8c-1.17,0.41-1.83,0.61-1.85,0.57
						c-0.01-0.03,0.61-0.32,1.78-0.78c1.24-0.5,2.9-1.16,4.89-1.98c4.21-1.7,9.89-3.98,16.16-6.51c6.26-2.55,11.92-4.85,16.12-6.56
						c2-0.81,3.64-1.48,4.88-1.99C742.27,752.16,742.92,751.93,742.93,751.96z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M764.7,721.57c0.01,0.03-0.6,0.35-1.73,0.88s-2.78,1.27-4.82,2.16c-4.08,1.79-9.77,4.17-16.07,6.73
						c-6.31,2.56-12.05,4.8-16.22,6.35c-2.09,0.78-3.79,1.39-4.96,1.8c-1.17,0.41-1.83,0.61-1.85,0.57
						c-0.01-0.03,0.61-0.32,1.78-0.78c1.24-0.5,2.9-1.16,4.89-1.98c4.21-1.7,9.89-3.98,16.16-6.51c6.26-2.55,11.92-4.85,16.12-6.56
						c2-0.81,3.64-1.48,4.88-1.99C764.03,721.78,764.68,721.54,764.7,721.57z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M784.82,691.19c0.01,0.03-0.6,0.35-1.73,0.88c-1.13,0.53-2.78,1.27-4.82,2.16
						c-4.08,1.79-9.77,4.17-16.07,6.73c-6.31,2.56-12.05,4.8-16.22,6.35c-2.09,0.78-3.79,1.39-4.96,1.8s-1.83,0.61-1.85,0.57
						c-0.01-0.03,0.61-0.32,1.78-0.78c1.24-0.5,2.9-1.16,4.89-1.98c4.21-1.7,9.89-3.98,16.16-6.51c6.26-2.55,11.92-4.85,16.12-6.56
						c2-0.81,3.64-1.48,4.88-1.99C784.16,691.39,784.81,691.15,784.82,691.19z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M805.76,661.98c0.01,0.03-0.6,0.35-1.73,0.88s-2.78,1.27-4.82,2.16c-4.08,1.79-9.77,4.17-16.07,6.73
						c-6.31,2.56-12.05,4.8-16.22,6.35c-2.09,0.78-3.79,1.39-4.96,1.8c-1.17,0.41-1.83,0.61-1.85,0.57
						c-0.01-0.03,0.61-0.32,1.78-0.78c1.24-0.5,2.9-1.16,4.89-1.98c4.21-1.7,9.89-3.98,16.16-6.51c6.26-2.55,11.92-4.85,16.12-6.56
						c2-0.81,3.64-1.48,4.88-1.99C805.09,662.18,805.74,661.94,805.76,661.98z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M861.32,706.61c0.01,0.01-0.21,0.26-0.64,0.72c-0.43,0.46-1.06,1.14-1.99,1.92
						c-1.79,1.59-4.61,3.7-8.53,5.51c-3.91,1.8-8.94,3.22-14.66,3.35c-2.85,0.05-5.86-0.25-8.88-1c-3.03-0.71-6.01-1.94-8.98-3.35
						c-5.9-2.88-11.15-6.27-16.28-8.63c-5.09-2.41-10.12-3.61-14.37-3.75c-4.27-0.15-7.66,0.81-9.8,1.83
						c-1.06,0.53-1.88,0.99-2.38,1.36c-0.52,0.35-0.8,0.53-0.81,0.52s0.24-0.22,0.75-0.6c0.48-0.41,1.28-0.9,2.35-1.47
						c2.14-1.1,5.59-2.14,9.91-2.04c4.31,0.1,9.42,1.26,14.59,3.68c5.18,2.36,10.45,5.74,16.3,8.59c2.93,1.39,5.88,2.61,8.86,3.33
						c2.96,0.74,5.92,1.05,8.74,1.01c5.63-0.1,10.62-1.45,14.5-3.18c3.9-1.73,6.73-3.78,8.55-5.3c0.93-0.75,1.59-1.4,2.05-1.83
						C861.05,706.82,861.3,706.6,861.32,706.61z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M846.04,652.35c0.02,0.03-0.48,0.32-1.39,0.81c-0.91,0.49-2.27,1.14-3.98,1.87
						c-1.71,0.74-3.8,1.48-6.15,2.2c-2.36,0.69-4.99,1.32-7.79,1.79c-2.8,0.45-5.5,0.68-7.96,0.78c-2.46,0.07-4.68,0.02-6.52-0.13
						c-1.85-0.14-3.35-0.32-4.37-0.5c-1.02-0.18-1.58-0.29-1.58-0.33c0.01-0.04,0.58,0,1.6,0.11c1.03,0.12,2.52,0.23,4.36,0.32
						c1.84,0.1,4.04,0.11,6.48,0.01c2.44-0.13,5.1-0.37,7.88-0.82c2.78-0.46,5.39-1.08,7.74-1.73c2.34-0.68,4.42-1.38,6.14-2.06
						c1.72-0.67,3.1-1.25,4.04-1.69C845.49,652.54,846.02,652.32,846.04,652.35z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M768.11,774.24c-0.03,0.02-0.37-0.37-0.99-1.11c-0.68-0.81-1.56-1.85-2.6-3.08
						c-2.22-2.58-5.39-6.07-9.19-9.6c-1.9-1.77-3.83-3.3-5.76-4.43c-1.92-1.13-3.83-1.81-5.49-2.13c-3.34-0.66-5.43,0.1-5.44-0.03
						c0-0.02,0.12-0.07,0.36-0.14s0.6-0.16,1.08-0.25c0.94-0.16,2.36-0.25,4.07,0.03c1.7,0.27,3.69,0.93,5.67,2.07
						c1.99,1.12,3.97,2.69,5.88,4.47c3.82,3.57,6.96,7.11,9.11,9.77c1.08,1.33,1.91,2.44,2.47,3.22
						C767.86,773.77,768.15,774.22,768.11,774.24z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M855.66,807.4c0.01,0.02-0.13,0.13-0.43,0.33c-0.3,0.2-0.71,0.5-1.31,0.83
						c-1.15,0.7-2.89,1.6-5.15,2.45c-4.47,1.71-11.29,2.95-18.58,1.47c-7.28-1.49-13.07-5.32-16.51-8.65
						c-1.75-1.67-2.99-3.17-3.78-4.27c-0.41-0.54-0.67-0.99-0.87-1.28c-0.19-0.3-0.29-0.46-0.26-0.47c0.04-0.03,0.47,0.57,1.31,1.61
						s2.11,2.5,3.86,4.12c3.47,3.22,9.2,6.93,16.34,8.38c7.14,1.46,13.87,0.31,18.33-1.28c2.25-0.79,3.99-1.62,5.17-2.26
						C854.99,807.74,855.63,807.36,855.66,807.4z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M887.63,876.89c0,0-0.12-0.16-0.32-0.5c-0.21-0.36-0.49-0.87-0.87-1.51c-0.7-1.37-1.99-3.25-3.75-5.56
						c-0.47-0.56-0.97-1.14-1.49-1.77c-0.26-0.31-0.53-0.63-0.8-0.94c-0.31-0.3-0.61-0.6-0.93-0.91c-0.64-0.63-1.3-1.27-1.98-1.94
						c-0.74-0.61-1.51-1.25-2.3-1.92c-3.24-2.57-7.24-5.14-12.05-7.17c-4.8-2.01-10.39-3.51-16.52-3.83
						c-6.11-0.35-12.71,0.59-19.13,3.07c-6.42,2.52-12.62,6.34-19.18,10.03c-3.28,1.84-6.68,3.64-10.31,5.1
						c-3.61,1.47-7.46,2.6-11.47,3.01c-4.01,0.46-8.01,0.09-11.84-0.71c-3.83-0.81-7.46-2.16-10.9-3.72
						c-6.89-3.14-12.93-7.28-18.56-11.28c-5.64-4.01-10.95-7.87-16.25-10.96c-5.29-3.08-10.55-5.45-15.62-6.67
						c-5.07-1.21-9.84-1.4-13.95-0.92c-4.12,0.45-7.54,1.62-10.14,2.93c-2.63,1.28-4.34,2.81-5.52,3.79
						c-0.53,0.53-0.94,0.94-1.23,1.24c-0.29,0.27-0.44,0.41-0.44,0.41s0.12-0.15,0.4-0.45c0.29-0.31,0.68-0.74,1.2-1.28
						c1.16-1.01,2.85-2.58,5.5-3.9c2.6-1.35,6.05-2.57,10.2-3.05c4.14-0.53,8.96-0.35,14.08,0.85c5.13,1.21,10.44,3.57,15.76,6.65
						c5.34,3.1,10.68,6.96,16.32,10.94c5.64,3.98,11.66,8.1,18.49,11.21c3.41,1.54,7,2.89,10.79,3.68c3.78,0.79,7.72,1.15,11.66,0.7
						c3.94-0.41,7.74-1.51,11.32-2.97c3.59-1.45,6.97-3.24,10.25-5.07c6.55-3.67,12.77-7.49,19.26-10.03
						c1.64-0.6,3.24-1.2,4.89-1.58c0.82-0.21,1.62-0.46,2.45-0.63c0.82-0.13,1.65-0.27,2.46-0.41c3.25-0.54,6.46-0.57,9.55-0.44
						c6.18,0.35,11.82,1.89,16.64,3.94c4.83,2.06,8.85,4.69,12.09,7.29c0.8,0.67,1.57,1.32,2.3,1.94c0.68,0.68,1.34,1.35,1.98,1.98
						c0.32,0.32,0.63,0.63,0.92,0.92c0.27,0.33,0.54,0.65,0.8,0.97c0.52,0.63,1.01,1.22,1.48,1.79c1.75,2.35,3,4.26,3.68,5.65
						c0.35,0.67,0.63,1.17,0.82,1.55C887.56,876.7,887.63,876.89,887.63,876.89z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M854.47,922.73c0,0.02-0.19,0.07-0.56,0.14c-0.44,0.09-0.97,0.19-1.62,0.31
						c-0.7,0.14-1.57,0.29-2.58,0.37c-1.01,0.1-2.16,0.27-3.45,0.3c-0.65,0.03-1.32,0.05-2.01,0.09c-0.7,0.02-1.43-0.01-2.18-0.02
						c-1.51,0.01-3.12-0.14-4.81-0.27c-3.37-0.37-7.06-1.03-10.83-2.11c-3.76-1.12-7.22-2.55-10.27-4.04
						c-1.5-0.8-2.94-1.53-4.2-2.35c-0.64-0.41-1.27-0.76-1.85-1.15c-0.57-0.4-1.12-0.79-1.65-1.15c-1.06-0.71-1.95-1.47-2.74-2.11
						c-0.81-0.63-1.46-1.21-1.98-1.7c-0.48-0.45-0.88-0.82-1.2-1.13c-0.27-0.26-0.41-0.41-0.4-0.42c0.01-0.01,0.18,0.1,0.47,0.34
						c0.34,0.29,0.76,0.63,1.27,1.05c0.54,0.47,1.21,1.03,2.03,1.62c0.81,0.6,1.7,1.34,2.78,2.02c0.53,0.36,1.09,0.72,1.66,1.12
						c0.58,0.38,1.22,0.74,1.85,1.12c1.27,0.8,2.7,1.5,4.19,2.28c3.03,1.46,6.46,2.86,10.2,3.96c3.73,1.06,7.39,1.73,10.72,2.13
						c1.68,0.14,3.27,0.32,4.76,0.33c0.75,0.01,1.47,0.07,2.16,0.05c0.69-0.02,1.36-0.03,2-0.05c1.27,0,2.43-0.14,3.44-0.21
						c1.01-0.07,1.88-0.18,2.58-0.27c0.66-0.08,1.2-0.15,1.64-0.21C854.26,922.72,854.46,922.71,854.47,922.73z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  fill={main}
                  d="M1301.28,836.27l36.55-3.58l3.79,38.73l-36.55,3.58l6.3,64.36l-48.07,4.7l-6.3-64.36l-124.16,12.14
					l-4.07-29.33l104.65-207.53l49.66-4.86L1301.28,836.27z M1175.53,848.57l77.67-7.59l-11.48-117.4l-1.23-0.27l-3.14,8.93
					L1175.53,848.57z"
                />
              </g>
              <g>
                <path
                  fill={dark1}
                  d="M1301.28,836.27l36.55-3.58l3.79,38.73l-36.55,3.58l6.3,64.36l-48.07,4.7l-6.3-64.36l-124.16,12.14l-4.07-29.33
					l104.65-207.53l49.66-4.86L1301.28,836.27z M1175.53,848.57l77.67-7.59l-11.48-117.4l-1.23-0.27l-3.14,8.93L1175.53,848.57z"
                />
              </g>
              <g>
                <path
                  fill={light1}
                  d="M1331.85,833.29l36.55-3.58l3.79,38.73l-36.55,3.58l6.3,64.36l-48.07,4.7l-6.3-64.36l-124.16,12.14
					l-4.07-29.33L1263.98,652l49.66-4.85L1331.85,833.29z M1206.11,845.58l77.67-7.59l-11.48-117.4l-1.23-0.29l-3.14,8.93
					L1206.11,845.58z"
                />
              </g>
              <g>
                <g>
                  <path
                    fill={light1}
                    d="M1160.74,865.03c0.02,0.03-0.36,0.33-1.09,0.82c-0.71,0.5-1.77,1.21-3.07,2.06
						c-2.61,1.71-6.27,4.02-10.35,6.48c-4.09,2.46-7.84,4.6-10.58,6.1c-1.37,0.76-2.48,1.35-3.26,1.74c-0.78,0.4-1.22,0.6-1.24,0.57
						c-0.02-0.03,0.38-0.31,1.13-0.76c0.84-0.5,1.91-1.14,3.17-1.9c2.79-1.66,6.44-3.83,10.48-6.24c4.02-2.44,7.66-4.64,10.44-6.32
						c1.26-0.76,2.32-1.39,3.17-1.91C1160.3,865.21,1160.73,864.99,1160.74,865.03z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1173.8,829.97c0.02,0.03-0.52,0.43-1.51,1.1c-1,0.67-2.45,1.62-4.26,2.79
						c-3.62,2.32-8.67,5.44-14.29,8.82c-5.63,3.38-10.76,6.37-14.5,8.48c-1.88,1.05-3.4,1.9-4.46,2.46
						c-1.06,0.56-1.66,0.86-1.68,0.82c-0.02-0.03,0.54-0.4,1.57-1.01c1.12-0.67,2.59-1.55,4.37-2.61c3.76-2.25,8.82-5.28,14.41-8.62
						c5.58-3.36,10.61-6.41,14.38-8.67c1.78-1.08,3.25-1.95,4.36-2.63C1173.19,830.27,1173.78,829.94,1173.8,829.97z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1191.27,797.89c0.02,0.03-0.55,0.43-1.59,1.11c-1.04,0.68-2.57,1.65-4.47,2.82
						c-3.8,2.34-9.09,5.5-14.96,8.92c-5.88,3.41-11.25,6.44-15.17,8.57c-1.95,1.06-3.56,1.91-4.66,2.48
						c-1.11,0.57-1.73,0.87-1.76,0.83c-0.02-0.03,0.57-0.4,1.65-1.02c1.16-0.67,2.7-1.56,4.57-2.65c3.94-2.27,9.23-5.33,15.08-8.71
						c5.84-3.4,11.12-6.48,15.05-8.77c1.87-1.09,3.4-1.99,4.55-2.66C1190.64,798.19,1191.25,797.86,1191.27,797.89z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1282.52,819.12c0.02,0.03-0.37,0.32-1.09,0.8c-0.72,0.48-1.78,1.17-3.09,2.01
						c-2.63,1.67-6.3,3.91-10.4,6.3c-4.1,2.38-7.88,4.46-10.62,5.92c-1.37,0.72-2.49,1.31-3.27,1.69c-0.78,0.38-1.22,0.58-1.24,0.55
						c-0.02-0.03,0.38-0.3,1.13-0.75c0.86-0.49,1.92-1.12,3.18-1.84c2.8-1.61,6.48-3.72,10.54-6.06c4.04-2.37,7.69-4.51,10.48-6.15
						c1.26-0.74,2.33-1.35,3.18-1.85C1282.07,819.3,1282.49,819.08,1282.52,819.12z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1287.48,879.3c0.02,0.03-0.35,0.32-1.05,0.8c-0.69,0.48-1.71,1.16-2.99,1.99
						c-2.54,1.66-6.09,3.87-10.06,6.24c-3.97,2.37-7.63,4.42-10.28,5.87c-1.33,0.72-2.41,1.3-3.17,1.68
						c-0.76,0.38-1.19,0.58-1.21,0.54c-0.02-0.03,0.37-0.3,1.1-0.74c0.83-0.49,1.87-1.11,3.08-1.83c2.72-1.6,6.27-3.69,10.2-6
						c3.91-2.35,7.43-4.47,10.14-6.09c1.22-0.72,2.25-1.34,3.08-1.83C1287.04,879.49,1287.46,879.27,1287.48,879.3z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1289.95,900.3c0.02,0.03-0.35,0.32-1.05,0.8c-0.69,0.48-1.71,1.16-2.99,1.99
						c-2.54,1.66-6.09,3.87-10.06,6.24c-3.97,2.37-7.63,4.42-10.28,5.87c-1.33,0.72-2.41,1.3-3.17,1.68
						c-0.76,0.38-1.19,0.58-1.21,0.54c-0.02-0.04,0.37-0.3,1.1-0.74c0.83-0.49,1.87-1.11,3.08-1.83c2.72-1.6,6.27-3.69,10.2-6
						c3.91-2.35,7.43-4.47,10.14-6.09c1.22-0.72,2.25-1.34,3.08-1.83C1289.51,900.49,1289.92,900.27,1289.95,900.3z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1292.42,921.3c0.02,0.03-0.35,0.32-1.05,0.8c-0.69,0.48-1.71,1.16-2.99,1.99
						c-2.54,1.66-6.09,3.87-10.06,6.24c-3.97,2.37-7.63,4.42-10.28,5.87c-1.33,0.72-2.41,1.3-3.17,1.68
						c-0.76,0.38-1.19,0.58-1.21,0.54c-0.02-0.03,0.37-0.3,1.1-0.74c0.83-0.49,1.87-1.11,3.08-1.83c2.72-1.6,6.27-3.69,10.2-6
						c3.91-2.35,7.43-4.47,10.14-6.09c1.22-0.72,2.25-1.34,3.08-1.83C1291.98,921.48,1292.39,921.26,1292.42,921.3z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1279.94,796.93c0.02,0.03-0.37,0.32-1.09,0.8c-0.72,0.48-1.78,1.17-3.09,2.01
						c-2.63,1.67-6.3,3.91-10.4,6.3c-4.1,2.38-7.88,4.46-10.62,5.92c-1.37,0.72-2.49,1.31-3.27,1.69c-0.78,0.38-1.22,0.58-1.24,0.55
						c-0.02-0.03,0.38-0.3,1.13-0.75c0.86-0.49,1.92-1.12,3.18-1.84c2.8-1.61,6.48-3.72,10.54-6.06c4.04-2.37,7.69-4.51,10.48-6.15
						c1.26-0.74,2.33-1.35,3.18-1.85C1279.49,797.12,1279.92,796.89,1279.94,796.93z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1277.36,774.74c0.02,0.03-0.37,0.32-1.09,0.8c-0.72,0.48-1.78,1.17-3.09,2.01
						c-2.63,1.67-6.3,3.91-10.4,6.3c-4.1,2.38-7.88,4.46-10.62,5.92c-1.37,0.72-2.49,1.31-3.27,1.69c-0.78,0.38-1.22,0.58-1.24,0.55
						c-0.02-0.03,0.38-0.3,1.13-0.75c0.86-0.49,1.92-1.11,3.18-1.84c2.8-1.61,6.48-3.72,10.54-6.06c4.04-2.37,7.69-4.51,10.48-6.15
						c1.26-0.74,2.33-1.35,3.18-1.85C1276.92,774.93,1277.35,774.71,1277.36,774.74z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1274.79,752.56c0.02,0.03-0.35,0.31-1.03,0.77c-0.69,0.46-1.69,1.12-2.94,1.92
						c-2.5,1.59-5.99,3.73-9.9,6c-3.92,2.27-7.51,4.24-10.13,5.62c-1.32,0.69-2.38,1.24-3.13,1.6s-1.16,0.55-1.19,0.52
						s0.36-0.29,1.08-0.71c0.82-0.47,1.83-1.06,3.04-1.77c2.68-1.54,6.18-3.54,10.04-5.76c3.84-2.25,7.31-4.29,9.98-5.86
						c1.2-0.69,2.22-1.28,3.04-1.77C1274.36,752.74,1274.77,752.53,1274.79,752.56z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1274.35,731.55c0.08,0.14-2.22,1.53-5.11,3.09c-2.9,1.57-5.31,2.73-5.39,2.59
						c-0.08-0.14,2.22-1.53,5.11-3.09C1271.86,732.58,1274.27,731.42,1274.35,731.55z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1208.54,764.75c0.02,0.03-0.55,0.43-1.59,1.11c-1.04,0.68-2.57,1.65-4.47,2.82
						c-3.8,2.34-9.09,5.5-14.96,8.92c-5.88,3.41-11.25,6.44-15.17,8.57c-1.95,1.06-3.56,1.91-4.66,2.48
						c-1.11,0.57-1.73,0.87-1.76,0.83s0.57-0.4,1.65-1.02c1.16-0.67,2.7-1.56,4.57-2.64c3.94-2.27,9.23-5.33,15.08-8.71
						c5.84-3.4,11.12-6.48,15.05-8.77c1.87-1.09,3.4-1.99,4.55-2.66C1207.92,765.04,1208.53,764.71,1208.54,764.75z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1225.83,731.6c0.02,0.03-0.55,0.43-1.59,1.11c-1.04,0.68-2.57,1.65-4.47,2.82
						c-3.8,2.34-9.09,5.5-14.96,8.92c-5.88,3.41-11.25,6.44-15.17,8.57c-1.95,1.06-3.56,1.91-4.66,2.48
						c-1.11,0.57-1.73,0.87-1.76,0.83c-0.02-0.03,0.57-0.4,1.65-1.02c1.16-0.67,2.7-1.56,4.57-2.64c3.94-2.27,9.23-5.33,15.08-8.71
						c5.84-3.4,11.12-6.48,15.05-8.77c1.87-1.09,3.4-1.99,4.55-2.66C1225.2,731.9,1225.81,731.57,1225.83,731.6z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1241.48,698.69c0.02,0.03-0.55,0.43-1.59,1.11c-1.04,0.68-2.57,1.65-4.47,2.82
						c-3.8,2.34-9.09,5.5-14.96,8.92c-5.88,3.41-11.25,6.44-15.17,8.57c-1.95,1.06-3.56,1.91-4.66,2.48
						c-1.11,0.57-1.73,0.87-1.76,0.83s0.57-0.4,1.65-1.02c1.16-0.67,2.7-1.56,4.57-2.64c3.94-2.27,9.23-5.33,15.08-8.71
						c5.84-3.4,11.12-6.48,15.05-8.77c1.87-1.09,3.4-1.99,4.57-2.66C1240.84,698.98,1241.46,698.65,1241.48,698.69z"
                  />
                </g>
                <g>
                  <path
                    fill={light1}
                    d="M1258.1,666.82c0.02,0.03-0.55,0.43-1.59,1.11c-1.04,0.68-2.57,1.65-4.47,2.82
						c-3.8,2.34-9.09,5.5-14.96,8.92c-5.88,3.41-11.25,6.44-15.17,8.57c-1.95,1.06-3.56,1.91-4.66,2.48
						c-1.11,0.57-1.73,0.87-1.76,0.83c-0.02-0.03,0.57-0.4,1.65-1.02c1.16-0.67,2.7-1.56,4.57-2.64c3.94-2.27,9.23-5.33,15.08-8.71
						c5.84-3.4,11.12-6.48,15.05-8.77c1.87-1.09,3.4-1.99,4.55-2.66C1257.47,667.11,1258.08,666.78,1258.1,666.82z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M1319.38,703.2c0.01,0.01-0.16,0.29-0.54,0.8c-0.36,0.52-0.9,1.28-1.7,2.18
						c-1.55,1.82-4.05,4.31-7.67,6.65c-3.61,2.33-8.41,4.44-14.05,5.38c-2.82,0.46-5.84,0.57-8.92,0.26
						c-3.09-0.29-6.23-1.08-9.35-2.05c-6.24-2.02-11.92-4.64-17.33-6.26c-5.38-1.68-10.53-2.15-14.76-1.69
						c-4.25,0.45-7.47,1.88-9.45,3.19c-0.98,0.67-1.71,1.24-2.16,1.68c-0.46,0.42-0.72,0.64-0.74,0.63s0.21-0.25,0.66-0.7
						c0.43-0.47,1.14-1.08,2.12-1.79c1.96-1.38,5.22-2.91,9.53-3.41c4.28-0.52,9.5-0.08,14.96,1.59c5.47,1.6,11.15,4.21,17.35,6.21
						c3.11,0.98,6.19,1.76,9.24,2.04c3.04,0.32,6.01,0.21,8.79-0.22c5.56-0.89,10.31-2.93,13.91-5.19c3.61-2.27,6.12-4.69,7.73-6.45
						c0.82-0.87,1.38-1.61,1.77-2.11C1319.15,703.44,1319.37,703.19,1319.38,703.2z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M1296.62,651.63c0.02,0.03-0.43,0.38-1.26,1c-0.83,0.63-2.09,1.45-3.68,2.4
						c-1.59,0.97-3.54,2-5.77,3.04c-2.24,1.01-4.75,2.01-7.46,2.86c-2.71,0.83-5.34,1.45-7.77,1.89c-2.43,0.42-4.62,0.68-6.48,0.79
						c-1.85,0.12-3.36,0.15-4.39,0.11c-1.04-0.02-1.61-0.05-1.61-0.1c0-0.04,0.57-0.08,1.6-0.12c1.03-0.03,2.52-0.12,4.37-0.3
						c1.84-0.16,4.02-0.46,6.41-0.9c2.39-0.47,5-1.09,7.69-1.92c2.68-0.85,5.18-1.82,7.42-2.8c2.22-1,4.18-2,5.78-2.91
						c1.6-0.9,2.89-1.68,3.75-2.24C1296.1,651.89,1296.6,651.59,1296.62,651.63z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M1236.62,783.26c-0.03,0.03-0.43-0.32-1.13-0.95c-0.79-0.71-1.8-1.61-3.01-2.69
						c-2.56-2.24-6.19-5.25-10.45-8.22c-2.13-1.48-4.26-2.73-6.32-3.58c-2.06-0.85-4.05-1.25-5.73-1.34
						c-3.4-0.19-5.37,0.86-5.39,0.72c-0.01-0.02,0.11-0.09,0.34-0.2c0.23-0.11,0.57-0.25,1.03-0.4c0.91-0.3,2.3-0.58,4.04-0.54
						c1.72,0.03,3.79,0.41,5.92,1.25c2.13,0.83,4.31,2.1,6.45,3.59c4.29,2.99,7.89,6.06,10.39,8.38c1.25,1.16,2.23,2.14,2.89,2.83
						C1236.3,782.83,1236.65,783.23,1236.62,783.26z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M1327.95,803.78c0.01,0.01-0.12,0.14-0.37,0.38c-0.26,0.23-0.64,0.6-1.17,1.01
						c-1.04,0.86-2.63,1.99-4.75,3.15c-4.18,2.33-10.77,4.51-18.19,4.07c-7.42-0.45-13.69-3.44-17.56-6.24
						c-1.96-1.4-3.4-2.72-4.34-3.7c-0.48-0.47-0.8-0.89-1.04-1.14c-0.23-0.27-0.34-0.42-0.33-0.43c0.03-0.03,0.55,0.5,1.53,1.42
						c0.97,0.92,2.44,2.18,4.41,3.52c3.89,2.7,10.08,5.56,17.36,6c7.29,0.44,13.77-1.65,17.97-3.85c2.12-1.1,3.73-2.17,4.81-2.96
						C1327.35,804.21,1327.93,803.74,1327.95,803.78z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M1369.4,868.08c0,0-0.14-0.15-0.38-0.46c-0.25-0.33-0.61-0.79-1.08-1.38c-0.89-1.25-2.43-2.93-4.5-4.98
						c-0.55-0.48-1.12-1-1.72-1.54c-0.31-0.26-0.61-0.55-0.93-0.82c-0.34-0.25-0.69-0.5-1.04-0.78c-0.71-0.53-1.46-1.08-2.23-1.65
						c-0.82-0.5-1.67-1.03-2.56-1.57c-3.57-2.09-7.89-4.07-12.94-5.4c-5.04-1.32-10.79-2.02-16.89-1.47
						c-6.1,0.5-12.5,2.37-18.5,5.73c-6,3.4-11.6,8.06-17.58,12.62c-3,2.28-6.11,4.54-9.48,6.51c-3.37,1.96-7.02,3.62-10.93,4.6
						c-3.9,1.02-7.92,1.22-11.82,0.95c-3.91-0.26-7.69-1.1-11.32-2.15c-7.27-2.14-13.82-5.39-19.96-8.56
						c-6.15-3.17-11.95-6.26-17.64-8.57c-5.67-2.32-11.22-3.92-16.4-4.41c-5.19-0.48-9.94-0.01-13.94,1.05
						c-4.02,1.02-7.24,2.67-9.63,4.32c-2.43,1.64-3.9,3.39-4.93,4.52c-0.45,0.6-0.8,1.06-1.04,1.4c-0.24,0.32-0.37,0.47-0.37,0.47
						s0.1-0.18,0.33-0.5c0.24-0.34,0.57-0.82,1.01-1.44c1.01-1.15,2.47-2.95,4.89-4.63c2.38-1.71,5.63-3.4,9.67-4.46
						c4.03-1.1,8.82-1.6,14.06-1.14c5.25,0.47,10.83,2.06,16.54,4.37c5.72,2.3,11.56,5.38,17.7,8.54
						c6.15,3.15,12.68,6.38,19.88,8.49c3.59,1.04,7.34,1.87,11.19,2.13c3.84,0.25,7.8,0.05,11.64-0.94
						c3.84-0.95,7.44-2.59,10.79-4.53c3.35-1.94,6.44-4.18,9.44-6.46c5.97-4.55,11.6-9.21,17.66-12.64
						c1.53-0.82,3.04-1.64,4.62-2.25c0.78-0.32,1.55-0.68,2.33-0.97c0.8-0.25,1.59-0.49,2.37-0.75c3.15-0.99,6.32-1.47,9.39-1.78
						c6.17-0.53,11.96,0.21,17.03,1.56c5.08,1.37,9.42,3.39,12.99,5.51c0.88,0.55,1.73,1.09,2.56,1.6c0.77,0.58,1.51,1.14,2.23,1.68
						c0.35,0.26,0.7,0.53,1.04,0.79c0.32,0.29,0.63,0.57,0.93,0.83c0.6,0.55,1.17,1.06,1.72,1.56c2.06,2.09,3.57,3.8,4.43,5.07
						c0.44,0.6,0.79,1.08,1.03,1.42C1369.29,867.91,1369.4,868.08,1369.4,868.08z"
                  />
                </g>
                <g>
                  <path
                    fill={muiBackground}
                    d="M1342.99,918.13c0.01,0.02-0.18,0.1-0.53,0.22c-0.42,0.14-0.93,0.32-1.56,0.54
						c-0.68,0.24-1.51,0.5-2.5,0.74c-0.99,0.24-2.11,0.57-3.37,0.78c-0.64,0.12-1.3,0.24-1.98,0.37c-0.69,0.12-1.42,0.19-2.16,0.29
						c-1.49,0.22-3.11,0.3-4.81,0.41c-3.39,0.11-7.13-0.02-11.02-0.56c-3.89-0.58-7.51-1.5-10.73-2.56c-1.59-0.58-3.13-1.1-4.5-1.73
						c-0.69-0.31-1.37-0.57-2-0.89s-1.22-0.63-1.8-0.91c-1.16-0.55-2.14-1.19-3.02-1.69c-0.89-0.5-1.61-0.99-2.19-1.4
						c-0.54-0.38-0.99-0.69-1.35-0.95c-0.31-0.22-0.46-0.35-0.45-0.36c0.01-0.01,0.19,0.08,0.52,0.26c0.37,0.23,0.83,0.52,1.4,0.86
						c0.6,0.38,1.34,0.85,2.24,1.32c0.89,0.48,1.88,1.09,3.04,1.61c0.57,0.27,1.17,0.57,1.8,0.87s1.31,0.55,2,0.85
						c1.37,0.61,2.89,1.11,4.48,1.67c3.2,1.02,6.8,1.92,10.66,2.49c3.85,0.53,7.55,0.68,10.92,0.6c1.68-0.09,3.28-0.14,4.76-0.35
						c0.75-0.09,1.47-0.14,2.15-0.25c0.68-0.11,1.34-0.23,1.98-0.33c1.26-0.18,2.38-0.48,3.37-0.69c1-0.2,1.83-0.44,2.52-0.64
						c0.64-0.18,1.16-0.32,1.59-0.43C1342.79,918.16,1342.99,918.11,1342.99,918.13z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </SvgIcon>
    </Box>
  );
};

export default Animated404Screen;
