import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ImprintPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h4" component="h1">
        {t("siteNotice")}
      </Typography>

      <Typography variant="h5" component="h2" mt={8}>
        {t("siteNoticeInformation")}
      </Typography>

      <Typography
        mt={2}
        variant="body1"
        component="p"
        sx={{ whiteSpace: "pre-wrap" }}
      >
        SCHULZ Systemtechnik GmbH{"\n"}
        Schneiderkruger Straße 12{"\n"}
        49429 Visbek
      </Typography>
      <Typography
        variant="body1"
        component="p"
        sx={{ whiteSpace: "pre-wrap" }}
        mt={2}
      >
        {t("commercialRegister")}: HRB 110136{"\n"}
        {t("registrationCourt")}: Handelsregister Oldenburg{"\n"}
      </Typography>

      <Typography variant="body1" component="p" mt={2} fontWeight="bold">
        {t("representedBy")}:
      </Typography>
      <Typography variant="body1" component="p">
        Heinrich Schulz, Tobias Schulz, Axel Hessenkämper
      </Typography>

      <Typography variant="h5" component="h2" mt={4}>
        {t("contact")}
      </Typography>

      <Typography
        mt={2}
        variant="body1"
        component="p"
        sx={{ whiteSpace: "pre-wrap" }}
      >
        {t("phone")}: +49494445897-0{"\n"}
        {t("telefax")}: +49494445897-300{"\n"}
        {t("email")}: Info@schulz.st{"\n"}
      </Typography>

      <Typography variant="h5" component="h2" mt={4}>
        {t("vatId")}
      </Typography>

      <Typography
        mt={2}
        variant="body1"
        component="p"
        sx={{ whiteSpace: "pre-wrap" }}
      >
        {t("vatIdInfo")}:{"\n"}
        DE 811 21 59 41
      </Typography>

      <Typography variant="h5" component="h2" mt={4}>
        {t("consumerArbitrationBoardHeader")}
      </Typography>

      <Typography
        mt={2}
        variant="body1"
        component="p"
        sx={{ whiteSpace: "pre-wrap" }}
      >
        {t("consumerArbitrationBoardInfo")}
      </Typography>
    </>
  );
};

export default ImprintPage;
