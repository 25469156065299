import { Button, Tooltip } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface DrawerButtonProps {
  children: React.ReactNode;
  link: string;
  title: string;
}

const DrawerButton: React.FC<DrawerButtonProps> = (props) => {
  const { children, link, title } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const onClickHandler = useCallback(() => navigate(link), [navigate, link]);

  return (
    <Tooltip title={t(title)} arrow placement="right">
      <Button
        onClick={onClickHandler}
        className="animatableDrawerButton"
        sx={{
          backgroundColor: "transparent",
          maxWidth: "45px",
          maxHeight: "45px",
          minWidth: "45px",
          minHeight: "45px",
          border: "0px",
          borderRadius: "15%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default DrawerButton;
