import React from "react";
import Badge from "@mui/material/Badge";
import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "hooks/use-api-client";

export type NotificationData = {
  id: string;
  occurredOn: Date;
  message: string;
  url?: string;
  readOn?: Date;
  archivedOn?: Date;
};

const NotificationsBadge: React.FC<{ children: React.ReactNode }> = (props) => {
  const { getNotifications } = useApiClient();
  const { data: notifications } = useQuery(
    ["getNotifications"],
    getNotifications
  );

  const numOfNotifications = notifications?.length ?? 0;
  const hasNotifications = numOfNotifications > 0;

  return (
    <Badge
      badgeContent={numOfNotifications}
      color="primary"
      invisible={!hasNotifications}
    >
      {props.children}
    </Badge>
  );
};

export default NotificationsBadge;
