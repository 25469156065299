import React, { useEffect, useMemo, useState } from "react";
import { AppBar, useScrollTrigger } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { keyframes, Keyframes } from "@emotion/react";

interface StyledDesktopAppbarProps {
  children: React.ReactNode;
}

interface blurOnScrollStyles {
  background?: string;
  color: string;
  animation?: string;
  width?: string;
  marginLeft?: string;
}

const getSxProps = (
  theme: Theme,
  isScrolled: boolean | null,
  blurAppbar: Keyframes,
  resetAppbar: Keyframes
) => {
  let blurOnScroll: blurOnScrollStyles = isScrolled
    ? {
        animation: `${blurAppbar} 300ms 0s forwards`,
        color: theme.palette.text.primary,
      }
    : isScrolled === null
    ? {
        background: theme.palette.frame,
        color: theme.palette.text.primary,
      }
    : {
        animation: `${resetAppbar} 300ms 0s forwards`,
        color: theme.palette.text.primary,
      };

  blurOnScroll.width = `calc(100% - ${theme.drawerWidth}px)`;
  blurOnScroll.marginLeft = `${theme.drawerWidth}px`;

  return blurOnScroll;
};

const StyledDesktopAppbar: React.FC<StyledDesktopAppbarProps> = ({
  children,
}) => {
  const [isScrolled, setIsScrolled] = useState<boolean | null>(null);
  const scrollTrigger = useScrollTrigger({
    threshold: 20,
    disableHysteresis: true,
  });
  const theme = useTheme();

  useEffect(() => {
    if (scrollTrigger) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [scrollTrigger]);

  const { blurAppbar, resetAppbar } = useMemo(() => {
    const blurAppbar = keyframes`
      from {
        backdrop-filter: blur(0px);
        background: ${theme.palette.frame};
      }
      to {
        backdrop-filter: blur(2px);
      }`;

    const resetAppbar = keyframes`
      from {
        backdrop-filter: blur(2px);
      }
      to {
        backdrop-filter: blur(0px);
        background: ${theme.palette.frame};
      }`;

    return { blurAppbar, resetAppbar };
  }, [theme.palette.frame]);

  return (
    <AppBar
      position="fixed"
      elevation={0}
      color="transparent"
      sx={(theme) => getSxProps(theme, isScrolled, blurAppbar, resetAppbar)}
    >
      {children}
    </AppBar>
  );
};

export default StyledDesktopAppbar;
