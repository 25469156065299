import { Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { useMobileBreakpoint } from "hooks/use-breakpoint";
import { useAuth0 } from "@auth0/auth0-react";
import AnimatableLogoutIcon from "./AnimatableLogoutIcon";
import { keyframes } from "@mui/system";

const logoutArrowAnimationIn = keyframes`
  0% {
    transform: translate(0rem,0rem);
  }
  
  100% {
    transform: translate(0.5rem,0rem);
  }`;

const logoutArrowAnimationOut = keyframes`
0% {
  transform: translate(0.5rem,0rem);
}

100% {
  transform: translate(0rem,0rem);
}`;

const logoutButtonAnimationIn = keyframes`
  0% {
    transform: scale(1);
  }
  
  100% {
    transform: scale(1.05);
  }`;

const logoutButtonAnimationOut = keyframes`
0% {
  transform: scale(1.05);
}

100% {
  transform: scale(1);
}`;

const AnimatedLogoutButton = () => {
  const isMobile = useMobileBreakpoint();
  const { logout } = useAuth0();
  const theme = useTheme();
  const textColor = theme.palette.text.primary;
  const primaryColor = theme.palette.primary.main;

  const logoutHandler = () => {
    logout({
      returnTo: `${window.location.origin}/`,
    });
  };

  const logoutFontColorAnimationIn = keyframes`
      0% {
        color: ${textColor};
      }

      100% {
          color: ${primaryColor};
      }`;

  const logoutFontColorAnimationOut = keyframes`
      0% {
          color: ${primaryColor};
      }

      100% {
          color: ${textColor};
      }`;

  return (
    <>
      {isMobile && (
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={`${isMobile ? "center" : "right"}`}
        marginBottom={"2rem"}
        marginTop={`${isMobile ? "1rem" : "0"}`}
      >
        <Button
          variant="outlined"
          color="inherit"
          onClick={logoutHandler}
          sx={{
            "&:hover": {
              animation: `${logoutButtonAnimationIn} 300ms 0s forwards`,
              "& .logoutArrowAnimation": {
                animation: `${logoutArrowAnimationIn} 300ms 0s forwards`,
              },
              "& .logoutFontColorAnimation": {
                animation: `${logoutFontColorAnimationIn} 300ms 0s forwards`,
              },
            },
            animation: `${logoutButtonAnimationOut} 300ms 0s forwards`,
            "& .logoutArrowAnimation": {
              animation: `${logoutArrowAnimationOut} 300ms 0s forwards`,
            },
            "& .logoutFontColorAnimation": {
              animation: `${logoutFontColorAnimationOut} 300ms 0s forwards`,
            },
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <AnimatableLogoutIcon />
          <Typography
            className="logoutFontColorAnimation"
            color={textColor}
            style={{
              marginLeft: "3rem",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            Logout
          </Typography>
        </Button>
      </Grid>
    </>
  );
};

export default AnimatedLogoutButton;
