import { useContext } from "react";
import DrawerContext from "context/DrawerContext";

export const useDrawer = () => {
  const drawerContext = useContext(DrawerContext);
  const { isOpen, setIsOpen } = drawerContext;

  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return { isOpen, toggleDrawer, closeDrawer };
};
