import { Box } from "@mui/material";
import React from "react";
import MenuSectionBody from "./MenuSectionBody";
import MenuSectionFooter from "./MenuSectionFooter";
import MenuSectionHeader from "./MenuSectionHeader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FactoryIcon from "@mui/icons-material/Factory";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Role } from "types/Common";
import ArchiveIcon from "@mui/icons-material/Archive";
// import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

export interface MenuSectionItem {
  id: string;
  text: string;
  path: string;
  icon: React.FC;
}

export type MenuSection = {
  id: string;
  permission: Role[];
  links: MenuSectionItem[];
};

const menuSections: MenuSection[] = [
  {
    id: "dashboards-8697843d",
    permission: ["Admin", "User", "ServiceTechnician"],
    links: [
      {
        id: "dashboard-eafe48cc",
        text: "dashboard",
        path: "/dashboard",
        icon: DashboardIcon,
      },
      {
        id: "devices-d538c334",
        text: "devices",
        path: "/devices",
        icon: ListAltIcon,
      },
      {
        id: "alarms-0d551a29",
        text: "alarms",
        path: "/alarms",
        icon: ErrorOutlineIcon,
      },
      {
        id: "export-c315eb03",
        text: "export",
        path: "/export",
        icon: ArchiveIcon,
      },
    ],
  },
  {
    id: "tools-a438e987",
    permission: ["Admin", "ServiceTechnician"],
    links: [
      {
        id: "organization-43139201",
        text: "organization",
        path: "/organization",
        icon: FactoryIcon,
      },
      // {
      //   id: "devicemanager-8590mu83",
      //   text: "deviceManger",
      //   path: "/device-manager",
      //   icon: BusinessCenterIcon,
      // },
    ],
  },
];

const MenuSectionList: React.FC = () => {
  return (
    <Box sx={{ mx: 1, mt: 1, height: "100%", overflow: "hidden" }}>
      <MenuSectionHeader />
      <MenuSectionBody menuSections={menuSections} />
      <MenuSectionFooter />
    </Box>
  );
};

export default MenuSectionList;
