import { InputAdornment, TextField, Tooltip, useTheme } from "@mui/material";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import { useTranslation } from "react-i18next";
import React from "react";

interface LanguageSelectFieldProps {
  short?: boolean;
  onChange?: (newLanguage: string) => void;
  children?: React.ReactNode;
  value?: string;
  variant: "short" | "outlined" | "standard";
}

const LanguageSelectField: React.FC<LanguageSelectFieldProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const InputProps =
    props.variant === "short"
      ? {
          disableUnderline: true,
        }
      : props.variant === "standard"
      ? {
          startAdornment: (
            <InputAdornment position="start">
              <TranslateOutlinedIcon />
            </InputAdornment>
          ),
          disableUnderline: true,
        }
      : {
          startAdornment: (
            <InputAdornment position="start">
              <TranslateOutlinedIcon />
            </InputAdornment>
          ),
        };

  return (
    <Tooltip placement="left" arrow title={t("language")}>
      <TextField
        select
        id="appbarLanguageSelector"
        aria-label="Language selector"
        InputProps={InputProps}
        value={props.value ? props.value : t("language")}
        variant={props.variant !== "short" ? props.variant : "standard"}
        onChange={(event) =>
          props.onChange
            ? event.target.value && props.onChange(event.target.value)
            : null
        }
        sx={{
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.secondary.main,
            },
          },
        }}
      >
        {props.children}
      </TextField>
    </Tooltip>
  );
};

export default LanguageSelectField;
