import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { ThemeContextProvider } from "context/ThemeContext";
import I18nProvider from "features/i18n/I18nProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Auth0ProviderWithNavigation from "features/auth/Auth0ProviderWithNavigation";
import { LicenseInfo } from "@mui/x-license-pro";

const muiXLicenseKey: string = process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY!;
LicenseInfo.setLicenseKey(muiXLicenseKey);

const container = document.getElementById("root");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
    },
  },
});
if (container) {
  const root = createRoot(container);

  root.render(
    <QueryClientProvider client={queryClient}>
      <ThemeContextProvider>
        <I18nProvider>
          <BrowserRouter>
            <Auth0ProviderWithNavigation>
              <React.StrictMode>
                <App />
              </React.StrictMode>
            </Auth0ProviderWithNavigation>
          </BrowserRouter>
        </I18nProvider>
      </ThemeContextProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
