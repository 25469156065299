import React from "react";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Skeleton,
  Grid,
} from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { toStartOfDay, toEndOfDay } from "services/date-helpers";
import ChipsSelect from "components/inputs/ChipsSelect";
import { Device } from "types/Devices";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DateSelect from "components/inputs/DateSelect/DateSelect";

export interface DeviceDetailsFilterState {
  rangeFilter?: {
    range: DateRange<Date>;
  };
  devicesFilter?: {
    selectedDeviceOptions: Device[] | null;
  };
}

interface DetailsPageFilterCardProps {
  /**
   * Indicating whether the parant component is loading
   */
  loading: boolean;
  minDate?: Date;
  maxDate?: Date;
  range: DateRange<Date>;
  deviceOptions: Device[];
  selectedDeviceOptions: Device[] | null;
  onFilterChanged: (state: DeviceDetailsFilterState) => void;
}

const DeviceDetailsFilterCard: React.FC<DetailsPageFilterCardProps> = ({
  loading,
  range,
  minDate,
  maxDate,
  deviceOptions,
  selectedDeviceOptions,
  onFilterChanged,
}) => {
  return (
    <Card
      // sx={{ px: 1, pt: 1, pb: 0 }}
      variant="outlined"
    >
      <CardHeader
        avatar={<FilterAltOutlinedIcon color="secondary" />}
        title="Filter-Menu"
        titleTypographyProps={{ fontSize: "1rem" }}
      />
      <CardContent sx={{ paddingTop: 1, paddingBottom: 0 }}>
        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              direction: "column",
            }}
          >
            <Skeleton variant="rectangular" width={160} height={32} />
            <Skeleton variant="text" width={32} height={32} sx={{ mx: 1 }} />
            <Skeleton variant="rectangular" width={160} height={32} />
          </Box>
        )}
        {!loading && (
          <Grid container>
            <Grid item xs={12}>
              <DateSelect
                range={range}
                onChange={(newValue: DateRange<Date>) => {
                  if (newValue[0] && newValue[1]) {
                    onFilterChanged({
                      rangeFilter: {
                        range: [
                          toStartOfDay(newValue[0]),
                          toEndOfDay(newValue[1]),
                        ],
                      },
                    });
                  }
                }}
              />
            </Grid>

            {deviceOptions.length > 1 && (
              <Grid item xs={12}>
                <ChipsSelect
                  options={deviceOptions}
                  selected={selectedDeviceOptions}
                  display={(d) => d.name}
                  onSelectedOptionsChange={(selectedDeviceOptions) => {
                    onFilterChanged({
                      devicesFilter: {
                        selectedDeviceOptions,
                      },
                    });
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default DeviceDetailsFilterCard;
