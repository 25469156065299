import React from "react";
import { DevicesFilterContextProvider } from "context/DevicesFilterContext";
import { LocationFilterContextProvider } from "context/LocationFilterContext";
import DevicesPageFilterAndGrid from "features/devices/DevicesPageFilterAndGrid";

const DevicesPage: React.FC = () => {
  return (
    <DevicesFilterContextProvider>
      <LocationFilterContextProvider>
        <DevicesPageFilterAndGrid />
      </LocationFilterContextProvider>
    </DevicesFilterContextProvider>
  );
};

export default DevicesPage;
