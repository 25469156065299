import { calculateUnitScale } from "services/chart-helpers";
import { roundDecimalPlaces } from "./math-helpers";

export interface UnitConverter {
  convertFromBaseUnit: (
    value: number,
    unit: string
  ) => { value: number; unit: string };
}

export class JouleConverter implements UnitConverter {
  convertFromBaseUnit = (value: number, unit: string) => {
    const wattHour = roundDecimalPlaces(value / 3600, 2);
    const unitScale = calculateUnitScale(wattHour, "Wh");

    return {
      value: roundDecimalPlaces(wattHour / unitScale.divideBy, 1),
      unit: unitScale.unit,
    };
  };
}

export class PercentConverter implements UnitConverter {
  convertFromBaseUnit = (value: number, unit: string) => {
    return {
      value: roundDecimalPlaces(value * 100, 1),
      unit: "%",
    };
  };
}

export class WattConverter implements UnitConverter {
  convertFromBaseUnit = (value: number, unit: string) => {
    const unitScale = calculateUnitScale(value, "W");
    return {
      value: roundDecimalPlaces(value / unitScale.divideBy, 1),
      unit: unitScale.unit,
    };
  };
}
