import React from "react";
import { useMobileBreakpoint } from "hooks/use-breakpoint";
import MobileAppBar from "./MobileAppBar/MobileAppBar";
import DesktopAppBar from "./DesktopAppBar";

const ResponsiveAppBar: React.FC = (props) => {
  const isMobile = useMobileBreakpoint();
  const appBar = isMobile ? <MobileAppBar /> : <DesktopAppBar />;

  return appBar;
};

export default ResponsiveAppBar;
