import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import React from "react";

const ChartSkeleton = () => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Stack direction="row">
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton height={30} width={200} sx={{ ml: 1 }} />
        </Stack>
        <Skeleton height={200} />
      </CardContent>
    </Card>
  );
};

export default ChartSkeleton;
