import React, { useState, useEffect } from "react";
import { useMobileBreakpoint } from "hooks/use-breakpoint";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { drawerShouldBeClosedBreakpoint } from "utils/constants/Layout";

type DrawerContextModel = {
  isOpen: boolean;
  setIsOpen: (prev: React.SetStateAction<boolean>) => void;
};

const DrawerContext = React.createContext<DrawerContextModel>({
  isOpen: false,
  setIsOpen: () => false,
});

export default DrawerContext;

export const DrawerContextProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const theme = useTheme();
  const isMobile = useMobileBreakpoint();
  const isClosedDefault = useMediaQuery(
    theme.breakpoints.down(drawerShouldBeClosedBreakpoint)
  );
  const [isOpen, setIsOpen] = useState(!isMobile);

  useEffect(() => {
    setIsOpen(!isMobile && !isClosedDefault);
  }, [isMobile, isClosedDefault]);

  const drawerContext = {
    isOpen,
    setIsOpen,
  };

  return (
    <DrawerContext.Provider value={drawerContext}>
      {props.children}
    </DrawerContext.Provider>
  );
};
