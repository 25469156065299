import { Icon, Tooltip, Chip } from "@mui/material";
import React from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import BoltIcon from "@mui/icons-material/Bolt";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import OpacityIcon from "@mui/icons-material/Opacity";
import { useTranslation } from "react-i18next";
import { DeviceErrorCategory, LogLevel } from "types/Alarms";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import GroupIcon from "@mui/icons-material/Group";
import CableIcon from "@mui/icons-material/Cable";
import FactoryIcon from "@mui/icons-material/Factory";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SecurityIcon from "@mui/icons-material/Security";

const codeToIcon = (code: DeviceErrorCategory) => {
  switch (code) {
    case "Unknown":
      return { icon: <QuestionMarkIcon />, descriptionKey: "Unknown" };
    case "ElectricalError":
      return { icon: <BoltIcon />, descriptionKey: "ElectricalError" };
    case "OverTemperature":
      return {
        icon: <ThermostatIcon />,
        descriptionKey: "OverTemperature",
      };
    case "UnderTemperature":
      return { icon: <AcUnitIcon />, descriptionKey: "UnderTemperature" };
    case "Humidity":
      return { icon: <OpacityIcon />, descriptionKey: "Humidity" };
    case "Fire":
      return { icon: <LocalFireDepartmentIcon />, descriptionKey: "Fire" };
    case "EmergencyOff":
      return { icon: <CloseIcon />, descriptionKey: "EmergencyOff" };
    case "GenericDeviceError":
      return {
        icon: <ErrorOutlineIcon />,
        descriptionKey: "GenericDeviceError",
      };

    case "GroupNotification":
      return {
        icon: <GroupIcon />,
        descriptionKey: "GroupNotification",
      };

    case "Communication":
      return {
        icon: <CableIcon />,
        descriptionKey: "Communication",
      };

    case "Operation":
      return {
        icon: <FactoryIcon />,
        descriptionKey: "Operation",
      };

    case "Disturbance":
      return {
        icon: <ErrorOutlineIcon />,
        descriptionKey: "Disturbance",
      };

    case "Maintenance":
      return {
        icon: <EngineeringIcon />,
        descriptionKey: "Maintenance",
      };

    case "Security":
      return {
        icon: <SecurityIcon />,
        descriptionKey: "Security",
      };

    default:
      return { icon: <QuestionMarkIcon />, descriptionKey: "Unknown" };
  }
};

const logLevelToColor = (logLevel: LogLevel) => {
  switch (logLevel) {
    case "Info":
      return "info";
    case "Warning":
      return "warning";
    case "Error":
    case "Critical":
    default:
      return "error";
  }
};

interface AlarmStatusIconProps {
  code: DeviceErrorCategory;
  level: LogLevel;
}

const AlarmStatusIcon: React.FC<AlarmStatusIconProps> = ({ code, level }) => {
  const { t } = useTranslation();
  const iconWithTooltip = codeToIcon(code);
  const color = logLevelToColor(level);
  return (
    <Tooltip title={t(iconWithTooltip.descriptionKey)}>
      <Chip
        variant="outlined"
        size="small"
        color={color}
        label={<Icon color={color}>{iconWithTooltip.icon}</Icon>}
      ></Chip>
    </Tooltip>
  );
};

export default AlarmStatusIcon;
